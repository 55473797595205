import type {Placement} from "@floating-ui/core";
import {randomHex} from "pg-isomorphic/utils";
import type {Directive, DirectiveBinding} from "vue";
import mitt from "mitt";

const GraphiteTooltipDirective: Directive = {
  mounted: handleDirectiveUpdate,
  updated: handleDirectiveUpdate,
  unmounted(el) {
    tooltipEmitter.emit(TooltipEvent.Destroy, el.getAttribute("data-tooltip-id"));
  },
};

function handleDirectiveUpdate(el: HTMLElement, binding: DirectiveBinding) {
  const existing = el.getAttribute("data-tooltip-id");
  if (existing) {
    tooltipEmitter.emit(TooltipEvent.Destroy, existing);
  }

  const newId = existing || randomHex();
  el.setAttribute("data-tooltip-id", newId);
  tooltipEmitter.emit(TooltipEvent.Create, {
    id: newId,
    trigger: el,
    content: binding.value,
    placement: getPlacement(binding.modifiers),
    maxWidth: getMaxWidth(binding.modifiers) || 400,
  });
}

export default GraphiteTooltipDirective;

export interface DynamicTooltip {
  id: string;
  trigger: HTMLElement;
  placement?: Placement;
  maxWidth?: number | string;
  content: string;
}

export enum TooltipEvent {
  Create = "Create",
  Destroy = "Destroy",
}

export type TooltipEvents = {
  [TooltipEvent.Create]: DynamicTooltip;
  [TooltipEvent.Destroy]: DynamicTooltip["id"];
};

export const tooltipEmitter = mitt<TooltipEvents>();

function getPlacement(modifiers: Record<string, boolean>): Placement | undefined {
  // first instance overrides
  const keys = Object.keys(modifiers);
  for (const key of keys) {
    switch (key.toLowerCase()) {
      case "top":
        return "top";
      case "right":
        return "right";
      case "bottom":
        return "bottom";
      case "left":
        return "left";
      case "top-start":
      case "topstart":
        return "top-start";
      case "right-start":
      case "rightstart":
        return "right-start";
      case "bottom-start":
      case "bottomstart":
        return "bottom-start";
      case "left-start":
      case "leftstart":
        return "left-start";
      case "top-end":
      case "topend":
        return "top-end";
      case "right-end":
      case "rightend":
        return "right-end";
      case "bottom-end":
      case "bottomend":
        return "bottom-end";
      case "left-end":
      case "leftend":
        return "left-end";
    }
  }

  return;
}

function getMaxWidth(bindings): number | undefined {
  const keys = Object.keys(bindings);
  for (const key of keys) {
    if (key.toLowerCase().startsWith("maxwidth") || key.toLowerCase().startsWith("max-width")) {
      return +key.replace(/max-?width-?/i, "");
    }
  }

  return;
}
