import type {ConnectionWithNames, EntityTaskCounts} from "../api/connection";
import type {BasicEntityInfo} from "../api/entity";
import {ConnectionParty, ConnectionRole, Stage} from "../enums";
import {compareStages, stageNumber} from "../enums/connections";
import {extractId} from "../utils";

export function connectionParty(connection: ConnectionWithNames, entity: BasicEntityInfo): ConnectionParty | undefined {
  if (entity.id === connection.requestingEntity) {
    return ConnectionParty.REQUESTING;
  } else if (entity.id === connection.respondingEntity) {
    return ConnectionParty.RESPONDING;
  }
  return undefined;
}

export function getTaskCounts(
  connection: ConnectionWithNames,
  entity: BasicEntityInfo,
): {entityTaskCounts: EntityTaskCounts; counterpartyTaskCounts: EntityTaskCounts} {
  if (
    !connection.taskCounts ||
    !(connection.taskCounts.requestingEntityTasks || connection.taskCounts.respondingEntityTasks)
  ) {
    throw new Error(`No task counts available on this connection ${connection._id}`);
  }

  switch (connectionParty(connection, entity)) {
    case ConnectionParty.REQUESTING:
      return {
        entityTaskCounts: connection.taskCounts.requestingEntityTasks || {},
        counterpartyTaskCounts: connection.taskCounts.respondingEntityTasks || {},
      };

    case ConnectionParty.RESPONDING:
      return {
        entityTaskCounts: connection.taskCounts.respondingEntityTasks || {},
        counterpartyTaskCounts: connection.taskCounts.requestingEntityTasks || {},
      };

    default:
      throw new Error(`Entity ${entity} is not part of this connection`);
  }
}

export type VisibleStages = number;
export const stageBit = (stage: Stage): number => {
  // tslint:disable-next-line:no-bitwise
  return stage === Stage.PRIVATE ? 0 : 1 << stageNumber[stage];
};

export const isVisibleAt = (visibleStages: VisibleStages, stage: Stage): boolean => {
  // tslint:disable-next-line:no-bitwise
  return Boolean(visibleStages & stageBit(stage));
};

export const firstVisibleAt = (visibleStages: VisibleStages, stage: Stage): boolean => {
  for (const s of Object.values(Stage).filter((s1) => s1 !== Stage.PUBLIC)) {
    if (isVisibleAt(visibleStages, s)) {
      return s === stage;
    }
  }
  return false;
};

export const unionVisibleStages = (visibleStages: VisibleStages[]): VisibleStages => {
  return visibleStages.reduce<VisibleStages>((a, b) => {
    // tslint:disable-next-line:no-bitwise
    return a | b;
  }, 0);
};

// For testing and migrations. This takes a Stage/name and returns a VisibleStages that
// starts at that stage and remains visible all the way through. i.e., old style
export function visibleFrom(stage: Stage | string): VisibleStages {
  let result = 0;
  for (const s of Object.values(Stage)) {
    if (compareStages(s, stage as Stage) >= 0) {
      // tslint:disable-next-line:no-bitwise
      result |= stageBit(s);
    }
  }
  return result;
}

export function visibleStagesToStages(visibleStages: VisibleStages): Stage[] {
  const result: Stage[] = [];
  for (const stage of Object.values(Stage)) {
    if (isVisibleAt(visibleStages, stage)) {
      result.push(stage);
    }
  }
  return result;
}

export function getConnectionRole(
  connection: ConnectionWithNames | undefined,
  entity: string,
): ConnectionRole | undefined {
  if (!connection) {
    return undefined;
  }
  if (extractId(entity) === extractId(connection.requestingEntity)) {
    return connection.requestingRole;
  } else if (extractId(entity) === extractId(connection.respondingEntity)) {
    return connection.respondingRole;
  } else {
    return undefined;
  }
}

export function isRegisteringForCampaigns(entityId: string, connection?: ConnectionWithNames): boolean {
  if (!connection) {
    return false;
  }

  const isSellerOwnedCampaign =
    connection.requestingRole === ConnectionRole.BUYER && (connection.buyerCampaignRegistrations?.length || 0) > 0;
  const isBuyerOwnedCampaign =
    connection.requestingRole === ConnectionRole.SELLER && (connection.campaignRegistrations?.length || 0) > 0;

  const entityRole = getConnectionRole(connection, entityId);

  return (
    (isSellerOwnedCampaign && entityRole === ConnectionRole.BUYER) ||
    (isBuyerOwnedCampaign && entityRole === ConnectionRole.SELLER)
  );
}

/**
 * Currently only relevant for campaigns but is safe to ALWAYS use.
 *   For campaigns: the "requesting" entity is actually the responding entity and it's flipped when they make a connection.
 */
export function getOwningEntity(connection: ConnectionWithNames): string;
export function getOwningEntity(connection: undefined): undefined;
export function getOwningEntity(connection?: ConnectionWithNames): string | undefined;
export function getOwningEntity(connection?: ConnectionWithNames): string | undefined {
  if (!connection) {
    return undefined;
  }

  if (isRegisteringForCampaigns(extractId(connection.requestingEntity), connection)) {
    return connection.respondingEntity;
  } else {
    return connection.requestingEntity;
  }
}
