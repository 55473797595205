import set from "lodash/set";
import get from "lodash/get";
import {mergeDeepRight} from "ramda";

// Recursively walk all modules looking for a special module field "persistedFields" and
// builds a Map<String, Boolean> from the full field path (e.g. signUp/country)
function findAllPersistedPaths(store) {
  const paths = {};
  const walk = (module, moduleStack) => {
    if (module._rawModule && module._rawModule.persistedFields) {
      module._rawModule.persistedFields.forEach((f) => {
        paths[moduleStack.concat([f]).join("/")] = true;
      });
    }
    if (module._children) {
      Object.keys(module._children).map((childName) => {
        const child = module._children[childName];
        walk(child, moduleStack.concat([childName]));
      });
    }
  };
  walk(store._modules.root, []);
  return paths;
}

// Store only fields listed in a module's `persistedFields` array in sessionStorage
export default function (store) {
  const storageKey = "graphite-app";
  const storage = window.sessionStorage;

  const getState = () => {
    try {
      const item = storage.getItem(storageKey);
      if (typeof item !== "undefined") {
        return JSON.parse(item);
      }
    } catch (e) {
      /*empty*/
    }
    return {};
  };

  const setState = (state) => {
    try {
      return storage.setItem(storageKey, JSON.stringify(state));
    } catch (e) {
      console.error(e);
    }
  };

  const savedState = getState();
  if (typeof savedState === "object" && savedState !== null) {
    store.replaceState(mergeDeepRight(store.state, savedState));
  }

  const paths = findAllPersistedPaths(store);
  // lodash's deep get,set want dot notation
  const pathList = Object.keys(paths).map((p) => p.replace("/", "."));

  // Return only the persisted fields of the state
  const reduce = (state) => {
    return pathList.reduce((subState, path) => set(subState, path, get(state, path)), {});
  };

  store.subscribe((mutation, state) => {
    if (paths[mutation.type]) {
      const subState = reduce(state);
      setState(subState);
    }
  });
}
