import cloneDeep from "lodash/cloneDeep";
import type {JSONArray} from "pg-isomorphic/utils";
import querystring from "querystring";
import globalLogger from "../../../logging";
import type {PGActionContext, PGActionTree} from "../../index-types";
import {autoMutations} from "../../utils";

const logger = globalLogger.getLogger("redirects");

export interface RedirectsState {
  requestingEntity: string;
  redirects: JSONArray;
  total: number;
  loading: boolean;
}

type Ctx = PGActionContext<RedirectsState>;

const initialState: RedirectsState = {
  requestingEntity: "",
  redirects: [],
  total: 0,
  loading: false,
};

const actions: PGActionTree<RedirectsState> = {
  async getRedirects({commit}: Ctx, args: {entityId: string; page: number; limit: number; search: string}) {
    commit("requestingEntity", args.entityId);
    commit("loading", true);

    const query = querystring.stringify({page: args.page, limit: args.limit, search: args.search});
    const response = await this.httpGet<{redirects: string[]; total: number}>(
      `/api/admin/redirects/${args.entityId}?${query}`,
    );

    commit("redirects", response.data.redirects);
    commit("total", response.data.total);
    commit("loading", false);
  },

  async undoRedirect({state, dispatch, commit}: Ctx, deletedEntityId: string) {
    commit("loading", true);
    logger.trace(() => `undoing redirect for entity ${deletedEntityId} for ${state.requestingEntity}`);

    await this.httpPost<{}>(`/api/admin/redirects/undo/${deletedEntityId}`, {});
    await dispatch("getRedirects", state.requestingEntity);
  },
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: autoMutations(initialState),
  actions,
};
