import axios from "axios";
import globalLogger from "../../logging";
import {path} from "ramda";
import * as Sentry from "@sentry/browser";

const logger = globalLogger.getLogger("axios-http-util");

export default (store) => {
  const getHeaders = () => {
    // see ./auth.js
    const headers = {};
    if (store.state.JWT) {
      headers.Authorization = `Bearer ${store.state.JWT}`;
    }
    return headers;
  };

  const httpRequest = async (method, {url, params, data}) => {
    try {
      // store.commit("apiError", null, {root: true});
      return await axios({
        method,
        url,
        headers: getHeaders(),
        data,
        params,
      });
    } catch (e) {
      // store.commit("apiError", e, {root: true});
      // store.commit('loading', false, {root: true});
      globalLogger.error(
        () => `HTTP ${e.response.status} (${e.response.statusText}) error: ${method} ${url} ${JSON.stringify(params)}`,
        path(["response"], e),
      );
      if (e.response && e.response.status === 401) {
        // redirect to login if unauthorized
        setTimeout(() => {
          logger.trace(() => `requested ${url} resulted in 401; current ${window.location.pathname}`);
          if (!["/signin", "/responder/signin"].includes(window.location.pathname)) {
            window.location.href = "/signin?r=httpauth&auth=false";
          }
        }, 200);
      } else {
        Sentry.captureException(e);
      }
      throw e;
    }
  };

  store.httpGet = async (url, params) => httpRequest("GET", {url, params});

  store.httpPost = async (url, data) => httpRequest("POST", {url, data});

  store.httpPatch = async (url, data) => httpRequest("PATCH", {url, data});

  store.httpPut = async (url, data) => httpRequest("PUT", {url, data});

  store.httpDelete = async (url, params) => httpRequest("DELETE", {url, params});
};
