import {clone} from "ramda";

export default function (store) {
  const initialState = clone(store.state);
  store.subscribe((mutation) => {
    if (mutation.type === "resetAll") {
      const newState = clone({
        ...initialState,
        // don't reset the route stuff
        currentRoute: store.state.currentRoute,
        previewRoute: store.state.previewRoute,
      });
      store.replaceState(newState);
    }
  });
}
