export interface Logger {
  error(msg: string | (() => string), error?: Error | (() => Error | null) | null): void;
  warn(msg: string | (() => string), error?: Error | (() => Error | null) | null): void;
  info(msg: string | (() => string), error?: Error | (() => Error | null) | null): void;
  debug(msg: string | (() => string), error?: Error | (() => Error | null) | null): void;
  trace(msg: string | (() => string), error?: Error | (() => Error | null) | null): void;
}

class DeferredLogger {
  private logger?: Logger;
  private deferredMessages: Array<() => void> = [];
  constructor(readonly name: string, readonly factory: DeferredLoggerFactory) {}

  public error(msg: string | (() => string), error?: Error | (() => Error | null) | null): void {
    this.defer(() => this.logger!.error(msg, error));
  }

  public warn(msg: string | (() => string), error?: Error | (() => Error | null) | null): void {
    this.defer(() => this.logger!.warn(msg, error));
  }

  public info(msg: string | (() => string), error?: Error | (() => Error | null) | null): void {
    this.defer(() => this.logger!.info(msg, error));
  }

  public debug(msg: string | (() => string), error?: Error | (() => Error | null) | null): void {
    this.defer(() => this.logger!.debug(msg, error));
  }

  public trace(msg: string | (() => string), error?: Error | (() => Error | null) | null): void {
    this.defer(() => this.logger!.trace(msg, error));
  }

  private checkLogger() {
    if (this.logger) {
      return true;
    } else if (this.factory.ready) {
      this.logger = this.factory.getLogger(this.name);
      for (const message of this.deferredMessages) {
        message();
      }
      this.deferredMessages = [];
      return true;
    }
    return false;
  }

  private defer(message: () => void) {
    if (this.checkLogger()) {
      message();
    } else {
      this.deferredMessages.push(message);
    }
  }
}

class DeferredLoggerFactory {
  public ready: boolean = false;
  private factory?: {getLogger(name: string): Logger};

  public setFactory(factory: {getLogger(name: string): Logger}) {
    this.factory = factory;
    this.ready = true;
  }

  public getLogger(named: string): Logger {
    if (this.ready) {
      return this.factory!.getLogger(named);
    } else {
      const logger = new DeferredLogger(named, this);
      logger.debug("Using a deferred logger. Fix module load sequence for better performance.");
      return logger;
    }
  }
}

export default new DeferredLoggerFactory();
