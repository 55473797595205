export enum MenuType {
  Normal = "normal",
  None = "none",
  Admin = "admin",
  Setup = "setup",
  DataShare = "data_share",
  Responder = "responder",
  ResponderExcel = "responder-excel",
}

export enum NavDirection {
  UP = "up",
  DOWN = "down",
}
