import {autoMutations} from "../utils";
import clone from "lodash/clone";
import globalLogger from "../../logging";
import {any, equals, find, findIndex, path, pathOr, pick, propEq, uniq} from "ramda";
import {State} from "pg-isomorphic/enums/actionplan";
import socketPlugin from "../plugins/socket";
import {RoutingKey} from "pg-isomorphic/queue";
import {ChangeType} from "pg-isomorphic/enums/queue";
import {ActionPlanEvent} from "pg-isomorphic/enums/events";
import {SearchScope} from "pg-isomorphic/enums";
import {ConnectionRole} from "pg-isomorphic/enums";
import {isEmptyOrUndefined} from "pg-isomorphic/utils";

const logger = globalLogger.getLogger("actionPlans");

export const state = {
  filterOwner: null,
  filterStatus: null,
  filterImpact: null,
  filterCreator: null,
  searchConnectionIds: [],
  allPlans: [],
  originalPlans: [],
  planTopics: {},
  stateCounts: {},
  loading: false,
  initialLoadingFinished: false,
  error: null,
  showActionPlanNotes: false,
  showRemindersNotes: false,
  showApprovalsNotes: false,
  showMessagesNotes: false,
  actionPlanNotes: [],
  showActionPlanMessages: false,
  selectedActionPlanId: null,
};

const defaultState = clone(state);

const getters = {
  planDetails(state) {
    return find((ap) => ap._id === state.selectedActionPlanId, state.allPlans) || {};
  },
};

const actions = {
  async reset({commit}) {
    for (const key of Object.keys(defaultState)) {
      commit(key, defaultState[key]);
    }
  },

  async search({commit}, {search}) {
    const connectionResponse = await this.httpPost(`/api/entities/search`, {
      scope: SearchScope.NAME,
      query: search,
      connectionRole: ConnectionRole.BOTH,
      limit: 1000,
    });
    const results = path(["data", "results"], connectionResponse);
    if (results && results.length) {
      commit(
        "searchConnectionIds",
        results.map((r) => r.connectionId),
      );
    } else {
      commit("searchConnectionIds", []);
    }
  },

  async get(
    {commit, state},
    {
      planState,
      connectionIds,
      impact,
      owner,
      creator,
      searchText,
      includeTasks,
      includeReminders,
      includeApprovals,
      includeNotes,
      includeMessages,
      append,
    },
  ) {
    logger.trace(() => `get ${planState} ${connectionIds} ${impact} ${owner}, ${searchText}`);
    commit("loading", true);

    try {
      const response = await this.httpGet("/api/actionplans", {
        state: planState,
        connectionIds,
        impact,
        owner,
        creator,
        includeTasks,
        includeReminders,
        includeApprovals,
        includeMessages,
        includeNotes,
        includeEntityInfo: true,
      });

      if (append) {
        commit("allPlans", state.allPlans.concat(response.data.actionPlans));
        if (!isEmptyOrUndefined(response.data.actionPlanTopics)) {
          const actionPlanTopics = state.planTopics || {};
          for (const topic of Object.keys(response.data.actionPlanTopics)) {
            if (!isEmptyOrUndefined(response.data.actionPlanTopics[topic])) {
              actionPlanTopics[topic] = uniq(
                (actionPlanTopics[topic] || []).concat(response.data.actionPlanTopics[topic]),
              );
            }
          }
          commit("planTopics", actionPlanTopics);
        }
      } else {
        commit("allPlans", response.data.actionPlans);
        commit("stateCounts", response.data.stateCounts);
        commit("planTopics", response.data.actionPlanTopics);
      }
      if (!state.filterOwner && !state.filterCreator && !state.filterImpact && !state.filterStatus) {
        commit("originalPlans", state.allPlans);
      }
    } catch (e) {
      logger.error(() => `get error`, e);
      commit("error", e);
    } finally {
      commit("loading", false);
      commit("initialLoadingFinished", true);
    }
  },

  async updateCurrentActionPlanRecord({commit, state, dispatch, getters}, {}) {
    logger.trace(() => `updateCurrentActionPlanRecord ${getters.planDetails._id}`);
    commit("loading", true);

    try {
      const response = await this.httpGet("/api/actionplans", {
        actionPlanIds: getters.planDetails._id,
        includeTasks: true,
        includeReminders: true,
        includeApprovals: true,
        includeMessages: true,
        includeNotes: true,
        includeEntityInfo: true,
      });
      const updateIndex = findIndex(propEq("_id", getters.planDetails._id))(state.allPlans);
      const updatedRecord = pathOr(null, ["data", "actionPlans", "0"], response);
      if (updatedRecord && updateIndex !== -1) {
        const allPlans = clone(state.allPlans);
        allPlans[updateIndex] = updatedRecord;
        commit("allPlans", allPlans);
        dispatch(
          "approvals/manage/setApprovals",
          {actionPlanId: updatedRecord._id, approvals: updatedRecord.approvals},
          {root: true},
        );
      }
    } catch (e) {
      logger.error(() => `get error`, e);
      commit("error", e);
    } finally {
      commit("loading", false);
    }
  },

  async create({commit, state}, plan) {
    logger.trace(() => `create`, plan);
    if (state.loading) {
      logger.debug("Create skipped because load/save in progress", plan);
      return;
    }
    commit("loading", true);

    try {
      const response = await this.httpPost("/api/actionplans", plan);
      return response.data.actionPlan;
    } catch (e) {
      logger.error(() => `create error`, e);
      commit("error", e);
    } finally {
      commit("loading", false);
    }
  },

  async update({commit}, plan) {
    logger.trace(() => `update`, plan);
    commit("loading", true);

    try {
      const updatePlan = pick(
        ["name", "summary", "owner", "impact", "requireCreatorApproval", "requireOwnerApproval"],
        plan,
      );
      // these are bool in the database
      updatePlan.requireCreatorApproval =
        (Array.isArray(updatePlan.requireCreatorApproval)
          ? any(equals(true), updatePlan.requireCreatorApproval)
          : !!updatePlan.requireCreatorApproval) || false; // false to ensure it's always updated
      updatePlan.requireOwnerApproval =
        (Array.isArray(updatePlan.requireOwnerApproval)
          ? any(equals(true), updatePlan.requireOwnerApproval)
          : !!updatePlan.requireOwnerApproval) || false;
      logger.trace(() => `updatePlan using update action`, updatePlan);
      await this.httpPatch(`/api/actionplans/${plan._id}`, updatePlan);
    } catch (e) {
      logger.error(() => `update error`, e);
      commit("error", e);
    } finally {
      commit("loading", false);
    }
  },

  delete({commit, state}, id) {
    logger.trace(() => `delete`, id);
    commit("loading", true);

    try {
      this.httpDelete(`/api/actionplans/${id}`);
      const delId = findIndex(propEq("_id", id))(state.allPlans);
      state.allPlans.splice(delId, 1);
      commit("allPlans", state.allPlans);
    } catch (e) {
      logger.error(() => `create error`, e);
      commit("error", e);
    } finally {
      commit("loading", false);
    }
  },

  setDetails({commit}, plan) {
    commit("selectedActionPlanId", pathOr(null, ["_id"], plan));
  },

  resetDetails({commit}) {
    commit("selectedActionPlanId", null);
  },

  markSelectedAPMessagesAsRead({state, commit}) {
    const updateIndex = findIndex(propEq("_id", state.selectedActionPlanId))(state.allPlans);
    const allPlans = clone(state.allPlans);
    allPlans[updateIndex].hasUserUnreadMessages = false;
    commit("allPlans", allPlans);
  },

  joinActionPlanRooms({dispatch, state, commit, rootState}, {connectionId}) {
    if (this.actionPlanSocket) {
      return;
    }
    const entityId = rootState.user.activeEntityId;
    logger.trace(() => `joinActionPlanRooms ${connectionId}`);

    this.actionPlanSocket = Object.create(this);
    socketPlugin(this.actionPlanSocket);

    this._socketUpdateActionPlan = (updatedRecord) => {
      logger.trace(() => `_socketUpdateActionPlan ${updatedRecord._id}`, updatedRecord);
      const updateIndex = findIndex(propEq("_id", updatedRecord._id))(state.allPlans);
      if (updatedRecord && updateIndex !== -1) {
        const allPlans = clone(state.allPlans);
        allPlans[updateIndex] = updatedRecord;
        commit("allPlans", allPlans);
        dispatch(
          "approvals/manage/setApprovals",
          {actionPlanId: updatedRecord._id, approvals: updatedRecord.approvals},
          {root: true},
        );
      }
    };

    this._socketDeleteActionPlan = (deletedRecord) => {
      logger.trace(() => `_socketDeleteActionPlan ${deletedRecord._id}`);
      const deleteIndex = findIndex(propEq("_id", deletedRecord._id))(state.allPlans);
      if (deleteIndex !== -1) {
        if (state.selectedActionPlanId === deletedRecord._id) {
          dispatch("resetDetails");
        }
        const allPlans = clone(state.allPlans);
        allPlans.splice(deleteIndex, 1);
        commit("allPlans", allPlans);
        dispatch("approvals/manage/removeObjectApprovals", deletedRecord._id, {root: true});
      }
    };

    this._socketInsertActionPlan = (addedRecord) => {
      logger.trace(() => `_socketInsertActionPlan ${addedRecord._id}`);
      if (connectionId) {
        const allPlans = clone(state.allPlans);
        allPlans.push(addedRecord);
        commit("allPlans", allPlans);
      } else {
        this.emitter.$emit(ActionPlanEvent.NEW_ACTION_PLAN, {addedRecord});
      }
    };

    this._getRoutingKeyType = (changeType) => {
      const extraParams = {};
      if (changeType) {
        extraParams.evt = changeType;
      }
      return connectionId
        ? RoutingKey.connectionActionPlan({connectionId, ...extraParams})
        : RoutingKey.entityActionPlan({entityId, ...extraParams});
    };

    this.actionPlanSocket.join(this._getRoutingKeyType());
    this.actionPlanSocket.socketOn(this._getRoutingKeyType(ChangeType.UPDATE), this._socketUpdateActionPlan);
    this.actionPlanSocket.socketOn(this._getRoutingKeyType(ChangeType.DELETE), this._socketDeleteActionPlan);
    this.actionPlanSocket.socketOn(this._getRoutingKeyType(ChangeType.INSERT), this._socketInsertActionPlan);
  },
  leaveActionPlanRooms({}, {}) {
    if (!this.actionPlanSocket) {
      return;
    }
    logger.trace(() => `leaveActionPlanRooms`);

    this.actionPlanSocket.leave(this._getRoutingKeyType());
    this.actionPlanSocket.socketOff(this._getRoutingKeyType(ChangeType.UPDATE), this._socketUpdateActionPlan);
    this.actionPlanSocket.socketOff(this._getRoutingKeyType(ChangeType.DELETE), this._socketDeleteActionPlan);
    this.actionPlanSocket.socketOff(this._getRoutingKeyType(ChangeType.INSERT), this._socketInsertActionPlan);
    this.actionPlanSocket.socketDisconnect();
    delete this.actionPlanSocket;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: {
    actionPlansPush: (state, actionPlan) => {
      const plans = clone(state.allPlans);
      plans.push({...actionPlan, state: State.PENDING});
      state.allPlans = plans;
    },
    ...autoMutations(state),
  },
};
