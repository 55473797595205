<template>
  <GraphiteModal
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :id="`confirm-modal-${uniqueId}`"
    :baseZIndex="1133"
    content-class="confirm-window"
    body-class="body"
    title-class="title"
    v-model:visible="isOpen"
  >
    <template #modal-header>
      {{ title || $t("actions.confirm") }}
    </template>
    <template #default>
      <div class="w-100">
        {{ text }}
      </div>
    </template>
    <template #modal-footer>
      <div class="w-100 footer">
        <GraphiteButton size="sm" variant="tertiary" v-if="showCancel" @click="doCancel">
          {{ cancelText || $t("actions.cancel") }}
        </GraphiteButton>
        <GraphiteButton
          v-focus="true"
          autofocus
          class="float-right padded-button"
          :variant="buttonText === $t('actions.delete') ? 'danger' : 'primary'"
          @click="doConfirm"
        >
          {{ buttonText || $t("actions.ok") }}
        </GraphiteButton>
      </div>
    </template>
  </GraphiteModal>
</template>

<script>
/**
 *  Don't use this directly - use via the `globalConfirm` store
 *  e.g.
 *  methods: {
 *    ...mapActions('globalConfirm', ['confirm']),
 *    async method () {
 *      if (await this.confirm({text: 'My Text'})) {
 *        // do the thing
 *      }
 *    }
 *  }
 */

import {mapComputedProperties} from "@/store/utils";
import {randomHex} from "pg-isomorphic/utils";
import {getNextPgId} from "@/composables/utils";

export default {
  name: "ConfirmDialog",
  methods: {
    async doConfirm() {
      this.onConfirm();
      this.isOpen = false;
    },
    doCancel() {
      this.onCancel();
      this.isOpen = false;
    },
  },
  data() {
    return {
      uniqueId: getNextPgId(),
    };
  },
  computed: {
    ...mapComputedProperties("globalConfirm", [
      "isOpen",
      "text",
      "title",
      "showCancel",
      "onCancel",
      "onConfirm",
      "buttonText",
      "cancelText",
    ]),
  },
};
</script>

<style lang="less"></style>
