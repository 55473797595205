import {complement, either, pathOr} from "ramda";
import {Internal} from "../enums";
import {JSONObject} from "../utils";
import {INSTANCE_ORDER} from "../utils/constants";

export const isDefaultInstance =
  (groupAnswer: JSONObject) =>
  (instanceId: string): boolean =>
    pathOr(false, [instanceId, Internal.FORCED_REQUIRED_INSTANCE], groupAnswer);

export const isDeletedInstance = (groupAnswer: JSONObject) => (instanceId: string) =>
  pathOr(false, [instanceId, Internal.DELETED], groupAnswer);

export const nonDefaultInstances = (groupAnswer): string[] =>
  ((groupAnswer && groupAnswer[INSTANCE_ORDER]) || []).filter(complement(isDefaultInstance(groupAnswer)));

export const nonDeletedInstances = (groupAnswer): string[] =>
  ((groupAnswer && groupAnswer[INSTANCE_ORDER]) || []).filter(complement(isDeletedInstance(groupAnswer)));

export const nonDefaultNonDeletedInstances = (groupAnswer): string[] =>
  ((groupAnswer && groupAnswer[INSTANCE_ORDER]) || []).filter(
    complement(either(isDefaultInstance(groupAnswer), isDeletedInstance(groupAnswer))),
  );
