import type {ConnectionRole} from "pg-isomorphic/enums";
import {GroupType} from "pg-isomorphic/enums";
import type {ComputedRef} from "vue";
import type {JSONObject, JSONQuestion} from "pg-isomorphic/utils";
import type {ConnectionWithNames, QuestionApiResponse} from "pg-isomorphic/api/connection";
import type {ExtendedEntityInfo} from "pg-isomorphic/api/entity";
import type {Task} from "pg-isomorphic/api/tasks";
import type {MessageInfo, MessageThreadInfo} from "pg-isomorphic/api/message";

export interface RiskOverride {
  score: number;
  risk: string;
  notes: string;
  updatedBy: string;
  updatedAt: string;
}

export const breadcrumbDisplayTypes = {
  [GroupType.SUBTOPIC]: true,
  [GroupType.TOPIC]: true,
  [GroupType.TAB]: true,
};

export interface SharedElementCount {
  count: number;
}

export interface ReminderElementCount extends SharedElementCount {
  overdueCount: number;
}

export interface NotesElementCount extends SharedElementCount {
  unreadCount: number;
}

export interface MessagesElementCount extends SharedElementCount {
  unreadCount: number;
}

export type TaskElementCount = SharedElementCount;

export interface ApprovalElementCount extends SharedElementCount {
  incompleteCount?: number;
  mostRecentIsApproved?: boolean;
}

export interface ElementCountOverrides {
  messageCount?: ComputedRef<SharedElementCount>;
  approvalCount?: ComputedRef<SharedElementCount>;
  notesCount?: ComputedRef<SharedElementCount>;
  taskCount?: ComputedRef<SharedElementCount>;
  reminderCount?: ComputedRef<SharedElementCount>;
}

export interface ConnectionUrlFilters {
  topic: string[];
  tag: string;
  unanswered: boolean;
  required: boolean;
  assigned_me: boolean;
  messages: boolean;
  reminders: boolean;
  notes: boolean;
  lowRisk: boolean;
  highRisk: boolean;
  profileSearch: string;
  campaign?: string;
}

export interface ConnectionFormFilters extends ConnectionUrlFilters {
  topic: string[];
  tag: string;
  unanswered: boolean;
  required: boolean;
  assigned_me: boolean;
  messages: boolean;
  reminders: boolean;
  notes: boolean;
  lowRisk: boolean;
  highRisk: boolean;
  profileSearch: string;
  statuses?: string[];
  elements?: string[];
  risk?: string[];
  questions?: JSONQuestion;
  campaign?: string;
}

export interface ProfileSideBarTabCompanyData {
  name: string;
  dba: string;
  logo: string;
  publicId: string;
  hasInheritance: boolean;
  parentName: string;
  parentPublicId: string;
  subsidiaries: any[];
  role: string;
  unverified: boolean;
}

export interface ConnectionSideData extends QuestionApiResponse {
  entity: ExtendedEntityInfo;
}

export interface MessageSocketEvent {
  messages: MessageInfo[];
  thread: MessageThreadInfo;
}

export interface ConnectionSocketHandlers {
  updateConnection?: (connection: ConnectionWithNames) => void;
}

export interface TaskSocketHandlers {
  updateTask?: (task: Task) => void;
  addTask?: (task: Task) => void;
  removeTask?: (task: Task) => void;
}

export interface MessageSocketHandlers {
  insertMessage?: (MessageSocketEvent) => void;
  deleteMessage?: (MessageSocketEvent) => void;
  updateMessage?: (MessageSocketEvent) => void;
}

export interface AnswerSocketHandlers {
  updateAnswers?: (args: {answers: JSONObject}) => void;
}

export interface ConnectionProfileData {
  mine?: ConnectionSideData;
  theirs?: ConnectionSideData;
  readonly current: ConnectionSideData; // Just points to mine or theirs for convenience.
  connection?: ConnectionWithNames;
}

export const QUICK_ADD_NS = "quick_add";

export interface PatchAnswersObject {
  connectionId?: string;
  pertainingToEntityId: string;

  [key: string]: any;
}

export interface QuestionApiParams {
  connectionRole?: ConnectionRole;
  connectionId?: string;
  connectedEntityId?: string;
  answerKeys?: string[];
  currentCampaign?: string;
  resolveUsers?: boolean;
  includeTinCheck?: boolean;
  changesSinceLastReview?: boolean;
}

export interface QuestionChangesSinceLastApiResponse {
  // TODO: move type to pg-iso, make backend comply
  changedSinceLastReview: any[];
}

export enum SpecialConnectionFilterFields {
  SHOW = "show",
}
