<template>
  <div class="flex align-items-center">
    <RadioButton
      v-model="selection"
      :inputId="uniqName"
      :name="uniqName"
      :value="value"
      v-bind="$attrs"
      @click="onClickHandler"
    />
    <label :for="uniqName" class="ml-2 mb-0 flex align-items-center radio-label">
      <slot>
        {{ label }}
      </slot>
    </label>
  </div>
</template>
<script lang="ts" setup>
import {randomHex} from "pg-isomorphic/utils";
import RadioButton from "primevue/radiobutton";
import {computed, nextTick} from "vue";

const props = defineProps<{
  value: any;
  modelValue: any;
  name?: string;
  label?: string; // prefer slot
  allowUnset?: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [any];
}>();

const onClickHandler = () => {
  nextTick(() => {
    if (props.allowUnset && props.modelValue === props.value) {
      emit("update:modelValue", null);
    } else {
      emit("update:modelValue", props.value);
    }
  });
};

const selection = computed<any>({
  set(val: any) {
    emit("update:modelValue", val);
  },
  get() {
    return props.modelValue;
  },
});

const uniqName = computed(() => (props.name ? `${props.name}_` : "") + `radio_${randomHex(4)}`);
</script>
