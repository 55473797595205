export enum State {
  ACTIVE = "active",
  PENDING = "pending",
  ON_HOLD = "on_hold",
  NEED_APPROVAL = "need_approval",
  COMPLETE = "complete",
}

export enum Impact {
  LOW = "low",
  MEDIUM = "medium",
  HIGH = "high",
}

export enum Topic {
  NO_TOPIC = "no_topic",
}
