import cloneDeep from "lodash/cloneDeep";
import type {ApprovalResponse, CompleteApproval} from "pg-isomorphic/api/approval";
import type {UploadedFile} from "pg-isomorphic/api/document";
import {ReviewEvent} from "pg-isomorphic/enums/events";
import type {APIError} from "pg-isomorphic/errors";
import type {JSONQuestion} from "pg-isomorphic/utils";
import type {GetterTree} from "vuex";
import globalLogger from "../../../logging";
import type {PGActionContext, PGActionTree, RootState} from "../../index-types";
import {actionRequestWrap} from "../../index-types";
import {questionLabelBreadcrumbs} from "../../typed-utils";
import {autoMutations} from "../../utils";

const logger = globalLogger.getLogger("approvals");

interface Approve {
  open: boolean;
  opening: boolean;
  loading: boolean;
  error: APIError | null;
  elementId: string | null;
  question: JSONQuestion | null;
  contextImg: string | null;
  contextName: string | null;
  instanceId: string | null;
  taskId: string | null;
  approvalId: string | null;
  approval: ApprovalResponse | null;
  editApprovalDecision: boolean;
  approved: boolean | null;
  notes: string | null;
  responseFiles: UploadedFile[];
}

const initialState: Approve = {
  open: false,
  opening: false,
  loading: false,
  error: null,
  elementId: null,
  question: null,
  contextImg: null,
  contextName: null,
  instanceId: null,
  taskId: null,
  approvalId: null,
  approval: null,
  editApprovalDecision: false,
  approved: null,
  notes: null,
  responseFiles: [],
};

type Ctx = PGActionContext<Approve>;

const req = actionRequestWrap(logger, "error loading approvals API");

interface InitArgs {
  elementId: string;
  approvalId: string;
  title: string;
  taskId: string;
  question: JSONQuestion;
  contextImg: string;
  contextName: string;
  instanceId?: string;
}

const actions: PGActionTree<Approve> = {
  async init({commit, dispatch}: Ctx, args: InitArgs) {
    if (!args.approvalId) {
      return;
    }
    const approve: Approve = cloneDeep(initialState);
    approve.approvalId = args.approvalId;
    approve.taskId = args.taskId;
    approve.elementId = args.elementId;
    approve.instanceId = args.instanceId || null;
    approve.opening = true;
    approve.question = args.question;
    approve.contextImg = args.contextImg;
    approve.contextName = args.contextName;
    Object.keys(approve).forEach((k) => commit(k, (approve as any)[k]));
    await dispatch("loadApproval");
  },
  loadApproval({commit, state, dispatch}: Ctx): Promise<void> {
    return req(async () => {
      const url = `/api/approvals/${state.approvalId}`;
      const response = await this.httpGet<{approval: ApprovalResponse}>(url);
      const approval = response.data.approval;
      await dispatch("setApproveData", {approval});
    }, commit);
  },
  setApproveData({commit}, {approval}: {approval: ApprovalResponse}) {
    if (approval) {
      commit("approval", approval);
      commit("approvalId", approval._id);
      if (approval.completedAt) {
        commit("approved", approval.approved);
        commit("responseFiles", approval.responseFiles || []);
        commit("notes", approval.approvalNotes);
      } else {
        commit("approved", null);
        commit("responseFiles", []);
        commit("notes", "");
      }
    } else {
      logger.warn(() => `setApproveData no approval`, approval);
    }
  },
  async complete({commit, state, dispatch}: Ctx): Promise<void> {
    if (!state.approvalId && state.approval) {
      commit("approvalId", state.approval._id);
    }
    const update: CompleteApproval = {
      responseFiles: state.responseFiles,
      notes: state.notes || undefined,
      approved: state.approved!,
      approvalId: state.approvalId!,
    };
    return req(async () => {
      const url = `/api/approvals/approve/${state.approvalId}`;
      const response = await this.httpPut<{approval: ApprovalResponse}>(url, update);
      commit("approval", response.data.approval);
      commit("open", false);
      await dispatch("sendReloadEvent");
    }, commit);
  },
  sendReloadEvent({state}: Ctx) {
    this.emitter.$emit(ReviewEvent.RELOAD_APPROVALS, {
      questionId: state.approval!.element,
      instanceId: state.approval!.instanceId,
      actionPlan: state.approval!.actionPlan,
      kitType: state.approval!.kitType,
      kitId: state.approval!.kitId,
    });
  },
  async reset({commit}: Ctx) {
    commit("opening", false);
    commit("open", false);
    commit("taskId", null);
    commit("elementId", null);
  },
};

const approveGetters: GetterTree<Approve, RootState> = {
  breadcrumbs(state: Approve): string[] {
    return state.question ? questionLabelBreadcrumbs(state.question) : [];
  },
  questionLabel(state: Approve): string {
    return state.question?.label || "";
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: {
    ...autoMutations(initialState),
  },
  actions,
  getters: approveGetters,
};
