import {autoMutations} from "../utils";
import cloneDeep from "lodash/cloneDeep";
import {filter, findIndex, is, mergeRight, path, pathOr, propEq, remove} from "ramda";
import {AuditEvent} from "pg-isomorphic/enums/events";
import {default as globalLogger} from "../../logging";
import {TaskType} from "pg-isomorphic/enums/task";

const logger = globalLogger.getLogger("notifications");

const state = {
  entity: {},
  user: {},
  notifications: {
    results: [],
  },
  loadingNotifications: false,
  page: 1,
  limit: 50,
};

export class Helper {
  static translateNotifications(notifications, user) {
    // logger.debug('notifications length', notifications.length);
    const mappedNotifications = notifications.map((n) => {
      switch (n.type) {
        case AuditEvent.MESSAGE_CREATED:
          n = this.translateMessage(n, user);
          break;
        case AuditEvent.TASK_CREATED:
        case AuditEvent.USER_GENERATED_TASK_ACTIVATED:
        case AuditEvent.USER_GENERATED_TASK_STATUS_CHANGED:
        case AuditEvent.APPROVAL_COMPLETED:
          n = this.translateTask(n, user);
          break;
        case AuditEvent.CONNECTION_INVITED:
        case AuditEvent.CONNECTION_DELETED:
        case AuditEvent.CONNECTION_COMPLETED:
        case AuditEvent.CONNECTION_DISCONNECT_STARTED:
        case AuditEvent.CONNECTION_REDIRECTED:
        case AuditEvent.EXTERNAL_INVITATION_ACCEPTED:
          n = this.translateConnection(n, user);
          break;
        case AuditEvent.ANSWERS_CHANGED:
          n = this.translateAnswersChanged(n, user);
          break;
        case AuditEvent.USER_ENTITY_ACCESS_CHANGED:
          n = this.translateUserJoined(n, user);
          break;
        default:
          logger.warn("unknown notification type", n);
          n = false;
      }
      return n;
    });

    // remove any empty/false notifications from translation
    return filter((n) => n, mappedNotifications);
  }

  static getEntityInfo(n, user, defaultPath) {
    let entityInfo = {};
    const connection = path(["data", "connection"], n);
    if (connection) {
      if (user.activeEntityId === connection.respondingEntity) {
        entityInfo = connection.requestingEntityInfo;
      } else {
        entityInfo = connection.respondingEntityInfo;
      }
    } else {
      entityInfo = path(defaultPath, n);
    }
    return entityInfo;
  }

  static translateMessage(m) {
    // messages are no longer shown in the notification center, so no transformation is needed here anymore
    // the ones one the home page have a different format, and its "translator" takes that into consideration)
    return {...m};
  }

  static translateTask(t, user) {
    if (!t.data || !t.data.task || is(String, t.data.task)) {
      logger.error("notification missing task", t);
      return false;
    }

    const connEntity = this.getEntityInfo(t, user, ["data", "task", "entityInfo"]);
    const taskData = t.data.task;
    let topic;

    switch (taskData.type) {
      case TaskType.ADVANCE_CONNECTION:
        topic = `${path(["entityInfo", "name"], taskData)} - ${taskData.localizedTitle}`;
        break;
      default:
        topic = `${taskData.localizedTopicLabel} - ${taskData.localizedTitle}`;
    }

    if (
      t.type === AuditEvent.USER_GENERATED_TASK_ACTIVATED ||
      t.type === AuditEvent.USER_GENERATED_TASK_STATUS_CHANGED
    ) {
      topic = taskData.localizedTitle;
    }

    const task = {
      _id: t._id,
      topic,
      task: taskData,
      reminder: t.data.reminder,
      date: t.createdAt,
      type: taskData.type === TaskType.REMINDER ? AuditEvent.REMINDER_ARRIVED : t.type,
      read: t.read,
      entityInfo: taskData.entityInfo,
      connEntity,
      state: taskData.state,
    };

    return task;
  }

  static translateConnection(n, user) {
    if (!n.data || !n.data.connection || is(String, n.data.connection)) {
      logger.error("notification missing connection", n);
      return false;
    }

    const connEntity = this.getEntityInfo(n, user);
    const connection = n.data.connection;
    const entity =
      connection.requestingEntity === n.entity ? connection.respondingEntityInfo : connection.requestingEntityInfo;

    const conn = {
      _id: n._id,
      type: n.type,
      read: n.read,
      connection: n.data.connection,
      date: n.createdAt,
      entityInfo: entity,
      connEntity,
      originalEntityInfo: n.originalEntityInfo,
    };
    return conn;
  }

  static translateAnswersChanged(n, user) {
    if (!n.data || !n.data.question || is(String, n.data.question)) {
      logger.error("notification missing question", n);
      return false;
    }

    const connEntity = this.getEntityInfo(n, user, ["respondingEntityInfo"]);

    const answersChanged = {
      _id: n._id,
      date: n.createdAt,
      type: n.type,
      read: n.read,
      entityInfo: n.pertainingToEntityInfo,
      connEntity,
      connection: n.data.connection,
      topic: path(["questionInfo", "label", user.locale], n),
      filter: n.filter,
    };

    return answersChanged;
  }

  static translateUserJoined(n, user) {
    const connEntity = this.getEntityInfo(n, user, ["pertainingToEntityInfo"]);

    return {
      _id: n._id,
      date: n.createdAt,
      type: n.type,
      read: n.read,
      entityInfo: n.pertainingToEntityInfo,
      connEntity,
      actor: pathOr("", ["userSection", "displayName"], n),
    };
  }
}

export const sharedActions = {
  // eslint-disable-next-line no-empty-pattern
  async markRead({}, {notifications}) {
    const notificationIds = filter(
      (n) => n,
      notifications.map((n) => (n._id ? n._id : false)),
    );
    logger.debug("markRead IDs", notificationIds);
    if (notificationIds.length) {
      await this.httpPost("/api/notifications/read", {notificationIds});
    }
  },
  insertNotification({state, rootState}, {notification}) {
    logger.debug("notification added", notification.type);
    logger.trace("notification", notification);
    let existingNotifications = cloneDeep(state.notifications);
    const newNotification = Helper.translateNotifications([notification], rootState.user)[0];
    if (!existingNotifications.results) {
      existingNotifications.results = [];
    }
    existingNotifications.results.unshift(newNotification);
    state.notifications = existingNotifications;
  },
  deleteNotification({state, rootState}, {notification}) {
    logger.debug("delete notifications", notification);
    let existingNotifications = cloneDeep(state.notifications);
    const oldNotification = Helper.translateNotifications([notification], rootState.user);
    existingNotifications.results = remove(
      findIndex(propEq("_id", oldNotification._id), existingNotifications.results),
      1,
      existingNotifications.results,
    );
    state.notifications = existingNotifications;
  },
  readNotification({state, rootState}, {notification}) {
    logger.trace("read notifications", notification);
    // TODO - update based on response
    let existingNotifications = cloneDeep(state.notifications);
    const readNotification = Helper.translateNotifications([notification], rootState.user);
    const foundIndex = findIndex(propEq("_id", readNotification?._id), existingNotifications.results);
    if (foundIndex) {
      existingNotifications.results[foundIndex] = mergeRight(existingNotifications.results[foundIndex], {
        readNotification,
      });
    }
    state.notifications = existingNotifications;
  },
};

// getters
const getters = {};

// actions
const actions = {
  ...sharedActions,
  async getNotifications({commit, state, rootState}) {
    commit("loadingNotifications", true);
    try {
      const entityId = rootState.user.activeEntityId;

      const response = await this.httpGet(`/api/notifications?notOfTypes=${AuditEvent.MESSAGE_CREATED}`, {
        entityId,
        skip: (state.page - 1) * state.limit,
        limit: state.limit,
      });

      const notifications = Helper.translateNotifications(response.data.results, rootState.user);
      state.notifications = {
        results: notifications,
        total: response.data.count,
      };
    } catch (e) {
      logger.error("error loading notifications", e);
    } finally {
      commit("loadingNotifications", false);
    }
  },
  async markAllRead() {
    await this.httpPost(`/api/notifications/read/all?notOfTypes=${AuditEvent.MESSAGE_CREATED}`);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: {
    ...autoMutations(state),
  },
};
