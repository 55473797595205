import {autoMutations} from "../../utils";
import globalLogger from "../../../logging";
import {pathOr, find, propEq} from "ramda";

const logger = globalLogger.getLogger("reminder");

const state = {
  showActionPlanReminders: false,
  showActionPlanRemindersChild: false,
  loading: false,
  upcomingReminders: [],
  incompleteReminders: [],
  completedReminders: [],
  reminderListOpen: false,
  reminderTitle: "",
  actionPlanId: "",
  connectionId: "",
  finishedGettingReminders: false,
  selectedReminder: "new",
  selectedReminderType: "upcoming",
  addError: null,
  reminders: [],
  editMode: false,
  entityId: null,
};

// getters
const getters = {
  reminders(state) {
    return [...state.incompleteReminders, ...state.upcomingReminders, ...state.completedReminders];
  },
  currentReminder(state) {
    if (state.selectedReminder === "new") {
      return {};
    }
    return find(propEq("_id", state.selectedReminder), state.reminders);
  },
};

// actions
const actions = {
  async addReminder({state, dispatch}, {recipientType, remindAt, notes}) {
    await this.httpPost(`/api/reminders`, {
      actionPlanId: state.actionPlanId,
      entityId: state.entityId,
      connectionId: state.connectionId,
      remindAt,
      notes,
      recipientType,
    });
    await dispatch("getReminders", {reminderType: "upcoming"});
    logger.trace("addReminder", remindAt, notes);
  },
  async updateReminder({state, dispatch}, {id, remindAt, notes}) {
    await this.httpPatch(`/api/reminders/${id}`, {
      actionPlanId: state.actionPlanId,
      entityId: state.entityId,
      remindAt: remindAt,
      notes: notes,
    });

    await dispatch("getReminders", {reminderType: "upcoming"});
    logger.trace("updateReminder", id, remindAt, notes);
  },
  async completeReminder({dispatch, state}, {id}) {
    await this.httpPost(`/api/reminders/complete/${id}`);

    await dispatch("getReminders", {reminderType: "all"});

    logger.trace("incomplete", state.incompleteReminders.length);
    logger.trace("upcoming", state.upcomingReminders.length);

    logger.trace("completeReminder", id);
  },
  async deleteReminder({dispatch}, {id}) {
    await this.httpDelete(`/api/reminders/${id}`);

    await dispatch("getReminders", {reminderType: "upcoming"});

    logger.trace("deleteReminder", id);
  },
  async getReminders({commit, state}, {reminderType}) {
    let getReminder = async (specificReminderType) => {
      const response = await this.httpGet(`/api/reminders/${specificReminderType}`, {
        actionPlanId: state.actionPlanId,
        pertainingToEntityId: state.entityId,
        skip: 0,
        limit: 100,
        daysBack: 365 * 5,
        allUsers: true,
      });
      commit(`${specificReminderType}Reminders`, pathOr([], ["data", "results"], response));
    };

    if (reminderType === "all" || reminderType === "upcoming") {
      await getReminder("upcoming");
    }
    if (reminderType === "all" || reminderType === "incomplete") {
      await getReminder("incomplete");
    }
    if (reminderType === "all" || reminderType === "completed") {
      await getReminder("completed");
    }
    commit("reminders", [...state.incompleteReminders, ...state.upcomingReminders, ...state.completedReminders]);
  },
  async initActionPlanReminder({commit, dispatch, rootState}, {reminderTitle, actionPlanId, connectionId}) {
    commit("loading", true);

    const entityId = rootState.user.activeEntityId;

    logger.debug(`initReminder ${actionPlanId} ${connectionId}`);
    commit("reminderTitle", reminderTitle);
    commit("actionPlanId", actionPlanId);
    commit("finishedGettingReminders", false);
    commit("reminderListOpen", true);
    commit("entityId", entityId);
    commit("connectionId", connectionId);

    await dispatch("getReminders", {reminderType: "all"});

    commit("finishedGettingReminders", true);
    commit("loading", false);
  },
};

const mutations = {
  ...autoMutations(state),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
