<template>
  <Transition
    name="expand-collapse"
    @enter="
      enter($event);
      $emit('show');
    "
    @afterEnter="
      afterEnter($event);
      $emit('shown');
    "
    @leave="
      leave($event);
      $emit('hide');
    "
    @afterLeave="$emit('hidden')"
  >
    <slot />
  </Transition>
</template>

<script lang="ts" setup>
// https://markus.oberlehner.net/blog/transition-to-height-auto-with-vue/
function afterEnter(element: HTMLElement) {
  element.style.height = "auto";
}

function leave(element: HTMLElement) {
  const height = getComputedStyle(element).height;

  element.style.height = height;

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  requestAnimationFrame(() => {
    element.style.height = "0";
  });
}

function enter(element: HTMLElement) {
  const width = getComputedStyle(element).width;

  element.style.width = width;
  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style.height = "auto";

  const height = getComputedStyle(element).height;

  element.style.width = null;
  element.style.position = null;
  element.style.visibility = null;
  element.style.height = "0";

  // Force repaint to make sure the
  // animation is triggered correctly.
  getComputedStyle(element).height;

  // Trigger the animation.
  // We use `requestAnimationFrame` because we need
  // to make sure the browser has finished
  // painting after setting the `height`
  // to `0` in the line above.
  requestAnimationFrame(() => {
    element.style.height = height;
  });
}

const emit = defineEmits<{
  "update:modelValue": [boolean];
  show: [];
  shown: [];
  hide: [];
  hidden: [];
}>();
</script>

<!--<style lang="less" scoped>-->
<!--.collapse-enter-active,-->
<!--.collapse-leave-active {-->
<!--  transition: opacity 0.5s ease;-->
<!--}-->

<!--.collapse-enter-from,-->
<!--.collapse-leave-to {-->
<!--  opacity: 0;-->
<!--}-->
<!--</style>-->

<style lang="less" scoped>
.expand-collapse-enter-active,
.expand-collapse-leave-active {
  transition: height 300ms ease-in-out;
  overflow: hidden;
}

.expand-collapse-enter,
.expand-collapse-leave-to {
  height: 0;
}
</style>
