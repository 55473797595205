import cloneDeep from "lodash/cloneDeep";
import {autoMutations} from "../utils";

const initialState = {
  inviteStatus: null,
  showInviteDialog: false,
  inviteType: null,
  inviteeRoleType: null,
  skeletonEntityId: null,
  skeletonConnectionId: null,
  overrideTitle: null,
  overrideSubtitle: null,
  initialAnswersForConnection: null,
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: autoMutations(initialState),
  actions: {
    async createSkeletonConnection({commit}, form) {
      const res = await this.httpPost("/api/connections/invite", {
        companyName: form.companyName,
        email: form.email,
        name: form.name,
        requestingRole: form.requestingRole,
        inviteeCustomizedRole: form.inviteeCustomizedRole,
        initialAnswersForConnection: form.initialAnswersForConnection,
      });
      commit("skeletonEntityId", res.data.connection.respondingEntity);
      commit("skeletonConnectionId", res.data.connection._id);
    },

    hideInviteDialog({commit}) {
      commit("showInviteDialog", false);
    },
  },
};
