import cloneDeep from "lodash/cloneDeep";
import {autoMutations} from "../../utils";
import {RoleType, ErrorCode} from "pg-isomorphic/enums";
import {indexBy, isEmpty, prop, find, findIndex, pathOr, forEachObjIndexed, filter, includes} from "ramda";
import globalLogger from "../../../logging";
import {UserGroupSearchIndex} from "pg-isomorphic/users/searchGroup";
import {ExpressionEvaluator} from "pg-isomorphic/rules/expression";
import {Locale} from "pg-isomorphic/enums";

const logger = globalLogger.getLogger("admin.users");
const ENABLED = "segmentationEnabled";
const SEGMENTS_OPTIONS = "segmentsAndOptions";
const NO_CHANGE = "noChange";

const state = {
  originalGroups: [],
  groups: [],
  roles: [],
  total: 0,
  query: {
    sort: {status: -1, name: 1},
    limit: 20,
    page: 1,
    includeAdminCount: true,
  },
  form: {
    id: "",
    name: "",
    description: "",
    type: "",
    users: [],
    permissions: [],
  },
  nameIsDuplicate: false,
  loading: false,
  error: null,
  searchIndex: null,
  search: "",
  segmentationOn: false,
  segmentsAllowed: false,
  categories: [],
  originalSegmentations: {}, // key by user id and category key
};

function searchUserGroups(searchIndex, searchText) {
  if (!searchIndex) {
    return;
  }
  let groupIds;
  if (!searchText) {
    groupIds = undefined;
  } else {
    const results = searchIndex.search(searchText);
    groupIds = results.map((r) => r.ref);
  }
  return groupIds;
}

function prepareSegmentations(segmentations, users) {
  let usersWithSegments = [];

  forEachObjIndexed((segment, userId) => {
    if (userId && includes(userId, users)) {
      let userSegmentList = [];
      forEachObjIndexed((val, key) => {
        if (key !== ENABLED && key !== "userId" && key !== SEGMENTS_OPTIONS && key !== NO_CHANGE) {
          userSegmentList.push({
            questionId: key,
            allSelected: val.allSelected,
            options: val.options,
          });
        }
      }, segment);

      usersWithSegments.push({
        userId,
        segmentationEnabled: segment.segmentationEnabled,
        userSegmentList,
        [NO_CHANGE]: !!segment[NO_CHANGE],
      });
    }
  }, segmentations);

  return usersWithSegments;
}

function filterGroups(groups) {
  return groups.filter((role) => role.type === RoleType.USER_GROUP && !role.hidden);
}

function filterRoles(groups) {
  return groups.filter((role) => {
    if (role.type !== RoleType.USER_GROUP && !role.hidden) {
      if (role.type === RoleType.TOPIC_OWNER) {
        return role?.topicInfo?.alwaysOn || role.user_count ? true : !role?.topicInfo?.hide;
      }
      return true;
    } else {
      return false;
    }
  });
}

const actions = {
  async getGroups({commit, dispatch}) {
    try {
      commit("loading", true);
      const response = await this.httpGet("/api/admin/groups");
      const formattedResponse = response.data.groups.map((g) => {
        return {
          ...g.group,
          user_count: g.user_count,
          users: g.users,
          segments: g.segments,
          userAndSegments: g.userAndSegments,
          topicInfo: g.topicInfo,
        };
      });
      commit("groups", filterGroups(formattedResponse));
      commit("roles", filterRoles(formattedResponse));
      commit("originalGroups", cloneDeep(formattedResponse));
      commit("total", formattedResponse.length);
      commit("segmentsAllowed", response.data.hasEntitySegments);
      await dispatch("updateSearchIndex");
      await dispatch("initSegmentationData");
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loading", false);
    }
  },

  checkIfNameIsValid({state, commit}) {
    const isDuplicate = (name, id) => name === state.form.name && id !== state.form.id;
    const nameIsDuplicate = !!find((g) => {
      const groupName = pathOr("", ["name", "en-US"], g);
      return isDuplicate(groupName, g._id);
    }, state.groups);
    commit("nameIsDuplicate", nameIsDuplicate);
  },

  async removeGroup({commit}, {id}) {
    try {
      await this.httpDelete(`/api/admin/groups/${id}`);
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error removing user", e);
      }
    }
  },

  // eslint-disable-next-line no-empty-pattern
  async saveGroup({dispatch, commit, state, rootState}, {locale, segmentations}) {
    try {
      let id = null;
      if (state.form.id) {
        let update = {
          type: state.form.type,
          description: {[locale]: state.form.description},
          name: {[locale]: state.form.name},
          segmentationOn: state.segmentationOn,
          permissions: state.form.permissions,
        };
        if (locale === Locale.EN_US) {
          update.key = state.form.name.toLowerCase();
        }
        await this.httpPut(`/api/admin/groups/${state.form.id}`, update);
        id = state.form.id;
      } else {
        const response = await this.httpPost(`/api/admin/groups`, {
          type: RoleType.USER_GROUP,
          description: {[locale]: state.form.description},
          name: {[locale]: state.form.name},
          key: state.form.name.toLowerCase(),
          permissions: state.form.permissions,
          segmentationOn: state.segmentationOn,
        });
        id = response.data._id;
      }

      let saveData = {users: state.form.users};
      if (!isEmpty(segmentations)) {
        const prepared = prepareSegmentations(segmentations, state.form.users);
        if (!isEmpty(prepared)) {
          saveData = {usersWithSegments: prepared};
        }
      }
      await this.httpPost(`/api/admin/groups/${id}/users`, saveData);

      // immediately update group info for the underlying screen
      const groups = state.groups;
      const idx = findIndex((g) => g._id === id, groups);
      if (idx > -1) {
        const filterUsers = filter((u) => includes(u._id, state.form.users), rootState.admin.users.allUsers);
        groups[idx].user_count = state.form.users.length;
        groups[idx].name[locale] = state.form.name;
        groups[idx].description[locale] = state.form.description;
        groups[idx].permissions = state.form.permissions;
        groups[idx].users = filterUsers;
        commit("groups", groups);
      } else {
        const roles = state.roles;
        const idx = findIndex((g) => g._id === id, roles);
        if (idx > -1) {
          const filterUsers = filter((u) => includes(u._id, state.form.users), rootState.admin.users.allUsers);
          roles[idx].user_count = state.form.users.length;
          roles[idx].name[locale] = state.form.name;
          if (typeof roles[idx].description !== "object") {
            roles[idx].description = {};
          }
          roles[idx].description[locale] = state.form.description;
          roles[idx].permissions = state.form.permissions;
          roles[idx].users = filterUsers;
          commit("roles", roles);
        } else {
          // if it's a new group, reload the groups to pull in the new one
          dispatch("getGroups");
        }
      }
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        commit("error", ErrorCode.UNKNOWN_ERROR);
        logger.error("Error updating approval for user", e);
      }
    }
  },

  async updateSearchIndex({commit, state, rootState}) {
    const index = await new UserGroupSearchIndex({
      groups: state.originalGroups,
      locale: rootState.user.locale,
    }).buildIndex();
    commit("searchIndex", index);
  },

  async searchGroups({commit, state}, query) {
    if (!isEmpty(query)) {
      const groupIds = searchUserGroups(state.searchIndex, query);
      const groups = groupIds ? filterGroups(state.originalGroups).filter((u) => groupIds.indexOf(u._id) > -1) : [];
      commit("groups", groups);
      const roles = groupIds ? filterRoles(state.originalGroups).filter((u) => groupIds.indexOf(u._id) > -1) : [];
      commit("roles", roles);
    } else if (isEmpty(query) && !isEmpty(state.search)) {
      const cloned = cloneDeep(state.originalGroups);
      commit("groups", filterGroups(cloned));
      commit("roles", filterRoles(cloned));
    }
    commit("search", query);
  },

  async clearSearch({commit}) {
    commit("search", "");
  },

  async initSegmentationData({commit, state, rootState}) {
    if (state.originalGroups && state.form.id) {
      const group = find((g) => g._id === state.form.id, state.originalGroups);

      if (group) {
        commit("segmentationOn", group.segmentationOn);

        const categories = group.segments || [];
        const expressionData = {
          Entity_Name: "<Entity_Name>",
          Counterparty: {
            Entity_Name: rootState?.entity?.basicInfo?.name,
          },
        };
        for (const category of categories) {
          const expression = new ExpressionEvaluator(category.label);
          for (const key of expression.answerKeys) {
            expressionData[key] = `<${key}>`;
          }
          category.label = String(expression.evaluate(expressionData));
        }
        commit("categories", categories);

        const segmentations = indexBy(prop("userId"), group.userAndSegments || []);
        commit("originalSegmentations", segmentations);
      } else {
        logger.error(`initSegmentationData - unable to find group ${state.form.id}`);
      }
    }
  },
};

const getters = {
  userGroups(state) {
    if (isEmpty(state.groups)) {
      return [];
    }
    return indexBy(prop("_id"), state.groups);
  },
};

export default {
  namespaced: true,
  state: cloneDeep(state),
  actions,
  getters,
  mutations: autoMutations(state),
};
