import cloneDeep from "lodash/cloneDeep";
import type {APIError} from "pg-isomorphic/errors";
import type {PGActionTree} from "../../index-types";
import {autoMutations} from "../../utils";

interface Session {
  entityId?: string;
  userId?: string;
  url: string;
  date: Date;
  key: string;
}

export interface ProfilingState {
  loading: boolean;
  error: APIError | null;
  sessions: Session[];
  sessionItems: string[];
  session: Session | null;
}

const initialState: ProfilingState = {
  loading: false,
  error: null,
  sessions: [],
  sessionItems: [],
  session: null,
};

const actions: PGActionTree<ProfilingState> = {
  async getSessions({commit}) {
    commit("loading", true);
    commit("sessions", []);
    try {
      const response = await this.httpGet<{sessions: string[]}>("/api/admin/profiling/sessions");
      const sessions: Session[] = [];
      for (const s of response.data.sessions) {
        const split = s.split("|");
        if (split.length === 4) {
          const [entityId, userId, url, date] = split;
          sessions.push({entityId, userId, url, date: new Date(parseInt(date, 10)), key: s});
        } else if (split.length === 2) {
          const [url, date] = split;
          sessions.push({url, date: new Date(parseInt(date, 10)), key: s});
        }
      }
      commit("sessions", sessions);
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loading", false);
    }
  },
  async getSessionItems({commit}, session: Session): Promise<void> {
    commit("loading", true);
    commit("sessionItems", []);
    commit("session", session);
    try {
      const response = await this.httpPost<{items: string[]}>(`/api/admin/profiling/sessions/items`, {
        sessionKey: session.key,
      });
      commit("sessionItems", response.data.items);
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loading", false);
    }
  },
  async clearSessions({commit}): Promise<void> {
    commit("loading", true);
    commit("sessionItems", []);
    commit("sessions", []);
    try {
      await this.httpPut(`/api/admin/profiling/sessions/clear`, {});
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loading", false);
    }
  },
  async enableProfiling({commit}, params: {entityId?: string; userId?: string; queue?: string}): Promise<void> {
    commit("loading", true);
    try {
      await this.httpPut(`/api/admin/profiling/enable`, params);
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loading", false);
    }
  },
  async disableProfiling(
    {commit},
    params: {entityId?: string; userId?: string; queue?: string; all?: boolean},
  ): Promise<void> {
    commit("loading", true);
    try {
      await this.httpPut(`/api/admin/profiling/disable`, params);
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loading", false);
    }
  },
};

const getters = {};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: {
    ...autoMutations(initialState),
  },
  actions,
  getters,
};
