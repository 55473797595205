<template>
  <div v-if="multiMode" ref="wrapperTriggerElem">
    <slot />
  </div>
  <span v-else-if="textMode" ref="wrapperTriggerElem">
    <slot />
  </span>
  <template v-else-if="singleSlotMode">
    <RenderNode :vnode="defaultSlots[0]" />
  </template>
  <template v-else>
    <slot />
  </template>

  <Transition @beforeEnter="$emit('show')" @afterLeave="onHide()">
    <div
      tabindex="-1"
      v-if="isVisible"
      ref="popoverElemRef"
      :style="popoverStyles"
      class="graphite-popover-wrap"
      :class="{[`arrow-side-${arrowSide}`]: showArrowWithDefault}"
      @mouseenter="showPopover"
      @mouseleave="hidePopoverIfHoverMode"
      v-bind="$attrs"
    >
      <div :style="{maxWidth: maxWidthStr, maxHeight: maxHeightStr}" class="graphite-popover">
        <slot v-bind="{close: immediatelyHidePopover}" name="popover" />
      </div>
    </div>
  </Transition>
</template>

<script lang="ts" setup>
import type {Placement} from "@floating-ui/core";
import {arrow, flip, offset, shift, useFloating} from "@floating-ui/vue";
import type {Dictionary} from "lodash";
import type {PropType, VNode} from "vue";
import {
  Comment,
  computed,
  defineComponent,
  defineOptions,
  Fragment,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  Text,
  useSlots,
  watch,
} from "vue";

let hideTimeout: ReturnType<typeof setTimeout> | null = null;

type TriggerEvent = "hover" | "click" | "focus";

const props = defineProps<{
  placement?: Placement;
  maxWidth?: number | string;
  maxHeight?: number | string;
  // provide a default slot(s) OR a trigger elem, but not both (passed in will override)
  targetElement?: HTMLElement;
  triggerEvents?: TriggerEvent[] | "manual"; // `default: `["click"]`
  modelValue?: boolean;
  disabled?: boolean;
  showArrow?: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [boolean];
  hide: [];
  show: [];
}>();

const RenderNode = defineComponent({
  name: "RenderNode",
  props: {vnode: Object as PropType<VNode>},
  render() {
    //@ts-ignore // TODO: Find another way to do this that doesn't offend TS
    return (node.value = this.vnode as VNode);
  },
  mounted() {
    _singleSlotModeTriggerElem.value = this.$el;
  },
});

const node = ref<VNode>();
const slots = useSlots();
const wrapperTriggerElem = ref<HTMLElement | null>(null);
const _singleSlotModeTriggerElem = ref<HTMLElement | null>(null);
const singleSlotModeTriggerElem = computed<HTMLElement | null>(() => {
  if (node.value?.type === Fragment) {
    return node.value.children[0].el as HTMLElement;
  }

  return _singleSlotModeTriggerElem.value;
});

const defaultSlots = computed(() => {
  return slots.default?.()?.filter((vnode) => vnode.type !== Comment) || [];
});

const singleSlotMode = computed(() => {
  return defaultSlots.value.length === 1;
});

const multiMode = computed(() => {
  return defaultSlots.value.length > 1;
});

const textMode = computed(() => {
  return singleSlotMode.value && defaultSlots.value.filter((vnode) => vnode.type === Text).length > 0;
});

const triggerElem = computed<HTMLElement | null>(() => {
  if (props.targetElement) {
    return props.targetElement;
  }

  if (multiMode.value || textMode.value) {
    return wrapperTriggerElem.value;
  }

  if (singleSlotMode.value) {
    return singleSlotModeTriggerElem.value;
  }

  return props.targetElement;
});

onMounted(() => {
  init();
});

let attempt = 0;
function init() {
  if (!triggerElem.value) {
    if (attempt > 5) {
      throw new Error("Must provide a trigger for the popover/tooltip");
    }
    nextTick(() => {
      attempt++;
      init();
    });

    return;
  }

  if (props.modelValue) {
    showPopover();
  }
}

const _isVisible = ref(props.modelValue);
const isVisible = computed({
  set(val: boolean) {
    emit("update:modelValue", val);
    _isVisible.value = val;
  },
  get() {
    return _isVisible.value;
  },
});

onMounted(() => {
  bindListeners();
});

onUnmounted(() => {
  unbindListeners();
});

const doNotReopen = ref(false);

const triggerEventsWithDefault = computed<TriggerEvent[]>(() =>
  props.triggerEvents?.length ? (typeof props.triggerEvents === "string" ? [] : props.triggerEvents) : ["click"],
);

const isManual = computed(() => props.triggerEvents === "manual");
const hasClickTrigger = computed(() => !isManual.value && triggerEventsWithDefault.value.includes("click"));
const hasHoverTrigger = computed(() => !isManual.value && triggerEventsWithDefault.value.includes("hover"));
const hasFocusTrigger = computed(() => !isManual.value && triggerEventsWithDefault.value.includes("focus"));
const showArrowWithDefault = computed(() => (props.showArrow !== undefined ? props.showArrow : true));

function getBindingPairs(getAllPossible = false): Array<Parameters<HTMLElement["addEventListener"]>> {
  let events: Array<Parameters<HTMLElement["addEventListener"]>> = [];
  if (getAllPossible || hasClickTrigger.value) {
    events = [...events, ["click", togglePopover]];
  }

  if (getAllPossible || hasHoverTrigger.value) {
    events = [...events, ["mouseenter", showPopover], ["mouseleave", hidePopover]];
  }

  if (getAllPossible || hasFocusTrigger.value) {
    events = [...events, ["focus", showPopover], ["blur", hidePopover]];
  }

  return events;
}

function bindListeners() {
  if (!triggerElem.value || props.disabled) {
    return;
  }

  getBindingPairs().forEach(([event, listener]) => {
    triggerElem.value!.addEventListener(event, listener);
  });

  if (hasClickTrigger.value || isManual.value) {
    document.addEventListener("click", hideOnBodyClick);
  }
}

function unbindListeners() {
  if (!triggerElem.value) {
    return;
  }

  getBindingPairs(true).forEach(([event, listener]) => {
    triggerElem.value!.removeEventListener(event, listener);
  });

  document.removeEventListener("click", hideOnBodyClick);
}

function hideOnBodyClick(event: MouseEvent) {
  if (!isVisible.value) {
    return;
  }
  let clickTarget: HTMLElement = event.target as HTMLElement;
  // if (clickTarget.contains(popoverElemRef.value) || clickTarget.contains(triggerElem.value)) {
  // if (clickTarget.contains(popoverElemRef.value)) {
  //   return;
  // }
  while (clickTarget) {
    if (clickTarget === popoverElemRef.value || clickTarget === triggerElem.value) {
      return;
    }
    clickTarget = clickTarget.parentElement;
  }

  immediatelyHidePopover(event);
}

function rebindListeners() {
  unbindListeners();
  bindListeners();
}

watch(triggerElem, () => {
  rebindListeners();
});

watch(
  () => props.disabled,
  () => rebindListeners(),
);

watch(
  () => props.modelValue,
  (val) => {
    _isVisible.value = val;

    if (val) {
      showPopover();
    } else {
      hidePopover();
    }
  },
  {immediate: true},
);

const maxWidthStr = computed(() => {
  if (!props.maxWidth) {
    return undefined;
  }

  return typeof props.maxWidth === "number" ? `${props.maxWidth}px` : props.maxWidth;
});

const maxHeightStr = computed(() => {
  if (!props.maxHeight) {
    return undefined;
  }

  return typeof props.maxHeight === "number" ? `${props.maxHeight}px` : props.maxHeight;
});

const popoverElemRef = ref<HTMLElement | null>(null);
const floatingArrow = ref<HTMLElement | null>(null);

const floatingRes = computed(() => {
  return useFloating(triggerElem, popoverElemRef, {
    placement: props.placement || "top",
    middleware: [flip({padding: 80}), offset(6), shift(), arrow({element: floatingArrow})],
  });
});

const floatingStyles = computed(() => floatingRes.value.floatingStyles.value);
const middlewareData = computed(() => floatingRes.value.middlewareData.value);
const floatingPlacement = computed(() => floatingRes.value.placement.value);

const popoverStyles = computed(() => {
  return {
    ...floatingStyles.value,
  };
});

const arrowSide = computed(() => {
  return {
    top: "bottom",
    right: "left",
    bottom: "top",
    left: "right",
  }[floatingPlacement.value.split("-")[0]];
});

const arrowStyles = computed(() => {
  const base: Dictionary<number> = middlewareData.value?.arrow || {};

  return {
    left: base.x ? `${base.x}px` : "",
    top: base.y ? `${base.y}px` : "",
    right: "",
    bottom: "",
    // 5px offset needs to match `@extraPadding` variable
    // [arrowSide.value]: "calc(-0.25rem + 5px)",
    [arrowSide.value]: "-0.25rem",
  };
});

function togglePopover(event?: Event) {
  event?.preventDefault();
  event?.stopPropagation();
  if (isVisible.value) {
    hidePopover(event);
  } else {
    showPopover(event);
  }
}

function showPopover(event?: Event) {
  event?.preventDefault();
  event?.stopPropagation();
  if (doNotReopen.value || !slots.popover) {
    return;
  }

  stopHideTimeout();
  isVisible.value = true;
}

function hidePopover(_event?: Event) {
  stopHideTimeout();
  hideTimeout = setTimeout(() => {
    isVisible.value = false;
  }, 64);
}

function immediatelyHidePopover(_event?: MouseEvent) {
  doNotReopen.value = true;
  isVisible.value = false;
}

function hidePopoverIfHoverMode(event: Event) {
  if (!hasHoverTrigger.value) {
    return;
  }

  hidePopover(event);
}

function stopHideTimeout() {
  if (hideTimeout) {
    clearTimeout(hideTimeout);
    hideTimeout = null;
  }
}

function onHide() {
  emit("hide");
  doNotReopen.value = false;
}

watch(maxWidthStr, () => {
  floatingRes.value.update();
});

defineOptions({
  inheritAttrs: false,
});
</script>

<style lang="less" scoped>
@import "@/less/global.less";
@extraPadding: 5px;

.graphite-popover-wrap {
  //padding: @extraPadding;
  //margin: -@extraPadding;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1200;
}

@shadowOffset: 1px;
@shadowBlur: 3px;
@shadowColor: rgba(black, 0.3);

.graphite-popover::before,
.graphite-popover::after {
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.graphite-popover::before {
  border: 6px solid transparent;
  border-color: rgba(255, 255, 255, 0);
}
.graphite-popover::after {
  border: 5px solid transparent;
  border-color: rgba(255, 255, 255, 0);
}
.graphite-popover {
  box-shadow: 0 @shadowOffset @shadowBlur @shadowColor;
}

.arrow-side-top {
  .graphite-popover::before {
    margin-left: -6px;
    border-bottom-color: @grey200;
  }
  .graphite-popover::after {
    margin-left: -5px;
    border-bottom-color: white;
  }
  .graphite-popover::before,
  .graphite-popover::after {
    left: 50%;
    transform: translateX(-50%);
    bottom: 100%;
  }
}

.arrow-side-bottom {
  .graphite-popover::before {
    border-top-color: @grey300;
  }
  .graphite-popover::after {
    border-top-color: white;
  }
  .graphite-popover::before,
  .graphite-popover::after {
    left: 50%;
    transform: translateX(-50%);
    top: 100%;
  }
}

.arrow-side-left {
  .graphite-popover::before {
    margin-bottom: -6px;
    border-right-color: @grey300;
  }
  .graphite-popover::after {
    margin-bottom: -5px;
    border-right-color: white;
  }
  .graphite-popover::before,
  .graphite-popover::after {
    right: 100%;
    bottom: 50%;
  }
}

.arrow-side-right {
  .graphite-popover::before {
    margin-bottom: -6px;
    border-left-color: @grey200;
  }
  .graphite-popover::after {
    margin-bottom: -5px;
    border-left-color: white;
  }
  .graphite-popover::before,
  .graphite-popover::after {
    left: 100%;
    bottom: 50%;
  }
}

.graphite-popover {
  width: max-content;
  background: white;
  padding: 5px 9px;
  border-radius: 4px;
  font-size: 0.9rem;
  line-height: 1.1rem;
  overflow-y: auto;
}

.graphite-popover-arrow {
  position: absolute;
  background: white;
  width: 0.5rem;
  height: 0.5rem;
  transform: rotate(45deg);
  //opacity: 0.8;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 234ms ease-in-out;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
