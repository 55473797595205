<script>
import {mapState} from "vuex";
import {defineComponent, h} from "vue";

export default defineComponent({
  mounted() {
    if (this.envConfig.jiraIssueCollectorURL) {
      const scriptTag = document.createElement("script");
      scriptTag.setAttribute("src", this.envConfig.jiraIssueCollectorURL);
      document.head.appendChild(scriptTag);
    }
  },
  computed: {
    ...mapState(["envConfig"]),
  },
  compatConfig: {
    MODE: 3,
  },
  render() {
    return h(`div`);
  },
});
</script>
