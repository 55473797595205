import {createI18n} from "vue-i18n";
// @ts-ignore JSON files won't play nice with tsconfig-paths and vue/webpack
import defaultLocale from "../../pg-isomorphic/src/locales/en.json";
// @ts-ignore
import _localeList from "../../pg-isomorphic/src/locales/_localesList.json";
import axios from "axios";
import {has} from "ramda";
import {Locale} from "pg-isomorphic/enums";

const loadedLanguages = [Locale.EN_US];

export const i18nRoot = createI18n({
  legacy: true,
  // allowComposition: true,
  locale: process.env.VUE_APP_I18N_LOCALE || Locale.EN_US,
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || Locale.EN_US,
  globalInjection: true,
  runtimeOnly: true,
  messages: {
    [defaultLocale["localization-metadata"].localizationCode]: defaultLocale,
  },
});

export const i18n = i18nRoot.global;

function setI18nLanguage(lang: Locale) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

function isValidLocale(lang: Locale) {
  return has(lang, _localeList);
}

export function loadLanguageAsync(lang: Locale) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  if (!isValidLocale(lang)) {
    lang = Locale.EN_US;
  }

  const fileName = _localeList[lang].filename;

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang));
  }

  // If the language hasn't been loaded yet
  // @ts-ignore JSON files won't play nice with tsconfig-paths and vue/webpack
  return import(`../../pg-isomorphic/src/locales/${fileName}.json`).then((messages) => {
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(lang);
  });
}

export const localeList = _localeList;
