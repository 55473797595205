import * as Sentry from "@sentry/browser";
import type {VersionInfo} from "pg-isomorphic/api/admin";
import type {EnvironmentConfig} from "pg-isomorphic/api/env";
import type {BasicUser} from "pg-isomorphic/api/user";

export function initSentry(env: EnvironmentConfig, version?: VersionInfo) {
  Sentry.init({
    dsn: env.sentryDsn,
    release: `web-app@${version?.branch}-${version?.date}-${version?.build}`,
    environment: env.environmentName,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 0.1,
    initialScope: {
      tags: {build: version.build, branch: version.branch, build_date: version.date},
    },
  });
}

export function setSentryUser(user: BasicUser) {
  Sentry.setUser({
    email: user.email,
    entity: user.activeEntityId,
  });
  Sentry.setTag("locale", user.locale);
}

export function clearSentryUser() {
  Sentry.setUser(null);
}
