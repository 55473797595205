export enum Channel {
  EMAIL = "email",
  SMS = "sms",
}

export enum NotificationChangeType {
  INSERT = "insert",
  DELETE = "delete",
  SENT = "sent",
  READ = "read",
}
