import type {Ref} from "vue";
import {computed, ref} from "vue";

const windowWidth: Ref<number> = ref(null);
const windowHeight: Ref<number> = ref(null);

export enum HORIZONTAL_WINDOW_SIZES {
  LESS_THAN_768 = "1",
  LESS_THAN_992 = "2",
  LESS_THAN_1440 = "3",
}

const horizontalWindowSizeValues = {
  [HORIZONTAL_WINDOW_SIZES.LESS_THAN_768]: 768,
  [HORIZONTAL_WINDOW_SIZES.LESS_THAN_992]: 992,
  [HORIZONTAL_WINDOW_SIZES.LESS_THAN_1440]: 1440,
};

export function updateWindowDimensions() {
  windowWidth.value = window.innerWidth;
  windowHeight.value = window.innerHeight;
}

export const windowWidthComputed = computed(() => windowWidth.value);
export const windowHeightComputed = computed(() => windowHeight.value);

export function getComputedHorizontalWindowSize() {
  return computed(() => {
    const horizontalEnumKeys = Object.keys(horizontalWindowSizeValues);
    for (const horizontalEnumKey of horizontalEnumKeys) {
      if (windowWidth.value < horizontalWindowSizeValues[horizontalEnumKey]) {
        return horizontalEnumKey;
      }
    }
  });
}
