export enum Format {
  COUNTRY = "country",
  STATE = "state",
  PHONE = "phone",
  URL = "url",
  LANGUAGE = "language",
  YEAR = "year",
}

export enum SkipRuleTarget {
  NEXT_TOPIC = "NEXT_TOPIC",
  NEXT_SUBTOPIC = "NEXT_SUBTOPIC",
}

export enum QuestionTag {
  TAX_ID = "TaxID",
  UNIQUE_TAX_ID = "UniqueTaxID",
  NSJ_DEFAULT_COUNTRY = "NSJ_Default_Country",
}

export enum RiskAssessment {
  OK = "ok", // green highlight
  CAUTION = "caution", // yellow highlight
  FLAG = "flag", // red highlight
}

export enum VisibilitySummary {
  NONE = 0,
  PUBLIC = 1,
  CONNECTIONS = 2,
  CUSTOMER_CONNECTIONS = 3,
  SUPPLIER_CONNECTIONS = 4,
  PRIVATE = 5,
}

export enum VisibilitySummaryLabel {
  PUBLIC = "PUBLIC",
  CONNECTIONS = "CONNECTIONS",
  CUSTOMER_CONNECTIONS = "CUSTOMER_CONNECTIONS",
  SUPPLIER_CONNECTIONS = "SUPPLIER_CONNECTIONS",
  MIX_CONNECTIONS = "MIX_CONNECTIONS",
  MIX_CUSTOMER_CONNECTIONS = "MIX_CUSTOMER_CONNECTIONS",
  MIX_SUPPLIER_CONNECTIONS = "MIX_SUPPLIER_CONNECTIONS",
  COMPANY_ONLY = "COMPANY_ONLY",
  NONE = "NONE",
  PRIVATE = "PRIVATE",
}

export enum QuestionClass {
  PROFILE = "PROFILE",
  RESPONDER = "RESPONDER",
}

export enum ValidationType {
  STRING = "string",
  EMAIL = "email",
  SHORT_URL = "shortUrl",
  EIN = "ein",
  NUMBER = "number",
  BOOLEAN = "boolean",
  DATE = "date",
  STRING_MATCH = "stringMatch",
  GRAPHITE_EMAIL = "graphiteEmail",
  STRONG_PASSWORD = "strongPassword",
}

export enum SpecialKeyValues {
  COUNTRIES = "countries",
  COUNTRIES_GLOBAL = "countries_global",
}

export enum QuestionBadge {
  DISREGARDED_ENTITY = "disregardedEntityBadge",
}

export enum SourceOfTruth {
  SPREADSHEET = "spreadsheet",
  DATABASE = "database",
}
