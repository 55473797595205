<template>
  <div
    role="radiogroup"
    class="flex"
    :class="{'gap-3': !stacked || verticalLayout, 'flex-column gap-2': !!stacked || verticalLayout}"
  >
    <div v-for="option in normalizedOptions" :key="uniqId(option.text)" class="flex align-items-center">
      <GraphiteRadio v-model="selection" :value="option.value" :allow-unset="allowUnset" :disabled="disabled">
        {{ option.text }}
      </GraphiteRadio>
    </div>
    <slot name="after" />
  </div>
</template>

<script lang="ts" setup>
import {randomHex} from "pg-isomorphic/utils";
import {computed, ref} from "vue";

export interface RadioOption {
  text: string;
  value: any;
}

const props = defineProps<{
  stacked?: boolean;
  /** @deprecated used `stacked` */
  verticalLayout?: boolean;
  options: string[] | RadioOption[];
  modelValue: any;
  allowUnset?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [any];
}>();

const uniqName = ref(`radio_${randomHex(4)}`);

function uniqId(label: string) {
  return `${uniqName.value}_${label}`;
}

const selection = computed<any>({
  set(val: any) {
    emit("update:modelValue", val);
  },
  get() {
    return props.modelValue;
  },
});

const normalizedOptions = computed<RadioOption[]>(() => {
  if (!props.options?.[0] || typeof props.options[0] === "string") {
    return (props.options as string[]).map((str: string) => ({text: str, value: str}));
  }

  return props.options as RadioOption[];
});
</script>
