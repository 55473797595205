import {getStore} from "@/composables/get.store";
import {
  createFakeTempMessageThread,
  createMessageThread,
  deleteMessage,
  getLatestRelatedThread,
  getMessageThread,
  markMessagesAsRead,
  respondInThread,
  translateMessage,
  updateMessage,
} from "@/composables/messaging/messaging";
import {getCurrentUser, getUserActiveEntityId} from "@/composables/vuex";
import {makeCountKey, syncCounts} from "@/store/modules/messaging/message-counts";
import cloneDeep from "lodash/cloneDeep";
import compact from "lodash/compact";
import isFunction from "lodash/isFunction";
import merge from "lodash/merge";
import uniqBy from "lodash/uniqBy";
import type {UploadedFile} from "pg-isomorphic/api/document";
import type {BasicEntityInfoLite} from "pg-isomorphic/api/entity";
import type {
  AnyRelatedThreadsInput,
  ExtendedMessageInfo,
  MessageCounts,
  MessageInfo,
  MessageThreadInfo,
  MessageThreadWithMessagesInfo,
  RelatedThreadsInput,
  TaskForThreadLite,
  UpdateMessageInput,
} from "pg-isomorphic/api/message";
import type {MessagesChanged} from "pg-isomorphic/api/queue/messages";
import type {UserAvatar} from "pg-isomorphic/api/user";
import {Locale} from "pg-isomorphic/enums";
import {TaskEvent} from "pg-isomorphic/enums/events";
import {MessageObjectType, TEMP_THREAD_ID} from "pg-isomorphic/enums/message";
import {ChangeType} from "pg-isomorphic/enums/queue";
import {RoutingKey} from "pg-isomorphic/queue";
import {getTextFromLocaleObj} from "pg-isomorphic/utils/locale";
import {reactive} from "vue";
import socketPlugin from "../../store/plugins/socket";

const DEFAULT_LIMIT = 20;

export interface MessageRepoOptions {
  limit?: number;
}

export interface UpdateMessageFullInput extends Omit<UpdateMessageInput, "attachmentIds"> {
  attachments?: UploadedFile[];
}

interface ReactiveProps {
  messages: ExtendedMessageInfo[];
  thread: MessageThreadInfo;
  // I don't think this one actually needs to be here, but just in case
  allMessagesAreLoaded: boolean;
}

export class MessageRepo {
  protected waitingForResponseProcessing = false;
  protected limit = 0;
  // need this so we can unsub from event emitters (won't work with arrow functions)
  protected _waitToInsertMessageHandler = this.waitToInsertMessageHandler.bind(this);
  protected _updateMessageHandler = this.updateMessageHandler.bind(this);
  protected _deleteMessageHandler = this.deleteMessageHandler.bind(this);
  protected messagesSocket: any;
  protected threadChangeCallbacks: Array<(thread: MessageThreadWithMessagesInfo) => void> = [];

  // note that `this` is not reactive in Vue 3, so we need to use a separate object to hold the reactive data
  protected _reactiveProps = reactive<ReactiveProps>({
    messages: [],
    thread: {} as MessageThreadInfo,
    allMessagesAreLoaded: true,
  });

  get thread(): MessageThreadInfo {
    return this._reactiveProps.thread;
  }

  get allMessagesAreLoaded(): boolean {
    return this._reactiveProps.allMessagesAreLoaded;
  }

  get messages(): ExtendedMessageInfo[] {
    return this._reactiveProps.messages;
  }

  get countKey(): string {
    switch (this.thread.objectType) {
      case MessageObjectType.ELEMENT:
        return makeCountKey({
          elementId: this.thread.elementId,
          threadEntityId: this.thread.entity.id,
          connectionId: this.thread.connectionId,
          objectType: this.thread.objectType,
          instanceId: this.thread.instanceId,
        });
      case MessageObjectType.TASK:
        return makeCountKey({
          threadEntityId: this.thread.entity.id,
          objectType: this.thread.objectType,
          connectionId: this.thread.connectionId,
          taskId: this.thread.task.id,
        });

      case MessageObjectType.ACTION_PLAN:
        return makeCountKey({
          threadEntityId: this.thread.entity.id,
          objectType: this.thread.objectType,
          connectionId: this.thread.connectionId,
          actionPlanId: this.thread.actionPlanId,
        });

      case MessageObjectType.GENERIC:
        return makeCountKey(this.thread.id);
    }
  }

  public get threadExists(): boolean {
    return this.thread && this.thread.id !== TEMP_THREAD_ID;
  }

  constructor(threadWithMessages: MessageThreadWithMessagesInfo, opts: MessageRepoOptions = {}) {
    this.limit = opts.limit || DEFAULT_LIMIT;

    this.setupThread(threadWithMessages);
  }

  static tempId(): string {
    return `temp-id_` + Math.random();
  }

  static async initThreadById(threadId: string, opts: MessageRepoOptions = {}): Promise<MessageRepo | null> {
    const threadWithMessages = await getMessageThread(threadId, DEFAULT_LIMIT);

    if (!threadWithMessages) {
      return null;
    }

    return new MessageRepo(threadWithMessages, opts);
  }

  static async initThreadByMeta(data: RelatedThreadsInput, opts: MessageRepoOptions = {}): Promise<MessageRepo> {
    const threadWithMessages = await getLatestRelatedThread({
      ...data,
      includeMessages: true,
      limit: opts.limit || DEFAULT_LIMIT,
    });

    if (!threadWithMessages) {
      return new PendingMessageRepo(data, opts);
    }

    return new MessageRepo(threadWithMessages, opts);
  }

  static getBaseLabel(thread?: MessageThreadInfo): string {
    const parts = (thread?.label || "").split(" > ");
    return parts[parts.length - 1];
  }

  static getBreadcrumbs(thread?: MessageThreadInfo): string[] {
    const parts = (thread?.label || "").split(" > ");
    return parts.slice(parts.length > 2 ? 1 : 0, parts.length - 1);
  }

  setLimit(limit: number): void {
    this.limit = limit;
  }

  getBaseLabel(): string {
    return MessageRepo.getBaseLabel(this.thread);
  }

  getBreadcrumbs(): string[] {
    return MessageRepo.getBreadcrumbs(this.thread);
  }

  async loadMoreMessages() {
    if (this.allMessagesAreLoaded) {
      return;
    }

    const {messages} = await getMessageThread(this.thread.id, this.limit, this.messages.length);
    this._reactiveProps.messages = [...this.messages, ...this.processMessages(messages)];

    this._reactiveProps.allMessagesAreLoaded = this.messages.length < this.limit;
  }

  async respond(message: MessageInfo): Promise<void> {
    this.waitingForResponseProcessing = true;

    try {
      this._reactiveProps.messages = [this.processMessage(message), ...this.messages];
      this.updateCounts();

      // this is to prevent side-effects of the user not being participating until the websocket update (which will replace it) comes through
      this.thread.participatingUserEntityPairs = uniqBy(
        [
          ...this.thread.participatingUserEntityPairs,
          {
            user: message.author,
            entity: message.authorEntity,
          },
        ],
        (pair) => `${pair.user._id}__${pair.entity.id}`,
      );

      await respondInThread(this.thread.id, {
        message: message.message,
        usesNewFormat: message.usesNewFormat,
        taggedUsers: message.taggedUsers,
        taggedGroups: message.taggedGroups,
        attachmentIds: (message.attachments || []).map((a) => a.fileId),
      });
    } finally {
      this.waitingForResponseProcessing = false;
    }

    // socket will update the message with the real ID from the server
  }

  // to update local store to instantly update any counts in the UI
  updateCounts(overrides?: Partial<MessageCounts>) {
    syncCounts({
      threadId: this.thread.id,
      changeType: ChangeType.UPDATE,
      counts: {
        total: overrides?.total ?? this.messages.length,
        unread: overrides?.total ?? 0,
      },
    });
  }

  async autoMarkMessageAsRead(): Promise<void> {
    await this.markMessagesAsRead(this.messages);
  }

  async markMessagesAsRead(messages: ExtendedMessageInfo[]): Promise<void> {
    const markReadIds: string[] = compact(messages.map((m) => !m.read && m.id));

    if (!markReadIds.length) {
      return;
    }

    this.updateMessageStore(markReadIds, {read: true});
    await markMessagesAsRead(markReadIds);
  }

  async deleteMessage(id: string): Promise<void> {
    this.updateMessageStore([id], {deleting: true});
    await deleteMessage(id);
    this.removeMessages([id]);
  }

  async updateMessage(id: string, payload: UpdateMessageFullInput, locale = Locale.EN_US) {
    this.updateMessageStore(
      [id],
      {
        ...payload,
        message: {
          [locale]: {
            value: payload.message,
          },
        } as any,
      },
      true,
    );

    await updateMessage(id, {
      message: payload.message,
      taggedUsers: payload.taggedUsers,
      taggedGroups: payload.taggedGroups,
      attachmentIds: (payload.attachments || []).map((f) => f.fileId),
    });
  }

  async translateMessage(id: string, locale: string): Promise<string> {
    const response = await translateMessage(id);

    this.updateMessageStore(
      [id],
      {
        message: {
          [locale]: {
            value: response,
          },
        } as any,
      },
      true,
    );

    return response;
  }

  joinMessageRooms() {
    if (this.messagesSocket) {
      return;
    }

    this.messagesSocket = Object.create(getStore());
    socketPlugin(this.messagesSocket);

    this.messagesSocket.join(this.makeRoomKey());
    this.messagesSocket.socketOn(this.makeRoomKey(ChangeType.INSERT), this._waitToInsertMessageHandler);
    if (this.threadExists) {
      this.messagesSocket.socketOn(this.makeRoomKey(ChangeType.UPDATE), this._updateMessageHandler);
      this.messagesSocket.socketOn(this.makeRoomKey(ChangeType.DELETE), this._deleteMessageHandler);
    }
  }

  leaveMessageRooms() {
    if (!this.messagesSocket) {
      return;
    }

    this.messagesSocket.leave(this.makeRoomKey());
    this.messagesSocket.socketOff(this.makeRoomKey(ChangeType.INSERT), this._waitToInsertMessageHandler);
    if (this.threadExists) {
      this.messagesSocket.socketOff(this.makeRoomKey(ChangeType.UPDATE), this._updateMessageHandler);
      this.messagesSocket.socketOff(this.makeRoomKey(ChangeType.DELETE), this._deleteMessageHandler);
    }

    this.messagesSocket.socketDisconnect();
    delete this.messagesSocket;
  }

  onThreadChange(fn: (thread: MessageThreadWithMessagesInfo) => void) {
    this.threadChangeCallbacks.push(fn);
  }

  protected makeRoomKey(type?: ChangeType) {
    if (this.threadExists) {
      return RoutingKey.messagesForThread(this.thread.id, type);
    }
    return RoutingKey.messagesByType(
      {
        entityId: this.thread.entity.id,
        connectionId: this.thread.connectionId,
        objectType: this.thread.objectType as Exclude<MessageObjectType, MessageObjectType.GENERIC>,
      },
      type as ChangeType.INSERT,
    );
  }

  protected processMessage(rawMessage: MessageInfo, fromWsUpdate = false): ExtendedMessageInfo {
    const userId = getCurrentUser()._id;
    const readState = !!(rawMessage.readBy || []).find((u) => u._id === userId);

    return {
      ...rawMessage,
      posted: true,
      deleting: false,
      read: readState,
      initialReadState: fromWsUpdate ? rawMessage.author._id === userId : readState,
    };
  }

  protected processMessages(rawMessages: MessageInfo[], fromWsUpdate = false): ExtendedMessageInfo[] {
    return rawMessages.map((msg) => this.processMessage(msg, fromWsUpdate));
  }

  protected updateMessageStore(
    idsOrTester: string[] | ((msg: ExtendedMessageInfo) => boolean),
    data: Partial<ExtendedMessageInfo>,
    deepMerge = false,
  ) {
    const tester: (msg: ExtendedMessageInfo) => boolean = isFunction(idsOrTester)
      ? idsOrTester
      : (m) => (idsOrTester as string[]).includes(m.id);

    this._reactiveProps.messages = this.messages.map((m) => {
      if (tester(m)) {
        if (deepMerge) {
          return cloneDeep(merge(m, data));
        }

        return {
          ...m,
          ...data,
        };
      }

      return m;
    });
  }

  protected removeMessages(idsOrTester: string[] | ((msg: ExtendedMessageInfo) => boolean)) {
    const tester: (msg: ExtendedMessageInfo) => boolean = isFunction(idsOrTester)
      ? idsOrTester
      : (m) => (idsOrTester as string[]).includes(m.id);

    this._reactiveProps.messages = this.messages.filter((m) => !tester(m));
  }

  protected async waitToInsertMessageHandler(event: MessagesChanged) {
    if (this.waitingForResponseProcessing) {
      setTimeout(
        (eventArg) => {
          this.waitToInsertMessageHandler(eventArg);
        },
        300,
        event,
      );
    } else {
      await this.insertMessageHandler(event);
    }
  }

  protected async insertMessageHandler(event: MessagesChanged) {
    if (event.isNewThread) {
      return;
    }

    if (event.thread.id !== this.thread.id) {
      return;
    }

    this._reactiveProps.thread = event.thread;
    // honestly, we do the same thing either way in this case, cuz we try and handle duplicate inserts
    await this.updateMessageHandler(event);

    if (this.thread.objectType === MessageObjectType.TASK) {
      getStore().emitter.$emit(TaskEvent.NEW_TASK, {message: event?.messages?.[0]});
    }
  }

  protected async updateMessageHandler(event: MessagesChanged) {
    // this covers all message types; we shouldn't have a thread ID
    // that doesn't match the correct e.g. action plan or task
    if (event.thread.id !== this.thread.id) {
      return;
    }

    this._reactiveProps.thread = event.thread;

    event.messages.forEach((m) => {
      const extendedMessageInfo = this.processMessage(m, true);
      const index = this.getMessageIndexById(extendedMessageInfo.id);
      if (index > -1) {
        this.messages[index] = extendedMessageInfo;
        this._reactiveProps.messages = [...this.messages];
      } else {
        this._reactiveProps.messages = [extendedMessageInfo, ...this.messages];
        this._reactiveProps.messages = this.messages.filter((m) => !m.id.startsWith("temp-id"));
      }
    });

    await this.autoMarkMessageAsRead();
  }

  protected deleteMessageHandler(event: MessagesChanged) {
    if (event.thread.id !== this.thread.id) {
      return;
    }

    this._reactiveProps.thread = event.thread;
    this.removeMessages(event.messages.map((m) => m.id));
  }

  protected getMessageIndexById(id: string): number {
    return this.messages.findIndex((m) => m.id === id);
  }

  protected setupThread(threadWithMessages: MessageThreadWithMessagesInfo) {
    const {messages, ...thread} = threadWithMessages;

    const processedMessages = this.processMessages(messages);
    this._reactiveProps.thread = thread;
    this._reactiveProps.messages = processedMessages;
    this._reactiveProps.allMessagesAreLoaded = processedMessages.length < this.limit;
    this.updateCounts();

    this.autoMarkMessageAsRead().catch((err) => {
      console.error("Error trying to mark messages as read on init", err);
    });
  }
}

// this subset of MessageRepo allows us to create temp threads that are never sent to the server unless
// someone actually sends a message, after which, they should behave exactly like regular `MessageRepo`s
class PendingMessageRepo extends MessageRepo {
  constructor(private readonly data: RelatedThreadsInput, opts: MessageRepoOptions = {}) {
    super(makeFakeLocalThread(data), opts);

    this.loadFakeThreadFromServer();
  }

  async loadFakeThreadFromServer() {
    const threadWithMessages = await createFakeTempMessageThread({
      message: "",
      usesNewFormat: true,
      // internal is sometimes calculated on the fly, so we pass it through here to make sure the UI is
      // consistent with what gets created (can only be forced to be internal, not the other way around)
      internalOverride: this.thread.internal,
      ...this.data,
    });

    threadWithMessages.id = TEMP_THREAD_ID;
    this.setupThread(threadWithMessages);
  }

  async respond(message?: MessageInfo): Promise<void> {
    if (!this.threadExists) {
      const threadWithMessages = await createMessageThread({
        message: message.message,
        usesNewFormat: message.usesNewFormat,
        taggedUsers: message.taggedUsers,
        taggedGroups: message.taggedGroups,
        attachmentIds: message.attachments.map((a) => a.fileId),
        // internal is sometimes calculated on the fly, so we pass it through here to make sure the UI is
        // consistent with what gets created (can only be forced to be internal, not the other way around)
        internalOverride: this.thread.internal,
        ...this.data,
      });

      this.setupThread(threadWithMessages);

      return;
    }

    await super.respond(message);
  }

  protected async insertMessageHandler(event: MessagesChanged) {
    if (event.isNewThread) {
      this.setupThread({...event.thread, messages: event.messages});
      return;
    }

    await super.insertMessageHandler(event);
  }

  protected setupThread(threadWithMessages: MessageThreadWithMessagesInfo) {
    // need to re-join rooms now that we have a thread ID
    const wasInRooms = !!this.messagesSocket;
    this.leaveMessageRooms();
    super.setupThread(threadWithMessages);
    if (wasInRooms) {
      this.joinMessageRooms();
    }
  }
}

function makeFakeLocalThread(data: AnyRelatedThreadsInput): MessageThreadWithMessagesInfo {
  const now = new Date().toISOString();
  const user = getCurrentUser();
  const userAvatar: UserAvatar = {
    _id: user._id,
    displayName: user.displayName,
    imageThumbnailUrl: user.imageThumbnailUrl,
    email: user.email,
  };

  const entity: BasicEntityInfoLite = {
    id: data.threadEntityId,
    name: getTextFromLocaleObj(data.fallbackEntityName, user.locale),
    logo: data.fallbackEntityLogo,
  };

  const label = getTextFromLocaleObj(data.fallbackElementLabel);
  const internal = data.fallbackIsInternal;

  const involvedEntities = [{id: internal ? null : data.fallbackCounterpartyId}, {id: getUserActiveEntityId()}].filter(
    (e) => !!e.id,
  );

  return {
    id: TEMP_THREAD_ID,
    entity,
    connectionId: data.connectionId,
    creator: userAvatar,
    label,
    objectType: data.objectType,
    involvedUserEntityPairs: [],
    participatingUserEntityPairs: [],
    taggedGroups: [],
    internal,
    involvedEntities,
    latestMessageMeta: {
      author: userAvatar,
      authorEntity: {id: data.threadEntityId} as BasicEntityInfoLite,
      timestamp: now,
    },
    totalMessages: 0,
    unreadMessages: 0,
    elementId: data.elementId,
    instanceId: data.instanceId,
    task: {id: data.taskId} as TaskForThreadLite,
    actionPlanId: data.actionPlanId,
    kitId: data.kitId,
    kitType: data.kitType,
    createdAt: now,
    updatedAt: now,
    messages: [],
  };
}
