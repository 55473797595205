import {Locale} from "pg-isomorphic/enums";
import {getTextFromLocaleObj, withoutMachineTranslations} from "pg-isomorphic/utils/locale";
import {pathOr} from "ramda";

export default function (store) {
  store.getModuleParentState = function (childState) {
    let parentState = null;
    const visitor = (module, parentModule) => {
      if (module.state === childState) {
        parentState = parentModule.state;
        return true;
      }
      const childKeys = module._children ? Object.keys(module._children) : [];
      for (const child of childKeys) {
        if (visitor(module._children[child], module)) {
          break;
        }
      }
      return false;
    };
    visitor(this._modules.root);
    return parentState;
  };

  store.getUserLocalizedText = function (txtOrObj, allowMachineTranslations) {
    let value = txtOrObj;
    if (!allowMachineTranslations) {
      value = withoutMachineTranslations(txtOrObj);
    }
    return getTextFromLocaleObj(value, store.getUserLocale(), true);
  };

  store.getUserLocale = function () {
    return pathOr(Locale.EN_US, ["user", "locale"], store.state);
  };
}
