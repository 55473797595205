import {getStore} from "@/composables/get.store";
import type {MessageRepo} from "@/composables/messaging/message-repo";
import type {FloatingChatWindowState} from "@/store/modules/messaging/floating-chat-window";

export class FloatingChatWindowService {
  static get state(): FloatingChatWindowState {
    return getStore().state.floatingChatWindow;
  }

  static open(messageRepo: MessageRepo) {
    // leave existing room if opening another chat window directly
    this.state.messageRepo?.leaveMessageRooms();

    getStore().commit("floatingChatWindow/messageRepo", messageRepo);
    messageRepo.joinMessageRooms();
    getStore().commit("floatingChatWindow/open", true);
  }

  static close() {
    this.state.messageRepo?.leaveMessageRooms();
    getStore().commit("floatingChatWindow/messageRepo", null);
    getStore().commit("floatingChatWindow/open", false);
  }
}
