export enum ContactInformationType {
  EMAIL = "email",
  PHONE = "phone",
  FAX = "fax",
}

export enum ContactCenterFilterType {
  PERMISSIONS = "permissions",
  ROLES = "roles",
  GROUPING = "grouping",
}

export const NUM_OF_CONTACTS_TO_SHOW = 5;
