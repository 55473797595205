import approve from "./approve";
import manage from "./manage";
import reviews from "./reviews";

export default {
  namespaced: true,
  modules: {
    manage,
    approve,
    reviews,
  },
};
