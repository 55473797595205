import cloneDeep from "lodash/cloneDeep";
import {processTreeTopDown} from "pg-isomorphic/profile";
import {ProfileFilter} from "pg-isomorphic/profile/filter";
import type {JSONObject, JSONQuestion} from "pg-isomorphic/utils";
import querystring from "querystring";
import {is} from "ramda";
import globalLogger from "../../../logging";
import type {PGActionContext, PGActionTree} from "../../index-types";
import {autoMutations} from "../../utils";

const logger = globalLogger.getLogger("switched");

export interface SwitchedState {
  switchLoading: boolean;
  error: JSONObject;
}

type Ctx = PGActionContext<SwitchedState>;

const initialState: SwitchedState = {
  switchLoading: false,
  error: {},
};

const filterMagicKeys = (data: JSONObject): JSONObject => {
  const newResult: JSONObject = {};
  for (const key of Object.keys(data)) {
    if (is(Object, data[key]) && !Array.isArray(data[key])) {
      newResult[key] = filterMagicKeys(data[key] as JSONObject);
    } else if (!key.startsWith("__")) {
      newResult[key] = data[key];
    }
  }
  return newResult;
};

const getInternalUse = (questions: JSONQuestion, answers: JSONObject): JSONObject => {
  const filtered = new ProfileFilter(questions, answers, {
    topic: [],
    unanswered: false,
    required: false,
    internalUse: true,
  }).filter();

  const results: JSONObject = {};
  processTreeTopDown((q: JSONQuestion) => {
    if (q.internalUse && answers[q.key]) {
      results[q.key] = answers[q.key];
    }
  })(filtered);

  return filterMagicKeys(results);
};

const actions: PGActionTree<SwitchedState> = {
  async getTheirs({commit}: Ctx, args: {connectionId: string; entityId: string}) {
    commit("switchLoading", true);

    const query = querystring.stringify({connectionId: args.connectionId});
    logger.trace(() => `grabbing questions for connection ${args.connectionId}, entity ${args.entityId}`);

    const response = await this.httpGet<{
      connection: JSONObject;
      questions: JSONQuestion;
      answers: JSONObject;
    }>(`/api/questions/theirs?${query}`);

    commit("switchLoading", false);

    return getInternalUse(response.data.questions, response.data.answers);
  },

  async saveData({commit}: Ctx, args: {connectionId: string; entityId: string; answers: JSONObject}) {
    try {
      commit("switchLoading", true);
      logger.trace(() => `saveData ${args.connectionId} => ${args.entityId}`);

      const result = await this.httpPatch("/api/answers", {
        connectionId: args.connectionId,
        pertainingToEntityId: args.entityId,
        ...args.answers,
      });

      if (result.status !== 200) {
        logger.error(() => `http error ${JSON.stringify(result, null, 2)}`);
        commit("error", result);
        return false;
      } else {
        return true;
      }
    } catch (e) {
      logger.error(() => `error ${JSON.stringify(e.response, null, 2)}`);
      commit("error", e.response);
      return false;
    } finally {
      commit("switchLoading", false);
    }
  },
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: autoMutations(initialState),
  actions,
};
