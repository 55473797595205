<template>
  <div class="outer-main" :data-key="getRouterViewKey()">
    <router-view :key="getRouterViewKey()" />
    <ConfirmDialog />
    <PrimeDialog />
    <PrimeToast />
    <IssueCollector />
    <GraphiteTooltipDirectiveHelper />
    <ZendeskChat v-if="zendeskKey" :zendeskKey="zendeskKey" />
    <SupplierSurveyModal />
    <!--    <ChatLink />-->
  </div>
</template>

<script lang="ts" setup>
import GraphiteTooltipDirectiveHelper from "@/components/common/GraphiteTooltipDirectiveHelper.vue";
import SupplierSurveyModal from "@/components/supplier-survey/SupplierSurveyModal.vue";
import ZendeskChat from "@/components/ZendeskChat.vue";
import {getStore} from "@/composables/get.store";
import {getI18n} from "@/composables/i18n";
import {setInitialLanguage} from "@/composables/menu";
import {CspNonceKey, RootKey} from "@/composables/root";
import {updateWindowDimensions} from "@/composables/screen";
import {getCurrentUser} from "@/composables/vuex";
import {getRouterViewKey} from "@/router/util";
import {globalEmitter, GlobalEvent} from "@/store/plugins/emitter";
import {onResize} from "@/utils/screen";
import {BroadcastChannel} from "broadcast-channel";
import debounce from "lodash/debounce";
import {Locale} from "pg-isomorphic/enums";
import {usePrimeVue} from "primevue/config";
import PrimeDialog from "primevue/dialog";
import DynamicDialog from "primevue/dynamicdialog";
import type {ToastMessageOptions} from "primevue/toast";
import PrimeToast from "primevue/toast";
import {useToast} from "primevue/usetoast";
import {computed, getCurrentInstance, onMounted, onUnmounted, provide, watch} from "vue";
import ConfirmDialog from "./components/shared/ConfirmDialog.vue";
import IssueCollector from "./utils/IssueCollector.vue";

const events = ["mousedown", "keypress", "touchstart", "click"];

// TODO [vue3] idk how else to do this right now
const root = getCurrentInstance().vnode.component as any;
const store = getStore();
const zendeskKey = computed(() => store.state.envConfig?.zendeskKey);

provide(CspNonceKey, document.querySelector<HTMLMetaElement>("meta[property=csp-nonce]")?.content || null);
provide(RootKey, root);
let channel: BroadcastChannel;
store.dispatch("joinClientVersionRooms");

const resetUserActivity = function (evt: Event) {
  store.dispatch("resetUserActivityTimer", {type: evt.type});
};

const toast = useToast();
function handleServiceToast(options: ToastMessageOptions) {
  toast.add(options);
}

onMounted(() => {
  window.addEventListener("resize", onResize);

  // for creating toasts in 'service' situations (outside of a component)
  globalEmitter.on(GlobalEvent.Toast, handleServiceToast);

  setInitialLanguage(window.navigator.language);
});

onUnmounted(() => {
  events.forEach((ev) => document.removeEventListener(ev, resetUserActivity));
  channel.close();
  store.dispatch("leaveClientVersionRooms");
  window.removeEventListener("resize", onResize);
  globalEmitter.off(GlobalEvent.Toast, handleServiceToast);
});

// Created lifecycle replacement, setup cannot be async in vue 2, hence this.
(async () => {
  events.forEach((ev) => document.addEventListener(ev, resetUserActivity));
  channel = new BroadcastChannel("activity");
  channel.addEventListener("message", (evt) => {
    if (evt?.type !== "message") {
      evt = {type: "message"};
    }
    resetUserActivity(evt);
  });
})();

updateWindowDimensions();
addEventListener("resize", debounce(updateWindowDimensions, 150));

const primevue = usePrimeVue();
const i18n = getI18n();

// if a locale changes and the messages don't exist, they are loaded.
// but if a locale changes and the messages already exist, messages don't change...
// so we need to watch both!
watch(
  [() => getCurrentUser()?.locale, () => i18n.messages[getCurrentUser()?.locale]],
  ([locale, msgs]) => {
    if (!locale || !msgs) {
      return;
    }

    const translations = i18n.messages[locale]?.prime;
    const fallback = i18n.messages[Locale.EN_US]?.prime;

    primevue.config.locale = {
      ...primevue.config.locale,
      ...fallback,
      ...translations,
      // handled in a different `watch`
      dateFormat: primevue.config.locale.dateFormat,
    };
  },
  {immediate: true},
);

watch(
  () => getCurrentUser()?.localeSettings?.dateFormat,
  (dateFormat?: string) => {
    if (!dateFormat) {
      return;
    }

    primevue.config.locale.dateFormat = dateFormat;
  },
  {immediate: true},
);

defineOptions({
  compatConfig: {
    MODE: 3,
  },
});
</script>

<style lang="less">
@import "./less/graphite-general.less";
@import "./less/input.less";
@import "./less/transitions.less";

.outer-main {
  position: relative;
}

.center {
  text-align: center;
}

.legal-row {
  margin-top: 10px;
}

.sign-in-row {
  margin-top: 30px;
  margin-bottom: 25px;
}

.btn-wrap {
  margin-top: 40px;
}
</style>
