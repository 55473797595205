///////////////////////////////////////////////////////////
// THIS FILE CANNOT IMPORT ANYTHING (other than isExcel) //
///////////////////////////////////////////////////////////

import type {PGStore} from "@/store/index-types";
import {isExcel} from "@/utils/excel";
import type {ResponderKeywordSearchType, ResponderSearchVersion} from "pg-isomorphic/api/responder";
import type {SignInResponse} from "pg-isomorphic/api/user";

// need newer version of TypeScript for recursion
// type Unwrapped<T> = T extends Array<infer U> ? Unwrapped<U> : T;
type Unwrapped<T> = T extends Array<infer U> ? (U extends Array<infer V> ? V : U) : T;

function isUnwrapped<T>(input: T | Unwrapped<T>): input is Unwrapped<T> {
  return !Array.isArray(input);
}

function unwrapArray<T>(input: T): Unwrapped<T> {
  if (isUnwrapped(input)) {
    return input;
  }

  return unwrapArray(input[0]);
}

export enum ResponderStorageKey {
  SignInResponse = "ResponderSignInResponse",
  RememberMe = "ResponderRememberMe",

  NumMatches = "ResponderNumMatches",

  Dev_UserQuestion = "DEV::RESPONDER::USER_QUESTION",
  Dev_ShowTokens = "DEV::RESPONDER::SHOW_TOKENS",
  Dev_ShowQuestionType = "DEV::RESPONDER::SHOW_QUESTION_TYPE",
  Dev_ShowScores = "DEV::RESPONDER::SHOW_SCORES",
  Dev_UseAndKeywordSearch = "DEV::RESPONDER::USE_AND_KEYWORD_SEARCH",
  Dev_ResponderSearchVersion = "DEV::RESPONDER::RESPONDER_SEARCH_VERSION",
}

export interface ExcelStorageTypes {
  [ResponderStorageKey.SignInResponse]: SignInResponse;
  [ResponderStorageKey.RememberMe]: boolean;

  [ResponderStorageKey.NumMatches]: number;

  [ResponderStorageKey.Dev_UserQuestion]: string;
  [ResponderStorageKey.Dev_ShowScores]: boolean;
  [ResponderStorageKey.Dev_ShowQuestionType]: boolean;
  [ResponderStorageKey.Dev_ShowTokens]: boolean;
  [ResponderStorageKey.Dev_UseAndKeywordSearch]: ResponderKeywordSearchType;
  [ResponderStorageKey.Dev_ResponderSearchVersion]: ResponderSearchVersion;
}

export enum ExcelEventKey {
  SearchWithCurrentCell = "ResponderSearchWithCurrentCell",
}

export interface ExcelEventTypes {
  [ExcelEventKey.SearchWithCurrentCell]: string;
}

type ExcelEventCallback<K extends ExcelEventKey> = (data: ExcelEventTypes[K]) => any;

interface Listener<K extends ExcelEventKey> {
  callback: ExcelEventCallback<K>;
}

export type DialogOptions = Partial<Office.DialogOptions> & {expectResponse?: boolean};
export type DialogOptionsWithExpectedResponse = Partial<Office.DialogOptions> & {expectResponse: true};
export type DialogOptionsNoExpectedResponse = Partial<Office.DialogOptions> & {expectResponse: false};

export interface SsoDialogResponse {
  token: string;
  tokenExpiresOn: number;
  rememberMe: boolean;
}

export type Dialog = Office.Dialog;

let questionCol: number;
let answerCol: number;

declare global {
  interface Window {
    __excelPubSub: Record<ExcelEventKey, ExcelEventCallback<ExcelEventKey>[]>;
  }
}

window.__excelPubSub = {
  [ExcelEventKey.SearchWithCurrentCell]: [],
};

export class ResponderExcel {
  public static storage = {
    async setItem<K extends ResponderStorageKey>(key: K, data: ExcelStorageTypes[K]): Promise<void> {
      if (!isExcel) {
        window.localStorage.setItem(key, JSON.stringify(data));
        return;
      }

      if (!ResponderExcel.checkOffice()) return;
      await OfficeRuntime.storage.setItem(key, JSON.stringify(data));
    },

    async getItem<K extends ResponderStorageKey>(key: K): Promise<ExcelStorageTypes[K]> {
      if (!isExcel) {
        return JSON.parse(window.localStorage.getItem(key) || null);
      }

      if (!ResponderExcel.checkOffice()) return;
      try {
        const strData: string = await OfficeRuntime.storage.getItem(key);
        return JSON.parse(strData) || null;
      } catch (e) {
        console.error("JSON parse error?", e);
        return null;
      }
    },

    async removeItem<K extends ResponderStorageKey>(key: K): Promise<void> {
      if (!ResponderExcel.checkOffice()) return;
      await OfficeRuntime.storage.removeItem(key);
    },

    async clear(): Promise<void> {
      if (!ResponderExcel.checkOffice()) return;
      await OfficeRuntime.storage.removeItems(await OfficeRuntime.storage.getKeys());
    },
  };

  public static async setCurrentCellText(str: string): Promise<void> {
    if (!ResponderExcel.checkOffice()) return;
    await Excel.run(async (context: Excel.RequestContext) => {
      const cell = context.workbook.getActiveCell();

      cell.load({text: true, columnIndex: true, rowIndex: true});
      await context.sync();

      cell.values = [[str]];

      answerCol = cell.columnIndex;
      if (questionCol !== undefined) {
        context.workbook.worksheets
          .getActiveWorksheet()
          .getRangeByIndexes(cell.rowIndex + 1, questionCol, 1, 1)
          .select();
      }

      await context.sync();
    });
  }

  public static async getCurrentCellText(): Promise<string> {
    if (!ResponderExcel.checkOffice()) return;
    let str = "";

    await Excel.run(async (context: Excel.RequestContext) => {
      const cell: Excel.Range = context.workbook.getActiveCell();

      cell.load({text: true, columnIndex: true, rowIndex: true});
      await context.sync();

      questionCol = cell.columnIndex;
      context.workbook.worksheets
        .getActiveWorksheet()
        .getRangeByIndexes(cell.rowIndex, answerCol ?? cell.columnIndex + 1, 1, 1)
        .select();

      str = unwrapArray(cell.text);
    });

    return str;
  }

  public static publish<K extends ExcelEventKey>(event: ExcelEventKey, data: ExcelEventTypes[K]): void {
    // we should define these above, but just in case...
    const listeners = window.__excelPubSub[event] || [];

    // bad things can happen if the events aren't async, lol
    setTimeout(() => {
      listeners.forEach((callback) => {
        callback(data);
      });
    });
  }

  public static subscribe<K extends ExcelEventKey>(event: ExcelEventKey, callback: Listener<K>["callback"]) {
    // we should define these above, but just in case...
    if (!window.__excelPubSub[event]) {
      window.__excelPubSub[event] = [];
    }

    window.__excelPubSub[event].push(callback as Listener<ExcelEventKey>["callback"]);
  }

  static async restore(store: PGStore) {
    const signInResponse = await ResponderExcel.storage.getItem(ResponderStorageKey.SignInResponse);
    const rememberMe = await ResponderExcel.storage.getItem(ResponderStorageKey.RememberMe);

    if (signInResponse?.tokenExpiresOn && signInResponse.tokenExpiresOn > new Date().getTime() / 1000) {
      await store.dispatch("completeSignIn", {signInResponse, rememberMe});
    }
  }

  static async signOut(store: PGStore) {
    await store.dispatch("signOut");
  }

  static async sendMessageFromDialog<T = any>(message: T): Promise<void> {
    if (!this.checkOffice()) {
      return;
    }

    await new Promise<void>((resolve) => {
      window.Office.onReady(() => {
        window.Office.context.ui.messageParent(JSON.stringify(message));
        resolve();
      });
    });
  }

  static async openSsoDialog(url: string) {
    const {getRouter} = await import("@/router/util");
    const {getStore} = await import("@/composables/get.store");

    const {response, dialog} = await this.openDialog<SsoDialogResponse>(url, {
      width: 550,
      height: 700,
      expectResponse: true,
    });
    if (response?.token) {
      dialog.close();
      await getStore().setJWT(response?.token, response?.tokenExpiresOn, false);
      await getStore().dispatch("checkAuthentication");
      await getRouter().push("/responder");
    } else {
      throw new Error("Did not complete SSO process");
    }
  }

  static async openDialog<T = any>(
    url: string,
    options: DialogOptionsWithExpectedResponse,
  ): Promise<{dialog: Dialog; response: T}>;
  static async openDialog(url: string, options: DialogOptionsNoExpectedResponse): Promise<{dialog: Dialog}>;
  static async openDialog<T = any>(url: string, options: DialogOptions): Promise<{dialog: Dialog; response?: T}> {
    if (!this.checkOffice()) {
      return;
    }

    const {expectResponse, ...officeOptions} = options || {};
    return await new Promise<{dialog: Dialog; response?: T}>((resolve, reject) => {
      window.Office.onReady(() => {
        window.Office.context.ui.displayDialogAsync(url, officeOptions, (dialogueResponse) => {
          if (dialogueResponse.error) {
            reject(new Error(dialogueResponse.error?.message));
            return;
          }

          const dialog = dialogueResponse.value;
          if (!expectResponse) {
            resolve({dialog});
            return;
          }

          dialog.addEventHandler(window.Office.EventType.DialogMessageReceived, async (event) => {
            if ((event as any).error) {
              reject(new Error((event as any).error));
              return;
            }

            try {
              resolve({dialog, response: JSON.parse((event as any).message)});
            } catch (err) {
              reject(err);
            }
          });
        });
      });
    });
  }

  private static checkOffice(): boolean {
    if (!window.OfficeRuntime) {
      // eslint-disable-next-line no-console
      console.warn("OfficeRuntime not found, cannot perform Excel-specific functionality");
      return false;
    }

    return true;
  }
}

////////////////////
// EXCEL COMMANDS //
////////////////////

async function contextualCopyCell(event: Office.AddinCommands.Event) {
  await Office.addin.showAsTaskpane();

  ResponderExcel.publish(ExcelEventKey.SearchWithCurrentCell, await ResponderExcel.getCurrentCellText());

  event.completed();
}

declare global {
  interface Window {
    contextualCopyCell: typeof contextualCopyCell;
  }
}

if (isExcel) {
  // the add-in command functions need to be available in global scope
  window.contextualCopyCell = contextualCopyCell;
}
