import {all, any, clone, either, filter, findIndex, has, propEq, sort, pathOr} from "ramda";
import type {PGActionTree, PGGetterTree, PGMutationTree} from "./../../index-types";
import {
  ConnectionCancelAction,
  ConnectionRole,
  getOppositeConnectionRole,
  Stage,
} from "pg-isomorphic/enums/connections";
import {RoutingKey} from "pg-isomorphic/queue";
import {TaskChangeType, TaskType} from "pg-isomorphic/enums";
import {ChangeType} from "pg-isomorphic/enums/queue";
import {canAdvanceConnection, canDisconnectConnection, check, Permission} from "pg-isomorphic/permissions";
import {simpleMutation} from "../../utils";
import moment from "moment";
import globalLogger from "../../../logging";
import {isEmptyOrUndefined, sleep} from "pg-isomorphic/utils";
import {ConnectionState, ProfileEvent} from "pg-isomorphic/enums/events";
import cloneDeep from "lodash/cloneDeep";
import type {ConnectionWithNames} from "pg-isomorphic/api/connection";
import type {Task} from "pg-isomorphic/api/tasks";
import type {AxiosResponse} from "axios";

const logger = globalLogger.getLogger("connectionStore");

let _updateConnection = null;
let _removeTask = null;
let _updateTask = null;
let _addTask = null;

export interface ConnectionVuexState extends ConnectionWithNames {
  connectionId: string;
  isReconnecting: boolean;
  collapsed: boolean;
  tasks: Task[];
  theirTasks: Task[];
  tasksAuditDetails: string[];
  theirTasksLoaded: boolean;
  initialTasksLoaded: boolean;
  approveConnectionError: string;
  resendResult: string;
  resetFilters: boolean;
  canceling: boolean;
  cancelingInvite: boolean;
  saving: boolean;
  applyingFilter: boolean;
  advancingConnection: boolean;
  saveTaskProcessing: boolean;
  error: string;
  inviteeDetails: any;
  taskDrawerOpen: boolean;
  cancelingError: null;
  showValidationStatus: boolean; // Added here but should have been profileStore, oh well composables changes all this junk
  fileUploading: boolean;
  preventSave: boolean;
  supplierDisconnectReasonCode: any;
  newlyRegisteredCampaign: string;
  buyerCampaignRegistrations: any;
}

const initialState: ConnectionVuexState = {
  status: null, // public, invite, accept, collaborate, connect
  isReconnecting: false,
  collapsed: true,
  _id: null,
  connectionId: null,
  createdAt: null,
  updatedAt: null,
  requestingEntity: null,
  respondingEntity: null,
  requestingRole: null,
  respondingRole: null,
  inviteApprovedBy: null,
  inviteApprovedAt: null,
  inviteCompletedAt: null,
  acceptApprovedBy: null,
  acceptApprovedAt: null,
  acceptCompletedAt: null,
  collabReqApprovedBy: null,
  collabReqApprovedAt: null,
  collabResApprovedBy: null,
  collabResApprovedAt: null,
  collabCompletedAt: null,
  connectReqApprovedBy: null,
  connectReqApprovedAt: null,
  connectResApprovedBy: null,
  connectResApprovedAt: null,
  connectCompletedAt: null,
  disconnectRequestedAt: null,
  disconnectCompletedAt: null,
  pendingTaskProcessing: null,
  taskCounts: null,
  tasks: [],
  theirTasks: [],
  tasksAuditDetails: [],
  theirTasksLoaded: false,
  initialTasksLoaded: false,
  approveConnectionError: null,
  resendResult: null,
  resetFilters: false,
  canceling: false,
  cancelingInvite: false,
  saving: false,
  applyingFilter: false,
  advancingConnection: false,
  saveTaskProcessing: false,
  error: null,
  inviteeDetails: {},
  taskDrawerOpen: false,
  cancelingError: null,
  showValidationStatus: false, // Added here but should have been profileStore, oh well composables changes all this junk
  campaignRegistrations: [],
  buyerCampaignRegistrations: [],
  fileUploading: false,
  preventSave: false,
  supplierDisconnectReasonCode: null,
  newlyRegisteredCampaign: "",
  counterpartyEntityInfo: null,
  respondingEntityInfo: null,
};

// getters
const getters: PGGetterTree<ConnectionVuexState> = {
  isMine: (state, getters, rootState) => {
    const requestingEntity = (rootState.user || {}).activeEntityId;
    return state.requestingEntity === requestingEntity;
  },
  connectionProgress: (state) => {
    if (!state.status || state.status === Stage.PUBLIC) {
      return 0;
    } else if (state.status === Stage.INVITE) {
      return 1;
    } else if (state.status === Stage.ACCEPT) {
      return 1;
    } else if (state.status === Stage.COLLAB) {
      return 2;
    } else if (state.status === Stage.CONNECT) {
      return 3;
    } else if (state.status === Stage.CONNECTED) {
      return 4;
    } else if (state.status === Stage.DISCONNECTED || state.status === Stage.DISCONNECT) {
      return 5;
    }
  },
  allTasksCompleted(state) {
    return all(either(propEq("completed", true), propEq("type", TaskType.ADVANCE_CONNECTION)), state.tasks);
  },
  allTasksCompletedMinusNonBlockingTasks(state) {
    return all((t) => {
      return (
        t.completed ||
        t.type === TaskType.ADVANCE_CONNECTION ||
        t.type === TaskType.USER_GENERATED ||
        t.type === TaskType.REMINDER ||
        (t.type === TaskType.WORKFLOW && !t.data.blocksAdvancement)
      );
    }, state.tasks);
  },
  canCancel(state, getters, rootState) {
    const connectionRole = getters.connectionRole;
    const isRequestingEntity = rootState.user.activeEntityId === state.requestingEntity;
    const total = (getters.theirTasks || []).length;
    const completed = total - (getters.theirOpenTasks || []).length;
    const allCounterPartyTasksCompleted = total > 0 && total === completed;
    switch (state.status) {
      case Stage.INVITE:
        return true;
      case Stage.DISCONNECT:
        return false;
      case Stage.ACCEPT:
        if (allCounterPartyTasksCompleted) {
          return canDisconnectConnection(
            state.status,
            connectionRole,
            rootState.user,
            state.isReconnecting,
            isRequestingEntity,
          );
        } else {
          return isRequestingEntity;
        }
      case Stage.DISCONNECTED:
        if (!isRequestingEntity) {
          return false;
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return canDisconnectConnection(
          state.status,
          connectionRole,
          rootState.user,
          state.isReconnecting,
          isRequestingEntity,
        );
    }
  },
  canAdvance: (state, getters, rootState) => {
    const connectionRole = getters.connectionRole;
    if (!(canAdvanceConnection(state.status, connectionRole, rootState.user) && state.status !== Stage.CONNECTED)) {
      return false;
    }
    if (
      rootState.profile?.mineEntity?.restrictConnectionAdvancementBySegmentation &&
      !check(Permission.APPROVE_ANY_CONNECTION_STAGE, rootState.user)
    ) {
      return any((t) => {
        return (
          !t.completed &&
          t.type === TaskType.ADVANCE_CONNECTION &&
          any((u) => {
            return u._id === rootState.user._id;
          }, t.effectiveAssignedUsers)
        );
      }, state.tasks);
    }
    return true;
  },
  connectionRole(state, getters, rootState) {
    return rootState.user.activeEntityId === state.requestingEntity ? state.requestingRole : state.respondingRole;
  },
  theirConnectionRole(state, getters) {
    return getters.connectionRole === ConnectionRole.BUYER ? ConnectionRole.SELLER : ConnectionRole.BUYER;
  },
  theirTasksFilteredByStage: (state) => {
    return filter((t: Task) => t.connectionStage === state.status || !has("connectionStage", t), state.theirTasks);
  },
  theirOpenTasks(state) {
    return filter((t: Task) => t.completed === false, state.theirTasks);
  },
  cancelingOrSaving(state) {
    return state.saving || state.canceling || state.fileUploading || state.preventSave;
  },
};

// actions
const actions: PGActionTree<ConnectionVuexState> = {
  async invite(
    {commit, rootState},
    {
      respondingEntity,
      requestingRole,
      inviteeCustomizedRole,
    }: {respondingEntity: string; requestingRole: ConnectionRole; inviteeCustomizedRole: any},
  ) {
    const requestingEntity = rootState.user.activeEntityId;
    const respondingRole = getOppositeConnectionRole(requestingRole);
    const connection = {
      requestingEntity,
      respondingEntity,
      requestingRole,
      respondingRole,
      status: "invite",
      inviteeCustomizedRole,
    };
    const saved: AxiosResponse<{connection: ConnectionWithNames}> = await this.httpPost("/api/connections", {
      data: connection,
    });
    commit("updateConnection", saved.data.connection);
  },

  async cancelInvite({state, dispatch, commit}) {
    try {
      commit("cancelingInvite", true);
      await sleep(50); // Give enough time for the activity indicator to show;
      const res: AxiosResponse<{action: ConnectionCancelAction}> = await this.httpDelete(
        `/api/connections/${state._id}`,
      );
      if (res.data.action === ConnectionCancelAction.CANCEL) {
        dispatch("reset");
        return {redirect: true};
      }
      return {redirect: false};
    } catch (e) {
      logger.error(() => `error cancelling invite`, e);
      commit("cancelingError", e.response.data);
    } finally {
      commit("cancelingInvite", false);
    }
  },

  reset({commit, dispatch}) {
    dispatch("leaveConnectionRooms");
    commit("updateConnection");
  },

  async approve({commit, state}) {
    try {
      commit("approveConnectionError", null);
      commit("advancingConnection", true);
      await sleep(50); // Give enough time for the activity indicator to show;
      await this.httpPut(`/api/connections/approval/${state._id}`, {status: state.status});
    } catch (err) {
      commit("approveConnectionError", err.response.data);
    } finally {
      commit("advancingConnection", false);
    }
  },

  async reconnect({commit, state}) {
    try {
      commit("approveConnectionError", null);
      commit("advancingConnection", true);
      await sleep(50); // Give enough time for the activity indicator to show;
      logger.trace(`reconnecting ${state._id}`);
      await this.httpPut(`/api/connections/reconnect/${state._id}`, {});
    } catch (err) {
      commit("approveConnectionError", err.response.data);
    } finally {
      commit("advancingConnection", false);
    }
  },

  async getConnection({commit}, {connectionId}: {connectionId: string}) {
    const response: AxiosResponse<{connection: ConnectionWithNames}> = await this.httpGet(
      `/api/connections/${connectionId}`,
    );

    commit("updateConnection", response.data.connection);
  },

  async reloadIfWaitingOnTasks({dispatch, state}) {
    if (!state.pendingTaskProcessing) {
      return;
    }
    await dispatch("getConnection", {connectionId: state._id});
    await dispatch("loadConnectionTasks", {});
  },

  async delayedUpdateConnectionAndTasks(
    {dispatch, state},
    {connectionId, onlyIfProcessing}: {connectionId: string; onlyIfProcessing: boolean},
  ) {
    setTimeout(() => {
      if (onlyIfProcessing && !state.pendingTaskProcessing) {
        return;
      }
      dispatch("getConnection", {connectionId});
      dispatch("loadConnectionTasks", {});
    }, 150);
  },

  updateCurrentConnection: ({commit, state, dispatch}, connection) => {
    const {status: oldStatus, updatedAt: oldUpdatedDate, pendingTaskProcessing: oldPendingCount} = state;
    // Don't update connection if we have a newer one
    if (moment(oldUpdatedDate).isAfter(moment(connection.updatedAt))) {
      logger.debug(() => `NOT updating current connection - it's too old`, connection);
      return;
    }
    logger.debug(() => `updating current connection`, connection);
    commit("saveTaskProcessing", false);
    commit("updateConnection", connection);
    if ((oldStatus !== state.status || oldPendingCount) && !connection.pendingTaskProcessing) {
      logger.debug(() => `connection phase changed - refreshing profiles`);
      dispatch("profile/refresh", {connectionId: state._id, campaignId: state.newlyRegisteredCampaign}, {root: true});
      commit("newlyRegisteredCampaign", "");
    }
  },

  async loadConnectionTasks({commit, state}, {connectionId, completed}: {connectionId: string; completed: boolean}) {
    try {
      const response: AxiosResponse<{tasks: Task[]}> = await this.httpPost("/api/tasks/query", {
        connectionId: connectionId || state._id,
        includeAssignedUsers: true,
        includeApprovals: true,
        includeAuditInfo: false,
        includeUpdatedByName: true,
        includeNotes: true,
        includeOwningEntity: true,
        includeMessageCounts: true,
        completed,
      });
      commit("tasks", response.data.tasks);

      let tasks = cloneDeep(response.data.tasks);
      let results = sort((task1, task2) => {
        return moment(task2.createdAt).unix() - moment(task1.createdAt).unix();
      }, tasks);
      commit(
        "tasksAuditDetails",
        filter((task) => !isEmptyOrUndefined(task.auditInfo), results),
      );
      this.emitter.$emit(ConnectionState.TASKS_LOADED, {});
      return response.data.tasks;
    } catch (err) {
      commit("error", err.response.data);
      return [];
    }
  },

  async loadConnectionTheirTasks({commit, state}, {connectionId}: {connectionId: string}) {
    try {
      if (connectionId || state._id) {
        const response: AxiosResponse<{tasks: Task[]}> = await this.httpPost("/api/tasks/query/theirs", {
          connectionId: connectionId || state._id,
          includeUpdatedByName: true,
        });
        commit("setTheirTasks", response.data.tasks);
        commit("setTheirTasksLoaded", true);
        return response.data.tasks;
      } else {
        return [];
      }
    } catch (err) {
      commit("error", err.response.data);
      return [];
    }
  },

  async joinConnectionRooms({dispatch, state, rootState}) {
    const entityId = rootState.user.activeEntityId;
    await dispatch("loadConnectionTasks", {});
    await dispatch("loadConnectionTheirTasks", {});
    _updateConnection = (connection) => {
      dispatch("updateCurrentConnection", connection);
      dispatch("loadConnectionTheirTasks", {connectionId: pathOr(null, ["_id"], connection)});
      this.emitter.$emit(ConnectionState.QUESTIONS_UPDATED, {});
    };
    _removeTask = (task) => dispatch("removeTask", task);
    _updateTask = (task) => dispatch("updateTask", task);
    _addTask = (task) => dispatch("addTask", task);

    this.join(RoutingKey.connection({connectionId: state._id}));
    this.socketOn(RoutingKey.connection({connectionId: state._id, evt: ChangeType.UPDATE}), _updateConnection);

    this.join(RoutingKey.connectionTasks({connectionId: state._id, entityId}));
    this.socketOn(
      RoutingKey.connectionTasks({connectionId: state._id, entityId, evt: TaskChangeType.COMPLETE}),
      _updateTask,
    );
    this.socketOn(
      RoutingKey.connectionTasks({connectionId: state._id, entityId, evt: TaskChangeType.UPDATE}),
      _updateTask,
    );
    this.socketOn(RoutingKey.connectionTasks({connectionId: state._id, entityId, evt: TaskChangeType.NEW}), _addTask);
    this.socketOn(
      RoutingKey.connectionTasks({connectionId: state._id, entityId, evt: TaskChangeType.DELETE}),
      _removeTask,
    );
  },

  leaveConnectionRooms({state, rootState}) {
    const entityId = rootState.user.activeEntityId;
    this.leave(RoutingKey.connection({connectionId: state._id}));
    this.socketOff(RoutingKey.connection({connectionId: state._id, evt: ChangeType.UPDATE}), _updateConnection);

    this.leave(RoutingKey.connectionTasks({connectionId: state._id, entityId}));
    this.socketOff(
      RoutingKey.connectionTasks({connectionId: state._id, entityId, evt: TaskChangeType.COMPLETE}),
      _updateTask,
    );
    this.socketOff(
      RoutingKey.connectionTasks({connectionId: state._id, entityId, evt: TaskChangeType.UPDATE}),
      _updateTask,
    );
    this.socketOff(RoutingKey.connectionTasks({connectionId: state._id, entityId, evt: TaskChangeType.NEW}), _addTask);
    this.socketOff(
      RoutingKey.connectionTasks({connectionId: state._id, entityId, evt: TaskChangeType.DELETE}),
      _removeTask,
    );
  },

  removeTask({state, commit}, task) {
    logger.debug(() => `task removed ${task.localizedTitle}`);
    const ix = findIndex((t) => t._id === task._id, state.tasks);
    if (ix > -1) {
      const updated = clone(state.tasks);
      updated.splice(ix, 1);
      commit("tasks", updated);
    }
  },

  addTask({state, commit}, task) {
    logger.debug(() => `task added ${task.localizedTitle}`);
    const updated = clone(state.tasks);
    updated.unshift(task);
    commit("tasks", updated);
  },

  updateTask({state, commit}, task) {
    logger.debug(() => `task updated ${task.localizedTitle}`);
    const ix = findIndex((t) => t._id === task._id, state.tasks);
    if (ix > -1) {
      const updated = clone(state.tasks);
      updated[ix] = task;
      commit("tasks", updated);
    }
  },
  async resendSkeletonInvite({commit}, {connection}) {
    logger.debug(`resending skeleton invite: ${connection}`);
    try {
      const response = await this.httpPost("/api/connections/resendinvite", {connection});
      commit("resendResult", response.status);
    } catch (e) {
      commit("resendResult", e.response.status);
    }
  },
  async updateCollapsed({commit}, {collapsedValue}) {
    await commit("collapsed", collapsedValue);
    this.emitter.$emit(ProfileEvent.CONNECTION_BAR_COLLAPSE_CHANGE, {collapsedValue});
  },
  async lookupConnection({}, {graphiteId}) {
    try {
      const response: AxiosResponse<{connection: ConnectionWithNames}> = await this.httpGet(
        `/api/connections/gid/${graphiteId}`,
      );
      return response.data.connection;
    } catch (err) {
      return undefined;
    }
  },
};

// mutations
const mutations: PGMutationTree<ConnectionVuexState> = {
  updateConnection(state, connection) {
    if (!connection) {
      connection = clone(initialState);
      connection.status = Stage.PUBLIC;
    }
    Object.keys(connection).forEach((key) => {
      state[key] = connection[key];
      if (key === "_id") {
        state["connectionId"] = connection[key];
      }
    });
  },
  collapsed: simpleMutation("collapsed"),
  tasks(state, tasks: Task[]) {
    const sortedTasks = sort((task1, task2) => {
      return moment(task2.createdAt).unix() - moment(task1.createdAt).unix();
    }, tasks);
    state.initialTasksLoaded = true;
    state.tasks = sortedTasks;
  },
  tasksAuditDetails: simpleMutation("tasksAuditDetails"),
  approveConnectionError: simpleMutation("approveConnectionError"),
  resendResult: simpleMutation("resendResult"),
  resetFilters: simpleMutation("resetFilters"),
  cancelingInvite: simpleMutation("cancelingInvite"),
  canceling: simpleMutation("canceling"),
  cancelingError: simpleMutation("cancelingError"),
  setTheirTasksLoaded: simpleMutation("theirTasksLoaded"),
  saveTaskProcessing: simpleMutation("saveTaskProcessing"),
  advancingConnection: simpleMutation("advancingConnection"),
  saving: simpleMutation("saving"),
  applyingFilter: simpleMutation("applyingFilter"),
  taskDrawerOpen: simpleMutation("taskDrawerOpen"),
  showValidationStatus: simpleMutation("showValidationStatus"),
  fileUploading: simpleMutation("fileUploading"),
  preventSave: simpleMutation("preventSave"),
  newlyRegisteredCampaign: simpleMutation("newlyRegisteredCampaign"),
  campaignRegistrations(state, crs) {
    state.campaignRegistrations = crs;
  },
  buyerCampaignRegistrations(state, crs) {
    state.buyerCampaignRegistrations = crs;
  },
  setTheirTasks(state, tasks) {
    state.theirTasks = tasks;
  },
  error: simpleMutation("error"),
};

export default {
  namespaced: true,
  state: clone(initialState),
  getters,
  actions,
  mutations,
};
