import type {Dictionary} from "ramda";

export interface ButtonConfig {
  id?: string;
  label?: string;
  icon?: Array<string>;
  variant?: string; // Bootstrap variant
  size?: string; // Bootstrap size (sm, md, lg)
  class?: string;
  click: Function;
  disabled?: boolean;
  title?: string;
}

export interface ButtonSpacer {
  spacer: true;
}

export interface UIError {
  code?: number;
  error?: string;
  customError?: string;
  status?: boolean;
  statusText?: string;
  messageParameters?: Dictionary<string>;
}

export enum ProgressBarVariants {
  PRIMARY = "primary",
  INFO = "info", // duplicate of primary
  WARNING = "warning",
  SUCCESS = "success",
  DANGER = "danger",
}
