import type {Dictionary} from "lodash";
import type {Emitter, Handler} from "mitt";
import mitt from "mitt";
import {onUnmounted} from "vue";
import type {NavigateToElementReturnData, QuestionInfoPaneData} from "@/composables/questions/types";
import type {TargetQuestionInfo} from "pg-isomorphic/props";

/**
 * PUT ALL GLOBAL EVENT BUS EVENTS HERE
 * Format of "<section>:<specificEventName>"
 */
type Events = {
  "visualWorkflow:horizontalScroll": number;
  "answer:answerChanged": {key: string; value: any};
  "answer:answerReverted": {key: string; value: any};
  "answer:answerRemoved": {key: string};
  "question:addNewInstance": {groupKey: string; instanceId: string};
  "question:swappedGroupIds": {swappedIds: Record<string, Record<string, string>>};
  "question:questionsReloaded": {};
  "question:swappedIdsAfterInit": {};
  "question:filtersCleared": {};
  "questions:updateProperty": {key: string; propertyToUpdate: string | number; targetQuestionInfo: TargetQuestionInfo};
  "questions:reRunGroupValidationState": {groupInstance: string};
  "questions:updateComputeToolLogging": {};
  "questions:showQuestionInfoPane": QuestionInfoPaneData;

  "reviews:complete": {eventNamespace: string; instanceId: string; instanceKey: string};
  "reviews:cancel": {eventNamespace: string};
  "reviews:saveProgress": {eventNamespace: string};
  "reviews:delete": {eventNamespace: string; instanceKey: string};
  "quickAdd:start": {groupKey: string; preselectedData?: Dictionary<string>};
  "router:proceed": {};
  "connections:changeQuickAddModelVisibility": {tableKey: string; open: boolean};
  "connections:reloadConnections": {waitBeforeRefreshMs?: number};
  "connections:goToSupplierOverview": {};
  "kit:kitSaved": {};
  "layout:wrapped": {elementQuery: string; wrapped: boolean};
  "workflow:completed": {};
  "adminEditAnswer:open": {answerKey: string; answerPath: string; currentAnswer: any; documentId: string};

  "contact:closeOtherPopUps": {showId: string};

  "floatingLeftPane:show": {elementId: string; show: boolean};
};

const emitter: Emitter<Events> = mitt<Events>();

/**
 * Listen to event bus from anywhere (use componentGlobalOn when using in composable API)
 * @param eventNamespace
 * @param responder
 */
export function globalOn<Key extends keyof Events>(eventNamespace: Key, responder: Handler<Events[Key]>) {
  emitter.on(eventNamespace, responder);
}

/**
 * Listen to event bus from anywhere once (use componentGlobalOn when using in composable API)
 * @param eventNamespace
 * @param responder
 */
export function globalOnce<Key extends keyof Events>(eventNamespace: Key, responder: Handler<Events[Key]>) {
  emitter.on(eventNamespace, (...args) => {
    emitter.off(eventNamespace);
    responder(...args);
  });
}

/**
 * Stop listening to event bus from anywhere
 * @param eventNamespace
 * @param responder
 */
export function globalOff<Key extends keyof Events>(eventNamespace: Key, responder: Handler<Events[Key]>) {
  emitter.off(eventNamespace, responder);
}

/**
 * Emit to event bus from anywhere
 * @param eventNamespace
 * @param data
 */
export function globalEmit<Key extends keyof Events>(eventNamespace: Key, data: Events[Key]) {
  emitter.emit(eventNamespace, data);
}

/**
 * Use in composable component since it will handle unregistering the event (for memory leaks)
 * @param eventNamespace
 * @param responder
 */
export function componentGlobalOn(eventNamespace: keyof Events, responder) {
  globalOn(eventNamespace, responder);
  onUnmounted(() => {
    globalOff(eventNamespace, responder);
  });
}
