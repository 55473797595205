import {autoMutations} from "../utils";

const state = {
  loading: false,
  sentForgotPasswordEmail: false,
  forgotPasswordError: "",
  resetPasswordError: "",
  passwordResetSuccessful: false,
};

// getters
const getters = {};

// actions
const actions = {
  async sendForgotPasswordEmail({commit}, {email}) {
    try {
      await this.httpPost("/api/users/forgot", {email});
      commit("sentForgotPasswordEmail", true);
    } catch (e) {
      commit("forgotPasswordError", e.response.data);
    }
  },

  async resetPassword({commit}, {email, token, password}) {
    try {
      await this.httpPost("/api/users/reset", {email, token, password});
      commit("passwordResetSuccessful", true);
    } catch (e) {
      commit("resetPasswordError", e.response.data);
    }
  },

  reset({commit}) {
    commit("forgotPasswordError", "");
    commit("resetPasswordError", "");
    commit("sentForgotPasswordEmail", false);
    commit("passwordResetSuccessful", false);
  },
};

const mutations = {
  ...autoMutations(state),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
