<template>
  <GraphiteButton
    :class="{
      'easy-icon-button': true,
      'has-text': hasText,
      'has-icon': hasIcon,
      'is-busy': isBusy,
      'p-button-icon-only': !hasText && !hasBeforeText,
    }"
    :disabled="isBusy || disabled"
    v-bind="$attrs"
  >
    <slot name="before" />
    <slot name="icon">
      <font-awesome-icon v-if="icon" :class="iconClass" :icon="icon" />
    </slot>
    <slot name="default" />
    <slot name="after" />
    <slot name="busy">
      <i class="fa fa-circle-notch fa-spin" :class="busyClass" aria-hidden="true" v-if="busy" />
    </slot>
  </GraphiteButton>
</template>

<script lang="ts" setup>
import {isVNodeEmpty} from "@/composables/utils";
import globalLogger from "@/logging";
import type {ComputedRef, PropType} from "vue";
import {computed, defineProps, onMounted, useAttrs, useSlots} from "vue";

const logger = globalLogger.getLogger("IconButton");

const props = defineProps({
  icon: {
    type: Array as PropType<string[]>,
    default: null,
  },
  busy: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();
const attrs = useAttrs();

const hasIcon: ComputedRef<boolean> = computed(() => {
  return Boolean(props.icon);
});

const hasBeforeText: ComputedRef<boolean> = computed(() => {
  return !isVNodeEmpty(slots?.before);
});
const hasDefaultText: ComputedRef<boolean> = computed(() => {
  return !isVNodeEmpty(slots?.default);
});
const hasText: ComputedRef<boolean> = computed(() => {
  return hasBeforeText.value || hasDefaultText.value;
});

const isBusy: ComputedRef<boolean> = computed(() => {
  return props.busy;
});

const iconClass: ComputedRef<string> = computed(() => {
  if (hasBeforeText.value) {
    return "ml-2";
  } else if (hasDefaultText.value) {
    return "mr-2";
  } else {
    return "";
  }
});

const busyClass: ComputedRef<string> = computed(() => {
  return props.busy && hasText.value ? "ml-2" : "";
});

onMounted(() => {
  if (!attrs.title && !attrs["aria-label"] && !hasText.value) {
    logger.warn(() => `!!Accessibility Issue!! No title passed in (${JSON.stringify(props.icon)})`, attrs);
  }
});

defineOptions({
  compatConfig: {
    MODE: 3,
  },
});
</script>

<style lang="less" scoped>
.easy-icon-button {
  display: inline-flex;
  flex-direction: row;
  justify-content: center;

  &:not(.has-text) {
    &.p-button-sm {
      padding: 0;
      width: 24px;
      height: 24px;
    }
  }
}
</style>
