import {autoMutations} from "../utils";
import hash from "hash-it";
import globalLogger from "../../logging";
import {pathOr} from "ramda";

const logger = globalLogger.getLogger("user-store");

const state = {
  users: {}, // key by tags and array of user objects
  connectionUsers: {},
  inviting: false,
  error: "",
  contactProfileRoles: {},
};

// getters
const getters = {};

async function fetchUsers(module, params) {
  const response = await module.httpPost("/api/users/query", params);
  if (response.status === 200) {
    return response.data;
  } else {
    console.error("bad users response", response.status, params);
  }
}
async function fetchCounterpartyConnectionUsers(module, connectionId, params) {
  const response = await module.httpGet(`/api/users/counterparty_users/${connectionId}`, params);
  if (response.status === 200) {
    return response.data;
  } else {
    console.error("bad users response", response.status, params);
  }
}

async function ensureValues(module, state, commit, options, key, forceNewRequest) {
  const users = {...state.users}; // Clone, don't mutate!
  if (!users[key] || forceNewRequest) {
    const data = await fetchUsers(module, options);
    if (data.users) {
      users[key] = data.users;
    }
  }
  commit("users", users);
  return users;
}

// actions
const actions = {
  async getUsers({commit, state}, {options, forceNewRequest}) {
    try {
      const key = hash(options);
      const users = await ensureValues(this, state, commit, options, key, forceNewRequest);

      return users[key];
    } catch (e) {
      console.error(e);
    }
  },
  async getConnectionUsers({commit, state}, {connectionId, options, forceNewRequest}) {
    try {
      const key = hash({connectionId, ...options});
      const connectionUsers = {...state.connectionUsers};
      if (!connectionUsers[key] || forceNewRequest) {
        const data = await fetchCounterpartyConnectionUsers(this, connectionId, options);
        if (data.users) {
          connectionUsers[key] = data.users;
        }
        commit("connectionUsers", connectionUsers);
        return connectionUsers[key];
      } else {
        return connectionUsers[key];
      }
    } catch (e) {
      console.error(e);
    }
  },

  async clearCache({commit, state}, {key}) {
    try {
      const users = {...state.users}; // Clone, don't mutate!
      if (users[key]) {
        delete users[key];
        commit("users", users);
      }
    } catch (e) {
      console.error(e);
    }
  },

  async inviteUser({commit}, {inviteUser}) {
    try {
      commit("inviting", true);
      const response = await this.httpPost(`/api/admin/users/invite`, inviteUser);
      const getUserResponse = await this.httpPost("/api/users/query", {userId: response.data._id});
      return {user: pathOr("", ["data", "users", "0"], getUserResponse)};
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error inviting new user", e);
      }
    } finally {
      commit("inviting", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: {
    ...autoMutations(state),
  },
};
