<template>
  <div class="component-wrap" ref="wrapElemRef">
    <div
      v-if="!scrollbarPosition || scrollbarPosition === 'both' || scrollbarPosition === 'top'"
      class="faux-scrollbar-wrap stick-to-top"
      ref="fauxScrollbarElemRef"
      :style="`height: ${scrollbarSize}px`"
      :class="{'fake-hide': disabled || hideFauxScrollbar}"
    >
      <div class="faux-scrollbar" :style="`width: ${targetWidth}px`"></div>
    </div>

    <slot />

    <div
      v-if="!scrollbarPosition || scrollbarPosition === 'both' || scrollbarPosition === 'bottom'"
      class="faux-scrollbar-wrap stick-to-bottom"
      ref="fauxScrollbarElemRef"
      :style="`height: ${scrollbarSize}px`"
      :class="{'fake-hide': disabled || hideFauxScrollbar}"
    >
      <div class="faux-scrollbar" :style="`width: ${targetWidth}px`"></div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, onBeforeUnmount, onMounted, ref, toRefs, useSlots, watch} from "vue";

const props = defineProps<{
  targetSelector?: string; // defaults to first child
  hideDefaultScrollbar?: boolean;
  scrollbarPosition?: "top" | "bottom" | "both"; // defaults to 'both'
  disabled?: boolean;
}>();

const {disabled, targetSelector, hideDefaultScrollbar} = toRefs(props);

const slots = useSlots();

const wrapElemRef = ref<HTMLElement>();

const targetElem = computed<HTMLElement>(() => {
  return ((targetSelector.value && wrapElemRef.value?.querySelector(targetSelector.value)) ||
    wrapElemRef.value?.children[0] || {scrollWidth: 0}) as HTMLElement;
});

watch(
  targetElem,
  (elem) => {
    if (elem?.classList) {
      if (!disabled.value && hideDefaultScrollbar.value) {
        elem.classList.add("force-hide-scrollbar");
      } else {
        elem.classList.remove("force-hide-scrollbar");
      }
    }
  },
  {immediate: true},
);

const fauxScrollbarElemRef = ref<HTMLElement>();

const targetWidth = ref(0);
const containerWidth = ref(0);

const hideFauxScrollbar = computed(() => {
  return targetWidth.value <= containerWidth.value;
});

const scrollbarSize = computed(() => {
  // macOS needs the `+ 1`
  return getScrollbarSize() + 1;
});

function onResize(entries: ResizeObserverEntry[]) {
  targetWidth.value = targetElem.value.scrollWidth;
  containerWidth.value = wrapElemRef.value!.clientWidth;
}

const resizeObserver = new ResizeObserver(onResize);

// super hacky sticky scrollbar at the top/bottom of the target elem
onMounted(() => {
  targetElem.value.addEventListener("scroll", copyRealScrollToFaux);
  fauxScrollbarElemRef.value!.addEventListener("scroll", copyFauxScrollToReal);
  targetWidth.value = targetElem.value.scrollWidth;

  if (!disabled.value) {
    targetElem.value.classList.add("overflow-y-scroll");
    resizeObserver.observe(targetElem.value, {box: "border-box"});
  }
});

watch(disabled, (newVal) => {
  if (newVal) {
    targetElem.value.classList.remove("overflow-y-scroll");
    resizeObserver.unobserve(targetElem.value);
  } else {
    targetElem.value.classList.add("overflow-y-scroll");
    resizeObserver.observe(targetElem.value, {box: "border-box"});
  }
});

onBeforeUnmount(() => {
  targetElem.value.removeEventListener("scroll", copyRealScrollToFaux);
  fauxScrollbarElemRef.value!.removeEventListener("scroll", copyFauxScrollToReal);
  resizeObserver.unobserve(targetElem.value);
  resizeObserver.disconnect();
});

watch(disabled, (newVal) => {
  if (newVal) {
    copyRealScrollToFaux();
  }
});

function copyFauxScrollToReal() {
  if (disabled.value) {
    return;
  }

  targetElem.value.scrollLeft = fauxScrollbarElemRef.value!.scrollLeft;
}

function copyRealScrollToFaux() {
  if (disabled.value) {
    return;
  }

  fauxScrollbarElemRef.value!.scrollLeft = targetElem.value.scrollLeft;
}

// see https://stackoverflow.com/questions/13382516/getting-scroll-bar-width-using-javascript d
function getScrollbarSize() {
  // Creating invisible container
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // forcing scrollbar to appear
  document.body.appendChild(outer);

  // Creating inner element and placing it in the container
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculating difference between container's full width and the child width
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Removing temporary elements from the DOM
  outer.parentNode!.removeChild(outer);

  return scrollbarWidth;
}
</script>

<style lang="less" scoped>
.component-wrap {
  width: 100%;
  position: relative;

  :deep(.force-hide-scrollbar) {
    // Hide scrollbar
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.faux-scrollbar-wrap {
  &.fake-hide {
    height: 0 !important;
  }

  position: sticky;
  overflow-x: auto;
  overflow-y: hidden;
  height: 20px;
  z-index: 2;

  &.stick-to-top {
    top: calc(var(--sticky-top-offset, 60) - 1px);
  }

  &.stick-to-bottom {
    bottom: 0;
  }

  .faux-scrollbar {
    width: 1000px;
  }
}
</style>
