<template>
  <PrimeSidebar
    :visible="visible"
    :showCloseIcon="false"
    :position="position"
    :pt="ptOptions"
    :dismissable="dismissable"
    :blockScroll="true"
    @update:visible="handleVisibilityUpdate"
  >
    <template #header>
      <IconButton
        v-if="showHeaderBackArrow"
        :icon="['fal', 'arrow-left']"
        :title="$t('actions.close')"
        variant="tertiary"
        @click="emit('backArrowClicked')"
        class="mr-2"
      />
      <slot name="title">
        <template v-if="!noHeader || title">
          <div class="header-wrap">
            <div class="sidebar-header">
              <div class="title-text">{{ title }}</div>
            </div>
          </div>
          <div style="height: 40px"></div>
        </template>
      </slot>
      <IconButton
        v-if="!noHeaderClose"
        :icon="['fal', 'close']"
        :title="$t('actions.close')"
        variant="tertiary"
        class="pg-header-close-icon"
        @click="handleVisibilityUpdate(false)"
      />
    </template>
    <div class="h-full flex flex-column">
      <div class="flex-grow-1" style="overflow-y: auto">
        <slot :hide="() => handleVisibilityUpdate(false)" />
      </div>
      <div style="flex-grow: 0">
        <slot name="footer" :hide="() => handleVisibilityUpdate(false)">
          <portal-target name="g-sidebar-footer" />
        </slot>
      </div>
    </div>
  </PrimeSidebar>
</template>

<script lang="ts" setup>
import IconButton from "@/components/shared/IconButton.vue";
import PrimeSidebar from "primevue/sidebar";
import isString from "lodash/isString";
import isObject from "lodash/isObject";
import {defineProps, watch, computed, toRefs, useSlots, defineEmits} from "vue";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  backdrop: Boolean,
  width: String,
  right: {
    type: Boolean,
    default: false,
  },
  noCloseOnBackdrop: {
    type: Boolean,
    default: false,
  },
  sidebarClass: {
    type: [String, Object, Array],
    default: null,
  },
  noHeader: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: null,
  },
  bodyClass: {
    type: [String, Object],
    default: null,
  },
  headerClass: {
    type: [String, Object],
    default: null,
  },
  noHeaderClose: {
    type: Boolean,
    default: false,
  },
  showHeaderBackArrow: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["change", "shown", "hidden", "backArrowClicked"]);

const {title, width, right, noCloseOnBackdrop, sidebarClass, headerClass, visible, bodyClass} = toRefs(props);
const slots = useSlots();

const bodyClassStr = computed(() => {
  let returnStr = "b-sidebar-body";
  if (isString(bodyClass.value)) {
    returnStr += ` ${bodyClass.value}`;
  } else if (isObject(bodyClass.value)) {
    const classKeys = Object.keys(bodyClass.value);
    for (const classKey of classKeys) {
      if (bodyClass.value[classKey]) {
        returnStr += ` ${classKey}`;
      }
    }
  }

  return returnStr;
});

const headerClassStr = computed(() => {
  let returnStr = "b-sidebar-header";
  if (isString(headerClass.value)) {
    returnStr += ` ${headerClass.value}`;
  } else if (isObject(headerClass.value)) {
    const classKeys = Object.keys(headerClass.value);
    for (const classKey of classKeys) {
      if (headerClass.value[classKey]) {
        returnStr += ` ${classKey}`;
      }
    }
  }

  return returnStr;
});

const ptOptions = computed(() => {
  return {
    root: {
      style: {width: width.value},
      ...(sidebarClass.value && {class: sidebarClass.value}),
    },
    content: {
      ...(bodyClassStr.value && {class: bodyClassStr.value}),
    },
    header: {
      style: {display: slots.title || title.value ? "flex" : "none"},
      ...(headerClassStr.value && {class: headerClassStr.value}),
    },
  };
});

const position = computed(() => (right.value ? "right" : "left"));

const dismissable = computed(() => !noCloseOnBackdrop.value);

function handleVisibilityUpdate(newVisibility) {
  emit("change", newVisibility);
  document.body.style.overflow = newVisibility ? "hidden" : "";
}

watch(visible, () => {
  setTimeout(() => {
    if (visible.value) {
      emit("shown");
    } else {
      emit("hidden");
    }
  }, 300); // To match the transition open / close timing
});
</script>

<style lang="less">
@import "@{globals}";

.header-wrap {
  flex: 1 1 auto;
  display: flex;
  // background-color: #f6f7fb;
  // border-bottom: 2px #e3e8f1 solid;
  z-index: 2000;

  .pg-header-close-icon {
    position: absolute;
    right: 16px;
    top: 24px;
    cursor: pointer;
  }

  &.hide {
    opacity: 0;
  }

  .sidebar-header {
    display: flex;
    flex-direction: column;
    //color: white;

    .title-text {
      //font-size: 1.25rem;
      color: #2a425e;
      font-weight: 400;
      font-size: 1.25rem;
    }

    .title-sub-text {
      font-size: 0.9rem;
    }

    .close {
      padding: 8px;
      font-size: 1rem;
      text-align: right;
      cursor: pointer;
      opacity: 1;
    }
  }
}

.b-sidebar-body {
  padding: 0;
}
</style>
