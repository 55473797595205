import {any, contains, Dictionary, groupBy, indexOf, path, pathOr} from "ramda";
import {find, findParent, isMultipleGroup, isQuestion, isUnanswerable, parentInstanceId, processTreeTopDown} from ".";
import {GroupSubType, GroupType, QuestionType, RiskAssessment} from "../enums";
import {isEmptyOrUndefined, isUnanswered, JSONObject, JSONQuestion} from "../utils";

export interface FilterRules {
  topic?: string[];
  tag?: string;
  unanswered?: boolean;
  required?: boolean;
  messages?: boolean;
  approvals?: boolean;
  notes?: boolean;
  tasks?: boolean;
  reminders?: boolean;
  highRisk?: boolean;
  mediumRisk?: boolean;
  lowRisk?: boolean;
  internalUse?: boolean;
  changedSinceLastReview?: boolean;
  campaign?: string;
  profileSearch?: string;
  questions?: Array<{questionId: string; instanceId?: string}>;
  onlyDocs?: boolean;
}

export const unsetFilterRules = {
  topic: [],
  tag: null,
  unanswered: null,
  required: null,
  messages: null,
  approvals: null,
  notes: null,
  tasks: null,
  reminders: null,
  highRisk: null,
  mediumRisk: null,
  lowRisk: null,
  internalUse: null,
  changedSinceLastReview: null,
  campaign: null,
  profileSearch: null,
  questions: null,
  onlyDocs: null,
};

type Filter = (question: JSONQuestion) => boolean;

export const hasAnyFilterSet = (filterRules: FilterRules) => {
  return (
    filterRules.unanswered ||
    filterRules.required ||
    filterRules.internalUse ||
    filterRules.questions ||
    !isEmptyOrUndefined(filterRules.topic) ||
    !isEmptyOrUndefined(filterRules.tag) ||
    filterRules.messages ||
    filterRules.notes ||
    filterRules.tasks ||
    filterRules.reminders ||
    filterRules.lowRisk ||
    filterRules.mediumRisk ||
    filterRules.highRisk ||
    filterRules.approvals ||
    filterRules.campaign ||
    filterRules.onlyDocs
  );
};

const isFilterVisible = (question: JSONQuestion) => question.filterVisible as boolean;

// If any questions in a table instance are visible, all should be
const setTableQuestionsFiltered = (question: JSONQuestion) => {
  if (isMultipleGroup(question) && question.subType === GroupSubType.TABLE && question.children) {
    for (const childInstance of question.children) {
      const haveVisibleQuestion = find(isFilterVisible)(childInstance);
      if (haveVisibleQuestion) {
        processTreeTopDown((q: JSONQuestion) => {
          q.filterVisible = true;
        })(childInstance);
      }
    }
  }
};

export class ProfileFilter {
  public questionFilter?: Filter;

  // these are placed here instead of the constructor due to an add bug in the vue tests. please leave them here
  private readonly profileAnswers!: JSONObject;
  private readonly filterRules!: FilterRules;
  private readonly profileQuestions!: JSONQuestion;

  constructor(profileQuestions: JSONQuestion, profileAnswers: JSONObject, filterRules: FilterRules) {
    this.profileAnswers = profileAnswers;
    this.filterRules = filterRules;
    this.profileQuestions = profileQuestions;
    if (this.filterRules.questions) {
      this.questionFilter = this.prepareQuestionFilter(this.filterRules.questions);
    }
  }

  public filter(): JSONQuestion {
    const init = hasAnyFilterSet(this.filterRules)
      ? (q) => (q.filterVisible = true)
      : (q) => (q.filterVisible = undefined);
    processTreeTopDown(init)(this.profileQuestions);
    if (!isEmptyOrUndefined(this.filterRules.topic)) {
      this.filterIncludeChildren(this.checkTopicQuestion);
    }
    if (this.questionFilter) {
      this.filterIncludeChildren(this.questionFilter);
    }
    if (!isEmptyOrUndefined(this.filterRules.tag)) {
      this.filterElementOnly(this.checkTagQuestion);
    }
    if (this.filterRules.unanswered) {
      this.filterElementOnly(this.checkUnansweredQuestion);
    }
    if (this.filterRules.required) {
      this.filterElementOnly(this.checkRequiredQuestion);
    }
    if (this.filterRules.internalUse) {
      this.filterElementOnly(this.checkInternalUseQuestion);
    }
    if (this.filterRules.messages) {
      this.filterElementOnly(this.checkQuestionWithMessage, true);
    }
    if (this.filterRules.approvals) {
      this.filterElementOnly(this.checkQuestionWithApprovals, true);
    }
    if (this.filterRules.notes) {
      this.filterElementOnly(this.checkQuestionWithNote, true);
    }
    if (this.filterRules.tasks) {
      this.filterElementOnly(this.checkQuestionWithTask, true);
    }
    if (this.filterRules.reminders) {
      this.filterElementOnly(this.checkQuestionWithReminder, true);
    }
    /*if (this.filterRules.lowRisk) {
      this.filterElementOnly(this.checkLowRiskQuestion);
    }
    if (this.filterRules.mediumRisk) {
      this.filterElementOnly(this.checkMediumRiskQuestion);
    }
    if (this.filterRules.highRisk) {
      this.filterElementOnly(this.checkHighRiskQuestion);
    }*/
    if (this.filterRules.lowRisk || this.filterRules.mediumRisk || this.filterRules.highRisk) {
      this.filterElementOnly(this.checkRiskQuestion);
    }
    if (this.filterRules.changedSinceLastReview) {
      this.filterElementOnly(this.checkChangedSinceLastReview);
    }
    if (this.filterRules.campaign) {
      this.filterElementOnly(this.checkCampaignQuestion, true);
    }
    if (this.filterRules.onlyDocs) {
      this.filterElementOnly(this.checkShouldIncludeInDocCenter);
    }
    processTreeTopDown(setTableQuestionsFiltered)(this.profileQuestions);
    processTreeTopDown((q: JSONQuestion) => delete q.overrideableContainer)(this.profileQuestions);

    return this.profileQuestions;
  }

  /**
   * QUESTION CHECKS
   */
  private checkTopicQuestion: Filter = (question: JSONQuestion): boolean => {
    if (question.type === GroupType.TOPIC && this.filterRules.topic) {
      return contains(question._id, this.filterRules.topic);
    }

    return false;
  };

  private checkTagQuestion: Filter = (question: JSONQuestion): boolean =>
    Boolean(question.tags && contains(this.filterRules.tag, question.tags));

  private checkQuestionWithMessage: Filter = (question: JSONQuestion): boolean =>
    pathOr(0, ["messageCount", "count"], question) > 0;

  private checkQuestionWithApprovals: Filter = (question: JSONQuestion): boolean =>
    pathOr(0, ["approvalCount", "count"], question) > 0;

  private checkQuestionWithNote: Filter = (question: JSONQuestion): boolean =>
    pathOr(0, ["noteCount", "count"], question) > 0;

  private checkQuestionWithTask: Filter = (question: JSONQuestion): boolean =>
    pathOr(0, ["taskCount", "count"], question) > 0;

  private checkQuestionWithReminder: Filter = (question: JSONQuestion): boolean =>
    pathOr(0, ["reminderCount", "count"], question) > 0;

  private checkRiskQuestion: Filter = (question: JSONQuestion): boolean => {
    if (this.filterRules.lowRisk && question.riskAssessment === RiskAssessment.OK) {
      return true;
    } else if (this.filterRules.mediumRisk && question.riskAssessment === RiskAssessment.CAUTION) {
      return true;
    } else if (this.filterRules.highRisk && question.riskAssessment === RiskAssessment.FLAG) {
      return true;
    }
    return false;
  };

  /*private checkLowRiskQuestion: Filter = (question: JSONQuestion): boolean =>
    question.riskAssessment === RiskAssessment.OK;

  private checkHighRiskQuestion: Filter = (question: JSONQuestion): boolean =>
    question.riskAssessment === RiskAssessment.FLAG;

  private checkMediumRiskQuestion: Filter = (question: JSONQuestion): boolean =>
    question.riskAssessment === RiskAssessment.CAUTION;*/

  private checkChangedSinceLastReview: Filter = (question: JSONQuestion): boolean =>
    pathOr(0, ["changedSinceLastReviewCount", "count"], question) > 0 ||
    !!findParent((q) => q.subType === GroupSubType.REVIEW, question);

  private checkCampaignQuestion: Filter = (question: JSONQuestion): boolean => {
    const questionCampaigns = question.campaigns ? question.campaigns : [];
    return !!question.alwaysShowForCampaigns || indexOf(this.filterRules.campaign, questionCampaigns as string[]) > -1;
  };

  private checkShouldIncludeInDocCenter: Filter = (question: JSONQuestion): boolean =>
    (question.visible &&
      (question.type === QuestionType.FILE ||
        question.type === QuestionType.FILE_OR_URL ||
        question.type === QuestionType.URL)) ||
    !!question.includeInDocumentCenter ||
    !!findParent((q) => q.includeInDocumentCenter, question);

  private checkUnansweredQuestion: Filter = (question: JSONQuestion): boolean =>
    !isUnanswerable(question) &&
    isUnanswered(
      path(question.key.split("."), this.profileAnswers),
      question.requiredLanguages,
      question.requiredSubparts,
    );

  private checkRequiredQuestion: Filter = (question: JSONQuestion): boolean => Boolean(question.required);

  private checkInternalUseQuestion: Filter = (question: JSONQuestion): boolean => Boolean(question.internalUse);

  private prepareQuestionFilter(questions: Array<{questionId: string; instanceId?: string}>): Filter {
    const byId: Dictionary<Array<{questionId: string; instanceId?: string}>> = groupBy((x) => x.questionId, questions);
    return (question: JSONQuestion) =>
      any((pair) => !pair.instanceId || pair.instanceId === parentInstanceId(question), byId[question._id!] || []);
  }

  private filterIncludeChildren = (condition: Filter) => {
    const f = (q: JSONQuestion) => {
      q.filterVisible = q.filterVisible && condition(q);
      if (q.filterVisible) {
        processTreeTopDown((c: JSONQuestion) => {
          c.filterVisible = isQuestion(c) || condition(c);
          if (!isQuestion(c)) {
            c.overrideableContainer = true;
          }
        })(q);
      } else {
        (q.children || []).forEach((c: JSONQuestion) => {
          delete c.overrideableContainer;
          f(c);
        });
      }
    };
    f(this.profileQuestions);
  };

  private filterElementOnly = (condition: Filter, overrideContainers: boolean = false) => {
    processTreeTopDown((q: JSONQuestion) => {
      q.filterVisible = (q.filterVisible || (overrideContainers && Boolean(q.overrideableContainer))) && condition(q);
    })(this.profileQuestions);
  };
}
