import {find, path} from "ramda";
import {ApprovalCount} from "../api/approval";
import {MessageCount} from "../api/message";
import {NoteCount} from "../api/note";
import {ReminderCount} from "../api/reminder";
import {TaskCount} from "../api/tasks";
import {GroupType, RiskAssessment} from "../enums";
import {UpdateCallback} from "../props";
import {advancedIsUnanswered, isEmptyOrUndefined, JSONObject, JSONQuestion} from "../utils";
import {find as profileFind, findParent, isQuestion, isReviewGroup, processTreeBottomUp} from "./index";

export const processSums =
  (propName: string, elementCount: (q: JSONQuestion, a: JSONObject) => number) =>
  (
    questions: JSONQuestion,
    answers: JSONObject,
    updateCallback: UpdateCallback = (target, key, value) => {
      target[key] = value;
    },
    _init?: boolean,
    childrenMap?: Map<JSONQuestion, JSONQuestion[]>,
  ) => {
    const visited = new Set<JSONQuestion>();
    const setCount = (q: JSONQuestion): void => {
      if (visited.has(q)) {
        return;
      }

      let count = 0;
      const children = childrenMap ? childrenMap.get(q) : q.children;
      if (children && q.visible && !q.hideSourceTable) {
        children.forEach((c) => {
          setCount(c);
          count += c[propName] as number;
        });
      }
      count += elementCount(q, answers);
      updateCallback(q, propName, count, {question: q});
      // q[propName] = count;
      visited.add(q);
    };
    setCount(questions);
  };

export const processUnansweredRequiredCounts = processSums("unansweredRequiredCount", (q, a) => {
  return isQuestion(q) &&
    q.visible &&
    q.required &&
    !q.internalUse &&
    advancedIsUnanswered(path(q.key.split("."), a), q.type, q.requiredLanguages, q.requiredSubparts)
    ? 1
    : 0;
});

export const processUnansweredRequiredInternalUseCounts = processSums("unansweredRequiredInternalUseCount", (q, a) => {
  return isQuestion(q) &&
    q.visible &&
    q.required &&
    q.internalUse &&
    advancedIsUnanswered(path(q.key.split("."), a), q.type, q.requiredLanguages, q.requiredSubparts)
    ? 1
    : 0;
});

export const processHighRiskCounts = processSums("highRiskCount", (q) => {
  return isQuestion(q) && q.visible && q.riskAssessment === RiskAssessment.FLAG ? 1 : 0;
});

export const processLowRiskCounts = processSums("lowRiskCount", (q) => {
  return isQuestion(q) && q.visible && q.riskAssessment === RiskAssessment.OK ? 1 : 0;
});

export const processUnknownRiskCounts = processSums("unknownRiskCount", (q) => {
  return isQuestion(q) && q.visible && q.riskAssessment === RiskAssessment.CAUTION ? 1 : 0;
});

const matcher = (q: JSONQuestion): (<T extends {elementId: string; instanceId?: string}>(c: T) => boolean) => {
  if (q.type === GroupType.GROUP_INSTANCE) {
    return ({elementId, instanceId}) => q.parent?._id === elementId && (!q.instance || q.instance === instanceId);
  } else {
    const groupInstanceParent = findParent((qq) => qq.type === GroupType.GROUP_INSTANCE)(q);
    return ({elementId, instanceId}) =>
      q._id === elementId &&
      (groupInstanceParent ? groupInstanceParent.instance === instanceId : isEmptyOrUndefined(instanceId));
  }
};

export class ProfileCount {
  public static setCounts(
    questions: JSONQuestion,
    countData: {
      messages?: MessageCount[];
      notes?: NoteCount[];
      reminders?: ReminderCount[];
      changedSinceLastReview?: any[];
      approvals?: ApprovalCount[];
      tasks?: TaskCount[];
    },
  ) {
    if (
      !countData.messages &&
      !countData.notes &&
      !countData.reminders &&
      !countData.changedSinceLastReview &&
      !countData.approvals &&
      !countData.tasks
    ) {
      return;
    }

    // const approvalsByElementId = groupBy(a => a.elementId, countData.approvals || []);

    processTreeBottomUp((q: JSONQuestion) => {
      const matchCount = matcher(q);

      if (countData.messages) {
        q.messageCount = find(matchCount, countData.messages) || {count: 0, unreadCount: 0};
      }
      if (countData.approvals) {
        q.approvalCount = find(matchCount, countData.approvals) || {
          count: 0,
          incompleteCount: 0,
        };
      }
      if (countData.reminders) {
        q.reminderCount = find(matchCount, countData.reminders) || {
          count: 0,
          overdueCount: 0,
        };
      }
      if (countData.notes) {
        q.noteCount = find(matchCount, countData.notes) || {count: 0};
      }
      if (countData.tasks) {
        q.taskCount = find(matchCount, countData.tasks) || {count: 0};
      }
      if (countData.changedSinceLastReview) {
        if (q.type === GroupType.TOPIC && !!profileFind(isReviewGroup, q)) {
          let changedSumCount: number = 0;
          processTreeBottomUp((cq: JSONQuestion): void => {
            changedSumCount += (isQuestion(cq) && cq.changedSinceLastReviewCount?.count) || 0;
          })(q);
          q.changedSinceLastReviewCount = {count: changedSumCount};
        } else {
          q.changedSinceLastReviewCount =
            isQuestion(q) && find(matchCount)(countData.changedSinceLastReview) ? {count: 1} : {count: 0};
        }
      }
    })(questions);
  }
}
