import type {Dictionary} from "lodash";
import compact from "lodash/compact";
import mitt from "mitt";
import type {Directive, DirectiveBinding, VNode} from "vue";

const callbackTracker: Dictionary<() => void> = {};

export enum ModalEvent {
  Open = "Open",
  Close = "Close",
  Toggle = "Toggle",
}

export type ModalEvents = {
  // [key in keyof any as `${ModalEvent}_${string}`]: string;
  [ModalEvent.Open]: string;
  [ModalEvent.Close]: string;
  [ModalEvent.Toggle]: string;
};

export const modalEmitter = mitt<ModalEvents>();

const GraphiteModalDirective: Directive = {
  mounted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const ids = compact([...Object.keys(binding.modifiers), binding.value]);
    ids.forEach((id) => {
      bindClick(el, id);
    });
  },
  updated(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const oldIds = compact([...Object.keys(binding.modifiers), binding.oldValue]);
    oldIds.forEach((id) => {
      unbindClick(el, id);
    });

    const ids = compact([...Object.keys(binding.modifiers), binding.value]);
    ids.forEach((id) => {
      bindClick(el, id);
    });
  },
  unmounted(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const ids = compact([...Object.keys(binding.modifiers), binding.value]);
    ids.forEach((id) => {
      unbindClick(el, id);
    });
  },
};

function bindClick(el: HTMLElement, id: string) {
  if (callbackTracker[id]) {
    console.warn(`Trying to bind a dynamic modal with a duplicate ID: ${id}`);
    unbindClick(el, id);
  }

  const callback = () => {
    modalEmitter.emit(ModalEvent.Open, id);
  };

  callbackTracker[id] = callback;
  el.addEventListener("click", callback);
}

function unbindClick(el: HTMLElement, id: string) {
  const callback = callbackTracker[id];
  if (callback) {
    el.removeEventListener("click", callback);
  }
  delete callbackTracker[id];
}

export default GraphiteModalDirective;
