// "lists" are "value sets" 2.0

import {httpDelete, httpGet, httpPost, httpPut} from "@/composables/xhr";
import globalLogger from "@/logging";
import type {Dictionary} from "lodash";
import mapKeys from "lodash/mapKeys";
import pickBy from "lodash/pickBy";
import mitt from "mitt";
import type {ListDTO, TranslatedListItem} from "pg-isomorphic/api/lists";
import {Locale} from "pg-isomorphic/enums";

const logger = globalLogger.getLogger("lists");

// the key is the combination key from `makeListCacheKey`: `OWNER___LOCALE___KEY`
const listCache: Dictionary<TranslatedListItem[]> = {};
const listCacheInFlight: Dictionary<Promise<TranslatedListItem[]>> = {};

export function getListCache(locale: string = Locale.EN_US, byListKeyOnly = true): Dictionary<TranslatedListItem[]> {
  const listCacheFilteredByLang = pickBy(listCache, (v, key) => getLocaleFromListCacheKey(key) === locale);
  if (!byListKeyOnly) {
    return listCacheFilteredByLang;
  }

  return mapKeys(listCacheFilteredByLang, (v, key) => getListKeyFromListCacheKey(key));
}

export function makeListCacheKey(parts: {key: string; owner?: string; locale?: string}) {
  return `${parts.owner || "GRAPHITE"}___${parts.locale || Locale.EN_US}___${parts.key}`;
}

export function getListKeyFromListCacheKey(listCacheKey: string): string {
  return listCacheKey.split("___")[2];
}

export function getLocaleFromListCacheKey(listCacheKey: string): string {
  return listCacheKey.split("___")[1];
}

export type ListEditableMeta = Pick<ListDTO, "key" | "public" | "owner" | "needsTranslation">;

export async function getListByIdOrKey(idOrKey: string): Promise<ListDTO> {
  return (await httpGet<ListDTO>(`/api/lists/manage/${idOrKey}`)).data;
}

export async function getTranslatedOptionsByKey(
  key: string,
  owner?: string,
  locale?: string,
): Promise<TranslatedListItem[]> {
  const cacheKey = makeListCacheKey({key, owner, locale});

  if (listCache[cacheKey]) {
    return listCache[cacheKey];
  }

  if (listCacheInFlight[cacheKey]) {
    return listCacheInFlight[cacheKey];
  }

  logger.trace("miss cache", cacheKey);

  listCacheInFlight[cacheKey] = httpGet<TranslatedListItem[]>(`/api/lists/options/${key}`, {owner, locale})
    .then((r) => r.data)
    .then((listItems) => {
      listCache[cacheKey] = listItems;
      return listItems;
    });

  return listCacheInFlight[cacheKey];
}

export async function getLists(allListOverride?: boolean): Promise<ListDTO[]> {
  return (await httpGet<ListDTO[]>(`/api/lists/manage`, {allListOverride})).data;
}

export async function createList(list: Omit<ListDTO, "_id">): Promise<ListDTO> {
  return (await httpPost<ListDTO>(`/api/lists/manage`, list)).data;
}

export async function updateList(list: ListDTO): Promise<ListDTO> {
  return (await httpPut<ListDTO>(`/api/lists/manage/${list._id}`, list)).data;
}

export async function deleteList(id: string): Promise<void> {
  await httpDelete<void>(`/api/lists/manage/${id}`);
}

export interface AdminListError {
  type: string;
}

export enum AdminListEvent {
  CollapseAll = "CollapseAll",
  ExpandAll = "ExpandAll",
}

export type AdminListEvents = {
  [AdminListEvent.CollapseAll]: void;
  [AdminListEvent.ExpandAll]: void;
};

export const adminListEvents = mitt<AdminListEvents>();

// this is just for conversion stuff and can probably be removed sooner than later
export async function convertToLists(lists?: string[]): Promise<string> {
  // you can send specific list keys to update
  return (await httpPost<{progressId: string}>(`/api/values/convert`, {lists})).data.progressId;
}

export async function checkConversionProgress(progressId: string): Promise<{completed: number; total: number} | null> {
  return (
    await httpPost<{progress: {completed: number; total: number} | null}>(`/api/values/conversion-progress`, {
      progressId,
    })
  ).data.progress;
}
