import lunr from "lunr";
import {Dictionary, pathOr} from "ramda";
import {isEmptyOrUndefined, JSONObject} from "../utils";
import {JSONUser} from "./search";

export interface UserGroupIndexDocument {
  userNames: string[];
  emails: string[];
  name: string;
  description: string;
  id: string;
}

export interface JSONUserGroup extends JSONObject {
  name: JSONObject;
  descripton: JSONObject;
  _id: string;
  users: JSONUser[];
}

export interface UserGroupSearchOptions {
  groups: JSONUserGroup[];
  locale: string;
}

export class UserGroupSearchIndex {
  private index?: lunr.Index;
  private documents: Dictionary<UserGroupIndexDocument> = {};

  constructor(private opts: UserGroupSearchOptions) {}

  public search(query: string): lunr.Index.Result[] {
    if (!this.index) {
      throw new Error("No index. Call buildIndex first.");
    }

    // return this.index.search(`*${query}*`);
    return this.index.query((lunrQuery) => {
      query.split(lunr.tokenizer.separator).forEach((term) => {
        const lower = term.toLowerCase().replace(/[^\w.'@]+/g, "");
        lunrQuery.term(lower, {
          presence: lunr.Query.presence.REQUIRED,
          // tslint:disable-next-line:no-bitwise
          wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
        });
      });
    });
  }

  public async buildIndex(): Promise<UserGroupSearchIndex> {
    const docs = await this.buildIndexDocuments();
    this.documents = {};
    const $this = this;
    this.index = lunr(function () {
      this.field("name");
      this.field("description");
      this.field("userNames");
      this.field("emails");
      this.ref("id");

      this.pipeline.remove(lunr.stopWordFilter);
      this.searchPipeline.remove(lunr.stopWordFilter);
      this.pipeline.remove(lunr.stemmer);
      this.searchPipeline.remove(lunr.stemmer);

      docs.forEach((doc) => {
        this.add(doc);
        $this.documents![doc.id] = doc;
      });
    });
    return this;
  }

  private async buildIndexDocuments(): Promise<UserGroupIndexDocument[]> {
    const {groups, locale} = this.opts;
    const docs: UserGroupIndexDocument[] = [];
    for (const group of groups) {
      const {users} = group;
      const userNames = !isEmptyOrUndefined(users) ? users.map((u) => pathOr("", ["profile", "displayName"], u)) : [];
      const emails = !isEmptyOrUndefined(users) ? users.map((u) => u.email) : [];
      const doc: UserGroupIndexDocument = {
        name: pathOr("", ["name", locale], group),
        description: pathOr("", ["description", locale], group),
        id: group._id,
        userNames,
        emails,
      };

      docs.push(doc);
    }
    return docs;
  }
}
