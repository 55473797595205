import globalLogger from "@/logging";
import type {PGActionContext, PGActionTree} from "@/store/index-types";
import {actionRequestWrap} from "@/store/index-types";
import {autoMutations} from "@/store/utils";
import type {ExportMessage} from "pg-isomorphic/api/exports";
import type {APISearchEntityParams, APISearchKitParams} from "pg-isomorphic/api/search";
import {ExportStatus} from "pg-isomorphic/enums/jobs";
import {RoutingKey} from "pg-isomorphic/queue";

export interface ExportState {
  open: boolean;
  status: ExportStatus | null;
  total: number;
  processed: number;
  fileId: string | null;
  error: string | null;
  rowErrors: RowError[];
  progress: number;
  jobId: string | null;
  loading: boolean;
  title: string | null;
}

export interface RowError {
  error: string;
  connectionId: string;
}

const initialState: ExportState = {
  open: false,
  status: ExportStatus.SEARCHING,
  total: 0,
  processed: 0,
  fileId: null,
  error: null,
  progress: 0,
  jobId: null,
  loading: false,
  title: null,
  rowErrors: [],
};

const logger = globalLogger.getLogger("export");
type Ctx = PGActionContext<ExportState>;
const req = actionRequestWrap(logger, "error exporting connections");

let jobMessage: (x: any) => void = () => {
  /**/
};

const actions: PGActionTree<ExportState> = {
  async start({commit, dispatch}: Ctx, {params, title}: {params: APISearchEntityParams; title?: string}) {
    return req(async () => {
      commit("open", true);
      commit("title", title);
      const response = await this.httpPost("/api/entities/search/export", params);
      const msg = response.data as ExportMessage;
      commit("jobId", msg.jobId);
      await dispatch("joinRooms");
    }, commit);
  },
  async startQuestionsExport({commit, dispatch}: Ctx) {
    return req(async () => {
      commit("open", true);
      const response = await this.httpGet("/api/admin/questions/export");
      const msg = response.data as ExportMessage;
      commit("jobId", msg.jobId);
      await dispatch("joinRooms");
    }, commit);
  },
  async startSearchKitsExport({commit, dispatch}: Ctx, {params}: {params: APISearchKitParams}) {
    return req(async () => {
      commit("open", true);
      const response = await this.httpPost(`/api/kit/search/${params.kitType}/export`, params);
      const msg = response.data as ExportMessage;
      commit("jobId", msg.jobId);
      await dispatch("joinRooms");
    }, commit);
  },
  reset({commit}: Ctx) {
    for (const k of Object.keys(initialState)) {
      commit(k, (initialState as any)[k]);
    }
  },
  joinRooms({state, commit}: Ctx) {
    jobMessage = (wsMessage) => {
      if (!wsMessage) {
        return;
      }
      if (wsMessage.status === ExportStatus.ROW_ERROR) {
        const rowErrors = [...state.rowErrors];
        rowErrors.push({
          error: wsMessage.error,
          connectionId: wsMessage.info?.connectionId,
        });
        commit("rowErrors", rowErrors);
        return;
      }
      // see ExportService.reportStatus
      for (const k of Object.keys(wsMessage)) {
        if (wsMessage[k]) {
          commit(k, wsMessage[k]);
        }
      }
    };
    this.join(RoutingKey.jobProgress({jobId: state.jobId!}));
    this.socketOn(RoutingKey.jobProgress({jobId: state.jobId!}), jobMessage);
  },
  async leaveRooms({state, dispatch}: Ctx) {
    this.leave(RoutingKey.jobProgress({jobId: state.jobId!}));
    this.socketOff(RoutingKey.jobProgress({jobId: state.jobId!}), jobMessage);
    await dispatch("reset");
  },
  async close({dispatch}: Ctx) {
    await dispatch("leaveRooms");
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: autoMutations(initialState),
  actions,
};
