import moment from "moment";
import isoLogger from "pg-isomorphic/utils/logging";

export enum Level {
  ERROR = "error",
  WARN = "warn",
  INFO = "info",
  DEBUG = "debug",
  TRACE = "trace",
}

const LevelSequence = {
  [Level.ERROR]: 1,
  [Level.WARN]: 2,
  [Level.INFO]: 3,
  [Level.DEBUG]: 4,
  [Level.TRACE]: 5,
};

const LOCAL_STORAGE_KEY = "__pg_logger";
const env = process.env.NODE_ENV;

/**
 * Logger
 *
 *  record: add all messages to `this.logs`
 *  scope: scope for this logger, used with filters to decide which filters to log or display
 *  logToConsole: use console.log to print messages
 *  filters: list of strings that will be matched against scopes using scope.indexOf(filter)
 */
export class Logger {
  level: Level = env === "production" ? Level.WARN : Level.INFO;
  record = false;
  scope = "";
  logToConsole = env === "development";
  parent = null;
  logs: string[] = [];
  filters: string[] = [];
  constructor(scope?: string) {
    this.scope = scope || "";
  }

  error(msg: string | (() => string), ...args: any[]) {
    return this.log(Level.ERROR, [msg, ...args]);
  }

  warn(msg: string | (() => string), ...args: any[]) {
    return this.log(Level.WARN, [msg, ...args]);
  }

  info(msg: string | (() => string), ...args: any[]) {
    return this.log(Level.INFO, [msg, ...args]);
  }

  debug(msg: string | (() => string), ...args: any[]) {
    return this.log(Level.DEBUG, [msg, ...args]);
  }

  trace(msg: string | (() => string), ...args: any[]) {
    return this.log(Level.TRACE, [msg, ...args]);
  }

  log(level: Level, messages: [string | (() => string), ...any[]]) {
    try {
      // Only pay attention to the parent's record and filter configuration
      const root = this.getRoot();

      const levelSeq = LevelSequence[level] || 999;
      const mySeq = LevelSequence[root.level] || 999;
      if (mySeq < levelSeq) {
        return;
      }

      if (!root.record && !root.logToConsole) {
        return;
      }

      if (root.filters.length > 0) {
        let passes = false;
        const scope = this.scope || "";
        for (const filter of root.filters) {
          if (scope.indexOf(filter) > -1) {
            passes = true;
            break;
          }
        }
        if (!passes) {
          return;
        }
      }

      const msgParts = this.makeMessageParts(level, messages);

      /*if (root.record) {
        root.logs.push(this.formatMessagePartsForLog(msgParts));
      }*/
      if (root.logToConsole) {
        this.logMessagePartsToConsole(msgParts, level);
      }
    } catch (e) {
      console.error("error while logging", e);
    }
  }

  makeMessageParts(level: Level, messages) {
    const date = moment();

    let scope = "";
    if (this.scope) {
      scope = `[${this.scope}]`;
    }

    let returnAr = [level, date, scope];
    for (const message of messages) {
      if (typeof message === "function") {
        let result = "";
        try {
          result += message();
        } catch (e) {
          this.error(() => "Error running log message function", e);
        }
        returnAr.push(result);
      } else if (message && (message.stack || message instanceof Error)) {
        returnAr.push(message.stack);
      } else {
        returnAr.push(message);
      }
    }
    return returnAr;
  }

  formatMessagePartsForLog(parts) {
    let messageStr = `${parts[0]} ${parts[1].format()} ${parts[2]} `;

    for (let i = 3; i < parts.length; i++) {
      if (typeof parts[i] === "object") {
        messageStr += JSON.stringify(parts[i], null, 2);
      } else {
        messageStr += parts[i] + "\n";
      }
    }
    return messageStr;
  }

  logMessagePartsToConsole(parts, level) {
    /* eslint-disable no-console */
    let initialTitle = `${parts[2]} ${parts[3]}`;
    let styles = "";
    if (level && LevelSequence[level] < LevelSequence[Level.INFO]) {
      initialTitle = `%c${initialTitle}`;
      styles = `color: red`;
      if (level === Level.WARN) {
        styles = `color: orange`;
      }
    }

    console.groupCollapsed(initialTitle, styles);
    for (let i = 4; i < parts.length; i++) {
      if (level === Level.ERROR) {
        console.error(parts[i]);
      } else if (level === Level.WARN) {
        console.warn(parts[i]);
      } else {
        console.log(parts[i]);
      }
    }
    console.log(`→ ${parts[0].toUpperCase()} level → ${parts[1].format("L LTS")}`);
    console.groupEnd();
    /* eslint-enable no-console */
  }

  addFilter(filter: string) {
    this.filters = [...this.filters, filter];
  }

  removeFilter(filter: string) {
    const ix = this.filters.indexOf(filter);
    if (ix > -1) {
      this.filters.splice(ix, 1);
    }
    this.filters = [...this.filters];
  }

  getLogger(scope: string): Logger {
    const child = new Logger(scope);
    child.parent = this;
    child.level = this.level;
    child.scope = scope;
    return child;
  }

  saveSettings() {
    const root = this.getRoot();
    const logSettings = {
      level: root.level,
      filters: root.filters,
      logToConsole: root.logToConsole,
      record: root.record,
    };
    if (window.localStorage) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(logSettings));
    }
  }

  restoreSettings() {
    if (window.localStorage) {
      const settingString = window.localStorage.getItem(LOCAL_STORAGE_KEY);
      if (settingString) {
        try {
          const settings = JSON.parse(settingString);
          this.level = settings.level;
          this.filters = settings.filters;
          this.logToConsole = settings.logToConsole;
          this.record = settings.record;
        } catch (e) {
          this.error("Error restoring settings", e);
        }
      }
    }
  }

  getRoot() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let _logger = this;
    while (_logger.parent) {
      _logger = _logger.parent;
    }
    return _logger;
  }
}

const globalLogger = new Logger();
globalLogger.restoreSettings();

if (window) {
  (window as any)._logger = globalLogger;
}

isoLogger.setFactory(globalLogger);
export default globalLogger;
