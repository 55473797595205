import globalLogger from "../../logging";
import {autoMutations} from "../utils";
import {findIndex, clone, propEq, mergeRight, pathOr, reject, isNil} from "ramda";
import moment from "moment";
import {Profile} from "pg-isomorphic/enums";
import {TranslateEvents} from "pg-isomorphic/enums/translate";
import cloneDeep from "lodash/cloneDeep";

const logger = globalLogger.getLogger("notes");

const stripNullParams = (params) => {
  return reject(isNil, params);
};

const state = {
  // Shared
  loading: false,
  error: true,
  notes: [],
  label: "",
  // Action Plan Specific
  showActionPlanNotes: false,
  actionPlanId: null,
  // Tasks
  taskId: null,
  // Profile Task Specific
  isOpen: false,
  elementId: null,
  instanceId: null,
  entityId: "",
  theirEntityId: "",
  connectionId: null,
  kitId: null,
  kitType: null,
  profile: Profile.MINE,
};

// getters
const getters = {};

const actions = {
  /**
   * Init for task notes only
   */
  async initNotes({commit, dispatch}, {entityId, label, elementId, instanceId, connectionId, theirEntityId}) {
    commit("loading", true);

    let profile = Profile.MINE;
    if (connectionId && entityId === theirEntityId) {
      profile = "theirs";
    }

    logger.debug(
      `initNotes ${entityId}, elementId: ${elementId}, instanceId: ${instanceId}, connectionId: ${connectionId}, ${theirEntityId}, ${profile}`,
    );

    commit("elementId", elementId);
    commit("instanceId", instanceId);
    commit("connectionId", connectionId);
    commit("entityId", entityId);
    commit("theirEntityId", theirEntityId);
    commit("label", label);
    commit("profile", profile);
    commit("taskId", null);
    commit("actionPlanId", null);

    commit("isOpen", true);

    await dispatch("getNotes", {});
    commit("loading", false);
  },
  /**
   * Init for action plan notes only
   */
  async initActionPlanNotes({commit, dispatch, rootState}, {label, actionPlanId}) {
    commit("loading", true);

    const entityId = rootState.user.activeEntityId;
    logger.debug(`initNotes ${entityId}, actionPlanId: ${actionPlanId}`);

    commit("actionPlanId", actionPlanId);
    commit("entityId", entityId);
    commit("label", label);
    commit("elementId", null);
    commit("instanceId", null);
    commit("connectionId", null);
    commit("taskId", null);

    commit("showActionPlanNotes", true);

    await dispatch("getNotes", {});
    commit("loading", false);
  },
  /**
   * Init for task notes only
   */
  async initTaskNotes({commit, dispatch, rootState}, {taskId}) {
    const entityId = rootState.user.activeEntityId;
    commit("loading", true);

    logger.debug(`initTaskNotes ${entityId}, taskId: ${taskId}`);

    commit("taskId", taskId);
    commit("entityId", entityId);
    commit("label", "");
    commit("elementId", null);
    commit("instanceId", null);
    commit("connectionId", null);
    commit("actionPlanId", null);

    await dispatch("getNotes", {});
    commit("loading", false);
  },
  async initKitNotes({commit, dispatch, rootState}, {kitId, kitType, elementId, instanceId}) {
    commit("loading", true);

    const entityId = rootState.user.activeEntityId;

    logger.debug(
      `kitId: ${kitId}, kitType: ${kitType}, elementId: ${elementId}, instanceId: ${instanceId}, entityId: ${entityId}`,
    );

    commit("kitId", kitId);
    commit("kitType", kitType);
    commit("entityId", entityId);
    commit("label", "");
    commit("elementId", elementId || null);
    commit("instanceId", instanceId || null);
    commit("connectionId", null);
    commit("actionPlanId", null);
    commit("taskId", null);

    commit("isOpen", true);

    await dispatch("getNotes", {});
    commit("loading", false);
  },
  async getNotes({commit, state}) {
    commit("error", false);
    try {
      const response = await this.httpGet(
        "/api/notes",
        stripNullParams({
          pertainingToEntityId: state.entityId,
          elementId: state.elementId,
          connectionId: state.connectionId,
          instanceId: state.instanceId,
          actionPlanId: state.actionPlanId,
          taskId: state.taskId,
          kitId: state.kitId,
          kitType: state.kitType,
        }),
      );
      logger.trace("getNotes:", JSON.stringify(response.data));
      if (response.status === 200 && response.data) {
        commit("notes", response.data.notes || []);
      }
    } catch (e) {
      commit("error", true);
    }
  },
  async addNote({commit, state, rootState}, {note}) {
    const response = await this.httpPost(
      "/api/notes",
      stripNullParams({
        entityId: state.entityId,
        elementId: state.elementId,
        connectionId: state.connectionId,
        instanceId: state.instanceId,
        actionPlanId: state.actionPlanId,
        taskId: state.taskId,
        kitId: state.kitId,
        kitType: state.kitType,
        note: note,
      }),
    );
    if (response.status === 200) {
      const notes = state.notes;
      const note = response.data.note;
      note.createdBy = {
        _id: note.createdBy,
        profile: {
          displayName: rootState.user.displayName,
          imageFile: rootState.user.imageFileId,
        },
      };
      notes.unshift(response.data.note);
      commit("notes", clone(notes));
    }
  },
  async deleteNote({commit, state, dispatch}, {id}) {
    const response = await this.httpDelete(`/api/notes/${id}`);
    if (response.status === 204) {
      logger.trace("deleteNote", id);
      commit(
        "notes",
        state.notes.filter((n) => n._id !== id),
      );
    }
  },
  async saveNote({commit, state}, {id, note, userLocale}) {
    const response = await this.httpPatch(`/api/notes/${id}`, {note});
    if (response.status === 204) {
      const idx = findIndex((n) => n._id === id, state.notes);
      if (idx > -1) {
        state.notes[idx] = mergeRight(state.notes[idx], {
          [userLocale]: {
            value: note,
          },
        });
        state.notes[idx].updatedAt = moment().format();
        logger.trace("saveNote", state.notes[idx]);
        commit("notes", clone(state.notes));
      }
    }
  },
  async translateNote({state, commit}, {noteId, userLocale}) {
    const response = await this.httpPost(`/api/notes/translate/${noteId}`);
    let existingNotes = cloneDeep(state.notes);
    const updatedId = findIndex(propEq("_id", noteId), existingNotes);
    logger.trace(() => `translateNote API response: ${noteId}`, response, {
      note: mergeRight(existingNotes[updatedId].note, {
        [userLocale]: {
          value: pathOr("", ["data", "translation"], response),
        },
      }),
    });
    existingNotes[updatedId] = mergeRight(existingNotes[updatedId], {
      note: mergeRight(existingNotes[updatedId].note, {
        [userLocale]: {
          value: pathOr("", ["data", "translation"], response),
        },
      }),
    });
    commit("notes", existingNotes);
    this.emitter.$emit(TranslateEvents.TRANSLATED, {error: false});
  },
  async rateNoteTranslation({}, {noteId, sourceLocale, goodTranslation}) {
    await this.httpPost(`/api/translate/rating`, {
      goodTranslation,
      sourceLocale,
      noteId,
    });
  },
};

const mutations = {
  ...autoMutations(state),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
