import {getStore} from "@/composables/get.store";
import {loadLanguageAsync, localeList} from "@/i18n";
import {getRouter} from "@/router/util";
import * as moment from "moment-timezone";
import type {ComputedRef, InjectionKey} from "vue";
import {shortLocale} from "pg-isomorphic/utils/locale";
import {Locale} from "pg-isomorphic/enums";

export async function signUp() {
  await getStore().dispatch("signOut");
  const route = getRouter().resolve(`/signup`);
  window.open(route.href, "_new");
}

export async function signOut() {
  await getRouter().push(`/signout?signoutFrom=${window.location.pathname}`);
}

function findClosestSupportedLanguage(lang: string): Locale {
  if (localeList[lang]) {
    return lang as Locale;
  }
  const shortLang = shortLocale(lang);
  for (const key of Object.keys(localeList)) {
    const locale = localeList[key];
    if (locale.filename === shortLang) {
      return key as Locale;
    }
  }
  return Locale.EN_US;
}

export function setInitialLanguage(lang: string) {
  const supportedLang = findClosestSupportedLanguage(lang);
  getStore().commit("language", supportedLang);
}

export function changeLanguage(lang) {
  const supportedLang = findClosestSupportedLanguage(lang);
  loadLanguageAsync(supportedLang);
  getStore().commit("language", supportedLang);
  window.localStorage.setItem("language", supportedLang);
  moment.locale(supportedLang);
}

export const IsMenuInMobileMode: InjectionKey<ComputedRef<boolean>> = Symbol("isMenuInMobileMode");
