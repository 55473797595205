import type {PGActionTree, PGGetterTree} from "./../../index-types";
import {autoMutations} from "../../utils";
import {TaskType} from "pg-isomorphic/enums/task";
import globalLogger from "../../../logging";
import {TaskActorType} from "pg-isomorphic/enums";
import type {BasicUser} from "pg-isomorphic/api/user";
import type {AxiosResponse} from "axios";

const logger = globalLogger.getLogger("assign-topic-owners");

export interface AssignTopicOwnersState {
  initialized: boolean;
  error: string;
  loading: boolean;
  assignableUsers: BasicUser[];
  processedName: string;
  finished: boolean;
  finishedWithInvite: boolean;
}

export const state: AssignTopicOwnersState = {
  initialized: false,
  error: null,
  loading: true,
  assignableUsers: [],
  processedName: "",
  finished: false,
  finishedWithInvite: false,
};

// getters
const getters: PGGetterTree<AssignTopicOwnersState> = {};

// actions
const actions: PGActionTree<AssignTopicOwnersState> = {
  async getAssignableUsers({commit}, {taskId, taskType}: {taskId: string; taskType: TaskType}) {
    try {
      commit("finished", false);
      commit("loading", true);
      commit("initialized", true);
      let requestBody: {forTaskId?: string} = {};
      if (taskType === TaskType.REVIEW) {
        requestBody.forTaskId = taskId;
      }
      const response: AxiosResponse<{users: BasicUser[]}> = await this.httpPost("/api/users/query", requestBody);
      commit("assignableUsers", response.data.users);
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loading", false);
    }
  },
  async inviteUser(
    {commit, dispatch},
    {
      inviteUser,
      makeTopicOwner,
      taskId,
      owningRole,
    }: {
      inviteUser: {
        name: string;
        email: string;
      };
      makeTopicOwner: boolean;
      taskId: string;
      owningRole: string;
    },
  ) {
    try {
      commit("finishedWithInvite", true);
      commit("loading", true);
      const response: AxiosResponse<{_id: string}> = await this.httpPost(`/api/admin/users/invite`, inviteUser);
      const getUserResponse: AxiosResponse<{users: BasicUser[]}> = await this.httpPost("/api/users/query", {
        userId: response.data._id,
      });
      dispatch("assignTask", {taskId, user: getUserResponse.data.users[0], makeTopicOwner, owningRole});
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error updating approval for user", e);
      }
    }
  },
  async assignTask({commit, dispatch}, {taskId, user, makeTopicOwner, owningRole}) {
    try {
      commit("loading", true);
      await this.httpPost("/api/tasks/assign", {
        taskId,
        actors: [{id: user._id, type: TaskActorType.USER}],
      });
      if (makeTopicOwner) {
        await dispatch("updateUserWithOwnerRole", {user, owningRole});
      }
      commit("processedName", user.name);
      commit("finished", true);
    } catch (err) {
      commit("error", err.response.data);
    } finally {
      commit("loading", false);
    }
  },
  async updateUserWithOwnerRole({commit}, {user, owningRole}) {
    user.roles.push(owningRole);
    try {
      await this.httpPut(`/api/admin/users/${user._id}`, user);
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error updating approval for user", e);
      }
    }
  },
  resetStates({commit}) {
    commit("initialized", false);
    commit("finished", false);
    commit("assignableUsers", []);
    commit("finishedWithInvite", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: {
    ...autoMutations(state),
  },
};
