import {autoMutations} from "../utils";
import {AuditEvent} from "pg-isomorphic/enums/events";
import {default as globalLogger} from "../../logging";
import {Helper, sharedActions} from "./notifications";

const logger = globalLogger.getLogger("notifications");

const state = {
  entity: {},
  user: {},
  notifications: {
    results: [],
  },
  loadingNotifications: false,
  loadingError: null,
  page: 1,
  limit: 50,
};

// actions
const actions = {
  ...sharedActions,
  async getNotifications({commit, state, rootState}, {messageRead, limit} = {}) {
    commit("loadingNotifications", true);
    try {
      const entityId = rootState.user.activeEntityId;

      const response = await this.httpGet(`/api/notifications`, {
        onlyOfTypes: AuditEvent.MESSAGE_CREATED,
        messageRead,
        entityId,
        skip: (state.page - 1) * (limit || state.limit),
        limit: limit || state.limit,
      });

      const notifications = Helper.translateNotifications(response.data.results, rootState.user);
      state.notifications = {
        results: notifications,
        total: response.data.count,
      };
    } catch (e) {
      logger.error("error loading notifications", e);
      commit("loadingError", e);
    } finally {
      commit("loadingNotifications", false);
    }
  },
  async markNotificationsRead() {
    await this.httpPost(`/api/notifications/read/all?onlyOfTypes=${AuditEvent.MESSAGE_CREATED}`);
  },
  // eslint-disable-next-line no-empty-pattern
  async markMessagesRead({}, {ids}) {
    await this.httpPost("/api/messages/read", {
      messageIds: ids,
    });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...autoMutations(state),
  },
};
