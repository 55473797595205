import {autoMutations} from "../../utils";
import globalLogger from "../../../logging";
import {pathOr} from "ramda";

const logger = globalLogger.getLogger("reminder");

const state = {
  loading: false,
  upcomingReminders: [],
  incompleteReminders: [],
  completedReminders: [],
  reminderListOpen: false,
  reminderTitle: "",
  breadcrumbs: ["", ""],
  contextName: "",
  contextImg: "",
  entityId: "",
  elementId: "",
  connectionId: "",
  instanceId: "",
  finishedGettingReminders: false,
  selectedReminder: "new",
  addError: null,
  reminders: [],
  editMode: false,
  kitId: null,
  kitType: null,
};

// getters
const getters = {
  reminders(state) {
    return [...state.incompleteReminders, ...state.upcomingReminders, ...state.completedReminders];
  },
};

// actions
const actions = {
  async addReminder({state, dispatch}, {recipientType, remindAt, notes}) {
    await this.httpPost(`/api/reminders`, {
      entityId: state.entityId,
      elementId: state.elementId,
      connectionId: state.connectionId,
      instanceId: state.instanceId,
      kitType: state.kitType,
      kitId: state.kitId,
      remindAt,
      notes,
      recipientType,
    });
    await dispatch("getReminders", {reminderType: "upcoming"});
    logger.trace("addReminder", remindAt, notes);
  },
  async updateReminder({state, dispatch}, {id, remindAt, notes}) {
    await this.httpPatch(`/api/reminders/${id}`, {
      entityId: state.entityId,
      elementId: state.elementId,
      connectionId: state.connectionId,
      kitType: state.kitType,
      kitId: state.kitId,
      remindAt: remindAt,
      notes: notes,
    });

    await dispatch("getReminders", {reminderType: "upcoming"});
    logger.trace("updateReminder", id, remindAt, notes);
  },
  async completeReminder({dispatch}, {id}) {
    await this.httpPost(`/api/reminders/complete/${id}`);

    await dispatch("getReminders", {reminderType: "all"});

    logger.trace("completeReminder", id);
  },
  async deleteReminder({dispatch}, {id}) {
    await this.httpDelete(`/api/reminders/${id}`);

    await dispatch("getReminders", {reminderType: "upcoming"});
    logger.trace("deleteReminder", id);
  },
  async getReminders({commit, state}, {reminderType}) {
    let getReminder = async (specificReminderType) => {
      const response = await this.httpGet(`/api/reminders/${specificReminderType}`, {
        pertainingToEntityId: state.entityId,
        connectionId: state.connectionId,
        elementId: state.elementId,
        instanceId: state.instanceId,
        kitType: state.kitType,
        kitId: state.kitId,
        skip: 0,
        limit: 100,
        daysBack: 365 * 5,
        allUsers: true,
      });
      // commit('reminders', pathOr([], ['data', 'results'], response));
      commit(`${specificReminderType}Reminders`, pathOr([], ["data", "results"], response));
    };

    if (reminderType === "all" || reminderType === "upcoming") {
      await getReminder("upcoming");
    }
    if (reminderType === "all" || reminderType === "incomplete") {
      await getReminder("incomplete");
    }
    if (reminderType === "all" || reminderType === "completed") {
      await getReminder("completed");
    }
    commit("reminders", [...state.incompleteReminders, ...state.upcomingReminders, ...state.completedReminders]);
  },
  async initReminder(
    {commit, dispatch},
    {breadcrumbs, reminderTitle, elementId, instanceId, connectionId, entityId, kitType, kitId},
  ) {
    commit("loading", true);

    logger.debug(
      `initReminder ${entityId} ${elementId} ${instanceId} ${connectionId} ${reminderTitle} ${kitType}, ${kitId}`,
    );
    commit("reminderTitle", reminderTitle);
    commit("breadcrumbs", breadcrumbs);
    commit("elementId", elementId);
    commit("instanceId", instanceId || "");
    commit("connectionId", connectionId);
    commit("entityId", entityId);
    commit("kitType", kitType || null);
    commit("kitId", kitId || null);
    commit("finishedGettingReminders", false);
    commit("reminderListOpen", true);

    await dispatch("getReminders", {reminderType: "all"});

    commit("finishedGettingReminders", true);
    commit("loading", false);
  },
};

const mutations = {
  ...autoMutations(state),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
