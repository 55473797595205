import {autoMutations} from "../utils";
import {
  filter,
  findIndex,
  propOr,
  difference,
  defaultTo,
  path,
  pathOr,
  pluck,
  isNil,
  isEmpty,
  intersection,
} from "ramda";
import clone from "lodash/clone";
import {RoutingKey} from "pg-isomorphic/queue";
import {ConnectionRole, EntityAccessStatus, Stage, TaskChangeReason, TaskChangeType} from "pg-isomorphic/enums";
import {stageNumber} from "pg-isomorphic/enums/connections";
import {ChangeType} from "pg-isomorphic/enums/queue";
import {TaskSortBy, TableType} from "pg-isomorphic/enums/task";
import throttle from "lodash/throttle";
import globalLogger from "../../logging";
import {TaskActorType, TaskTypeExtra} from "pg-isomorphic/enums/task";

// TODO: This used to be dashboard.js and was for the old dashboard. By making it tasks I'm trying to make it more
// TODO: specific so a lot of stuff should be removed.

const logger = globalLogger.getLogger("tasks");

export const taskTypeMap = {
  [TableType.ME]: "tasks",
  [TableType.MY_ROLES]: "roleTasks",
  [TableType.COMPANY]: "companyTasks",
  [TableType.COUNTERPARTY]: "counterpartyTasks",
};

export const taskTypeCountMap = {
  [TableType.ME]: "myTotalCount",
  [TableType.MY_ROLES]: "roleTotalCount",
  [TableType.COMPANY]: "companyTotalCount",
  [TableType.COUNTERPARTY]: "counterpartyTotalCount",
};

export const state = {
  pending_connections: [],
  pending_count: 0,
  recent_by_user_connections: [],
  recent_by_user_count: 0,
  skeleton_connections: [],
  skeleton_count: 0,
  connected_connections: [],
  connected_count: 0,
  reminders_overdue: [],
  reminders_upcoming: [],
  reminders_count: 0,
  companyTasks: [],
  companyTotalCount: 0,
  taskPage: 0,
  taskLimit: 20,
  noMoreTasks: false,
  notifications: [],
  entity: {},
  error: null,
  loadingTasks: false,
  assignableUsers: [],
  assignableUsersMulti: [],
  loadingAssignableUsers: false,
  filter_group: null,
  filter_topic: null,
  filter_connection_stage: null,
  filter_owner: null,
  filter_task_state: null,
  filter_task_type: null,
  filterUsers: [],
  reloadNextUpdate: false,
  activeTaskPage: 0,
  counterpartyTasks: [],
  counterpartyTotalCount: 0,
  counterpartyIsOn: false,
  tasks: [],
  roleTasks: [],
  myTotalCount: null,
  roleTotalCount: null,
  search_text: null,
  search_scope: null,
  searchConnectionIds: [],
  noSearchResultFound: false,
  selectedTableType: null,
  changeUsers: [],
  loadingChangeUsers: false,
  allTaskData: null,
};

const defaultState = clone(state);

const noReset = ["filterUsers"];

// getters
const getters = {
  hasTasks(state) {
    return state.myTotalCount + state.roleTotalCount + state.companyTotalCount + state.counterpartyTotalCount > 0;
  },
  hasFilters(state) {
    return (
      state.filter_topic ||
      state.filter_connection_stage ||
      state.filter_owner ||
      state.filter_task_state ||
      state.filter_task_type ||
      state.searchConnectionIds.length
    );
  },
};

export function wrapConnection(entityId, conn) {
  let companyName,
    companyDba,
    companyEntityId,
    companyRole,
    myRole,
    companyLogo,
    companyCountry,
    companyIndustry,
    companyProducts,
    companyRisk;
  if (conn.requestingEntity === entityId) {
    companyName = conn.respondingEntityName;
    companyDba = conn.respondingEntityDba;
    companyEntityId = conn.respondingEntity;
    companyRole = conn.respondingRole;
    companyLogo = conn.respondingEntityLogo;
    companyCountry = conn.respondingEntityCountry;
    companyIndustry = conn.respondingEntityIndustry;
    companyProducts = conn.respondingEntityProducts;
    companyRisk = conn.respondingEntityRisk;
    myRole = conn.requestingRole;
  } else {
    companyName = conn.requestingEntityName;
    companyDba = conn.requestingEntityDba;
    companyEntityId = conn.requestingEntity;
    companyRole = conn.requestingRole;
    companyLogo = conn.requestingEntityLogo;
    companyCountry = conn.requestingEntityCountry;
    companyIndustry = conn.requestingEntityIndustry;
    companyProducts = conn.requestingEntityProducts;
    companyRisk = conn.requestingEntityRisk;
    myRole = conn.respondingRole;
  }
  return {
    companyName,
    companyDba,
    companyEntityId,
    companyRole,
    companyLogo,
    companyCountry,
    companyIndustry,
    companyProducts,
    myRole,
    companyRisk,
    amRequester: conn.requestingEntity === entityId,
    status: conn.status,
    stageNumber: stageNumber[conn.status],
    updatedAt: conn.updatedAt,
    _id: conn._id,
  };
}

const loadNextTaskPage = throttle(({commit, state, dispatch}) => {
  if (state.loadingTasks || state.noMoreTasks) {
    return;
  }
  commit("taskPage", state.taskPage + 1);
  return dispatch("loadUserTasks", {append: true});
}, 1000);

function getConnectionCollectionKey(connection) {
  if (connection.status === Stage.CONNECTED) {
    return "connected_connections";
  }
  if (connection.isSkeleton) {
    return "skeleton_connections";
  }
  return "pending_connections";
}

// actions
const actions = {
  async reset({commit}) {
    for (const currentMutationName of Object.keys(defaultState)) {
      if (!noReset.includes(currentMutationName)) {
        commit(currentMutationName, defaultState[currentMutationName]);
      }
    }
  },
  async getConnections({commit, dispatch}, {type, limit, role}) {
    try {
      type = type || "pending"; // : 'connected';
      limit = limit || 5;
      const entityId = propOr(null, "activeEntityId", await dispatch("getCurrentUser", false, {root: true}));
      const response = await this.httpGet(`/api/connections/${type}?limit=${limit}&page=0&role=${role}`);
      const connections = response.data.results.map((conn) => wrapConnection(entityId, conn));
      commit(`${type}_connections`, connections);
      commit(`${type}_count`, response.data.total);
      return {connections, count: response.data.total};
    } catch (e) {
      /*empty*/
    }
  },

  async getReminders({commit}, {limit, page, days, noOverdue}) {
    try {
      limit = defaultTo(10, limit);
      page = defaultTo(0, page);
      days = defaultTo(30, days);

      let overdueTotal = 0;

      if (!noOverdue) {
        const overdue = await this.httpGet(`/api/reminders/incomplete?limit=${limit}&page=${page}&daysBack=${days}`);
        commit("reminders_overdue", overdue.data.results);
        overdueTotal = overdue.data.total;
      }

      const upcoming = await this.httpGet(`/api/reminders/upcoming?limit=${limit}&page=${page}&daysBack=${days}`);
      commit("reminders_upcoming", upcoming.data.results);

      commit(`reminders_count`, overdueTotal + upcoming.data.total);
    } catch (e) {
      /*empty*/
    }
  },

  async getEntity({commit, dispatch}) {
    try {
      const entityId = propOr(null, "activeEntityId", await dispatch("getCurrentUser", false, {root: true}));
      if (entityId) {
        // it's possible we can get here without a root entity in the store, so we call
        // that action to ensure we have the entity data we need there in the store as well
        // TODO we shouldn't be doing 2 requests
        const response = await dispatch("entity/getEntity", {entityId}, {root: true});
        commit("entity", response);
      }
    } catch (e) {
      /*empty*/
    }
  },

  async doSearch({commit, state}) {
    if (state.search_text && state.search_scope) {
      commit("counterpartyIsOn", true);
      const connectionResponse = await this.httpPost(`/api/entities/search`, {
        scope: state.search_scope,
        query: state.search_text,
        connectionRole: ConnectionRole.BOTH,
        limit: 1000,
      });
      const results = path(["data", "results"], connectionResponse);
      if (results && results.length) {
        commit(
          "searchConnectionIds",
          results.map((r) => r.connectionId),
        );
        commit("noSearchResultFound", false);
      } else {
        commit("searchConnectionIds", []);
        commit("noSearchResultFound", true);
      }
    } else {
      commit("searchConnectionIds", []);
      commit("noSearchResultFound", false);
    }
  },

  async loadUserTasks({commit, state}, {force, append, whosTasks, sortBy, sortOrder, connectionId}) {
    try {
      sortBy = sortBy || TaskSortBy.UPDATED;
      sortOrder = isNil(sortOrder) ? 1 : parseInt(sortOrder);

      let stateTaskNs = "tasks";
      let totalCountNs = "myTotalCount";
      let taskPageNs = "taskPage";
      if (whosTasks.indexOf(TableType.MY_ROLES) > -1) {
        stateTaskNs = "roleTasks";
        totalCountNs = "roleTotalCount";
      } else if (whosTasks === TableType.COMPANY) {
        stateTaskNs = "companyTasks";
        totalCountNs = "companyTotalCount";
      } else if (whosTasks === TableType.COUNTERPARTY) {
        stateTaskNs = "counterpartyTasks";
        totalCountNs = "counterpartyTotalCount";
      } else if (whosTasks === TableType.ALL) {
        commit("taskLimit", undefined);
      }

      commit("loadingTasks", true);
      if (force) {
        commit(taskPageNs, 0);
        commit(stateTaskNs, []);
        state[taskPageNs] = 0;
      }

      const page = append && state.taskLimit ? parseInt(state[stateTaskNs].length / state.taskLimit) : 0;
      let postData = {
        includeEntity: true,
        completed: false,
        includeAssignedUsers: true,
        includeApprovals: true,
        includeUpdatedByName: true,
        includeMessageCounts: true,
        includeMessages: true,
        includeNotes: true,
        limit: state.taskLimit,
        includeOwningEntity: true,
        page,
        assignedDirectlyToMe: whosTasks.indexOf("me") > -1,
        assignedToMyRoles: whosTasks.indexOf("my_roles") > -1,
        assignedToCompany: whosTasks === TableType.COMPANY,
        connectionId,
        sortBy,
        sortOrder,
      };
      if (state.searchConnectionIds && state.searchConnectionIds.length) {
        postData.searchConnectionIds = state.searchConnectionIds;
      } else if (state.search_text && state.noSearchResultFound) {
        if (!append) {
          commit(stateTaskNs, null);
        }
        commit(totalCountNs, 0);
        commit("noMoreTasks", true);
        return {total: 0};
      }
      if (state.filter_group) {
        if (Array.isArray(state.filter_group)) {
          postData.groupBy = pluck("value", state.filter_group).join(",");
        } else {
          postData.groupBy = state.filter_group.value;
        }
      }
      if (state.filter_topic) {
        if (Array.isArray(state.filter_topic)) {
          postData.topicElementIds = pluck("value", state.filter_topic);
        } else {
          postData.topicElementIds = [state.filter_topic.value];
        }
      }
      if (state.filter_connection_stage) {
        if (Array.isArray(state.filter_connection_stage)) {
          postData.connectionStages = pluck("value", state.filter_connection_stage);
        } else {
          postData.connectionStages = [state.filter_connection_stage.value];
        }
      }
      if (state.filter_owner) {
        if (Array.isArray(state.filter_owner)) {
          postData.filterUserIds = filter((v) => Boolean(v), pluck("value", state.filter_owner));
        } else if (state.filter_owner.value) {
          postData.filterUserIds = [state.filter_owner.value];
        }
      }

      if (!isEmpty(state.filter_task_state) && !isNil(state.filter_task_state)) {
        if (Array.isArray(state.filter_task_state)) {
          postData.states = pluck("value", state.filter_task_state);
        } else {
          postData.states = [state.filter_task_state.value];
        }
      }

      if (!isEmpty(state.filter_task_type) && !isNil(state.filter_task_type)) {
        const types = Array.isArray(state.filter_task_type)
          ? pluck("value", state.filter_task_type)
          : [state.filter_task_type.value];

        postData.onlyActionPlans = types.indexOf(TaskTypeExtra.ACTION_PLAN) > -1;
        postData.onlySupport = types.indexOf(TaskTypeExtra.SUPPORT) > -1;
      }

      let response;
      if (whosTasks === TableType.COUNTERPARTY) {
        postData.completed = false;
        response = await this.httpPost("/api/tasks/query/theirs", postData);
      } else {
        response = await this.httpPost("/api/tasks/query", postData);
      }

      if (append) {
        commit(stateTaskNs, state[stateTaskNs].concat(response.data.tasks));
      } else {
        if (whosTasks === TableType.ALL) {
          commit("allTaskData", response.data);
        } else {
          commit(stateTaskNs, response.data.tasks);
        }
      }
      commit(totalCountNs, response.data.total);
      if (response.data.tasks.length === 0) {
        commit("noMoreTasks", true);
      }
      return response.data;
    } catch (err) {
      commit("error", err.response.data);
      // throw the error so the promise.all can stop the multiple queries
      throw err;
    } finally {
      commit("loadingTasks", false);
    }
  },

  async loadMoreTasks({commit, dispatch, state}) {
    loadNextTaskPage({commit, dispatch, state});
  },

  async joinUserTasksRoom({dispatch}) {
    const user = await dispatch("getCurrentUser", false, {root: true});

    this._removeTask = (task) => dispatch("removeTask", task);
    this._updateTask = (task) => dispatch("updateTask", task);
    this._addTask = (task) => dispatch("addTask", task);

    this._addConnection = (connection) => dispatch("addConnection", connection);
    this._removeConnection = (connection) => dispatch("removeConnection", connection);
    this._updateConnection = (connection) => dispatch("updateConnection", connection);

    this.join(RoutingKey.entityTasks({entityId: user.activeEntityId}));
    this.socketOn(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.COMPLETE}),
      this._removeTask,
    );
    this.socketOn(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.UPDATE}),
      this._updateTask,
    );
    this.socketOn(RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.NEW}), this._addTask);
    this.socketOn(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.DELETE}),
      this._removeTask,
    );

    this.join(RoutingKey.entityConnections({entityId: user.activeEntityId}));
    this.socketOn(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.INSERT}),
      this._addConnection,
    );
    this.socketOn(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.DELETE}),
      this._removeConnection,
    );
    this.socketOn(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.UPDATE}),
      this._updateConnection,
    );
  },

  async leaveUserTasksRoom({dispatch}) {
    const user = await dispatch("getCurrentUser", false, {root: true});

    this.leave(RoutingKey.entityTasks({entityId: user.activeEntityId}));
    this.socketOff(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.COMPLETE}),
      this._removeTask,
    );
    this.socketOff(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.UPDATE}),
      this._updateTask,
    );
    this.socketOff(RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.NEW}), this._addTask);
    this.socketOff(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.DELETE}),
      this._removeTask,
    );

    this.leave(RoutingKey.entityConnections({entityId: user.activeEntityId}));
    this.socketOff(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.INSERT}),
      this._addConnection,
    );
    this.socketOff(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.DELETE}),
      this._removeConnection,
    );
    this.socketOff(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.UPDATE}),
      this._updateConnection,
    );
  },

  removeTask({state, commit}, task) {
    logger.debug(() => `task removed ${task.localizedTitle}`);
    const removeTaskHandler = (namespace, totalNs) => {
      const ix = findIndex((t) => t._id === task._id, state[namespace]);
      if (ix > -1) {
        const updated = clone(state[namespace]);
        updated.splice(ix, 1);
        commit(namespace, updated);
        commit(totalNs, state[totalNs] - 1);
      }
    };
    removeTaskHandler("tasks", "myTotalCount");
    removeTaskHandler("roleTasks", "roleTotalCount");
    removeTaskHandler("companyTasks", "companyTotalCount");
  },

  addTask({state, commit, getters}, task) {
    if (getters.hasFilters) {
      return;
    }
    logger.debug(() => `task added ${task.localizedTitle}`);
    let stateToClone = "tasks";
    let whichStateTotal = "myTotalCount";
    if (task.dashboardTableType === TableType.MY_ROLES) {
      stateToClone = "roleTasks";
      whichStateTotal = "roleTotalCount";
    } else if (task.dashboardTableType === TableType.COMPANY) {
      stateToClone = "companyTasks";
      whichStateTotal = "companyTotalCount";
    }
    if ((state[stateToClone] || []).find((t) => t._id === task._id)) {
      return;
    }
    const updated = clone(state[stateToClone]);
    updated.unshift(task);
    commit(stateToClone, updated);
    commit(whichStateTotal, state[whichStateTotal] + 1);
  },

  updateTask({state, commit, dispatch}, task) {
    logger.debug(() => `task updated ${task.localizedTitle}`);

    const reloadAll = async () => {
      await dispatch("loadUserTasks", {force: true, whosTasks: TableType.ME});
      await dispatch("loadUserTasks", {force: true, whosTasks: TableType.MY_ROLES});
      await dispatch("loadUserTasks", {force: true, whosTasks: TableType.COMPANY});
    };

    const updateTaskHandler = async (namespace) => {
      const ix = findIndex((t) => t._id === task._id, state[namespace]);
      if (ix > -1) {
        logger.trace(() => `task in ${namespace} ${task._id}`);
        const updated = clone(state[namespace]);
        let assignedDiff = difference(updated[ix].effectiveAssignedUsers, task.effectiveAssignedUsers);
        if (assignedDiff.length) {
          // if users change then reload tables since it might mean task(s) has moved tables
          await reloadAll();
        } else {
          updated[ix] = task;
          commit(namespace, updated);
        }
      }
    };

    if (state.reloadNextUpdate) {
      reloadAll();
      commit("reloadNextUpdate", false);
    } else {
      updateTaskHandler("tasks");
      updateTaskHandler("roleTasks");
      updateTaskHandler("companyTasks");
    }
  },

  async updateTaskComments({state}, {taskId, comments}) {
    logger.trace(() => `updating task comments for taskId: ${taskId}`);

    const updateTaskHandler = async (namespace) => {
      const ix = findIndex((t) => t._id === taskId, state[namespace]);
      if (ix > -1) {
        logger.trace(() => `task in ${namespace} ${taskId}`);
        const updated = state[namespace];
        updated["comments"] = comments;
      }
    };

    updateTaskHandler("tasks");
    updateTaskHandler("roleTasks");
    updateTaskHandler("companyTasks");
  },

  async assignTask({state, dispatch}, {taskId, actor, type}) {
    logger.trace(() => `assign task: ${taskId} (${type}) => ${actor._id} (${actor.name})`);
    const taskType = taskTypeMap[type];

    const idx = findIndex((t) => t.id === taskId, state[taskType]);
    const task = state[taskType][idx];
    const newAssignee = {
      _id: actor._id,
      email: actor.email,
      displayName: actor.name,
      imageThumbnailUrl: actor.imageFileId ? `/api/files/${actor.imageFileId}?thumbnail=true` : "",
    };

    task.effectiveAssignedUsers = [newAssignee];
    await dispatch(
      "taskDetails/fakeChangeLog",
      {
        type: TaskChangeReason.ASSIGNMENT,
        assignmentChange: {
          actors: [{id: actor._id, type: TaskActorType.USER}],
        },
      },
      {root: true},
    );
  },

  async assignTasks({state, commit}, {taskIds, actor, type}) {
    try {
      logger.trace(() => `assign task: ${taskIds} (${type}) => ${actor._id} (${actor.name})`);

      await this.httpPost("/api/tasks/assign", {
        taskIds: taskIds,
        actors: [{id: actor._id, type: TaskActorType.USER}],
      });

      for (const taskId of taskIds) {
        const taskType = taskTypeMap[type];
        const idx = findIndex((t) => t.id === taskId, state[taskType]);
        const task = state[taskType][idx];
        const newAssignee = {
          _id: actor._id,
          email: actor.email,
          displayName: actor.name,
          imageThumbnailUrl: actor.imageFileId ? `/api/files/${actor.imageFileId}?thumbnail=true` : "",
        };
        task.effectiveAssignedUsers = [newAssignee];
      }

      return true;
    } catch (err) {
      await commit("error", pathOr("", ["response", "data"], err));
      return false;
    }
  },

  async getAssignableUsers({commit}, {taskId}) {
    try {
      commit("loadingAssignableUsers", true);
      const response = await this.httpPost("/api/users/query", {
        forTaskId: taskId,
      });
      commit("assignableUsers", response.data.users);
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loadingAssignableUsers", false);
    }
  },

  async getChangeUsers({commit}, {userIds}) {
    try {
      commit("loadingChangeUsers", true);
      const response = await this.httpPost("/api/users/query", {
        userIds,
        includeSupportUsers: true,
      });
      commit("changeUsers", response.data.users);
      return response.data.users;
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loadingChangeUsers", false);
    }
  },

  async getAssignableUsersMulti({commit}, {taskIds}) {
    try {
      commit("loadingAssignableUsers", true);
      let responses = [];
      for (const taskId of taskIds) {
        const response = await this.httpPost("/api/users/query", {
          forTaskId: taskId,
        });
        if (responses.length) {
          responses = intersection(responses || [], response.data.users);
        } else {
          responses = response.data.users;
        }
      }
      commit("assignableUsersMulti", responses);
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loadingAssignableUsers", false);
    }
  },

  async getAllUsers({commit}) {
    try {
      const response = await this.httpPost("/api/users/query", {
        associationStatus: EntityAccessStatus.ACCEPTED,
        limit: 0,
      });
      commit("filterUsers", response.data.users);
    } catch (e) {
      commit("error", e.response.data);
    }
  },

  async addConnection({state, commit, dispatch}, connection) {
    logger.debug(() => `connection added ${connection._id}`);
    const key = getConnectionCollectionKey(connection);
    const user = await dispatch("getCurrentUser", false, {root: true});
    const wrapped = wrapConnection(user.activeEntityId, connection);
    const connections = clone(state[key]);
    connections.unshift(wrapped);
    commit(key, connections);
  },

  removeConnection({state, commit}, connectionChange) {
    logger.debug(() => `connection removed ${connectionChange._id}`);
    const key = getConnectionCollectionKey(connectionChange);
    const ix = findIndex((t) => t._id === connectionChange.connectionId, state[key]);
    if (ix > -1) {
      const updated = clone(state.connected_connections);
      updated.splice(ix, 1);
      commit(key, updated);
    }
  },

  async updateConnection({state, commit, dispatch}, connection) {
    logger.debug(() => `connection updated ${connection._id}`);
    const key = getConnectionCollectionKey(connection);
    const ix = findIndex((t) => t._id === connection._id, state[key]);
    if (ix > -1) {
      const updated = clone(state[key]);
      const user = await dispatch("getCurrentUser", false, {root: true});
      updated[ix] = wrapConnection(user.activeEntityId, connection);
      commit(key, updated);
    } else {
      dispatch("addConnection", connection);
    }
  },
  async markViewed({commit, state}, taskId) {
    const res = await this.httpPost("/api/tasks/mark_viewed", {taskId});
    if (res.status === 204) {
      // no content
      const idx = findIndex((t) => t._id === taskId, state.tasks);
      if (idx > -1) {
        state.tasks[idx].changesViewed = true;
        commit("tasks", clone(state.tasks));
      }
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: {
    ...autoMutations(state),
  },
};
