import type {ApprovalResponse} from "pg-isomorphic/api/approval";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {makeKey} from "pg-isomorphic/utils";
import globalLogger from "../../../logging";
import type {PGActionContext, PGActionTree} from "../../index-types";
import {actionRequestWrap} from "../../index-types";
import {autoMutations} from "../../utils";

const logger = globalLogger.getLogger("approvals.reviews");

interface Reviews {
  loading: boolean;
  approvals: {[key: string]: ApprovalResponse[]};
}

const initialState: Reviews = {
  loading: false,
  approvals: {},
};

type Ctx = PGActionContext<Reviews>;

const req = actionRequestWrap(logger, "error loading approvals API");

interface LoadArgs {
  question: JSONQuestion;
  pertainingToEntityId: string;
  connectionId?: string;
  instanceId?: string;
}

const actions: PGActionTree<Reviews> = {
  load({commit, state}: Ctx, args: LoadArgs): Promise<void> {
    const {pertainingToEntityId, connectionId, instanceId, question} = args;
    let url = `/api/approvals/entities/${pertainingToEntityId}?elementId=${question!._id}`;
    if (connectionId) {
      url += `&connectionId=${connectionId}`;
    }
    if (instanceId) {
      url += `&instanceId=${instanceId}`;
    }
    return req(async () => {
      const response = await this.httpGet<{approvals: ApprovalResponse[]}>(url);

      const key = makeKey([args.question._id, args.pertainingToEntityId, args.instanceId]);
      const approvals = {...state.approvals};
      approvals[key] = response.data.approvals;
      commit("approvals", approvals);
    }, commit);
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: {
    ...autoMutations(initialState),
  },
  actions,
};
