import cloneDeep from "lodash/cloneDeep";
import type {EntityExternalValidation, ExternalValidation, ValidationAuditInfo} from "pg-isomorphic/api/admin";
import type {UploadedFile} from "pg-isomorphic/api/document";
import type {APIError} from "pg-isomorphic/errors";
import {clone, findIndex, isEmpty, path} from "ramda";
import type {PGActionTree} from "../../index-types";
import {autoMutations} from "../../utils";
import type {Task} from "pg-isomorphic/api/tasks";
import {ConnectionRole, TaskChangeType, Tax} from "pg-isomorphic/enums";

export interface ValidationState {
  loading: boolean;
  error: APIError | null;
  limit: number;
  page: number;
  validation: EntityExternalValidation[];
  validationTasks: Task[];
  validationTaskTotal: number;
  topicOptions: [];
  entityConnections: [];
}

export interface StatusPayload {
  answerKey: string;
  entityId: string;
  status: string;
  groupKey?: string;
  instanceId?: string;
  note?: string;
  value?: string | boolean;
}

const initialState: ValidationState = {
  loading: false,
  error: null,
  limit: 5,
  page: 0,
  validation: [],
  validationTasks: [],
  validationTaskTotal: null,
  topicOptions: [],
  entityConnections: [],
};

interface APIData {
  validation: EntityExternalValidation[];
}

interface AuditAPIData {
  validationAuditInfo: ValidationAuditInfo;
}

const actions: PGActionTree<ValidationState> = {
  async getAllPendingValidations({commit, state}) {
    try {
      const response = await this.httpGet<APIData>("/api/admin/validation/pending", {
        page: state.page,
      });
      commit("validation", response.data.validation);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async searchValidations({commit, state}, searchText: string) {
    try {
      const response = await this.httpGet<APIData>(`/api/admin/validation/search?searchText=${searchText}`, {
        page: state.page,
        limit: state.limit,
        includeTasks: true,
      });
      commit("validation", response.data.validation);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async refreshEntityValidationInfo({commit, state}, publicId: string) {
    try {
      const response = await this.httpGet<APIData>(`/api/admin/validation/search?searchText=${publicId}`, {
        page: state.page,
        limit: state.limit,
        includeTasks: true,
      });
      if (response.data.validation.length === 1) {
        const validation = cloneDeep(state.validation);
        const entityIndex = validation.findIndex((v) => v.publicId === publicId);
        if (entityIndex >= 0) {
          validation[entityIndex] = response.data.validation[0];
          commit("validation", validation);
        }
      }
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async changeValidationAnswer({commit, state}, payload: StatusPayload): Promise<void> {
    commit("loading", true);
    try {
      const response = await this.httpPut("/api/admin/validation/answer", {
        entityId: payload.entityId,
        answerKey: payload.answerKey,
        groupKey: payload.groupKey,
        instanceId: payload.instanceId,
        value: payload.value,
      });
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async updateDisregardedEntityTaxFlag({commit, state}, payload: StatusPayload): Promise<void> {
    commit("loading", true);
    if (payload.answerKey !== Tax.DISREGARED_TAX_ENTITY) {
      commit("error", "Incorrect Answer Key");
      return;
    }
    try {
      await this.httpPut("/api/admin/validation/disregarded_entity", {
        entityId: payload.entityId,
        answerKey: payload.answerKey,
        value: payload.value,
      });
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async saveValidation({commit, state}, payload: StatusPayload): Promise<void> {
    commit("loading", true);
    try {
      const response = await this.httpPut<AuditAPIData>("/api/admin/validation", {
        entityId: payload.entityId,
        answerKey: payload.answerKey,
        status: payload.status,
        groupKey: payload.groupKey,
        instanceId: payload.instanceId,
        note: payload.note,
        value: payload.value,
      });

      const validation = cloneDeep(state.validation);
      const entityIndex = validation.findIndex((v) => v.entityId === payload.entityId);
      if (path(["data", "validationAuditInfo"], response) && entityIndex > -1) {
        const answerIndex = validation[entityIndex].fields.findIndex(
          (f) =>
            f.answerKey === payload.answerKey &&
            (!payload.groupKey || (payload.groupKey && f.groupKey === payload.groupKey)) &&
            (!payload.instanceId || (payload.instanceId && f.instanceId === payload.instanceId)),
        );
        if (answerIndex > -1) {
          validation[entityIndex].fields[answerIndex].validatedOn = path(
            ["data", "validationAuditInfo", "validatedOn"],
            response,
          );
          validation[entityIndex].fields[answerIndex].validatedBy = path(
            ["data", "validationAuditInfo", "validatedBy"],
            response,
          );
          commit("validation", validation);
        }
      }
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async saveValidationNote({commit}, payload: StatusPayload): Promise<void> {
    commit("loading", true);
    try {
      await this.httpPut<AuditAPIData>("/api/admin/validation/note", {
        entityId: payload.entityId,
        answerKey: payload.answerKey,
        status: payload.status,
        groupKey: payload.groupKey,
        instanceId: payload.instanceId,
        note: payload.note,
        value: payload.value,
      });
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async unblockEntityValidationFlag({commit, state}, entityId) {
    try {
      const response = await this.httpPatch<boolean>(`/api/admin/validation/${entityId}/unblock_validation`, {});
      const validation = cloneDeep(state.validation);
      const entityIndex = validation.findIndex((v) => v.entityId === entityId);
      validation[entityIndex].hasBlockingValidation = response.data;
      commit("validation", validation);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async getValidationTasks({commit, state}, params) {
    try {
      const response = await this.httpGet<{tasks; total}>(`/api/admin/validation/tasks`, params);
      commit("validationTasks", response.data.tasks);
      commit("validationTaskTotal", response.data.total);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async getEntityConnections({commit}, entityId) {
    try {
      const response = await this.httpGet("/api/admin/validation/entityConnections", {entityId});
      commit("entityConnections", response.data);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async grantAccessToEntity({commit}, entityId) {
    try {
      await this.httpPost<{user: any}>(`/api/admin/super/associate/${entityId}`, {
        activeConnectionRole: ConnectionRole.BOTH,
        defaultConnectionRole: ConnectionRole.SELLER,
      });
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async completeValidationTask({commit, state, dispatch}, tasks) {
    try {
      await this.httpPost("/api/admin/validation/completeTasks", {tasks});
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async uploadDocumentToValidation({commit, state}, data) {
    if (!data.entityId || !data.answerKey) {
      commit("error", "Error no answer key or entity id");
      return;
    }
    try {
      const response = await this.httpPost<{supportingFiles: UploadedFile[]; fileNote: string}>(
        "/api/admin/validation/supporting_file",
        data,
      );

      const validation = cloneDeep(state.validation);
      const entityIndex = validation.findIndex((v) => v.entityId === data.entityId);
      const fieldIndex = validation[entityIndex].fields.findIndex(
        (f) => f.answerKey === data.answerKey && f.instanceId === data.instanceId,
      );
      validation[entityIndex].fields[fieldIndex].supportingFiles = response.data.supportingFiles;
      validation[entityIndex].fields[fieldIndex].fileNote = response.data.fileNote;

      commit("validation", validation);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  taskChange({state, commit}, {task, event}) {
    switch (event) {
      case TaskChangeType.UPDATE: {
        const ix = findIndex((t) => t._id === task._id, state.validationTasks);
        if (ix > -1) {
          const updated = clone(state.validationTasks);
          updated[ix] = task;
          commit("validationTasks", updated);
        }
        state.validation.forEach((v) => {
          const i = findIndex((t) => t._id === task._id, v.validationTasks);
          if (i > -1) {
            v.validationTasks[i] = task;
          }
        });
        break;
      }
      case TaskChangeType.DELETE:
      case TaskChangeType.COMPLETE: {
        const ix = findIndex((t) => t._id === task._id, state.validationTasks);
        if (ix > -1) {
          const updated = clone(state.validationTasks);
          updated.splice(ix, 1);
          commit("validationTasks", updated);
        }
        state.validation.forEach((v) => {
          const i = findIndex((t) => t._id === task._id, v.validationTasks);
          if (i > -1) {
            v.validationTasks.splice(i, 1);
          }
        });
        break;
      }
    }
  },
  changeViewingValidations({state}, {entities}) {
    state.validationTasks.forEach((task) => {
      if (entities.includes(task.pertainingToEntity)) {
        task.viewingValidation = true;
      } else {
        task.viewingValidation = false;
      }
    });
  },
};

const getters = {
  entities(state: ValidationState): EntityExternalValidation[] {
    if (isEmpty(state.validation)) {
      return [];
    }
    return state.validation;
  },
  fields(state: ValidationState, getter: any): ExternalValidation[] {
    const entityId = getter.entityId;
    let entityValidation: EntityExternalValidation | undefined;
    if (entityId) {
      entityValidation = state.validation.find((p: EntityExternalValidation) => p.entityId === entityId);
    }
    if (entityValidation) {
      return entityValidation.fields;
    } else {
      return [];
    }
  },
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: {
    ...autoMutations(initialState),
  },
  actions,
  getters,
};
