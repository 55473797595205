import type {RouteRecordRaw} from "vue-router";

const testRoutes: RouteRecordRaw[] = [
  {
    path: "/temp",
    redirect: (to) => {
      return "/components";
    },
  },
  {
    path: "/components",
    name: "Components",
    component: () => import("@/components/testComponents/TestLanding.vue"),
  },
  {
    path: "/components/modals",
    name: "Modals",
    component: () => import("@/components/testComponents/ModalTest.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/associated-kits-table/:connectionId?",
    name: "Associated Kits Table",
    component: () => import("@/components/testComponents/AssociatedKitsTableTest.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/supplier-list-kits",
    name: "Supplier List",
    component: () => import("@/components/testComponents/SupplierListKitsTest.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/toast",
    name: "Toast",
    component: () => import("@/components/testComponents/ToastTest.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/timezones",
    name: "Timezones",
    component: () => import("@/components/testComponents/TestTimezones.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/inputs/:inputType?",
    name: "Inputs",
    component: () => import("@/components/testComponents/InputListTest.vue"),
    meta: {
      hide_menu: true,
    },
    props: (route) => {
      return {
        inputType: route.params.inputType,
      };
    },
  },
  {
    path: "/components/template-question",
    name: "Template",
    component: () => import("@/components/testComponents/TemplateQuestionTest.vue"),
    meta: {
      hide_menu: true,
    },
    props: (route) => {
      return {};
    },
  },
  {
    path: "/components/tree/:treeTestType?",
    name: "Question Tree",
    component: () => import("@/components/testComponents/QuestionTreeTest.vue"),
    meta: {
      hide_menu: true,
    },
    props: (route) => {
      return {
        treeTestType: route.params.treeTestType,
      };
    },
  },
  {
    path: "/components/kit-aux",
    name: "Kit Header",
    component: () => import("@/components/testComponents/TestKitHeader.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/form",
    name: "Form",
    component: () => import("@/components/testComponents/TestForm.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/companyname",
    name: "Company Name",
    component: () => import("@/components/testComponents/CompanyNameTest.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/buttons",
    name: "Buttons",
    component: () => import("@/components/testComponents/Buttons.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/card-selector",
    name: "Card",
    component: () => import("@/components/testComponents/TestCardSelector.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/bank-doc-validation",
    name: "Bank",
    component: () => import("@/components/testComponents/TestBankDocValidation.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/masks",
    name: "Masks",
    component: () => import("@/components/testComponents/Masks.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/version",
    name: "Version",
    component: () => import("@/components/testComponents/TestVersion.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/contact-modal",
    name: "Contact",
    component: () => import("@/components/testComponents/ContactModal.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/checkbox",
    name: "Checkbox",
    component: () => import("@/components/testComponents/Checkbox.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/tooltip-and-popover",
    name: "Tooltip & Popover",
    component: () => import("@/components/testComponents/TooltipAndPopover.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/paginator",
    name: "Paginator",
    component: () => import("@/components/testComponents/Paginator.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/collapse",
    name: "Collapse",
    component: () => import("@/components/testComponents/Collapse.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/file",
    name: "File",
    component: () => import("@/components/testComponents/File.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/w9",
    name: "W9 Tester",
    component: () => import("@/components/testComponents/W9.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/tables/custom",
    name: "Custom Table",
    component: () => import("@/components/testComponents/TestCustomTable.vue"),
    meta: {
      hide_menu: true,
    },
    props: (route) => {
      return {
        inputType: route.params.inputType,
      };
    },
  },
  {
    path: "/components/index",
    name: "Index",
    component: () => import("@/components/testComponents/TestLanding.vue"),
    meta: {
      hide_menu: true,
    },
    props: (route) => {
      return {
        inputType: route.params.inputType,
      };
    },
  },
  {
    path: "/components/dropdowns",
    name: "Dropdowns",
    component: () => import("@/components/testComponents/Dropdowns.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/progress-bars",
    name: "Progress Bars",
    component: () => import("@/components/testComponents/ProgressBars.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/badges",
    name: "Badges",
    component: () => import("@/components/testComponents/Badges.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/alerts-and-notes",
    name: "Alerts and Notes",
    component: () => import("@/components/testComponents/AlertsNotes.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/static-map",
    name: "Static Map",
    component: () => import("@/components/testComponents/TestStaticMap.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/confirm-popup",
    name: "Confirm Popup",
    component: () => import("@/components/testComponents/TestConfirmPopup.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/supplier-survey",
    name: "Supplier Survey",
    component: () => import("@/components/testComponents/TestSupplierSurvey.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/connection-status",
    name: "Connection Status",
    component: () => import("@/components/testComponents/TestConnectionStatus.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/topic-assign",
    name: "Topic Assign",
    component: () => import("@/components/testComponents/TestTopicAssign.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/hover-actions",
    name: "Hover Actions",
    component: () => import("@/components/testComponents/TestHoverActions.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/connection-overview",
    name: "Connection Overview",
    component: () => import("@/components/testComponents/TestConnectionOverview.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/auto-collapsing-items",
    name: "Auto Collapsing Items",
    component: () => import("@/components/testComponents/TestAutoCollapsingItems.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/trust-center",
    name: "Trust Center",
    component: () => import("@/components/testComponents/TestTrustCenter.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/master-data-connections-table",
    name: "Master data Table",
    component: () => import("@/components/testComponents/TestMasterDataConnectionsTable.vue"),
    meta: {
      hide_menu: true,
    },
  },
  {
    path: "/components/search-charts",
    name: "Search Charts",
    component: () => import("@/components/testComponents/TestSearchCharts.vue"),
    meta: {
      hide_menu: true,
    },
  },
];

export default testRoutes;
