import {DiffOptions} from "jest-diff";
import type {Dictionary} from "lodash";
import cloneDeep from "lodash/cloneDeep";
import fromPairs from "lodash/fromPairs";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import isNil from "lodash/isNil";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import uniqBy from "lodash/uniqBy";
import {Localizable} from "../api";
import {BasicEntityInfo} from "../api/entity";
import {ListAnswerPathConfig, ListAnswerType, ListDTO, ListItemDTO, ListLookup, TranslatedListItem} from "../api/lists";
import {Locale} from "../enums";
import {randomHex} from "../utils";
import {anyDiff} from "../utils/diff";
import {shortLocale} from "../utils/locale";

export type ExcelListRow = Dictionary<string | undefined>;
export type ExcelListData = ExcelListRow[];

export function localizeListItemLabel(
  localizableLabel: Localizable<string> | string | null | undefined,
  locale: string,
  localizations: Dictionary<Localizable<string>>,
  useFallback: false,
): string | undefined;
export function localizeListItemLabel(
  localizableLabel: Localizable<string> | string | null | undefined,
  locale: string,
  localizations?: Dictionary<Localizable<string>>,
  useFallback?: true,
): string;
export function localizeListItemLabel(
  localizableLabel: Localizable<string> | string | null | undefined,
  locale: string,
  localizations: Dictionary<Localizable<string>> = {}, // e.g. master localization.json file (should only use on backend)
  useFallback = true,
): string | undefined {
  if (isNil(localizableLabel) || typeof localizableLabel === "string") {
    return localizableLabel || (useFallback ? "" : undefined);
  }

  const englishLabel = localizableLabel[Locale.EN_US] || localizableLabel[shortLocale(Locale.EN_US)] || "";
  return (
    localizableLabel[locale] ||
    localizableLabel[shortLocale(locale)] ||
    (useFallback ? localizations[englishLabel]?.[locale] || englishLabel || "" : undefined)
  );
}

export function getOptionsFromListByAnswerPath(
  listItems: TranslatedListItem[],
  answers: Dictionary<string | string[]> = {},
  answerPath: ListAnswerPathConfig[] = [],
  forSearch = false,
): TranslatedListItem[] {
  return getOptionsFromListByParentAnswers(listItems, getAnswersFromPath(answers, answerPath), forSearch);
}

export function getAnswersFromPath(
  answers: Dictionary<string | string[]> = {},
  answerPath: ListAnswerPathConfig[] = [],
): Array<string | string[]> {
  return answerPath.map((answer) => (answer.type === ListAnswerType.Key ? get(answers, answer.value) : answer.value));
}

export function getOptionsFromListByParentAnswers<T extends ListItemDTO | TranslatedListItem>(
  listItems: T[],
  parentAnswers: Array<string | string[]> = [],
  forSearch = false,
): T[] {
  parentAnswers = [...parentAnswers];

  let listSlice: T[] = [...listItems];
  while (parentAnswers.length) {
    const answerValue: string | string[] = parentAnswers.shift()!;
    listSlice = getAllMatchingChildren(listSlice, answerValue, forSearch);
  }

  // if any of the parentAnswers were an array, we may have duplicates.
  // note that if you have "cousin" items with same value and different
  // label... all but the first of them is going to magically disappear!
  // Note: we prefix with `value_` so that it doesn't mess up with sort order if `item.value` is number-ish
  const groups = groupBy(listSlice, (item) => `value_${item.value}`);

  return Object.values(groups).map((group) => {
    if (group.length === 1) {
      return group[0];
    }

    // combine duplicates' children and IDs (mostly useful for debugging and knowing if any
    // children exist. will not affect subsequent `getOptionsFromListByAnswerPath` on scions)
    const ids: string[] = [];
    const children: T[] = [];

    const result = group[0];

    group.forEach((item) => {
      ids.push(item.id);
      children.push(...(item.children as T[]));
    });

    return {
      ...result,
      id: ids.join("__"),
      children,
    };
  });
}

function getAllMatchingChildren<T extends ListItemDTO | TranslatedListItem>(
  listItems: T[],
  answerValue: string | string[],
  forSearch = false,
): T[] {
  // if `forSearch` is true, we get all of the options, ignoring any parent answers. i.e. it could be all the grandchildren of all the grandparent answers
  if (forSearch) {
    return listItems.flatMap((listItem) => (listItem?.children || []) as unknown as T[]);
  }

  if (Array.isArray(answerValue)) {
    // this may have duplicates and will be de-duped at the last stage
    return listItems
      .filter((listItem) => answerMatch(listItem, answerValue))
      .flatMap((listItem) => (listItem?.children || []) as unknown as T[]);
  }

  return (listItems.find((listItem) => answerMatch(listItem, answerValue))?.children as T[]) || [];
}

function answerMatch(listItem: ListItemDTO | TranslatedListItem, answerValue: string | string[]): boolean {
  return Array.isArray(answerValue) ? answerValue.includes(listItem.value) : listItem.value === answerValue;
}

export function listItemsToExcel(
  listItems: ListItemDTO[] | TranslatedListItem[],
  columns: string[],
  locale: string,
): ExcelListData {
  return listItems.flatMap((listItem) => buildExcel(listItem, {}, columns, locale));
}

function buildExcel(
  listItem: ListItemDTO | TranslatedListItem,
  row: ExcelListRow = {},
  columns: string[] = [],
  locale,
  depth = 0,
): ExcelListData {
  const valueHeader =
    columns.length === 1 && columns[0].toLowerCase() === "value"
      ? "Value"
      : `${!columns[depth] ? `Column ${depth + 1}` : columns[depth]}: Value`;
  const labelHeader =
    columns.length === 1 && columns[0].toLowerCase() === "value"
      ? "Label"
      : `${!columns[depth] ? `Column ${depth + 1}` : columns[depth]}: Label`;

  if (!listItem.children?.length) {
    return [
      {
        ...row,
        [valueHeader]: listItem.value,
        [labelHeader]:
          listItem.label?.[locale] ||
          listItem.label?.[shortLocale(locale)] ||
          (typeof listItem.label === "string" ? listItem.label : "") ||
          "",
        ...(listItem.lookup || {}),
      },
    ];
  }

  return listItem.children.flatMap((child) =>
    buildExcel(
      child,
      {
        ...row,
        [valueHeader]: listItem.value,
        [labelHeader]: listItem.label?.[locale] || (typeof listItem.label === "string" ? listItem.label : "") || "",
      },
      columns,
      locale,
      depth + 1,
    ),
  );
}

interface Piece {
  value: string;
  label: string;
  parents: string[];
  lookup: Record<string, string>;
}

const SEPARATOR = "_|_";

export function excelToListItems(
  data: ExcelListData,
  locale: string = Locale.EN_US,
): {listItems: ListItemDTO[]; columns: string[]} {
  if (data.length === 0) {
    return {listItems: [], columns: []};
  }

  const firstRowKeys = Object.keys(data[0]);
  const isLegacyStandard = firstRowKeys[0].toLowerCase() === "value";

  const columns: string[] = [];
  const lookupKeys: string[] = [];
  // lookups can be on new-style hierarchical lists, (but only on leaf nodes)
  const firstLookupColumnIndex = isLegacyStandard
    ? 2
    : firstRowKeys.findIndex((col) => !col.endsWith(": Value") && !col.endsWith(": Label"));

  if (isLegacyStandard) {
    columns.push("value");
  } else {
    const standardColumns = firstLookupColumnIndex >= 2 ? firstLookupColumnIndex : firstRowKeys.length;
    for (let i = 0; i < standardColumns; i = i + 2) {
      columns.push(firstRowKeys[i].replace(": Value", ""));
    }
  }

  if (firstLookupColumnIndex >= 2) {
    for (let i = firstLookupColumnIndex; i < firstRowKeys.length; i++) {
      lookupKeys.push(firstRowKeys[i]);
    }
  }

  const grid: Array<Array<string | undefined>> = data.map((row) => Object.values(row));

  const columnCount = isLegacyStandard ? 1 : columns.length;
  const piecesWithDupes: Piece[] = grid.flatMap((row) => {
    const subpieces: Piece[] = [];
    const parents: string[] = [];
    for (let i = 0; i < columnCount * 2; i = i + 2) {
      const value: string = (row[i] ?? "") + "";
      const label: string = (row[i + 1] ?? "") + "";

      const lookup: Record<string, string> = {};
      if (firstLookupColumnIndex >= 2) {
        row.slice(firstLookupColumnIndex).map((val, j) => {
          lookup[lookupKeys[j]] = val || "";
        });
      }

      subpieces.push({value, label, lookup, parents: [...parents]});
      parents.push(value);
    }

    return subpieces;
  });

  const pieces = uniqBy(piecesWithDupes, (piece) => `${piece.value}---${piece.parents.join(SEPARATOR)}`);

  // Lookup values will get duplicated on parents, but only leaf nodes are allowed to have them, so we remove them from non-leaf nodes
  const listItems = listItemsMap(buildTree(pieces, [], locale), (listItem) => {
    if (listItem.children.length) {
      return {
        ...listItem,
        lookup: {},
      };
    }

    return listItem;
  });

  return normalizeList({listItems, columns});
}

function buildTree(pieces: Piece[], parents: string[], locale: string): ListItemDTO[] {
  const parentPath = parents.join(SEPARATOR);
  const matches = pieces.filter((piece) => piece.parents.join(SEPARATOR) === parentPath);

  return matches.map((piece) => {
    const listItem: ListItemDTO = {
      id: randomHex(),
      value: piece.value,
      label: piece.label ? {[locale]: piece.label} : {},
      children: buildTree(pieces, [...parents, piece.value], locale),
      lookup: piece.lookup,
    };

    return listItem;
  });
}

export function mergeTranslations(
  existingItems: ListItemDTO[],
  newItems: ListItemDTO[],
  unsetExistingLocales: string[] = [],
) {
  return listItemsMap(newItems, (newItem, parentAnswers) => {
    const existingItem = findListItemByValueAndParentAnswers(newItem.value, existingItems, parentAnswers);
    if (!existingItem) {
      return newItem;
    }

    return {
      ...newItem,
      label: {
        ...omit(existingItem.label, unsetExistingLocales),
        ...newItem.label,
      },
    };
  });
}

export function listItemsMap<
  T extends TranslatedListItem | ListItemDTO,
  R extends TranslatedListItem | ListItemDTO = T,
>(listItems: T[], fn: (listItem: T, parentAnswers: string[]) => Omit<R, "children">): R[] {
  return _listItemsMap(listItems, fn, []);
}

function _listItemsMap<T extends TranslatedListItem | ListItemDTO, R extends TranslatedListItem | ListItemDTO = T>(
  listItems: T[],
  fn: (listItem: T, parentAnswers: string[]) => Omit<R, "children">,
  parentAnswers: string[],
): R[] {
  return listItems.map((listItem) => {
    return {
      ...fn({...listItem}, parentAnswers),
      children: _listItemsMap([...listItem.children] as unknown as T[], fn, [...parentAnswers, listItem.value]),
    } as unknown as R;
  });
}

export function listItemsFlatMapTo<LI extends ListItemDTO | TranslatedListItem, R>(
  listItems: LI[],
  fn: (listItem: LI) => R,
): R[] {
  return listItems.flatMap((listItem) => [
    fn({...listItem}),
    ...listItemsFlatMapTo([...listItem.children] as unknown as LI[], fn),
  ]);
}

export function listItemsFind<LI extends ListItemDTO | TranslatedListItem>(
  listItems: LI[],
  fn: (listItem: LI) => boolean,
): LI | undefined {
  const foundItem = listItems.find((listItem) => fn(listItem));
  if (foundItem) {
    return foundItem;
  }

  for (const listItem of listItems) {
    const foundChild = listItemsFind(listItem.children as unknown as LI[], fn);
    if (foundChild) {
      return foundChild;
    }
  }

  return;
}

export function findListItemByValueAndParentKeys<LI extends ListItemDTO | TranslatedListItem>(
  value: string,
  listItems: LI[],
  answers: Dictionary<string> = {},
  parentKeys: string[] | null = null, // null means search all items recursively
): LI | undefined {
  if (!parentKeys) {
    return findListItemByValueAndParentAnswers(value, listItems);
  }

  const parentAnswers: string[] = parentKeys.map((key) => answers[key] || "");

  return findListItemByValueAndParentAnswers(value, listItems, parentAnswers);
}

export function findListItemByValueAndParentAnswers<LI extends ListItemDTO | TranslatedListItem>(
  value: string,
  listItems: LI[],
  parentAnswers: string[] | null = null, // null means search all items recursively
): LI | undefined {
  if (parentAnswers) {
    const listItemsSlice = getOptionsFromListByParentAnswers(listItems, parentAnswers);
    return listItemsSlice.find((item) => item.value === value);
  }

  // there may be multiple items in this list with this value, but without
  // `parentAnswers` context, the best we can do is return the first we find
  return listItemsFind(listItems, (item) => item.value === value);
}

export function listItemsReduce<T>(
  listItems: ListItemDTO[],
  fn: (accumulator: T, listItem: ListItemDTO) => T,
  initial: T,
): T {
  return listItems.reduce((accumulator, listItem) => {
    accumulator = fn(accumulator, listItem);
    accumulator = listItemsReduce(listItem.children, fn, accumulator);
    return accumulator;
  }, initial);
}

export function listItemsIterateLeafFirst<LI extends ListItemDTO | TranslatedListItem>(
  listItems: LI[],
  fn: (listItem: LI, parents: string[]) => void,
  parentIds: string[] = [],
) {
  listItems.forEach((listItem) => {
    try {
      listItemsIterateLeafFirst([...(listItem.children as LI[])], fn, [...parentIds, listItem.id]);
      fn({...listItem}, parentIds);
    } catch (err) {
      // tslint:disable-next-line:no-console
      console.log(listItems, listItem);
      // tslint:disable-next-line:no-console
      console.error(err);
    }
  });
}

export function listItemsIterateRootFirst<LI extends ListItemDTO | TranslatedListItem>(
  listItems: LI[],
  fn: (listItem: LI, parents: string[]) => void,
  parentIds: string[] = [],
) {
  listItems.forEach((listItem) => {
    fn({...listItem}, parentIds);
    listItemsIterateRootFirst([...(listItem.children as LI[])], fn, [...parentIds, listItem.id]);
  });
}

export function listItemsSome(listItems: ListItemDTO[], fn: (listItem: ListItemDTO) => boolean): boolean {
  return listItems.some((listItem) => fn(listItem) || listItemsSome(listItem.children, fn));
}

export function makeBlankListItem(copyLookupKeysFrom: ListLookup = {}, locale: string = Locale.EN_US): ListItemDTO {
  return {
    id: randomHex(),
    label: {[locale]: ""},
    value: "",
    lookup: mapValues(copyLookupKeysFrom, () => ""),
    children: [],
  };
}

export function makeBlankList(owner: BasicEntityInfo, locale: string = Locale.EN_US): ListDTO {
  return {
    _id: "",
    key: "",
    owner,
    items: [makeBlankListItem({}, locale)],
    public: false,
    hideFromAdmin: false,
    needsTranslation: false,
    columns: [],
  };
}

export function getListDepth(list: ListDTO): number {
  let depth = 1;
  listItemsIterateLeafFirst(list.items, (_item, parents) => {
    depth = Math.max(parents.length + 1, depth);
  });

  return depth;
}

export function getDuplicateValuesAtLevel(listItems: ListItemDTO[]): string[] {
  return Object.values(groupBy(listItems, (item) => item.value))
    .filter((grp) => grp.length > 1)
    .map((grp) => grp[0].value);
}

// trim white space, make sure everything is a string that should be...
export function normalizeList<T extends Partial<Pick<ListDTO, "key" | "items" | "columns">>>(list: T): T {
  const result: Partial<Pick<ListDTO, "key" | "items" | "columns">> = {};

  if (list?.items?.length) {
    result.items = listItemsMap(list.items, (item) => ({
      ...item,
      label: mapValues(item.label, (l) => ((l ?? "") + "").trim()),
      value: ((item.value ?? "") + "").trim(),
      lookup: fromPairs(
        Object.entries(item.lookup || {}).map(([key, val]) => [((key ?? "") + "").trim(), ((val ?? "") + "").trim()]),
      ),
    }));
  }

  if (list?.key) {
    result.key = list.key.trim();
  }

  if (list?.columns) {
    result.columns = list.columns.map((c) => c.trim());
  }

  return {
    ...list,
    ...result,
  };
}

export function diffList(original: ListItemDTO[], updated: ListItemDTO[], options?: DiffOptions) {
  // const originalClone = orderBy(
  //   listItemsMap(cloneDeep(original), (item) => {
  //     delete (item as any).id;
  //     delete (item as any).lookup;
  //     item.children = orderBy(item.children, (x) => x.value);
  //     return item;
  //   }),
  //   (x) => x.value,
  // );
  //
  // const updatedClone = orderBy(
  //   listItemsMap(cloneDeep(updated), (item) => {
  //     delete (item as any).id;
  //     delete (item as any).lookup;
  //     item.children = orderBy(item.children, (x) => x.value);
  //     return item;
  //   }),
  //   (x) => x.value,
  // );

  const originalClone = listItemsMap(cloneDeep(original), (item) => {
    delete (item as any).id;
    delete (item as any).lookup;
    return item;
  });

  const updatedClone = listItemsMap(cloneDeep(updated), (item) => {
    delete (item as any).id;
    delete (item as any).lookup;
    return item;
  });

  return anyDiff(originalClone, updatedClone, options);
}
