import {openSupplierSurvey} from "@/composables/supplierSurvey";
import cloneDeep from "lodash/cloneDeep";
import {autoMutations} from "../utils";
import socketPlugin from "../plugins/socket";
import {RoutingKey} from "pg-isomorphic/queue";
import globalLogger from "../../logging";

const logger = globalLogger.getLogger("info-modal");

const initialState = {
  showingDialog: false,
  variant: "primary",
  headerText: "",
  headerIcon: null,
  bodyText: "",
  footerText: "",
  nextText: "",
  nextUrl: "/dash",
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: autoMutations(initialState),
  actions: {
    displayDialog({commit}) {
      commit("showingDialog", true);
    },

    hideDialog({commit}) {
      commit("showingDialog", false);
    },

    joinInfoRoom({commit, dispatch, rootState}) {
      this.infoSocket = Object.create(this);
      socketPlugin(this.infoSocket);

      this._openDialog = (wsResponse) => {
        logger.trace(() => `received infoModal event: ${JSON.stringify(wsResponse)}`);
        const data = wsResponse.content;

        // all other data is ignored if this flag is true
        if (data.showSupplierSurvey) {
          openSupplierSurvey({
            ...data,
            connectionId: wsResponse.connectionId,
          });
          return;
        }

        commit("variant", data.variant || initialState.variant);
        commit("headerText", data.headerText);
        commit("headerIcon", data.headerIcon);
        commit("bodyText", data.bodyText);
        commit("nextText", data.nextText);
        commit("nextUrl", data.nextUrl);
        dispatch("displayDialog");
      };

      logger.trace(() => `joining room with user: ${rootState.user._id}, entity: ${rootState.user.activeEntityId}`);
      this.infoSocket.join(RoutingKey.info({userId: rootState.user._id, entityId: rootState.user.activeEntityId}));
      this.infoSocket.socketOn(
        RoutingKey.info({userId: rootState.user._id, entityId: rootState.user.activeEntityId}),
        this._openDialog,
      );
    },
    leaveInfoRoom({rootState}) {
      logger.trace(() => `leaving room with user: ${rootState.user._id}, entity: ${rootState.user.activeEntityId}`);
      this.infoSocket.leave(RoutingKey.info({userId: rootState.user._id, entityId: rootState.user.activeEntityId}));
      this.infoSocket.socketOff(
        RoutingKey.info({userId: rootState.user._id, entityId: rootState.user.activeEntityId}),
        this._openDialog,
      );
      this.infoSocket.socketDisconnect();
      delete this.infoSocket;
    },
  },
};
