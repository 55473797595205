import {computed, ref} from "vue";
import debounce from "lodash/debounce";

const screenSize = ref<{width: number; height: number}>({width: 1920, height: 1080});

export const onResize = debounce(_onResize, 300);
function _onResize() {
  screenSize.value = {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

export const screenWidth = computed<number>(() => {
  return screenSize.value.width;
});

export const screenHeight = computed<number>(() => {
  return screenSize.value.height;
});

export const isMobile = computed<boolean>(() => {
  return screenWidth.value <= 768;
});

_onResize();
