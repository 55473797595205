import cloneDeep from "lodash/cloneDeep";
import {autoMutations} from "../../utils";
import {SecurityRole, RoleType, Entity as Ent, EntityAccessStatus} from "pg-isomorphic/enums";
import {indexBy, isEmpty, prop, propEq, find} from "ramda";
import globalLogger from "../../../logging";
import {UserSearchIndex} from "pg-isomorphic/users/search";
import {isGraphiteAdminRole} from "pg-isomorphic/permissions";

const logger = globalLogger.getLogger("admin.users");

const state = {
  users: [],
  total: 0,
  roles: [],
  loadingRoles: false,
  query: {
    sort: {status: -1, name: 1},
    limit: 20,
    page: 1,
    includeAdminCount: true,
    associationStatuses: null,
  },
  tags: [],
  topics: [],
  topicRoles: [],
  loading: false,
  error: null,
  inviteEmail: "",
  inviteName: "",
  numberOfAdmins: 0,
  allUsers: [],
  searchIndex: null,
  search: "",
};

function setRowVariants(users) {
  return users.map((user) => ({
    ...user,
    _rowVariant: user.status === EntityAccessStatus.REQUESTED ? "ignore requested" : "",
  }));
}

function searchUsers(searchIndex, searchText) {
  if (!searchIndex) {
    return;
  }
  let userIds;
  if (!searchText) {
    userIds = undefined;
  } else {
    const results = searchIndex.search(searchText);
    userIds = results.map((r) => r.ref);
  }
  return userIds;
}

const actions = {
  async getRoles({commit, state, dispatch}, {force} = {}) {
    try {
      const user = await dispatch("getCurrentUser", false, {root: true});
      if (user && !state.loadingRoles && (!state.roles.length || force)) {
        commit("loadingRoles", true);
        logger.debug(() => `getting roles from api: ${state.roles.length}`);
        const response = await this.httpGet("/api/users/roles");
        commit("roles", response.data.roles);
      } else {
        logger.debug(`Returning existing roles. Pass 'force' to require re-query.`);
        logger.trace(`existing roles`, state.roles);
      }
      return state.roles;
    } catch (e) {
      commit("error", e.response.data);
    } finally {
      commit("loadingRoles", false);
    }
  },

  async getUsers({commit, state}, query) {
    commit("loading", true);
    const params = {...state.query};
    if (query) {
      params.userPageSearchText = query;
      commit("search", query);
    } else if (state.search) {
      // for next page
      params.userPageSearchText = state.search;
    }
    try {
      const response = await this.httpPost("/api/users/query", params);
      commit("users", setRowVariants(response.data.users));
      commit("total", response.data.total);
      commit("numberOfAdmins", response.data.numberOfAdmins);
    } catch (e) {
      commit("error", e.response.data);
    }
  },

  async getAllUsers({commit}, returnNameOnly) {
    const response = await this.httpPost("/api/users/query", {
      getAllUsers: true,
      returnNameOnly,
    });
    commit("allUsers", setRowVariants(response.data.users));
  },

  async loadUser({commit}, userId) {
    try {
      const response = await this.httpPost(`/api/users/query`, {userId, includeAdminCount: true});
      commit("numberOfAdmins", response.data.numberOfAdmins);
      return response.data.users.length > 0 ? response.data.users[0] : null;
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error removing user", e);
      }
    }
  },

  async removeUser({commit}, user) {
    try {
      await this.httpDelete(`/api/admin/users/${user._id}`);
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error removing user", e);
      }
    }
  },

  async updateApproval({commit}, {user, status}) {
    try {
      await this.httpPut(`/api/admin/users/${user._id}/approval`, {status});
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error updating approval for user", e);
      }
    }
  },

  async updateUser({commit}, {user}) {
    try {
      await this.httpPut(`/api/admin/users/${user._id}`, user);
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error updating approval for user", e);
      }
    }
  },

  async getTopicTree({commit}, entityId) {
    try {
      const response = await this.httpGet(`/api/questions/topics/${entityId}`);
      commit("topics", response.data.questions);
      commit("topicRoles", response.data.topicRoles);
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error getting topics", e);
      }
    }
  },

  async getTags({commit}) {
    try {
      const response = await this.httpGet(`/api/entities/companytags`);
      const tags = response.data[Ent.AttributeOptions] || [];
      commit("tags", tags);
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error getting topics", e);
      }
    }
  },

  async inviteUser({commit}, updateUser) {
    try {
      const response = await this.httpPost(`/api/admin/users/invite`, updateUser);
      return response.data;
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error updating approval for user", e);
      }
    }
  },

  async updateUserSearchIndex({commit}, {locale, statusLabels}) {
    const response = await this.httpPost("/api/users/query", {
      getAllUsers: true,
    });
    commit("allUsers", setRowVariants(response.data.users));
    const users = response.data.users.map((user) => {
      const statusLabel = find(propEq("status", user.status), statusLabels);
      return {
        ...user,
        translatedStatus: statusLabel ? statusLabel.label : "",
      };
    });
    const index = await new UserSearchIndex({users: users, locale}).buildIndex();
    commit("searchIndex", index);
  },

  async searchUsers({commit, state}, query) {
    if (!isEmpty(query)) {
      const userIds = searchUsers(state.searchIndex, query);
      const users = userIds ? state.allUsers.filter((u) => userIds.indexOf(u._id) > -1) : [];
      commit("users", users);
    } else if (isEmpty(query) && !isEmpty(state.search)) {
      commit("users", cloneDeep(state.originalUsers));
    }
    commit("search", query);
  },

  async clearSearch({commit}) {
    commit("search", "");
  },
  async getHistory({state, commit}, params) {
    commit("loading", true);
    try {
      const response = await this.httpGet(`/api/admin/users/history`, params);
      commit("loading", false);
      return response.data;
    } catch (e) {
      if (e.response) {
        commit("error", e.response.data);
      } else {
        logger.error("Error getting user history", e);
      }
    }
  },
};

const getters = {
  topicRoles(state) {
    if (isEmpty(state.roles)) {
      return [];
    }
    const roles = state.roles.filter((role) => role.type === RoleType.TOPIC_OWNER);
    return indexBy(prop("_id"), roles);
  },
  userRoles(state) {
    if (isEmpty(state.roles)) {
      return [];
    }
    return indexBy(
      prop("_id"),
      state.roles.filter(
        (role) =>
          role.type === RoleType.SECURITY_ROLE &&
          role.key !== SecurityRole.ADMIN &&
          !isGraphiteAdminRole(role.key) &&
          !role.hidden,
      ),
    );
  },
  adminRole(state) {
    if (isEmpty(state.roles)) {
      return [];
    }
    return state.roles.filter((role) => role.key === SecurityRole.ADMIN)[0];
  },
  adminRoles(state) {
    if (isEmpty(state.roles)) {
      return [];
    }
    return indexBy(
      prop("_id"),
      state.roles.filter(
        (role) =>
          role.key === SecurityRole.ADMIN ||
          role.key === SecurityRole.IT_ADMIN ||
          role.key === SecurityRole.SYSTEM_ADMIN,
      ),
    );
  },
};

export default {
  namespaced: true,
  state: cloneDeep(state),
  actions,
  getters,
  mutations: autoMutations(state),
};
