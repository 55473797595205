import type {KitType} from "pg-isomorphic/enums/element";
import type {PGActionTree, PGGetterTree} from "./../../index-types";
import httpPlugin from "../../plugins/http";
import {autoMutations} from "../../utils";
import {clone, pathOr, filter} from "ramda";
import {TaskState} from "pg-isomorphic/enums/task";
import {ProfileEvent} from "pg-isomorphic/enums/events";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {isEmptyOrUndefined} from "pg-isomorphic/utils";
import type {Task} from "pg-isomorphic/api/tasks";

const plugins = [httpPlugin];

export interface QuestionTasksState {
  connectionId: string;
  elementId: string;
  element: JSONQuestion;
  topic: JSONQuestion;
  instanceId: string;
  open: boolean;
  error: boolean;
  loading: boolean;
  tasks: Task[];
  sideTrayOpen: boolean;
  selectedTask: string;
  editing: boolean;
  pertainingToEntity: string;
  newComment: boolean;
  canAdd: boolean;
  isTableHeader: boolean;
  kitType: KitType;
  kitId: string;
}

const initialState: QuestionTasksState = {
  connectionId: null,
  elementId: null,
  element: null,
  topic: null,
  instanceId: null,
  open: false,
  error: false,
  loading: false,
  tasks: [],
  sideTrayOpen: false,
  selectedTask: null,
  editing: false,
  pertainingToEntity: null,
  newComment: false,
  canAdd: false,
  isTableHeader: false,
  kitType: null,
  kitId: null,
};

const getters: PGGetterTree<QuestionTasksState> = {
  openTasks(state): Task[] {
    return filter((t: Task) => t.state !== TaskState.COMPLETE, state.tasks);
  },
  completedTasks(state): Task[] {
    return filter((t) => t.state === TaskState.COMPLETE, state.tasks);
  },
};

const actions: PGActionTree<QuestionTasksState> = {
  initTaskList(
    {state, commit, dispatch},
    {
      connectionId,
      elementId,
      element,
      topic,
      instanceId,
      pertainingToEntity,
      taskId,
      withNewComment,
      canAdd,
      isTableHeader,
      kitId,
      kitType,
    }: {
      connectionId: string;
      elementId: string;
      element: JSONQuestion;
      topic: JSONQuestion;
      instanceId?: string;
      pertainingToEntity: string;
      taskId: string;
      withNewComment?: boolean;
      canAdd?: boolean;
      isTableHeader?: boolean;
      kitType?: KitType;
      kitId?: string;
    },
  ) {
    commit("connectionId", connectionId);
    commit("elementId", elementId);
    commit("instanceId", instanceId);
    commit("element", element);
    commit("topic", topic);
    commit("pertainingToEntity", pertainingToEntity);
    commit("open", true);
    commit("selectedTask", taskId);
    commit("newComment", withNewComment);
    commit("isTableHeader", isTableHeader);
    commit("kitType", kitType);
    commit("kitId", kitId);
    if (!isEmptyOrUndefined(canAdd) && state.canAdd !== canAdd) {
      commit("canAdd", canAdd);
    }
    dispatch("getQuestionTasks", {});
  },
  async getQuestionTasks({state, commit, dispatch}, {}) {
    commit("loading", true);
    const connectionId = state.connectionId;
    try {
      const response = await this.httpPost("/api/tasks/query", {
        connectionId: connectionId,
        elementId: state.elementId || state.kitId, // just so we limit it for now
        instanceId: state.instanceId,
        pertainingToEntityId: state.pertainingToEntity,
        includeAssignedUsers: true,
        includeApprovals: true,
        includeAuditInfo: false,
        includeUpdatedByName: true,
        includeNotes: true,
        getUserGenerated: true,
        includePending: true,
        includeEntity: true,
        includeOwningEntity: true,
        includeMessageCounts: true,
        isTableHeader: state.isTableHeader,
      });
      commit("tasks", pathOr({}, ["data", "tasks"], response));

      if (state.selectedTask) {
        const toSelect = state.tasks.find((t) => t._id === state.selectedTask);
        if (toSelect) {
          if (state.newComment) {
            dispatch("taskDetails/initDetailsNewComment", {task: toSelect, skipVisible: true}, {root: true});
          } else {
            dispatch("taskDetails/initDetails", {task: toSelect, skipVisible: true}, {root: true});
          }
          this.emitter.$emit(ProfileEvent.CST_CHILD_TOGGLE, {id: "question_task_list"});
          commit("selectedTask", null);
        }
      } else if (state.kitId) {
        dispatch("taskDetails/initDetails", {}, {root: true});
      }
    } catch (e) {
      commit("error", true);
    } finally {
      commit("loading", false);
    }
  },
};

export default {
  namespaced: true,
  state: clone(initialState),
  getters,
  actions,
  mutations: {
    ...autoMutations(initialState),
  },
  plugins,
};
