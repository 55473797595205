import lunr from "lunr";
import {Dictionary, pathOr} from "ramda";
import {SecurityRole} from "../enums";
import {JSONObject} from "../utils";

export interface UserIndexDocument {
  name: string;
  email: string;
  roles: string[];
  translatedStatus: string;
  activeConnectionRole: string;
  id: string;
}

export interface JSONUser extends JSONObject {
  name: string;
  email: string;
  roles: JSONObject[];
  status: string;
  translatedStatus: string;
  activeConnectionRole: string;
  _id: string;
}

export interface UserSearchOptions {
  users: JSONUser[];
  locale: string;
}

export class UserSearchIndex {
  private index?: lunr.Index;
  private documents: Dictionary<UserIndexDocument> = {};

  constructor(private opts: UserSearchOptions) {}

  public search(query: string): lunr.Index.Result[] {
    if (!this.index) {
      throw new Error("No index. Call buildIndex first.");
    }

    // return this.index.search(`*${query}*`);
    return this.index.query((lunrQuery) => {
      query.split(lunr.tokenizer.separator).forEach((term) => {
        const lower = term.toLowerCase().replace(/[^\w.'@]+/g, "");
        lunrQuery.term(lower, {
          presence: lunr.Query.presence.REQUIRED,
          // tslint:disable-next-line:no-bitwise
          wildcard: lunr.Query.wildcard.LEADING | lunr.Query.wildcard.TRAILING,
        });
      });
    });
  }

  public async buildIndex(): Promise<UserSearchIndex> {
    const docs = await this.buildIndexDocuments();
    this.documents = {};
    const $this = this;
    this.index = lunr(function () {
      this.field("name");
      this.field("email");
      this.field("roles");
      this.field("translatedStatus");
      this.field("activeConnectionRole");
      this.ref("id");

      this.pipeline.remove(lunr.stopWordFilter);
      this.searchPipeline.remove(lunr.stopWordFilter);
      this.pipeline.remove(lunr.stemmer);
      this.searchPipeline.remove(lunr.stemmer);

      docs.forEach((doc) => {
        this.add(doc);
        $this.documents![doc.id] = doc;
      });
    });
    return this;
  }

  public getDocumentsFromResults(results: lunr.Index.Result[]): UserIndexDocument[] {
    return results.map((r) => this.documents[r.ref]);
  }

  private async buildIndexDocuments(): Promise<UserIndexDocument[]> {
    const {users, locale} = this.opts;
    const docs: UserIndexDocument[] = [];
    for (const user of users) {
      const roleLabels = user.roles
        .filter((r) => !r.hidden && !r.deleted)
        .map((role) =>
          role.key === SecurityRole.ADMIN
            ? "Admin"
            : pathOr("", ["name", locale], role) || pathOr("", ["name", "en-US"], role),
        );
      const doc: UserIndexDocument = {
        name: user.name,
        email: user.email,
        translatedStatus: user.translatedStatus,
        activeConnectionRole: user.activeConnectionRole,
        roles: roleLabels,
        id: user._id,
      };
      docs.push(doc);
    }
    return docs;
  }
}
