import {getCurrentProfile} from "@/composables/connections/get-current.profile";
import type {Task} from "pg-isomorphic/api/tasks";
import {Profile} from "pg-isomorphic/enums";
import type {APIError} from "pg-isomorphic/errors";
import type {UserPermissions} from "pg-isomorphic/permissions";
import {check, Permission} from "pg-isomorphic/permissions";
import {isMultipleGroup} from "pg-isomorphic/profile";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {any, pathOr} from "ramda";
import globalLogger from "../../logging";
import type {PGActionContext, PGActionTree} from "../index-types";
import {actionRequestWrap} from "../index-types";
import {autoMutations} from "../utils";
import {findQuestion} from "@/composables/questions/questionTree";
import type {AxiosResponse} from "axios";

const logger = globalLogger.getLogger("workflow");

export interface WorkflowState {
  opening: boolean;
  open: boolean;
  loading: boolean;
  error: APIError | null;
  workflowElementId: string | null;
  workflowAdditionalElementIds: string | null;
  workflowInstanceId: string | null;
  workflowTaskId: string | null;
  workflowTask: Task | null;
  pertainingToEntityId: string | null;
  workflowEmailLogId: string | null;
  isGlobalCommunicationEmail: boolean;
}

const initialState: WorkflowState = {
  opening: false,
  open: false,
  loading: false,
  error: null,
  workflowElementId: null,
  workflowAdditionalElementIds: null,
  workflowInstanceId: null,
  workflowTaskId: null,
  workflowTask: null,
  pertainingToEntityId: null,
  workflowEmailLogId: null,
  isGlobalCommunicationEmail: false,
};

type Ctx = PGActionContext<WorkflowState>;

interface InitializeArgs {
  workflowElementId: string;
  workflowTaskId: string;
  tasks: Task[];
  isGlobalCommunicationEmail?: boolean;
}

const req = actionRequestWrap(logger, "error loading workflow API");

const actions: PGActionTree<WorkflowState> = {
  async init({commit}: Ctx, args: InitializeArgs) {
    let tasks: Task[] = args.tasks;
    if (args.isGlobalCommunicationEmail) {
      const response: AxiosResponse<{tasks: Task[]}> = await this.httpPost("/api/tasks/query", {
        taskIds: [args.workflowTaskId],
        includeAssignedUsers: true,
      });
      tasks = response.data.tasks;
      commit("isGlobalCommunicationEmail", true);
    }
    const task = tasks.find((t) => t._id === args.workflowTaskId);
    if (!task) {
      return;
    }
    logger.trace(
      () => `Init Workflow -> workflowTaskId: ${args.workflowTaskId} - workflowElementId: ${args.workflowElementId}`,
      task,
    );
    commit("workflowTask", task);
    commit("workflowElementId", args.workflowElementId);
    commit("workflowInstanceId", pathOr(null, ["data", "instanceId"], task));
    commit("workflowTaskId", args.workflowTaskId);
    commit("workflowEmailLogId", pathOr(null, ["data", "emailLogId"], task));
    commit("opening", true);
    commit("open", true);
  },
  async completeConnectionTask({commit, dispatch, state}: Ctx) {
    return req(
      async () => {
        await this.httpPost("/api/tasks/complete", {
          taskId: state.workflowTaskId,
        });
        await dispatch("reset");
      },
      commit,
      "error workflow completion",
    );
  },
  async reset({commit}: Ctx) {
    logger.trace(() => "Reset Workflow");
    commit("opening", false);
    commit("open", false);
    commit("workflowTaskId", null);
    commit("workflowElementId", null);
    commit("workflowEmailLogId", null);
    commit("workflowTask", null);
    commit("isGlobalCommunicationEmail", false);
  },
  readyToComplete({state, rootState}: Ctx, questions: JSONQuestion): boolean {
    // Must be an admin or an assignee to complete
    const user = rootState.user!;
    if (
      !check(Permission.NO_REALLY_COMPLETE_ANY_WORKFLOW_TASK, user as unknown as UserPermissions) &&
      (state.workflowTask?.data?.onlyAssigneeCanComplete ||
        !check(Permission.COMPLETE_ANY_WORKFLOW_TASK, user as unknown as UserPermissions)) &&
      !any((u: {_id: string}) => u._id === user._id, state.workflowTask!.effectiveAssignedUsers)
    ) {
      return false;
    }

    if (!state.workflowTask?.data?.requireAnswers) {
      return true;
    }
    const currentProfile = getCurrentProfile();
    for (const taskElementId of [
      ...(state.workflowTask.element ? [state.workflowTask.element._id] : []),
      ...(state.workflowTask.additionalElements ?? []),
    ]) {
      let element = findQuestion(taskElementId, questions);
      if (element && isMultipleGroup(element) && state.workflowInstanceId && element.children) {
        element = (element.children as JSONQuestion[]).find((child) => child.instance === state.workflowInstanceId);
      }
      if (
        element &&
        ((currentProfile === Profile.MINE && element.unansweredRequiredCount > 0) ||
          (currentProfile === Profile.THEIRS && element.unansweredRequiredInternalUseCount > 0))
      ) {
        return false;
      }
    }
    return true;
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: {
    ...autoMutations(initialState),
  },
  actions,
};
