<template>
  <div class="rating-selector">
    <div class="main-label mb-3" v-if="label">{{ label }}</div>

    <div class="rating-labels">
      <div class="rating-label low">{{ ratingLabels[0] }}</div>
      <div class="rating-label middle">{{ ratingLabels[1] }}</div>
      <div class="rating-label high">{{ ratingLabels[2] }}</div>
    </div>
    <div
      class="rating-options"
      tabindex="0"
      @keydown="keySetRating($event)"
      @keydown.right="focusOption($event, ratingOptionElemRefs[0])"
      @keydown.left="focusOption($event, ratingOptionElemRefs[ratingOptionElemRefs.length - 1])"
    >
      <div
        tabindex="-1"
        ref="ratingOptionElemRefs"
        @keyup.enter="setRating(i)"
        @keyup.space="setRating(i)"
        @keydown.right="focusOption($event, ratingOptionElemRefs[i])"
        @keydown.left="focusOption($event, ratingOptionElemRefs[i - 2])"
        v-for="i in maxRating"
        :key="i"
        @click="setRating(i)"
        class="rating-option"
        :class="{active: selectedValue === i}"
      >
        {{ i }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {translate} from "@/composables/i18n";
import type {PropType} from "vue";
import {ref} from "vue";

const selectedValue = defineModel("modelValue");

defineProps({
  label: {
    type: String,
  },
  ratingLabels: {
    type: Array as PropType<string[]>, // [string, string, string]
    default: () => [
      translate("ratings_selector.low"),
      translate("ratings_selector.middle"),
      translate("ratings_selector.high"),
    ], // TODO translate default labels
  },
  maxRating: {
    type: Number,
    default: 10,
  },
});

const ratingOptionElemRefs = ref<HTMLElement[]>([]);

function setRating(rating: number) {
  selectedValue.value = rating;
}

function focusOption(event: KeyboardEvent, focusTo: HTMLElement) {
  event.preventDefault();
  event.stopPropagation();

  setTimeout(() => {
    focusTo?.focus();
  });
}

function keySetRating(event: KeyboardEvent) {
  const key = parseInt(event.key, 10);
  // could be improved to actually support `10`, etc
  if (!isNaN(key) && key >= 0 && key <= 9) {
    selectedValue.value = key === 0 ? 10 : key;
  }
}
</script>

<style lang="less" scoped>
@import "@/less/global.less";

.rating-selector {
  margin-bottom: 1rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.rating-options {
  display: flex;
  border-left: 1px solid @grey200;
  border-top: 1px solid @grey200;
  font-size: 0.875rem;
  font-weight: 700;
}

.rating-option {
  flex-grow: 1;
  text-align: center;
  border-right: 1px solid @grey200;
  border-bottom: 1px solid @grey200;
  cursor: pointer;
  padding: 0.625rem;

  &.active {
    background-color: @primary;
    color: white;
  }
}

.rating-labels {
  position: relative;
  font-size: 0.875rem;
  line-height: 1.125rem;
  margin-bottom: 1px;

  .rating-label {
    position: absolute;
    top: 0;

    &.low {
      left: 0;
    }

    &.middle {
      position: static;
      text-align: center;
    }

    &.high {
      right: 0;
    }
  }
}
</style>
