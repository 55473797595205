<template>
  <div class="checkbox-group" :class="{stacked}">
    <slot>
      <template v-for="opt in parsedOptions" :key="opt.value">
        <GraphiteCheckbox :disabled="disabled" :name="uniqName" :value="opt.value">
          {{ opt.label }}
        </GraphiteCheckbox>
      </template>
    </slot>
  </div>
</template>

<script lang="ts" setup>
import {CheckboxGroupTriggerChangeKey, CheckboxGroupValueKey} from "@/composables/checkbox/injections";
import type {Dictionary} from "lodash";
import {randomHex} from "pg-isomorphic/utils";
import {computed, defineEmits, provide, ref, watch} from "vue";

const uniqName = randomHex();

const props = defineProps<{
  modelValue?: any[];
  options?: string[] | Dictionary<string> | CheckboxOption[];
  disabled?: boolean;
  stacked?: boolean;
}>();

export interface CheckboxOption {
  label: string;
  value: string;
}

const parsedOptions = computed<CheckboxOption[]>(() => {
  if (!props.options) {
    return [];
  }

  if (Array.isArray(props.options)) {
    if (typeof props.options[0] !== "string" && "value" in props.options[0] && "label" in props.options[0]) {
      return props.options as CheckboxOption[];
    }

    return props.options.map((val) => ({label: val, value: val}));
  }

  return Object.entries(props.options).map(([value, label]) => ({label, value}));
});

const emit = defineEmits<{
  /** @deprecated use `update:modelValue` */
  (event: "input", val?: string | boolean | any): void;
  (event: "update:modelValue", val?: string | boolean | any): void;
  (event: "change", val?: string | boolean | any): void;
}>();

function triggerChangeByClick() {
  emit("change", internalValue.value);
}

const _internalValue = ref(props.modelValue);
const internalValue = computed({
  set(val: any[]) {
    emit("update:modelValue", val);
    _internalValue.value = val;
  },
  get() {
    return _internalValue.value;
  },
});

watch(
  () => props.modelValue,
  (val) => {
    _internalValue.value = val;
    // legacy
    emit("input", internalValue.value);
  },
);

provide(CheckboxGroupValueKey, internalValue);
provide(CheckboxGroupTriggerChangeKey, triggerChangeByClick);

defineOptions({
  compatConfig: {
    MODE: 3,
  },
});
</script>

<style lang="less" scoped>
.checkbox-group {
  display: flex;
  gap: 0.3rem 1.3rem;
  flex-wrap: wrap;

  &.stacked {
    flex-direction: column;
    gap: 0.3rem;
  }
}
</style>
