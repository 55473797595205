<template>
  <ProgressBar
    :class="`graphite-progress-bar mr-2 ${variant}`"
    :value="effectiveValue"
    :showValue="false"
    :aria-label="ariaLabel || $t('prime.aria.progressbar')"
    :pt="{
      value: {
        class: `graphite-value-bar ${variant}`,
      },
    }"
  >
    <slot />
  </ProgressBar>
</template>

<script lang="ts" setup>
import {ProgressBarVariants} from "@/composables/ui-types";
import ProgressBar from "primevue/progressbar";
import type {PropType} from "vue";
import {computed, defineProps, toRefs} from "vue";

const props = defineProps({
  // this is NOT a modelValue!
  value: {
    type: Number,
  },
  max: {
    type: Number,
  },
  variant: {
    type: String as PropType<ProgressBarVariants>,
    default: ProgressBarVariants.PRIMARY,
  },
  ariaLabel: {
    type: String,
  },
});

const {max, value} = toRefs(props);

const effectiveValue = computed(() => {
  if (max.value) {
    return Math.floor(value.value * (100 / max.value));
  } else {
    return value.value;
  }
});
</script>

<style lang="less">
@import "@{globals}";

.graphite-progress-bar {
  height: 10px;
  display: inline-block;
  width: 100%; // let parent constrain the width

  &.info,
  &.primary {
    background-color: @blue100;

    .p-progressbar-value {
      background-color: @blue400;
    }
  }

  &.success {
    background-color: @green100;

    .p-progressbar-value {
      background-color: @green400;
    }
  }

  &.warning {
    background-color: @yellow100;

    .p-progressbar-value {
      background-color: @yellow400;
    }
  }

  &.danger {
    background-color: @red100;

    .p-progressbar-value {
      background-color: @red400;
    }
  }
}
</style>
