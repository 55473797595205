import {getStore} from "@/composables/get.store";
import {getCurrentUser} from "@/composables/vuex";
import formatter from "format-number";
import {coerceToArray} from "pg-isomorphic/utils";
import {uniq} from "ramda";
import type {ComputedRef, WritableComputedRef} from "vue";
import {computed} from "vue";
import get from "lodash/get";
import random from "lodash/random";
import type {WorkBook} from "xlsx";
import * as XLSX from "xlsx";
import {SHEET_NAME_CHARACTER_LIMIT} from "pg-isomorphic/enums/export";
import Clipboard from "clipboard";

let uniqueId = 0;

export type ComputedProxy<T> = Omit<{[P in keyof T]: ComputedRef<T[P]>}, "computed">;

// This only works if the underlying properties of the object are eventually reactive
// e.g. a Ref<> or a getter to some Ref<>
export function makeComputedProxy<T extends object>(obj: T): ComputedProxy<T> {
  return new Proxy(obj, {
    get(obj, prop) {
      const computedKey = `__c_${String(prop)}`;
      if (!obj[computedKey]) {
        obj[computedKey] = computed(() => obj[prop]);
      }
      return obj[computedKey];
    },
  }) as ComputedProxy<T>;
}

export function wrapStoreWritableCompute<T>(storePath: string): WritableComputedRef<T> {
  const store = getStore();
  const pathWithDots = storePath.replace(/\//g, ".");
  const pathWithSlashes = storePath.replace(/\./g, "/");
  return computed({
    get() {
      return get(store.state, pathWithDots);
    },
    set(val: T) {
      store.commit(pathWithSlashes, val);
    },
  });
}

export function wrapStoreCompute<T>(storePath: string): ComputedRef<T> {
  const store = getStore();
  const pathWithDots = storePath.replace(/\//g, ".");
  return computed(() => get(store.state, pathWithDots));
}

export {getInitials} from "pg-isomorphic/utils/string-prep";

export function getNextPgId() {
  return `pg-id-${++uniqueId}`;
}

export function getRandomId() {
  return Date.now().valueOf() + random(10000, 10000000) + "";
}

export function setSaveInProcess() {
  getStore().commit("cancelSaveProcessing", true);
}

export function setAnswersBeingSaved(answersBeingSaved?) {
  getStore().commit("cancelSaveAnswersBeingSaved", answersBeingSaved);
}

export function clearSaveInProcess() {
  getStore().commit("cancelSaveProcessing", false);
}

export function clearAnswersBeingSaved() {
  getStore().commit("cancelSaveAnswersBeingSaved", null);
}

export function getSaveInProcess(): boolean {
  return getStore().state.cancelSaveProcessing;
}

export function getAnswersBeingSaved(): {} {
  return getStore().state.cancelSaveAnswersBeingSaved;
}

export function getSaveInProcessComputed(): ComputedRef<boolean> {
  return computed(() => getStore().state.cancelSaveProcessing);
}

export function getSuppressedErrorsComputed(): ComputedRef<string[]> {
  return computed(() => getStore().state.cancelSaveSuppressedErrors || []);
}

export function startSuppressingErrorByKey(key: string) {
  const errors = getSuppressedErrorsComputed();
  errors.value.push(key);
  getStore().commit("cancelSaveSuppressedErrors", uniq(errors.value));
}

export function stopSuppressingErrorByKey(key: string) {
  const errors = getSuppressedErrorsComputed();
  getStore().commit("cancelSaveSuppressedErrors", uniq(errors.value.filter((e) => e !== key)));
}

export function formatNumber(number: number | string, separator?: string, dec?: string): string {
  const user = getCurrentUser();
  const integerSeparator = separator || user?.localeSettings?.groupSeparator || ",";
  const decimal = dec || user?.localeSettings?.decimalSeparator || ".";
  return formatter({
    integerSeparator,
    decimal,
  })(+number);
}

export function downloadExcel(
  name: string,
  locale: string,
  data: Array<Record<string, string | number | boolean | Date>>,
) {
  const workSheet = XLSX.utils.json_to_sheet(data);

  const workBook: WorkBook = XLSX.utils.book_new();
  const sheetName = XLSX.utils.book_append_sheet(workBook, workSheet, name.substring(0, SHEET_NAME_CHARACTER_LIMIT));

  XLSX.writeFile(workBook, `${name}.${locale}.xlsx`);
}

export function convertToExcelBoolean(val: boolean | undefined | null): string {
  if (val === undefined || val === null) {
    return "";
  }
  return val ? "true" : "false";
}

// assumes all data is in first sheet
export function extractDataFromExcel<T extends Record<string, string | number | boolean | Date>>(
  file: File,
): Promise<T[]> {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);

    fileReader.onload = (e) => {
      const bufferArray = e?.target.result;
      const workBook = XLSX.read(bufferArray, {type: "buffer"});
      const firstSheetName = workBook.SheetNames[0];
      resolve(XLSX.utils.sheet_to_json(workBook.Sheets[firstSheetName]));
    };

    fileReader.onerror = (err) => {
      reject(err);
    };
  });
}

export function getSecondsDiff(startDate, endDate) {
  const msInSecond = 1000;

  return Math.round(Math.abs(endDate - startDate) / msInSecond);
}

export function getMicroSecondsDiff(startDate, endDate) {
  return Math.round(Math.abs(endDate - startDate));
}

export function camelToUnderscore(str: string): string {
  return str.replace(/[A-Z]/g, (match) => "_" + match.toLowerCase());
}

export function stringToUnderscore(str: string): string {
  return str.replace(/[^a-zA-Z0-9]/g, "_").toLowerCase();
}

export function isVNodeEmpty(vnode) {
  return !vnode || coerceToArray(vnode).every((v) => v.type === Comment);
}

export function copyTextToClipboard(text: string, container?: HTMLElement): Promise<void> {
  return new Promise((resolve, reject) => {
    const btn = document.createElement("button");
    const clipboard = new Clipboard(btn, {
      text: () => text,
      action: () => "copy",
      container: container instanceof HTMLElement ? container : document.body,
    });

    clipboard.on("success", (e) => {
      clipboard.destroy();
      resolve();
    });

    clipboard.on("error", (e) => {
      clipboard.destroy();
      reject(new Error("Error copying from clipboard."));
    });

    btn.click();
  });
}

let filePondPromise: Promise<any> | undefined;
let vueFilePond: any;
export async function dynamicFilePondImport() {
  if (vueFilePond) {
    return vueFilePond;
  }

  if (filePondPromise) {
    return filePondPromise;
  }

  const makeVueFilePond = (await import("vue-filepond")).default;

  vueFilePond = makeVueFilePond(
    (await import("filepond-plugin-file-validate-type")).default,
    (await import("filepond-plugin-image-preview")).default,
  );

  return vueFilePond;
}

export async function dynamicFilePondImageImport() {
  if (vueFilePond) {
    return vueFilePond;
  }

  if (filePondPromise) {
    return filePondPromise;
  }

  const makeVueFilePond = (await import("vue-filepond")).default;

  vueFilePond = makeVueFilePond(
    (await import("filepond-plugin-file-validate-type")).default,
    (await import("filepond-plugin-image-preview")).default,
    (await import("filepond-plugin-image-exif-orientation")).default,
    (await import("filepond-plugin-image-edit")).default,
    (await import("filepond-plugin-image-crop")).default,
    (await import("filepond-plugin-image-resize")).default,
    (await import("filepond-plugin-image-transform")).default,
  );

  return vueFilePond;
}

export function safeSplitDisplayName(val: string): [string, string] {
  if (!val) {
    return ["", ""];
  }

  const splits = val.split(" ", 2);
  if (splits.length === 1) {
    return [splits[0], ""];
  } else {
    return [splits[0], splits[1]];
  }
}

/**
 * Use when you are dealing with tapping events on mobile devices
 */
export function isMobileOS() {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  } else {
    return false;
  }
}
