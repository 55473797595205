import allUsers from "./allUsers";
import users from "./users";
import groups from "./groups";
import entity from "./entity";
import validationFields from "./validationFields";
import profiling from "./profiling";
import imports from "./imports";
import redirects from "./redirects";
import switched from "./switched";
import entities from "./entities";
import loader from "./loader";

export default {
  namespaced: true,
  modules: {
    users,
    groups,
    entity,
    validationFields,
    profiling,
    imports,
    redirects,
    switched,
    entities,
    loader,
    allUsers,
  },
};
