import {getStore} from "@/composables/get.store";
import {getRealId} from "@/composables/questions/internalGroup";
import {getCurrentUser} from "@/composables/vuex";
import {httpDelete, httpGet, httpPatch, httpPost} from "@/composables/xhr";
import type {SkipFilterPagination} from "pg-isomorphic/api";
import type {BulkMessageTemplate, CreateMessageTemplatePayload} from "pg-isomorphic/api/bulkmessages";
import type {TabLite} from "pg-isomorphic/api/datashare";
import type {
  AnyRelatedThreadsInput,
  AnyRelatedThreadsWithMessagesInput,
  CreateMessageThreadInput,
  MentionSearchPayload,
  MessageInfo,
  MessageThreadInfo,
  MessageThreadWithMessagesInfo,
  RespondToThreadInput,
  TaskForThreadLite,
  ThreadFilters,
  CoreThreadSearchOptions,
  ThreadSearchParameters,
  UpdateMessageInput,
} from "pg-isomorphic/api/message";
import type {MentionSearchResult} from "pg-isomorphic/api/message";
import {Profile} from "pg-isomorphic/enums";
import {MessageObjectType} from "pg-isomorphic/enums/message";
import {TranslateEvents} from "pg-isomorphic/enums/translate";
import {UrlUtility} from "pg-isomorphic/utils/url";

/** @deprecated use `searchThreads` */
export async function getMessageThreadsForCurrentUser(limit?: number, skip?: number): Promise<MessageThreadInfo[]> {
  return (await httpGet<MessageThreadInfo[]>(`/api/messages/threads`, {limit, skip})).data;
}

export async function searchThreads(
  params: ThreadSearchParameters = {},
): Promise<SkipFilterPagination<MessageThreadInfo>> {
  if (params.elementIds) {
    params.elementIds = params.elementIds.map((elementId) => getRealId(elementId));
  }
  return (await httpPost<SkipFilterPagination<MessageThreadInfo>>(`/api/messages/threads/search`, params)).data;
}

export async function getThreadSearchFilters(queryParams: CoreThreadSearchOptions): Promise<ThreadFilters> {
  return (await httpGet<ThreadFilters>(`/api/messages/threads/filters`, queryParams)).data;
}

export async function getRelatedThreads(params: AnyRelatedThreadsInput): Promise<MessageThreadInfo[]> {
  if (params.elementId) {
    params.elementId = getRealId(params.elementId);
  }
  return (await httpGet<MessageThreadInfo[]>(`/api/messages/threads/related`, params)).data;
}

export async function getLatestRelatedThread(params: AnyRelatedThreadsInput): Promise<MessageThreadInfo | null>;
export async function getLatestRelatedThread(
  params: AnyRelatedThreadsWithMessagesInput,
): Promise<MessageThreadWithMessagesInfo | null>;
export async function getLatestRelatedThread(
  params: AnyRelatedThreadsInput | AnyRelatedThreadsWithMessagesInput,
): Promise<MessageThreadInfo | MessageThreadWithMessagesInfo | null> {
  if (params.elementId) {
    params.elementId = getRealId(params.elementId);
  }
  return (
    await httpGet<MessageThreadInfo | MessageThreadWithMessagesInfo | null>(`/api/messages/threads/latest`, params)
  ).data;
}

export async function markMessagesAsRead(messageIds: string[]): Promise<void> {
  await httpPost(`/api/messages/read`, {messageIds});
}

export async function markNotificationsReadForThreads(threadIds: string[]): Promise<void> {
  await httpPost(`/api/messages/notifications-read-by-thread`, {threadIds});
}

export async function getMessageThread(
  threadId: string,
  limit?: number,
  skip?: number,
): Promise<MessageThreadWithMessagesInfo> {
  return (await httpGet<MessageThreadWithMessagesInfo>(`/api/messages/threads/${threadId}`, {limit, skip})).data;
}

export async function rateMessageTranslation(messageId: string, goodTranslation: boolean, sourceLocale: string) {
  await httpPost(`/api/translate/rating`, {
    goodTranslation,
    sourceLocale,
    messageId,
  });
}

export async function createMessageThread(data: CreateMessageThreadInput): Promise<MessageThreadWithMessagesInfo> {
  if (data.elementId) {
    data.elementId = getRealId(data.elementId);
  }
  return (await httpPost<MessageThreadWithMessagesInfo>(`/api/messages/threads`, data)).data;
}

export async function createFakeTempMessageThread(
  data: CreateMessageThreadInput,
): Promise<MessageThreadWithMessagesInfo> {
  if (data.elementId) {
    data.elementId = getRealId(data.elementId);
  }
  return (await httpPost<MessageThreadWithMessagesInfo>(`/api/messages/create-fake-temp-thread`, data)).data;
}

export async function respondInThread(threadId: string, data: RespondToThreadInput): Promise<MessageInfo> {
  return (await httpPost<MessageInfo>(`/api/messages/threads/${threadId}`, data)).data;
}

export async function deleteMessage(id: string): Promise<void> {
  await httpDelete(`/api/messages/${id}`);
}

export async function updateMessage(id: string, payload: UpdateMessageInput): Promise<MessageInfo> {
  return (await httpPatch<MessageInfo>(`/api/messages/${id}`, payload)).data;
}

export async function translateMessage(id: string): Promise<string> {
  const result = (await httpPost<{translation: string}>(`/api/messages/translate/${id}`, {})).data?.translation || "";
  getStore().emitter.$emit(TranslateEvents.TRANSLATED, {error: false});
  return result;
}

export async function getTopicsForMessages(): Promise<TabLite[]> {
  return (await httpGet<{topics: TabLite[]}>(`/api/messages/directed-to-topics`)).data.topics;
}

export async function getMessageTemplates(): Promise<BulkMessageTemplate[]> {
  return (await httpGet<{templates: BulkMessageTemplate[]}>(`/api/messages/templates`)).data.templates;
}

export async function createMessageTemplates(template: CreateMessageTemplatePayload): Promise<void> {
  await httpPost(`/api/messages/templates`, template);
}

export async function getMentionables(input: MentionSearchPayload): Promise<MentionSearchResult> {
  const payload: MentionSearchPayload = {
    ...input,
    // make sure we trim the search text!
    searchText: input.searchText.trim(),
  };

  return (await httpPost<MentionSearchResult>(`/api/messages/mention-search`, payload)).data;
}

export function linkToMessageContext(thread: MessageThreadInfo): string {
  const objectType: MessageObjectType = thread.objectType;
  const elementId: string | undefined = thread.elementId;
  const connectionId: string | undefined = thread.connectionId;
  const entityId: string | undefined = thread.entity.id;
  const instanceId: string | undefined = thread.instanceId;
  const task: TaskForThreadLite | undefined = thread.task;
  const actionPlanId: string | undefined = thread.actionPlanId;

  if (objectType === MessageObjectType.ELEMENT && elementId) {
    const connection = connectionId;
    let whichProfile = null;
    let hasConnection = false;

    if (connection) {
      hasConnection = true;
      whichProfile = getCurrentUser().activeEntityId === entityId ? Profile.MINE : Profile.THEIRS;
    }

    let baseUrl = "profile";
    if (hasConnection) {
      baseUrl = `/connection/${entityId}/${connectionId}/${whichProfile}`;
    }

    return `${baseUrl}?elementId=${elementId}&instanceId=${instanceId || ""}&action=message`;
  }

  if (objectType === MessageObjectType.TASK && task) {
    return UrlUtility.generateTaskMessageUrl("", {
      entityId: task.entityForThread.id,
      taskId: task.id,
      connectionId: task.connectionId,
    });
  }

  if (objectType === MessageObjectType.ACTION_PLAN && actionPlanId) {
    return UrlUtility.generateActionPlanMessageUrl("", {
      entityId,
      actionPlanId,
      connectionId,
    });
  }

  return "";
}

export enum CommunicationCenterTab {
  Messages = "Messages",
  Emails = "Emails",
  Notes = "Notes",
}
