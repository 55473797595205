import globalLogger from "@/logging";
import {GroupSubType, GroupType} from "pg-isomorphic/enums";
import type {JSONQuestion} from "pg-isomorphic/utils";

const logger = globalLogger.getLogger("internalGroup");

const INTERNAL_USE_PREFIX = "internal-use-";

export function makeInternalUseId(id: string): string {
  return `${INTERNAL_USE_PREFIX}${id}`;
}

export function getRealId(id: string | undefined): string {
  if (id?.startsWith(INTERNAL_USE_PREFIX)) {
    return id.substr(INTERNAL_USE_PREFIX.length);
  }
  return id;
}

export function isDynamicInternalUseId(id: string | undefined): boolean {
  return id?.startsWith(INTERNAL_USE_PREFIX) || false;
}

enum DisplayMode {
  NORMAL,
  COUNTERPARTY,
  INTERNAL_USE,
}

function getDisplayMode(question: JSONQuestion): DisplayMode {
  if (question.internalUse && !question.nakedInternalUse) {
    return DisplayMode.INTERNAL_USE;
  } else if (question.counterpartyCanEditAnswer) {
    return DisplayMode.COUNTERPARTY;
  } else {
    return DisplayMode.NORMAL;
  }
}

export function splitChildren(parent: JSONQuestion, children: JSONQuestion[], isSharedKit = false): JSONQuestion[] {
  const out: JSONQuestion[] = [];
  let currentContainer;
  for (const child of children) {
    const currentMode = getDisplayMode(child);
    if (
      currentMode === DisplayMode.NORMAL ||
      (currentMode === DisplayMode.COUNTERPARTY && isSharedKit) ||
      child.type === GroupType.TOPIC ||
      child.type === GroupType.TAB ||
      child.type === GroupType.SUBTOPIC ||
      (currentMode === getDisplayMode(parent) && parent.type !== GroupType.TOPIC && parent.type !== GroupType.SUBTOPIC)
    ) {
      // Stays the same.
      out.push(child);
      currentContainer = undefined;
    } else {
      // Goes into a UI-only container.
      if (
        currentContainer &&
        getDisplayMode(currentContainer) === currentMode &&
        child.subType !== GroupSubType.REVIEW
      ) {
        currentContainer.children.push(child);
      } else {
        if (child.subType === GroupSubType.REVIEW) {
          logger.trace("Making review be a separate container.", child.key);
        }
        currentContainer = {
          type: GroupType.INTERNAL_USE_GROUP,
          parent: parent,
          visible: true,
          key: makeInternalUseId(child.key),
          _id: makeInternalUseId(child._id),
          children: [child],
        };
        if (currentMode === DisplayMode.INTERNAL_USE) {
          currentContainer.internalUse = true;
        } else if (currentMode === DisplayMode.COUNTERPARTY) {
          currentContainer.counterpartyCanEditAnswer = true;
        }
        if (child.subType === GroupSubType.REVIEW) {
          currentContainer.subType = GroupSubType.REVIEW;
        }
        out.push(currentContainer);
      }
    }
  }
  return out;
}
