import type {QueryParam, RouteLite} from "@/composables/vuex";
import {getCurrentRouteFromStore, getPreviousRouteFromStore} from "@/composables/vuex";
import {router} from "@/router";
import type {Dictionary} from "lodash";
import compact from "lodash/compact";
import isEqual from "lodash/isEqual";
import isNil from "lodash/isNil";
import omitBy from "lodash/omitBy";
import {isEmptyOrUndefined} from "pg-isomorphic/utils";
import {handleNilStr} from "pg-isomorphic/utils/string-prep";
import type {ComputedRef} from "vue";
import {computed} from "vue";
import type {Router} from "vue-router";

export function useRouter(): Router {
  return router;
}

export function getRouter(): Router {
  return useRouter() as Router;
}

export function getCurrentRoute(): RouteLite {
  return getCurrentRouteFromStore();
}

export function getCurrentRouteComputed(): ComputedRef<RouteLite> {
  return computed(() => getCurrentRoute());
}

export function getPreviousRoute(): RouteLite | undefined {
  return getPreviousRouteFromStore();
}

export function getQueryParamString(id: string): string {
  const param = getCurrentRoute().query[id];
  return Array.isArray(param) ? param.join(",") : param;
}

export function ensureSingleQueryParam<T extends string = string>(queryParam: T | string[]): T;
export function ensureSingleQueryParam<T extends string = string>(queryParam: QueryParam): T | undefined;
export function ensureSingleQueryParam<T extends string = string>(queryParam: QueryParam): T | undefined {
  if (Array.isArray(queryParam)) {
    // normalize null to undefined just in case
    return (handleNilStr(queryParam[0]) as unknown as T) ?? undefined;
  }
  return (handleNilStr(queryParam) as unknown as T) ?? undefined;
}

export function ensureMultipleQueryParams<T extends string = string>(queryParam: QueryParam): T[] {
  if (!Array.isArray(queryParam)) {
    return (isNil(queryParam) ? [] : compact([queryParam])) as T[];
  }
  return compact(queryParam) as T[];
}

export function updateQueryParams(params: Dictionary<QueryParam>, replace = true) {
  const route = getCurrentRoute();
  const router = getRouter();
  const query = route.query;
  const newQuery = omitBy({...query, ...params}, (v) => isEmptyOrUndefined(v));

  if (!isEqual(query, newQuery)) {
    if (replace) {
      router.replace({name: route.name, query: newQuery});
    } else {
      router.push({name: route.name, query: newQuery});
    }
  }
}

export function getRouterViewKey(): string {
  const route = getCurrentRoute();
  const kitKey = route.meta.kitKey;
  if (kitKey) {
    const [_blank, _path, kitType, kitId] = route.path.split("/");
    return `kit-${kitKey}-${kitType || ""}-${kitId || ""}`;
  }

  if (route.name === "search-results") {
    return route.path;
  }

  return "";
}
