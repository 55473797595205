export enum ErrorCode {
  // GENERAL
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  INVALID_REQUEST = "INVALID_REQUEST",
  VALIDATION_ERROR = "VALIDATION_ERROR",
  TOO_MANY_LOGINS = "TOO_MANY_LOGINS",
  UNAUTHORIZED_REQUEST = "UNAUTHORIZED_REQUEST",
  USER_NOT_VERIFIED = "USER_NOT_VERIFIED",
  FORBIDDEN_REQUEST = "FORBIDDEN_REQUEST",
  NOT_FOUND = "NOT_FOUND",
  CONFLICT = "CONFLICT",
  EXTERNAL_SERVICE_ERROR = "EXTERNAL_SERVICE_ERROR",
  REQUIRED_FIELD = "REQUIRED_FIELD",
  DUPLICATE_TAX_ID = "DUPLICATE_TAX_ID",

  // AUTH
  PASSWORD_LENGTH = "PASSWORD_LENGTH",
  PASSWORD_NOT_SECURE = "PASSWORD_NOT_SECURE",
  INVALID_EMAIL = "INVALID_EMAIL",
  OAUTH_NO_EMAIL = "OAUTH_NO_EMAIL",
  OAUTH_PREVIOUSLY_LINKED = "OAUTH_PREVIOUSLY_LINKED",
  OAUTH_INVALID_USER = "OAUTH_INVALID_USER",
  OAUTH_INVALID_EMAIL = "OAUTH_INVALID_EMAIL",
  OAUTH_ACCOUNT_EXISTS = "OAUTH_ACCOUNT_EXISTS",
  USER_ASSOCIATION_EXISTS = "USER_ASSOCIATION_EXISTS",
  USER_ASSOCIATION_REQUESTED = "USER_ASSOCIATION_REQUESTED",
  USER_ASSOCIATION_INVALID = "USER_ASSOCIATION_INVALID",

  // CONNECTIONS
  INVALID_CONNECTION_ROLES = "INVALID_CONNECTION_ROLES",
  INVALID_CUSTOMIZED_CONNECTION_ROLES = "INVALID_CUSTOMIZED_CONNECTION_ROLES",
  INVALID_REQUESTING_ENTITY = "INVALID_REQUESTING_ENTITY",
  INVALID_CONNECTION_STAGE = "INVALID_CONNECTION_STAGE",
  DUPLICATE_CONNECTION = "DUPLICATE_CONNECTION",
  CONNECTION_TO_SELF = "CONNECTION_TO_SELF",
  CONNECTION_LOCKED = "CONNECTION_LOCKED",
  CONTACT_EMAIL_INVALID_DOMAIN = "CONTACT_EMAIL_INVALID_DOMAIN",
  INVALID_CONNECTION_PDF_REQUEST = "INVALID_CONNECTION_PDF_REQUEST",

  // USERS
  USER_EMAIL_EXISTS = "USER_EMAIL_EXISTS",
  USER_PREVIOUSLY_VERIFIED = "USER_PREVIOUSLY_VERIFIED",
  USER_PASSWORD_RESET_LINK_EXPIRED = "USER_PASSWORD_RESET_LINK_EXPIRED",
  USER_PASSWORDS_DO_NOT_MATCH = "USER_PASSWORDS_DO_NOT_MATCH",
  USER_CURRENT_PASSWORD_INCORRECT = "CURRENT_PASSWORD_INCORRECT",
  USER_REMOVE_LAST_ADMIN = "USER_REMOVE_LAST_ADMIN",
  USER_REMOVE_LAST_ACCEPT = "USER_REMOVE_LAST_ACCEPT",
  USER_GRAPHITE_ADMIN_EMAIL_CHANGE = "USER_GRAPHITE_ADMIN_EMAIL_CHANGE",
  USER_MULTI_ENTITY_EMAIL_CHANGE_BY_ADMIN = "USER_MULTI_ENTITY_EMAIL_CHANGE_BY_ADMIN",
  USER_SESSION_TERMINATED = "USER_SESSION_TERMINATED",
  USER_INVALID_ENTITY_OR_TOKEN_EXPIRED = "USER_INVALID_ENTITY_OR_TOKEN_EXPIRED",

  // DATA SHARE
  DATA_SHARE_LINK_EXPIRED = "DATA_SHARE_LINK_EXPIRED",
  DATA_SHARE_NOT_FOUND = "DATA_SHARE_NOT_FOUND",

  // TRANSLATION
  PROBLEM_TRANSLATING = "PROBLEM_TRANSLATING",

  // ENTITY
  ENTITY_ACCESS_PREVIOUSLY_REQUESTED = "ENTITY_ACCESS_PREVIOUSLY_REQUESTED",
  ENTITY_ALREADY_EXISTS = "ENTITY_ALREADY_EXISTS",
  ENTITY_NOT_SKELETON = "ENTITY_NOT_SKELETON",
  INVITE_NO_LONGER_VALID = "INVITE_NO_LONGER_VALID",
  INVITE_CONNECT_TO_SELF = "INVITE_CONNECT_TO_SELF",

  // TASKS
  TASKS_REVIEW_REQUIRED_MISSING = "TASKS_REVIEW_REQUIRED_MISSING",
  TASKS_REVIEW_APPROVAL_PENDING = "TASKS_REVIEW_APPROVAL_PENDING",
  OUTSTANDING_TASKS = "OUTSTANDING_TASKS",
  INVALID_DASHBOARD_QUERY = "INVALID_DASHBOARD_QUERY",
  DUPLICATE_TASK = "DUPLICATE_TASK",
  TASK_ALREADY_COMPLETE = "TASK_ALREADY_COMPLETE",
  INVALID_TASK_STATE_QUERY = "INVALID_TASK_STATE_QUERY",
  FILTER_FAILED_VALIDATION = "FILTER_FAILED_VALIDATION",

  // CONFIGURATION
  CONFIGURATION_ERROR_MISSING_KEY = "CONFIGURATION_ERROR_MISSING_KEY",

  // 2FA
  BAD_TWO_FACTOR_AUTH_CODE = "BAD_TWO_FACTOR_AUTH_CODE",
  TWO_FACTOR_AUTH_CODE_EXPIRED = "TWO_FACTOR_AUTH_CODE_EXPIRED",

  // Public API
  AMBIGUOUS_CONNECTIONS = "AMBIGUOUS_CONNECTIONS",
  NO_CONNECTION = "NO_CONNECTION",
  USER_DOESNT_EXIST = "USER_DOESNT_EXIST",
  ENTITY_DOESNT_EXIST = "ENTITY_DOESNT_EXIST",
  API_KEY_INVALID = "API_KEY_INVALID",

  // FILE
  FILE_INFECTED = "FILE_INFECTED",

  // ANSWERS
  DUPLICATE_KEY = "DUPLICATE_KEY",

  // Super-admin
  MAKE_NON_NETWORK_IS_BUYER = "MAKE_NON_NETWORK_IS_BUYER",
  MAKE_NON_NETWORK_TOO_MANY_CONNECTIONS = "MAKE_NON_NETWORK_TOO_MANY_CONNECTIONS",
  MAKE_NON_NETWORK_UNCONNECTED = "MAKE_NON_NETWORK_UNCONNECTED",
  MAKE_NON_NETWORK_INCORRECT_STAGE = "MAKE_NON_NETWORK_INCORRECT_STAGE",
  DELETE_ENTITY_TO_MANY_CONNECTIONS = "To many active connections to delete entity",

  // Docusign
  DOCUSIGN_CONSENT_ERROR = "DOCUSIGN_CONSENT_ERROR",
  DOCUSIGN_INVALID_USER_ERROR = "DOCUSIGN_INVALID_USER_ERROR",
  DOCUSIGN_COULD_NOT_AUTH = "DOCUSIGN_COULD_NOT_AUTH",

  // AdobeSign
  ADOBE_SIGN_AUTH_ERROR = "ADOBE_SIGN_AUTH_ERROR",
  ADOBE_SIGN_UPSTREAM_API_ERROR = "ADOBE_SIGN_UPSTREAM_API_ERROR",

  // Responder
  NLP_REQUEST_ERROR = "NLP_REQUEST_ERROR",

  // Rapid Ratings
  RR_OUTREACH_ERROR = "RR_OUTREACH_ERROR",

  // Custom Company Logo
  CUSTOM_LOGO_UPLOAD_ERROR = "CUSTOM_LOGO_UPLOAD_ERROR",

  // Question admin errors
  METADATA_NOT_LOCKED = "METADATA_NOT_LOCKED", // Metadata lock not held by the user requesting the update
  NON_OVERRIDEABLE_PROPERTY = "NON_OVERRIDEABLE_PROPERTY", // Attempt to change a non-overrideable property on a Graphite-owned question
  NON_GROUP_PROPERTY = "NON_GROUP_PROPERTY", // Attempt to change a question-only property on a group
  NON_QUESTION_PROPERTY = "NON_QUESTION_PROPERTY", // Attempt to change a group-only property on a question
  INVALID_QUESTION_ID = "INVALID_QUESTION_ID", // Update refers to a question ID that does not exist
  ELEMENT_DISALLOWS_ADMIN_CHANGES = "ELEMENT_DISALLOWS_ADMIN_CHANGES",
}
