import {globalEmitter, GlobalEvent} from "@/store/plugins/emitter";
import type {ToastMessageOptions} from "primevue/toast";

export type ToastMessageSecondaryOptions = Omit<ToastMessageOptions, "detail" | "summary">;

class ToastHelper {
  constructor(private defaultOptions: ToastMessageSecondaryOptions = {}) {}

  add(options: ToastMessageOptions) {
    globalEmitter.emit(GlobalEvent.Toast, {
      ...this.defaultOptions,
      ...options,
    });
  }

  error(detail: string): void;
  error(detail: string, summary: string): void;
  error(detail: string, config: ToastMessageSecondaryOptions): void;
  error(detail: string, summary: string, config: ToastMessageSecondaryOptions): void;
  error(detail: string, summary?: string | ToastMessageSecondaryOptions, config?: ToastMessageSecondaryOptions): void {
    const input = this.parseInput(detail, summary, config);
    this.add({
      ...input,
      severity: "error",
    });
  }

  info(detail: string): void;
  info(detail: string, summary: string): void;
  info(detail: string, config: ToastMessageSecondaryOptions): void;
  info(detail: string, summary: string, config: ToastMessageSecondaryOptions): void;
  info(detail: string, summary?: string | ToastMessageSecondaryOptions, config?: ToastMessageSecondaryOptions): void {
    const input = this.parseInput(detail, summary, config);
    this.add({
      ...input,
      severity: "info",
    });
  }

  setDefaults(defaults: ToastMessageSecondaryOptions): void {
    this.defaultOptions = defaults;
  }

  success(detail: string): void;
  success(detail: string, summary: string): void;
  success(detail: string, config: ToastMessageSecondaryOptions): void;
  success(detail: string, summary: string, config: ToastMessageSecondaryOptions): void;
  success(
    detail: string,
    summary?: string | ToastMessageSecondaryOptions,
    config?: ToastMessageSecondaryOptions,
  ): void {
    const input = this.parseInput(detail, summary, config);
    this.add({
      ...input,
      severity: "success",
    });
  }

  warning(detail: string): void;
  warning(detail: string, summary: string): void;
  warning(detail: string, config: ToastMessageSecondaryOptions): void;
  warning(detail: string, summary: string, config: ToastMessageSecondaryOptions): void;
  warning(
    detail: string,
    summary?: string | ToastMessageSecondaryOptions,
    config?: ToastMessageSecondaryOptions,
  ): void {
    const input = this.parseInput(detail, summary, config);
    this.add({
      ...input,
      severity: "warn",
    });
  }

  private parseInput(
    inputBody: string,
    inputTitleOrConfig?: string | ToastMessageSecondaryOptions,
    inputMaybeConfig?: ToastMessageSecondaryOptions,
  ): ToastMessageOptions {
    const detail = inputBody;
    let summary = "";
    let config: ToastMessageSecondaryOptions = {};

    if (typeof inputTitleOrConfig === "string") {
      summary = inputTitleOrConfig;
    } else if (inputTitleOrConfig) {
      config = inputTitleOrConfig;
    }

    if (!config) {
      config = inputMaybeConfig;
    }

    return {
      detail,
      summary,
      ...config,
    };
  }
}

const toastHelper = new ToastHelper({
  life: 5000,
});

export function getToast(): ToastHelper {
  return toastHelper;
}
