import cloneDeep from "lodash/cloneDeep";
import type {ImportError, ImportResults} from "pg-isomorphic/api/jobs";
import {RoutingKey} from "pg-isomorphic/queue";
import {omit} from "ramda";
import type {PGActionTree} from "../../index-types";
import {autoMutations} from "../../utils";

export interface ImportState {
  jobId: string | null;
  manualJobId: string | null;
  messages: string[];
  errors: ImportError[];
  progress: ImportResults | null;
}

const initialState: ImportState = {
  jobId: null,
  messages: [],
  errors: [],
  progress: null,
  manualJobId: null,
};

let jobMessage: (x: any) => void = () => {
  /**/
};

const actions: PGActionTree<ImportState> = {
  joinRooms({state, commit}) {
    jobMessage = (wsMessage) => {
      if (wsMessage.msg) {
        commit("messages", [...state.messages, wsMessage]);
      } else if (wsMessage.error) {
        commit("errors", [...state.errors, wsMessage.error]);
      } else {
        commit("progress", omit(["errors"], wsMessage));
      }
    };
    this.join(RoutingKey.jobProgress({jobId: state.jobId!}));
    this.socketOn(RoutingKey.jobProgress({jobId: state.jobId!}), jobMessage);
  },
  leaveRooms({state, commit}) {
    this.leave(RoutingKey.jobProgress({jobId: state.jobId!}));
    this.socketOff(RoutingKey.jobProgress({jobId: state.jobId!}), jobMessage);
    commit("messages", []);
    commit("errors", []);
    commit("jobId", null);
    commit("progress", null);
  },
  async attachManualJob({state, commit, dispatch}) {
    if (state.manualJobId) {
      if (state.jobId) {
        await dispatch("leaveRooms");
      }
      commit("jobId", state.manualJobId);
      await dispatch("joinRooms");
    }
  },
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: autoMutations(initialState),
  actions,
};
