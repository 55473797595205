import get from "lodash/get";
import {
  any,
  both,
  curry,
  either,
  flatten,
  forEach,
  has,
  invert,
  isEmpty,
  isNil,
  pluck,
  propOr,
  repeat,
  uniq,
} from "ramda";
import {ElementType, GroupSubType, GroupType, QuestionType} from "../enums";
import {Expression} from "../rules/expression";
import {isUnanswered, JSONObject, JSONQuestion} from "../utils";

export interface Tree<T extends Tree<T>> {
  children?: T[];
}

export interface TreeWithParent<T extends TreeWithParent<T>> extends Tree<T> {
  parent?: T;
}

export interface Typed {
  type: ElementType;
}

export interface Visibility {
  visible?: boolean;
}

export interface Tagged {
  tags?: string[];
}

export const processTree = <T extends Tree<T>>(
  beforeChildren: (q: T) => void,
  afterChildren: (q: T) => void,
  question: T,
): T => {
  beforeChildren(question);
  if (question.children) {
    for (const child of question.children) {
      processTree(beforeChildren, afterChildren, child);
    }
  }
  afterChildren(question);
  return question;
};

export const processTreeBottomUp: <T>(afterChildren: (q: T) => void) => (question: T) => T = <T>(
  afterChildren: (q: T) => void,
) => {
  return (question: T) => processTree(() => undefined, afterChildren as any, question as any) as any;
};

export const processTreeTopDown: <T>(beforeChildren: (q: T) => void) => (question: T) => T = <T>(
  beforeChildren: (q: T) => void,
) => {
  return (question: T) => processTree(beforeChildren as any, () => undefined, question as any) as any;
};

export const processTreeWithBranchFiltering = <T extends Tree<T>>(
  beforeChildren: (q: T) => boolean, // If not true, skip children
  afterChildren: (q: T) => void,
  question: T,
): T => {
  const processChildren = beforeChildren(question);
  if (question.children && processChildren) {
    for (const child of question.children) {
      processTreeWithBranchFiltering(beforeChildren, afterChildren, child);
    }
  }
  afterChildren(question);
  return question;
};

const invertGroupType = invert(GroupType);
export const isContainer = (q: {type: ElementType}) => !!invertGroupType[q.type];

export const checkForEmptyValue = either(isNil, isEmpty);

export const isVisible: (q: Visibility) => boolean = propOr(false, "visible");

const invertQuestionType = invert(QuestionType);
export const isQuestion = (q: Typed) => !!invertQuestionType[q.type];

const _find = <T extends Tree<T>>(p: (question: T) => boolean, q: T): T | undefined => {
  if (p(q)) {
    return q;
  } else if (q.children) {
    for (const child of q.children) {
      const found = _find(p, child);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
};

export const find = curry(<T extends Tree<T>>(predicate: (question: T) => boolean, question: T): T | undefined => {
  return _find(predicate, question);
});

export const findByKey = <T extends Tree<T> & {key: string}>(key: string, tree: Tree<T>) =>
  find((q: T) => q.key === key, tree);

export const findByKeyInGroup = <T extends Tree<T> & {key: string}>(key: string, tree: Tree<T>) =>
  find((q: T) => q.key.match(`\.${key}$`), tree);

export function findByKeyAndTryVisible(key: string, questionData: JSONQuestion): JSONQuestion | null {
  let question = find((q: JSONQuestion) => q.key === key && q.visible, questionData);
  if (!question) {
    question = find((q: JSONQuestion) => q.key === key, questionData);
  }
  return question;
}

export const findNearestNeighbor = <T extends TreeWithParent<T>>(
  predicate: (question: T) => boolean,
  question: T,
): T | undefined => find(predicate, question) || (question.parent && findNearestNeighbor(predicate, question.parent));

export async function findAsync<T extends Tree<T>>(p: (question: T) => Promise<boolean>, q: T): Promise<T | undefined> {
  if (await p(q)) {
    return q;
  } else if (q.children) {
    for (const child of q.children) {
      const found = await findAsync(p, child);
      if (found) {
        return found;
      }
    }
  }
  return undefined;
}

const _filter = <T extends Tree<T>>(predicate: (question: T) => boolean, question: T): T[] => {
  const results: T[] = [];
  if (predicate(question)) {
    results.push(question);
  }
  if (question.children) {
    question.children.forEach((child) => {
      results.push(...filter(predicate, child));
    });
  }
  return results;
};

export const filter = curry(<T extends Tree<T>>(predicate: (question: T) => boolean, question: T): T[] => {
  return _filter(predicate, question);
});

export const filterAsync = async <T extends Tree<T>>(
  predicate: (question: T) => Promise<boolean>,
  question: T,
): Promise<T[]> => {
  const results: T[] = [];
  if (await predicate(question)) {
    results.push(question);
  }
  for (const child of question.children || []) {
    results.push(...(await filterAsync(predicate, child)));
  }
  return results;
};

const _findParentOfType = <T extends TreeWithParent<T> & Typed>(
  type: GroupType,
  question: T | undefined,
): T | undefined => (!question || question.type === type ? question : findParentOfType(type, question.parent));

export const findParentOfType = curry(
  <T extends TreeWithParent<T> & Typed>(type: GroupType, question: T | undefined): T | undefined =>
    _findParentOfType(type, question),
);

export const findTopic = findParentOfType(GroupType.TOPIC);

export const findSubTopic = findParentOfType(GroupType.SUBTOPIC);

export const findGroupInstance = findParentOfType(GroupType.GROUP_INSTANCE);

export const findGroup = <T extends TreeWithParent<T> & Typed>(question: T | undefined): T | undefined =>
  !question || question.type === GroupType.GROUP || question.type === GroupType.INTERNAL_USE_GROUP
    ? question
    : findGroup(question.parent);

const _findParent = <T extends TreeWithParent<T>>(predicate: (question: T) => boolean, question: T): T | undefined => {
  if (predicate(question)) {
    return question;
  } else if (question.parent) {
    return findParent(predicate, question.parent);
  } else {
    return undefined;
  }
};

export const findParent = curry(
  <T extends TreeWithParent<T>>(predicate: (question: T) => boolean, question: T): T | undefined => {
    return _findParent(predicate, question);
  },
);

export function allParents(q: JSONQuestion | null): JSONQuestion[] {
  const parents: JSONQuestion[] = [];
  let parent: JSONQuestion | null | undefined = q;
  while (parent) {
    parents.push(parent);
    parent = parent.parent;
  }
  return parents;
}

export const isTopic = (q: Typed): boolean => q.type === GroupType.TOPIC;

export const isMultipleGroup = (q: Typed & {multiple?: boolean}): boolean =>
  (q.type === GroupType.GROUP || q.type === GroupType.INTERNAL_USE_GROUP) && Boolean(q.multiple);

export const isReviewGroup = (q: Typed & {multiple?: boolean; subType?: GroupSubType}): boolean =>
  isMultipleGroup(q) && q.subType === GroupSubType.REVIEW;

export const isTicketGroup = (q: Typed & {multiple?: boolean; subType?: GroupSubType}): boolean =>
  isMultipleGroup(q) && q.subType === GroupSubType.TICKET;

export const hasTags = (q: Tagged): boolean => has("tags", q);

export const isUnanswerable = (q: Typed): boolean =>
  !isQuestion(q) ||
  q.type === QuestionType.INFO ||
  q.type === QuestionType.DIVIDER ||
  q.type === QuestionType.STOP ||
  q.type === QuestionType.WARNING;

// Container visibility
const containerVisibility: (q: Tree<any> & Typed) => boolean = (q) =>
  any((g) => isVisible(g) && !(isMultipleGroup(g) && g.hideSourceTable), q.children || []);
const setVisibility: (q: JSONQuestion) => void = (q: JSONQuestion) => {
  if (isContainer(q) && !isMultipleGroup(q)) {
    q.visible = q.quickHideVisible === false ? q.quickHideVisible : q.filterVisible || containerVisibility(q);
  }
};
export const processContainerVisibility = processTreeBottomUp(setVisibility);

export const inheritVisibility = processTreeTopDown(<T extends Tree<T> & {visible: boolean}>(q: T) => {
  if (!q.visible) {
    for (const child of q.children || []) {
      child.visible = false;
    }
  }
});

const getTopicFilter = <T extends Typed & {label: Expression; _id: string}>(question: T) => {
  if (question.type === GroupType.TOPIC) {
    return {value: question._id, text: question.label};
  } else {
    // Shouldn't ever get here unless a tab has children that aren't topics.
    return undefined;
  }
};
export const getTabFilter = <T extends Tree<T> & Typed & {label: Expression; _id: string}>(question: T) => {
  if (question.type === GroupType.TAB) {
    return {tabLabel: question.label, topics: question.children!.map(getTopicFilter)};
  } else {
    // Shouldn't ever get here unless the root has children that aren't tabs.
    return undefined;
  }
};

export const getFilterTags = <T extends Tree<T> & Typed & Tagged & {label: Expression; _id: string}>(
  question: T,
): Array<string[] | undefined> => {
  const taggedQuestions: T[] = filter(both(hasTags, isVisible), question);
  return uniq(flatten(pluck("tags", taggedQuestions)));
};

export const getTopicFilters = <T extends Tree<T> & Typed & {label: Expression; _id: string}>(question: T) => {
  if (question.type === GroupType.ROOT && question.children) {
    return {
      filters: question.children.map(getTabFilter),
      tags: question.children.map(getFilterTags),
    };
  } else {
    // Shouldn't ever get here unless called on something other than the root of the tree
    return undefined;
  }
};

export const parentInstanceId = (question?: JSONQuestion): string | undefined =>
  question && (question.instance || parentInstanceId(question.parent));

export const applyAll = (functions: Array<(...arg: any) => void>, ...arg: any[]) =>
  forEach((f) => f(...arg), functions);

export const cloneTree = <T extends Tree<T>>(question: T) => ({
  ...question,
  children: question?.children?.map(cloneTree),
});

const fixParents = <T extends TreeWithParent<T>>(question: T, parent?: T): T => {
  if (parent) {
    question.parent = parent;
  }
  if (question.children) {
    question.children.forEach((child) => fixParents(child, question));
  }
  return question;
};

export const cloneTreeWithParents = <T extends TreeWithParent<T>>(question: T, parent?: T): T =>
  fixParents(cloneTree(question), parent);

export function processTreeTopDownWithParentMultipleGroup<T extends Tree<T> & Typed>(
  f: (question: T, parentMultipleGroup?: T) => void,
  questionTree: T,
) {
  let parentMultipleGroup: T | undefined;
  processTree(
    (question: T) => {
      f(question, parentMultipleGroup);
      if (isMultipleGroup(question)) {
        parentMultipleGroup = question;
      }
    },
    (question: T) => {
      if (parentMultipleGroup === question) {
        parentMultipleGroup = undefined;
      }
    },
    questionTree,
  );
}

// Output will be masked E.164
// https://www.twilio.com/docs/glossary/what-e164
export const maskPhone = (phoneNumber: string): string => {
  const stripped = phoneNumber.replace(/[^\d]/g, "");
  if (stripped.length < 4) {
    return repeat("*", stripped.length).join("");
  }
  const stars = repeat("*", stripped.length - 3).join("");
  return "+" + stripped.charAt(0) + stars + stripped.slice(-2);
};

export const maskEmail = (email: string): string => {
  if (!email) {
    return email;
  }
  const pair = email.split("@");
  const nameStars = repeat("*", pair[0].length - 1);
  const name = pair[0].charAt(0) + nameStars.join("");
  if (pair[1]) {
    let domain = "";
    const domainPair = pair[1].split(".");
    if (domainPair[1]) {
      const domainStars = repeat("*", domainPair[0].length - 1);
      domain = domainStars.join("") + domainPair[0].charAt(domainPair[0].length - 1) + "." + domainPair[1];
    } else {
      const domainStars = repeat("*", pair[1].length - 1);
      domain = domainStars.join("") + pair[1].charAt(pair[1].length - 1);
    }
    return `${name}@${domain}`;
  }
  return name;
};

export function isLocaleObjectQuestion(q: {type: ElementType; enableAnswerTranslation?: boolean}): boolean {
  return Boolean(q.type === QuestionType.MULTI_LANGUAGE_TEXT || q.enableAnswerTranslation);
}

export const checkRequired = (question: JSONQuestion, answers: JSONObject): boolean => {
  const answer = get(answers, question.key);
  return !!(
    isQuestion(question) &&
    question.required &&
    question.visible &&
    isUnanswered(answer, question.requiredLanguages, question.requiredSubparts)
  );
};

const processTreeAsync = async <T extends Tree<T>>(
  beforeChildren: (q: T) => Promise<void> | void,
  afterChildren: (q: T) => Promise<void> | void,
  question: T,
): Promise<T> => {
  await beforeChildren(question);
  if (question.children) {
    for (const child of question.children) {
      await processTreeAsync(beforeChildren, afterChildren, child);
    }
  }
  await afterChildren(question);
  return question;
};

export const processTreeTopDownAsync = async <T extends Tree<T>>(
  beforeChildren: (q: T) => Promise<void> | void,
  question: T,
): Promise<T> => processTreeAsync(beforeChildren, () => undefined, question);

export async function processTreeTopDownWithParentMultipleGroupAsync<T extends Tree<T> & Typed>(
  f: (question: T, parentMultipleGroup?: T) => Promise<void>,
  questionTree: T,
) {
  let parentMultipleGroup: T | undefined;
  await processTreeAsync(
    async (question: T) => {
      await f(question, parentMultipleGroup);
      if (isMultipleGroup(question)) {
        parentMultipleGroup = question;
      }
    },
    (question: T) => {
      if (parentMultipleGroup === question) {
        parentMultipleGroup = undefined;
      }
    },
    questionTree,
  );
}
