import globalLogger from "../../logging";
import {autoMutations} from "../utils";
import {findIndex, clone, pathOr, isEmpty, pick} from "ramda";
import moment from "moment";
import {TaskActorType, TaskChangeReason, TaskType, TaskSpecialActor} from "pg-isomorphic/enums/task";
import cloneDeep from "lodash/cloneDeep";
import {State} from "pg-isomorphic/enums/actionplan";
import {TaskEvent} from "pg-isomorphic/enums/events";
import {ConnectionRole} from "pg-isomorphic/enums";

const logger = globalLogger.getLogger("taskDetails");

const initialState = {
  error: false,
  errorMsg: false,
  comments: [],
  hasUserUnreadMessages: false,
  _id: null,
  title: "",
  type: "",
  subtitle: "",
  description: "",
  entity: "",
  entityInfo: {},
  createdAt: null,
  updatedAt: null,
  updatedByDisplayName: "",
  updatedByDisplayDate: null,
  visible: false,
  startWithNew: false,
  loading: false,
  state: null,
  stateReason: null,
  stateReasonMore: null,
  stateDate: null,
  canReassign: null,
  effectiveAssignedUsers: [],
  topicLabel: null,
  currentTab: 1,
  priority: null,
  actionPlan: null,
  changeLogs: [],
  isChild: false,
  isEditing: false,
  onConnection: null,
  startDate: null,
  dueDate: null,
  connection: {},
  actors: [],
  createdByEntity: null,
  owningEntityInfo: {},
  element: {},
  topic: {},
  createdBy: null,
  nextGetGeneratedAssignableUsersMoment: null,
  pertainingToEntity: null,
  loadingUsers: false,
  loadingUsersError: null,
  assignableActionPlans: [],
  actionPlansByTopic: {},
  loadingActionPlans: false,
  loadingActionPlansError: null,
  hideActionPlans: false,
  requireAnswers: false,
  messageCount: 0,
  unreadMessageCount: 0,
  origTaskBeforeEdit: null,
};

const userEditableFields = [
  "title",
  "description",
  "actionPlan",
  "priority",
  "effectiveAssignedUsers",
  "startDate",
  "dueDate",
  "actors",
  "onConnection",
];

const state = clone(initialState);

const filterChanges = (changes) => {
  return changes.filter(
    (t) =>
      t.reason !== TaskChangeReason.TOPIC_ANSWERS &&
      t.reason !== TaskChangeReason.TOPIC_MESSAGE &&
      t.reason !== TaskChangeReason.TOPIC_APPROVAL &&
      t.reason !== TaskChangeReason.COMMENT,
  );
};

// getters
const getters = {
  selectedTask(state) {
    return state._id;
  },
  isCounterparty: (state, getters, rootState) => {
    return pathOr(1, ["user", "activeEntityId"], rootState) !== pathOr(2, ["owningEntityInfo", "id"], state);
  },
  entityConnectionRole: (state) => {
    if (state.connection) {
      if (state.owningEntityInfo.id === state.connection.respondingEntity) {
        return state.connection.respondingRole;
      } else if (state.owningEntityInfo.id === state.connection.requestingEntity) {
        return state.connection.requestingRole;
      }
    }
  },
};

const actions = {
  async initDetails({commit, getters}, {task, isEditing, skipVisible, hideActionPlans, initialTab}) {
    if (!task) {
      logger.warn(() => `no task - full stop`);
      return;
    }
    logger.trace(`initDetails ${task.localizedTitle} => ${task._id}`);
    commit("_id", task._id);
    commit("title", task.localizedTitle);
    commit("subtitle", task.localizedSubtitle);
    commit("comments", task.comments || []);
    commit("description", task.localizedDescription);
    commit("hasUserUnreadMessages", task.hasUserUnreadMessages);
    commit("entityInfo", task.entityInfo);
    commit("updatedByDisplayName", task.updatedByDisplayName);
    commit("updatedByDisplayDate", task.updatedByDisplayDate);
    commit("state", task.state);
    commit("stateReason", task.stateReason);
    commit("stateReasonMore", task.stateReasonMore);
    commit("stateDate", task.stateDate);
    commit("canReassign", task.canReassign);
    commit("type", task.type);
    commit("topicLabel", task.localizedTopicLabel);
    commit("onConnection", task.onConnection || false);
    commit("startDate", task.startDate);
    commit("dueDate", task.dueDate);
    commit("effectiveAssignedUsers", task.effectiveAssignedUsers || []);
    commit("connection", task.connection || {});
    commit("element", task.element || {});
    commit("topic", task.topic || {});
    commit("entity", task.entity);
    commit("priority", task.priority);
    commit("actionPlan", task.actionPlan);
    commit("createdBy", task.createdBy);
    commit("createdByEntity", task.createdByEntity);
    commit("owningEntityInfo", task.owningEntityInfo);
    commit("pertainingToEntity", task.pertainingToEntity);
    commit("changeLogs", filterChanges(task.changeLogs || []));
    commit("requireAnswers", task.data?.requireAnswers || false);
    commit("messageCount", task.messageCount);
    commit("unreadMessageCount", task.unreadMessageCount);

    let actor = task.actors?.[0]?.id;
    if (getters.isCounterparty) {
      actor =
        getters.entityConnectionRole === ConnectionRole.SELLER ? TaskSpecialActor.SUPPLIER : TaskSpecialActor.CUSTOMER;
    } else if (task.actors?.[0]?.type === TaskActorType.ROLE) {
      actor = TaskSpecialActor.TOPIC_OWNER;
    }
    commit("actors", [{id: actor, type: task.actors?.[0]?.type}]);

    commit("isEditing", isEditing || false);
    commit("hideActionPlans", hideActionPlans);

    if (!skipVisible) {
      commit("visible", true);
    }
    commit("currentTab", initialTab || 1);
    commit("startWithNew", false);
    commit("origTaskBeforeEdit", cloneDeep(pick(userEditableFields, state)));
  },
  async initDetailsNewComment({commit, dispatch}, {task, isEditing, skipVisible}) {
    logger.trace(() => `initDetailsNewComment`, task);
    dispatch("initDetails", {task, skipVisible, isEditing});
    commit("startWithNew", true);
    commit("currentTab", 1);
  },
  async initDetailsNewTask(
    {dispatch},
    {owningEntityInfo, entityInfo, element, topic, pertainingToEntity, actionPlan, connection, hideActionPlans},
  ) {
    let task = {
      _id: "new",
      title: "",
      subtitle: "",
      comments: [],
      messageCount: 0,
      unreadMessageCount: 0,
      description: "",
      hasUserUnreadMessages: false,
      state: "",
      stateReason: "",
      stateDate: "",
      canReassign: false,
      type: TaskType.USER_GENERATED,
      topicLabel: "",
      effectiveAssignedUsers: [],
      changeLogs: [],
      actors: [{id: "", type: TaskActorType.USER}],
      entity: "",
      onConnection: true,
      startDate: null,
      endDate: null,
      createdByEntity: "",
      priority: null,
      requireAnswers: false,
      actionPlan,
      element,
      topic,
      entityInfo,
      owningEntityInfo,
      pertainingToEntity,
      connection,
    };
    if (!hideActionPlans) {
      dispatch("getActionPlans", {});
    }
    dispatch("initDetails", {task, isEditing: true, skipVisible: true, hideActionPlans});
  },
  async fakeChangeLog({commit, state, rootState}, {type, taskState, stateReason, assignmentChange}) {
    logger.trace(`fakeChangeLog: ${type}, ${taskState}`, assignmentChange);
    const logs = state.changeLogs;
    const newLog = {
      reason: type,
      assignmentChange,
      state: taskState,
      stateReason,
      date: new Date(),
      changedBy: rootState.user._id,
    };
    logger.trace(`fakeChangeLog newLog`, newLog);
    logs.unshift(newLog);
    commit("changeLogs", logs);
  },
  async resetState({commit}, {isEditing}) {
    const cached = ["nextGetGeneratedAssignableUsersMoment", "assignableActionPlans"];
    for (const stateKey in initialState) {
      if (cached.indexOf(stateKey) === -1) {
        commit(stateKey, initialState[stateKey]);
      }
    }
    commit("isEditing", isEditing);
  },
  async updateState({state, dispatch}, {taskState, stateReason, stateReasonMore}) {
    const updateData = {
      taskId: state._id,
      state: taskState || state.state,
      stateReason: state.stateReason,
      stateReasonMore: state.stateReasonMore,
    };
    await this.httpPost("/api/tasks/update_state", updateData);
    dispatch("fakeChangeLog", {
      type: taskState ? TaskChangeReason.STATE : TaskChangeReason.STATE_REASON,
      taskState,
      stateReason: stateReason ? stateReason : stateReasonMore,
    });
    this.emitter.$emit(TaskEvent.STATE_UPDATE, updateData);
  },
  async updatePriority({commit, state}, {priority}) {
    try {
      await this.httpPatch("/api/tasks/priority", {
        taskId: state._id,
        value: priority,
      });
    } catch (e) {
      commit("errorMsg", e.response.data);
    }
  },
  async addComment({commit, state, dispatch, rootState}, {comment}) {
    const response = await this.httpPost("/api/tasks/create_comment", {
      taskId: state._id,
      note: comment,
    });
    if (response.status === 200) {
      const comments = state.comments;
      const newComment = {
        _id: response.data.commentId,
        note: comment,
        owner: rootState.user._id,
        updatedAt: new Date(),
        ownerInfo: {
          displayName: rootState.user.displayName,
          imageFile: rootState.user.imageFileId,
        },
      };

      logger.trace(() => `addComment: ${JSON.stringify(newComment)}`);
      comments.unshift(newComment);
      commit("comments", clone(comments));
      await dispatch("tasks/updateTaskComments", {taskId: state.taskId, comments}, {root: true});
    }
  },
  async deleteComment({commit, dispatch, state}, {taskId, commentId}) {
    const response = await this.httpPost(`/api/tasks/delete_comment`, {taskId, commentId});
    if (response.status === 204) {
      logger.trace(() => `deleteComment: ${commentId}`);
      const comments = state.comments.filter((n) => n._id !== commentId);
      commit("comments", comments);
      dispatch("tasks/updateTaskComments", {taskId: state.taskId, comments}, {root: true});
    }
  },
  async saveComment({commit, dispatch, state}, {taskId, commentId, comment}) {
    const response = await this.httpPost(`/api/tasks/update_comment`, {
      taskId,
      commentId,
      note: comment,
    });
    if (response.status === 204) {
      const idx = findIndex((n) => n._id === taskId, state.comments);
      if (idx > -1) {
        state.comments[idx].note = comment;
        state.comments[idx].updatedAt = moment().format();
        logger.trace(() => `saveComment: ${state.comments[idx]}`);
        commit("comments", clone(state.comments));
        dispatch("tasks/updateTaskComments", {taskId: state.taskId, comments: clone(state.comments)}, {root: true});
      }
    }
  },
  async assignTask({commit, state}, {actor}) {
    const newAssignee = {
      _id: actor._id,
      email: actor.email,
      displayName: actor.name,
      imageThumbnailUrl: actor.imageFileId ? `/api/files/${actor.imageFileId}?thumbnail=true` : "",
    };
    commit("effectiveAssignedUsers", [newAssignee]);
    try {
      await this.httpPost("/api/tasks/assign", {
        taskId: state._id,
        actors: [{id: actor._id, type: TaskActorType.USER}],
      });
    } catch (e) {
      if (e.response.status === 403) {
        commit("errorMsg", e.response.data);
      }
    }
  },
  async getActionPlans({commit, state}, {}) {
    if (state.loadingActionPlans) {
      logger.warn(() => `getActionPlans aborted because still loading`);
      return;
    }

    try {
      commit("loadingActionPlans", true);
      const connectionIds = state.connection._id;
      if (connectionIds) {
        const response = await this.httpGet("/api/actionplans", {connectionIds});
        commit("assignableActionPlans", response.data.actionPlans);
        commit("actionPlansByTopic", response.data.actionPlanTopics);
      } else {
        logger.warn(() => `no connectionId to load action plans`);
      }
    } catch (e) {
      commit("loadingActionPlansError", e.response.data);
    } finally {
      commit("loadingActionPlans", false);
    }
  },
  async saveUserGeneratedTask({state, commit, rootState}, {instanceId}) {
    logger.trace(() => `saveUserGeneratedTask`, state._id);
    commit("loading", true);
    commit("error", false);

    const user = rootState.user;
    const params = {
      entityId: pathOr(null, ["activeEntityId"], user),
      pertainingToEntity: state.pertainingToEntity,
      actors: Object.values(TaskSpecialActor).some((actor) => actor === state.actors?.[0]?.id)
        ? undefined
        : state.actors,
      title: state.title,
      description: state.description,
      priority: state.priority?.value || state.priority,
      actionPlanId: state.actionPlan,
      assignToSupplier: state.actors?.[0]?.id === TaskSpecialActor.SUPPLIER,
      assignToCustomer: state.actors?.[0]?.id === TaskSpecialActor.CUSTOMER,
      assignToTopicOwner: state.actors?.[0]?.id === TaskSpecialActor.TOPIC_OWNER,
      dueDate: state.dueDate,
      startDate: state.startDate,
      onConnection: state.onConnection,
    };

    try {
      if (state._id === "new") {
        const connectionId = state.connection._id;
        if (!isEmpty(state.element)) {
          // don't bother if it's an actionPlan
          logger.trace(() => `saveUserGeneratedTask connection`, connectionId, state.element.key);
          params.elementId =
            instanceId && state.element._id.indexOf(instanceId) > -1 ? state.element.parent._id : state.element._id;
          params.instanceId = instanceId;
        }
        params.connectionId = connectionId;
        logger.trace(() => `saveUserGeneratedTask saving new`, params);
        const response = await this.httpPost(`/api/tasks/user_generated`, params);
        commit("_id", response.data.task._id);
        commit("state", response.data.task.state);
        commit("startDate", response.data.task.startDate);
        commit("onConnection", response.data.task.onConnection);
        commit("createdByEntity", response.data.task.createdByEntity);
        this.emitter.$emit(TaskEvent.NEW_TASK, response.data.task);
      } else {
        await this.httpPatch(`/api/tasks/user_generated/${state._id}`, params);
        this.emitter.$emit(TaskEvent.TASK_UPDATE, {_id: state._id, ...params});
      }
      commit("origTaskBeforeEdit", cloneDeep(pick(userEditableFields, state)));
    } catch (e) {
      logger.trace(() => `Unable to saveUserGeneratedTask`, e);
      commit("error", true);
    } finally {
      commit("loading", false);
    }
  },
  async deleteUserGeneratedTask({commit, state}, {}) {
    commit("loading", true);
    try {
      await this.httpDelete(`/api/tasks/user_generated`, {taskIds: state._id});
      logger.trace(() => `deleteUserGeneratedTask`, state._id);
      commit("visible", false);
      this.emitter.$emit(TaskEvent.DELETE_TASK, {taskId: state._id});
    } catch (e) {
      commit("error", true);
    } finally {
      commit("loading", false);
    }
  },
  async resetDetailsOnCancel({commit, dispatch, state}, {}) {
    logger.trace(() => `resetDetailsOnCancel`, state._id);
    userEditableFields.forEach((field) => {
      commit(field, state.origTaskBeforeEdit[field]);
    });
  },
};

const mutations = {
  messagesPush: (state, message) => {
    let messages = cloneDeep(state.messages);
    messages.push(message);
    state.messages = messages;
  },
  availableActionPlansPush: (state, actionPlan) => {
    const plans = clone(state.assignableActionPlans);
    plans.push({...actionPlan, state: State.PENDING});
    state.assignableActionPlans = plans;
  },
  ...autoMutations(state),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
