/**
 * This file contains Helper composables to use the vuex store, complete with typing.
 */

import {getStore} from "@/composables/get.store";
import cloneDeep from "lodash/cloneDeep";
import type {BasicEntityInfo} from "pg-isomorphic/api/entity";
import type {BasicUser, EntityAssociationInfo} from "pg-isomorphic/api/user";
import {ConnectionRole, Locale, Subsidiaries} from "pg-isomorphic/enums";
import type {Permission} from "pg-isomorphic/permissions";
import {has, isEmpty, pathOr} from "ramda";
import type {ComputedRef} from "vue";
import {computed} from "vue";
import type {RouteLocationNormalized} from "vue-router";

export interface EnvAuthConfig {
  minPassword: number;
  minScore: number;
}

export interface EnvGmapConfig {
  static: string;
}

export interface TwoFactorConfig {
  allowEmail: boolean;
}

export interface EnvConfig {
  socketEnvPrefix: string;
  env: string;
  auth: EnvAuthConfig; //
  jwtTTL: number;
  gaCode: string;
  ssAuthKey: string;
  gMap: EnvGmapConfig;
  jiraIssueCollectorURL: string;
  environmentName: string;
  twoFactorAuth: TwoFactorConfig;
}

export interface UserWithSets extends Omit<BasicUser, "permissions" | "topicOwnerRoles"> {
  permissions: Set<Permission>;
  topicOwnerRoles: Set<string>;
}

export interface VueAttrs {
  attributes: {[key: string]: string};
  listeners: {[key: string]: Function};
}

export type RouteLite = Omit<RouteLocationNormalized, "matched">;
export type QueryParam = string | Array<string | null> | undefined;

// the vue router doesn't play nice with the composition API, so we store a copy in the store on route change
// `getCurrentRoute()` uses this, so you don't need to use this directly
export function getCurrentRouteFromStore(): RouteLite {
  return getStore().state.currentRoute;
}

export function getPreviousRouteFromStore(): RouteLite | undefined {
  return getStore().state.previousRoute;
}

/**
 * @deprecated -- use getCurrentUser
 */
export function getUserStoreData() {
  return pathOr({}, ["state", "user"], getStore());
}

export function getUserValueSets() {
  return pathOr({}, ["state", "values", "valuesets"], getStore());
}

// it's *roughly* BasicEntityInfo & LeanDocument<EntityModelData>
export function getEntityInfo(): any {
  return getStore().state.entity;
}

export function getUserLocale(): string {
  return pathOr(Locale.EN_US, ["state", "user", "locale"], getStore());
}

export function getUserLanguage(): string {
  const store = getStore();
  return store?.state?.user?.localeSettings?.language || store?.state?.language || Locale.EN_US;
}

export function getUserDateFormat(fallback = "MM/DD/YYYY"): string {
  const store = getStore();
  return (store?.state?.user?.localeSettings?.dateFormat || fallback).replace(/__/g, ".");
}

export function getUserTimeFormat(fallback = "HH:mm"): string {
  const store = getStore();
  return store?.state?.user?.localeSettings?.timeFormat || fallback;
}

export function getUserDateTimeFormat(): string {
  return `${getUserDateFormat()} ${getUserTimeFormat()}`;
}

export function getUserTimestampFormat(): string {
  return `${getUserDateFormat()} HH:mm:ss`;
}

export function getUserTimezone(fallback = "America/Los_Angeles"): string {
  const store = getStore();
  return store?.state?.user?.localeSettings?.timezone || fallback;
}

export function getUserSsoDomain(): string | undefined {
  const domain = pathOr(undefined, ["state", "user", "activeEntityInfo", "ssoDomain"], getStore());
  return domain ? "@" + domain : domain;
}

export function getCurrentUser(): UserWithSets | undefined {
  return pathOr(undefined, ["state", "user"], getStore());
}

export function getCurrentUserId(): string {
  return getCurrentUser()?._id;
}

export function getCurrentUserComputed(): ComputedRef<UserWithSets> | undefined {
  return computed(() => pathOr({}, ["state", "user"], getStore()));
}

export function getUserActiveEntityData(): EntityAssociationInfo | undefined {
  const activeEntityId = getUserActiveEntityId();
  return getCurrentUser().entities.find((ent) => ent.entityId === activeEntityId);
}

export function isFeatureOn(feature): boolean {
  return getStore().state?.user?.activeEntityInfo?.features?.[feature] === true;
}

export function getDefaultConnectionRole(): ConnectionRole.BUYER | ConnectionRole.SELLER | undefined {
  return getUserActiveEntityData()?.defaultConnectionRole as ConnectionRole.BUYER | ConnectionRole.SELLER | undefined;
}

// This may return ConnectionRole.BOTH, so only call this if you can handle that.
export function getActiveConnectionRole(): ConnectionRole | undefined {
  return getUserActiveEntityData()?.activeConnectionRole as
    | ConnectionRole.BUYER
    | ConnectionRole.SELLER
    | ConnectionRole.BOTH
    | undefined;
}

export function getConnectionRole(): ConnectionRole.BUYER | ConnectionRole.SELLER | undefined {
  let connectionRole: ConnectionRole | undefined = getActiveConnectionRole();
  if (!connectionRole || connectionRole === ConnectionRole.BOTH) {
    connectionRole = getDefaultConnectionRole();
  }

  return connectionRole;
}

export function getUserActiveEntityId(): string | undefined {
  return getStore().state?.user?.activeEntityId;
}

export function getUserActiveEntity(): BasicEntityInfo | undefined {
  return getStore().state?.user?.activeEntityInfo;
}

export function getEnvStoreData(): EnvConfig {
  return pathOr({}, ["state", "envConfig"], getStore()) as EnvConfig;
}

export function getParentName(): string {
  return pathOr("", ["profileData", "answers", Subsidiaries.PARENT_NAME], getStore());
}

export function getParentId(): string {
  return pathOr("", ["profileData", "answers", Subsidiaries.PARENT_GRAPHITE_ID], getStore());
}

export function canInviteSeller(): boolean {
  return getStore().getters.canInviteSeller;
}

export function canInviteBuyer(): boolean {
  return getStore().getters.canInviteBuyer;
}

export function canConnect(): boolean {
  return canInviteSeller() || canInviteBuyer();
}

export function globalLoading(): boolean {
  return getStore().getters.loading;
}

// TODO I don't think these are typed anywhere yet...
interface Subsidary {
  [Subsidiaries.SUBSIDIARY_NAME]: string;
  [Subsidiaries.SUBSIDIARY_GRAPHITE_ID]: string;
}

export function getSubsidiaries<T extends Subsidary = Subsidary>(): T[] {
  const subsidiaries = pathOr({}, ["profileData", "answers", Subsidiaries.SUBSIDIARY_LIST], getStore());
  const subs = cloneDeep(subsidiaries);
  if (has("instanceOrder", subs)) {
    delete subs.instanceOrder;
  }
  return Object.values(subs);
}

export function hasInheritance(): boolean {
  const parent = getParentName();
  const subs = getSubsidiaries();
  return !isEmpty(parent) || (subs && subs.length > 0 && !isEmpty(subs[0]));
}

export const getComputedVuexGetSetValue = (propName: string, path: string, defaultValue?) => {
  const resolvePath = (ctx, path) => {
    if (typeof path === "function") {
      return path.call(ctx);
    }
    return path;
  };
  const store = getStore();
  defaultValue = typeof defaultValue !== "undefined" ? defaultValue : {};
  return computed({
    get() {
      if (path) {
        return pathOr(defaultValue, resolvePath(this, path).split("/"), store.state)[propName];
      }
      return store.state[propName];
    },
    set(x) {
      let propPath = propName;
      if (path && resolvePath(this, path).length) {
        propPath = `${resolvePath(this, path)}/${propName}`;
      }
      store.commit(propPath, x);
    },
  });
};

export const getSupportEmail = (): string => {
  return "support@graphiteconnect.com";
};
