<template>
  <GraphiteModal
    ref="dialogRef"
    v-model:visible="showModal"
    contentClass="supplier-survey-modal"
    :showHeader="false"
    :hideFooter="true"
    :noCloseOnEsc="true"
    :noCloseOnBackdrop="true"
  >
    <SupplierSurvey
      @updateValidState="($event) => (isValid = $event)"
      :headerText="options.headerText"
      :headerIcon="options.headerIcon"
      :bodyText="options.bodyText"
      :nextText="options.nextText"
      :nextUrl="options.nextUrl"
      v-model="results"
    />

    <div class="actions">
      <GraphiteButton :disabled="isInFlight || !isValid" @click="modalSubmit()" variant="primary">
        {{ $t(`actions.${options.nextText}`) }}
      </GraphiteButton>
    </div>
  </GraphiteModal>
</template>

<script setup lang="ts">
import type {GraphiteModalExposedMethods} from "@/components/modal/GraphiteModal.vue";
import SupplierSurvey from "@/components/supplier-survey/SupplierSurvey.vue";
import GraphiteModal from "@/components/modal/GraphiteModal.vue";
import {getConnectionIdFromRoute} from "@/composables/connections";
import {translate} from "@/composables/i18n";
import type {SupplierSurveyOptions} from "@/composables/supplierSurvey";
import {submitSupplierSurvey} from "@/composables/supplierSurvey";
import {getToast} from "@/composables/toast";
import {globalEmitter, GlobalEvent} from "@/store/plugins/emitter";
import type {SupplierSurveyResults} from "pg-isomorphic/api/suppliersurvey";
import {onBeforeUnmount, onMounted, ref} from "vue";
import {useRouter} from "vue-router";

const router = useRouter();
const toast = getToast();

const dialogRef = ref<GraphiteModalExposedMethods>();
const results = ref<SupplierSurveyResults>({});
const options = ref<SupplierSurveyOptions>({});
const isValid = ref(true);
const isInFlight = ref(false);
const connectionId = ref();

const showModal = ref(false);

async function modalSubmit() {
  if (isInFlight.value) {
    return;
  }

  try {
    isInFlight.value = true;
    await submitSupplierSurvey({
      ...results.value,
      connectionId: connectionId.value,
    });
    close();
  } finally {
    isInFlight.value = false;
  }
}

function close() {
  if (results.value.rating || results.value.comments?.trim()?.length) {
    toast.add({
      severity: "success",
      summary: translate("supplier_survey.thanks_for_feedback"),
      detail: translate("supplier_survey.response_submitted"),
      life: 5000,
    });
  }

  results.value = {};
  dialogRef.value.close();

  router.push(options.value.nextUrl);
}

onMounted(() => {
  globalEmitter.on(GlobalEvent.OpenSupplierSurvey, openModal);
});

onBeforeUnmount(() => {
  globalEmitter.off(GlobalEvent.OpenSupplierSurvey, openModal);
});

function openModal(opts: SupplierSurveyOptions) {
  options.value = {
    headerText: translate("supplier_survey.you_are_done"),
    headerIcon: ["far", "trophy-alt"],
    bodyText: translate("supplier_survey.body"),
    nextText: "continue",
    nextUrl: "/dash",
    ...opts,
  };

  // preferably pass in connectionId, but fallback to what's in the route
  // (which it's possible due to timing they may have navigated away)
  connectionId.value = opts.connectionId || getConnectionIdFromRoute() || undefined;
  showModal.value = true;
}
</script>

<style lang="less">
// styles for when used in a modal opened via `openSupplierSurvey(...)`
.supplier-survey-modal {
  padding: 0;
}

// NOTE: these styles are global
</style>

<style lang="less" scoped>
@import "@/less/global.less";

.actions {
  padding: 1rem;
  text-align: right;
}
</style>
