<template>
  <Badge :severity="severity" :class="customClass">
    <slot />
  </Badge>
</template>

<script lang="ts" setup>
import {defineProps, computed, toRefs} from "vue";
import type {PropType} from "vue";
import Badge from "primevue/badge";

const props = defineProps({
  variant: {
    type: String as PropType<BadgeVariant>,
    default: "secondary",
  },
});

const {variant} = toRefs(props);

const severity = computed(() => {
  let returnSeverity = undefined;
  switch (variant.value) {
    case BadgeVariant.INFO:
      returnSeverity = "info";
      break;
    case BadgeVariant.DANGER:
    case BadgeVariant.WARNING:
    case BadgeVariant.SUCCESS:
      returnSeverity = variant.value;
      break;
    case BadgeVariant.SECONDARY:
      // Intentionally nothing.
      break;
  }
  return returnSeverity;
});

const customClass = computed(() => {
  if (!variant.value || variant.value === BadgeVariant.SECONDARY) {
    return {"p-badge-secondary": true};
  }

  return {};
});
</script>

<script lang="ts">
export enum BadgeVariant {
  INFO = "info",
  DANGER = "danger",
  WARNING = "warning",
  SUCCESS = "success",
  SECONDARY = "secondary",
}
</script>

<style lang="less" scoped>
@import "@/less/global.less";
</style>
