import {MenuType} from "@/composables/menu/enums";
import {getCurrentUser} from "@/composables/vuex";
import {check, Permission} from "pg-isomorphic/permissions";
import type {RouteRecordRaw} from "vue-router";

export const responderRoute: RouteRecordRaw = {
  path: "/responder",
  component: () => import("@/components/layouts/private.vue"),
  // no_role_modal: true,
  children: [
    {
      path: "",
      component: () => import("@/components/responder/Responder.vue"),
      meta: {
        title: "nav.responder",
        menu_type: MenuType.Responder,
        admin_perms: [Permission.RESPONDER],
      },
    },
    {
      path: "admin/questions",
      component: () => import("@/components/responder/admin/ResponderManager.vue"),
      meta: {
        title: "Responder Admin - Question Manager",
        menu_type: MenuType.Responder,
        admin_perms: [Permission.RESPONDER],
      },
    },
  ],
};

export const responderExcelSsoHandlerRoute = {
  path: "/responder-sso-handler",
  component: () => import("@/components/responder/auth/ResponderSsoHandler.vue"),
  meta: {
    requiresAuth: false,
    title: "graphiteResponder",
    menu_type: MenuType.Setup,
  },
};

export const responderExcelRoute = {
  path: "/:pathMatch(.*)*",
  component: () => import("@/components/layouts/private.vue"),
  no_role_modal: true,
  children: [
    {
      path: "",
      component: () => import("@/components/responder/Responder.vue"),
      meta: {
        title: "nav.responder",
        menu_type: MenuType.ResponderExcel,
      },
      props: {
        isResponder: true,
      },
    },
  ],
};

export const responderReportsRoute = {
  path: "/responder/reports",
  component: () => import("@/components/layouts/private.vue"),
  no_role_modal: true,
  beforeEnter: (to, from, next) => {
    if (!check(Permission.VIEW_RESPONDER_REPORTS, getCurrentUser())) {
      return next("/responder");
    }

    next();
  },
  children: [
    {
      path: "",
      component: () => import("@/components/responder/reports/ResponderReports.vue"),
      meta: {
        title: "Responder Reports",
        menu_type: MenuType.Responder,
        admin_perms: [Permission.RESPONDER],
      },
    },
  ],
};

export const makeResponderSignInRoute = (signInRoute) => {
  return {
    ...signInRoute,
    path: "/responder/signin",
    props: (route) => ({
      ...signInRoute.props(route),
      isResponder: true,
    }),
  };
};

export const responderSignUpRoute = {
  path: "/responder/signup",
  component: () => import("@/components/responder/auth/ResponderSignUp.vue"),
  meta: {
    requiresAuth: false,
    title: "nav.sign_in",
    menu_type: MenuType.Setup,
  },
};
