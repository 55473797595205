import {UserAndEntityIds} from "../api/message";
import {TaskChangeType} from "../enums";
import {KitType} from "../enums/element";
import {MessageObjectType} from "../enums/message";
import {ChangeType, Topic} from "../enums/queue";

function makeKey(items: Array<string | undefined>): string {
  return items.filter((i) => !!i).join(".");
}

let socketEnvPrefix = "";
export function registerSocketEnvPrefix(prefix: string) {
  socketEnvPrefix = prefix;
}

export interface AMQPOption {
  version?: number;
  durable?: boolean;
  maxPriority?: number;
}

export interface MessagesByTypeParams {
  entityId: string;
  objectType: Exclude<MessageObjectType, MessageObjectType.GENERIC>;
  connectionId?: string;
}

export const topicExchangeOptions: Partial<{[topic in Topic]: AMQPOption}> = {
  [Topic.CONNECTIONS]: {durable: true, version: 0},
  [Topic.VERSION]: {durable: false, version: 0},
  [Topic.TASKS]: {durable: false, version: 0},
  [Topic.ANSWERS]: {durable: true, version: 0},
  [Topic.KIT_ANSWERS]: {durable: true, version: 0},
  [Topic.MESSAGES]: {durable: true, version: 0},
  [Topic.MESSAGE_COUNTS]: {durable: true, version: 0},
  [Topic.THREADS]: {durable: true, version: 0},
  [Topic.EMAIL_LOGS]: {durable: true, version: 0},
  [Topic.ACTION_PLAN]: {durable: true, version: 0},
  [Topic.QUESTIONS]: {durable: false, version: 0},
  [Topic.VALUE_SETS]: {durable: false, version: 0},
  [Topic.NOTIFICATIONS]: {durable: false, version: 0},
  [Topic.JOB_PROGRESS]: {durable: false, version: 0},
  [Topic.INFO]: {durable: false, version: 0},
  [Topic.USER_ROLES]: {durable: false, version: 0},
  [Topic.APPROVALS]: {durable: false, version: 0},
  [Topic.VALIDATIONS]: {durable: false, version: 0},
  [Topic.USER_SIGNUP]: {durable: false, version: 0},
};

export const topicName = (name: Topic): string => {
  const version: number = topicExchangeOptions[name]?.version || 0;
  const versionSuffix = version === 0 ? "" : `-${version}`;
  return `${socketEnvPrefix}${name}${versionSuffix}`;
};

export class RoutingKey {
  public static version(): string {
    return topicName(Topic.VERSION);
  }

  public static entityTasks({entityId, evt}: {entityId: string; evt?: string}) {
    return makeKey([topicName(Topic.TASKS), "entity", entityId, evt]);
  }

  public static connectionTasks({connectionId, entityId, evt}: {connectionId: string; entityId: string; evt?: string}) {
    return makeKey([topicName(Topic.TASKS), "connection", connectionId, "entity", entityId, evt]);
  }

  public static kitTasks({
    entityId,
    kitType,
    kitId,
    evt,
  }: {
    entityId: string;
    kitType: string;
    kitId: string;
    evt?: string;
  }) {
    return makeKey([topicName(Topic.TASKS), "kit", entityId, kitType, kitId, evt]);
  }

  public static superAdminTasks({evt}: {evt?: string}) {
    return makeKey([topicName(Topic.TASKS), "superAdmin", evt]);
  }

  public static viewingValidations({evt}: {evt?: string}) {
    return makeKey([topicName(Topic.VALIDATIONS), "viewing", evt]);
  }

  public static connection({connectionId, evt}: {connectionId: string; evt?: string}) {
    return makeKey([topicName(Topic.CONNECTIONS), "connection", connectionId, evt]);
  }

  public static entityConnections({entityId, evt}: {entityId: string; evt?: string}) {
    return makeKey([topicName(Topic.CONNECTIONS), "entity", entityId, evt]);
  }

  public static indexedConnections({
    entityId,
    connectionId,
    evt,
  }: {
    entityId: string;
    connectionId: string;
    evt?: string;
  }) {
    return makeKey([topicName(Topic.CONNECTIONS), "indexed", entityId, connectionId, evt]);
  }

  public static answers({entityId, connectionId}: {entityId: string; connectionId?: string}) {
    return makeKey([topicName(Topic.ANSWERS), entityId, connectionId || "none"]);
  }

  public static kitAnswers({entityId, kitType, kitId}: {entityId: string; kitType: KitType; kitId: string}) {
    return makeKey([topicName(Topic.KIT_ANSWERS), entityId, kitType, kitId]);
  }

  // NOTE: only use `changeType` when listening for events, never to publish (except in the TopicHandler that calls underlying `socket.emit`)
  public static threads({entityId, userId}: UserAndEntityIds, changeType?: ChangeType) {
    // these are specifically changes about threads, like counts or new threads, relevant in the comm center
    return makeKey([topicName(Topic.THREADS), userId, entityId, changeType]);
  }

  // NOTE: only use `changeType` when listening for events, never to publish (except in the TopicHandler that calls underlying `socket.emit`)
  public static emailLogs({entityId}: {entityId?: string}, changeType?: ChangeType) {
    // these are specifically changes about threads, like counts or new threads, relevant in the comm center
    return makeKey([topicName(Topic.EMAIL_LOGS), entityId, changeType]);
  }

  public static messagesByType({entityId, objectType, connectionId}: MessagesByTypeParams, changeType?: ChangeType) {
    return makeKey([topicName(Topic.MESSAGES), entityId, objectType, connectionId, changeType]);
  }

  public static messagesForThread(threadId: string, changeType?: ChangeType) {
    return makeKey([topicName(Topic.MESSAGES), threadId, "BY_THREAD_ID", changeType]);
  }

  public static messageCounts(userId: string, entityId: string, changeType?: ChangeType.UPDATE) {
    return makeKey([topicName(Topic.MESSAGE_COUNTS), `count_tracker`, userId, entityId, changeType]);
  }

  public static entityActionPlan({entityId, evt}: {entityId?: string; evt?: string}) {
    return makeKey([topicName(Topic.ACTION_PLAN), "entity", entityId, evt]);
  }

  public static connectionActionPlan({connectionId, evt}: {connectionId?: string; evt?: string}) {
    return makeKey([topicName(Topic.ACTION_PLAN), "connection", connectionId, evt]);
  }

  public static questions({entityId}: {entityId?: string}) {
    return makeKey([topicName(Topic.QUESTIONS), entityId]);
  }

  public static valueSets({entityId}: {entityId?: string}) {
    return makeKey([topicName(Topic.VALUE_SETS), entityId]);
  }

  public static lists({entityId}: {entityId?: string}) {
    return makeKey([topicName(Topic.VALUE_SETS), entityId]);
  }

  public static userNotifications({userId, entityId, evt}: {userId: string; entityId: string; evt?: string}) {
    return makeKey([topicName(Topic.NOTIFICATIONS), "user", userId, "entity", entityId, evt]);
  }

  public static jobProgress({jobId}: {jobId: string}) {
    return makeKey([topicName(Topic.JOB_PROGRESS), jobId]);
  }

  public static info({userId, entityId}: {userId: string; entityId: string}) {
    return makeKey([topicName(Topic.INFO), "user", userId, "entity", entityId]);
  }

  public static userRoles({entityId}: {entityId?: string}) {
    return makeKey([topicName(Topic.USER_ROLES), entityId]);
  }

  public static roles({roleId}: {roleId?: string}) {
    return makeKey([topicName(Topic.ROLES), roleId]);
  }

  public static entityApproval({entityId, evt}: {entityId: string; evt?: TaskChangeType}) {
    return makeKey([topicName(Topic.APPROVALS), "entity", entityId, evt]);
  }

  public static internalBadges({entityId}: {entityId: string}) {
    return makeKey([topicName(Topic.INTERNAL_BADGES), entityId]);
  }

  public static userSignup({userId}: {userId: string}) {
    return makeKey([topicName(Topic.USER_SIGNUP), userId]);
  }

  public static clientVersion() {
    return makeKey([topicName(Topic.CLIENT_VERSION)]);
  }

  public static logOptions() {
    return makeKey([topicName(Topic.LOG_OPTIONS)]);
  }

  public static roomTopicMatcher() {
    return new RegExp(`^([a-zA-Z0-9-_]+)\.?`);
  }
}
