import globalLogger from "@/logging";
import type {PGActionTree, RootState} from "@/store/index-types";
import {autoMutations} from "@/store/utils";
import type {IdentityVerificationResults} from "pg-isomorphic/api/identityverification";
import type {ListUser} from "pg-isomorphic/api/user";
import type {Commit, GetterTree} from "vuex";
import type {AdminEntityInfo} from "pg-isomorphic/api/entity";

const logger = globalLogger.getLogger("allUsers");

export interface AdminAllUsersState {
  users: ListUser[];
  total: number;
  loading: boolean;
  error: Error | null;
  entities: AdminEntityInfo[];
  user: any;
  identificationAttempts: IdentityVerificationResults;
}

const initialState: AdminAllUsersState = {
  users: [],
  total: 0,
  loading: false,
  error: null,
  entities: [],
  user: null,
  identificationAttempts: null,
};

// const req = actionRequestWrap(logger, "error loading allUsers API");

const actions: PGActionTree<AdminAllUsersState> = {
  async getUsers({commit}, query) {
    logger.trace(() => `getUsers params`, query);

    if (!query.search) {
      commit("users", []);
      return;
    }

    try {
      commit("loading", true);

      const response = await this.httpGet<{total: number; users: ListUser[]}>(`/api/admin/super/users`, query);

      logger.trace(() => `getUsers results`, response.data);

      commit("users", response.data.users);
      commit("total", response.data.total);
    } catch (e) {
      commit("error", {customError: e});
    } finally {
      commit("loading", false);
    }
  },

  async getUserInfo({commit}, query) {
    if (!query.id) {
      commit("error", "No User id");
    }
    try {
      const response = await this.httpGet(`/api/admin/super/users/${query.id}`);
      commit("user", response.data);
      const response2 = await this.httpGet(`/api/admin/super/users/${query.id}/identity-verification`);
      commit("identificationAttempts", response2.data);
      return response.data;
    } catch (e) {
      commit("error", "error loading in user ");
    }
  },

  async reset2fa({commit}, user) {
    try {
      await this.httpPut(`/api/admin/super/reseting2fa`, user);
    } catch (e) {
      commit("error", `error cant reset 2FA`);
    }
  },

  async updateUser({commit}, data) {
    for (let key in data) {
      if (data[key] == null || data[key] == undefined) {
        commit("error", "Updating user to null");
      }
    }
    try {
      return await this.httpPut(`/api/admin/super/users/${data.userId}`, data);
    } catch (e) {
      commit("error", {customError: e});
    }
  },
  async revokeAccess({commit, state}, entityId) {
    const userId = state.user._id;
    try {
      await this.httpDelete<{user: any}>(`/api/admin/super/dissociate/${entityId}/${userId}`, {});
    } catch (e) {
      commit("error", {customError: e});
    }
  },
  async grantAccess({commit, state}, entity) {
    const entityId = entity._id;
    const userId = state.user._id;
    try {
      await this.httpPost<{user: any}>(`/api/admin/super/associate/${entityId}/${userId}`, {});
    } catch (e) {
      commit("error", {customError: e});
    }
  },
  async searchEntities({commit}, search) {
    try {
      const response = await this.httpGet<{entities: AdminEntityInfo[]}>(
        `/api/admin/super/entities?search=${encodeURIComponent(search)}`,
      );
      commit("entities", response.data.entities);
    } catch (e) {
      commit("error", {customError: e});
    }
  },
};

const getters: GetterTree<AdminAllUsersState, RootState> = {};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: {
    ...autoMutations(initialState),
  },
  actions,
  getters,
};
