import {autoMutations} from "../utils";
import {ErrorCode} from "pg-isomorphic/enums/errors";
import cloneDeep from "lodash/cloneDeep";
import {findIndex, propEq, path} from "ramda";
import globalLogger from "../../logging";
import * as moment from "moment-timezone";
import {loadLanguageAsync} from "@/i18n";
import {PhoneNumberType} from "pg-isomorphic/enums/users";
import {removeFromStorage, StorageKey} from "@/composables/local";
import {SsoUserAttribute} from "pg-isomorphic/enums/entity";

const logger = globalLogger.getLogger("userProfile");

const localStore = window.localStorage;

const state = {
  userEntities: [],
  originalUserEntities: [],
  userProfile: {},
  editingEntities: false,
  editingPersonal: false,
  editingLocale: false,
  editingAvatar: false,
  error: null,
  showEntityVisibilitySummary: true,
};

// actions
const actions = {
  async getUserEntities({commit}) {
    try {
      const response = await this.httpGet(`/api/users/current/entities`);
      commit("userEntities", response.data.entities);
      commit("originalUserEntities", cloneDeep(response.data.entities));
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async getSimpleUserInfo({commit}, {userId}) {
    try {
      const response = await this.httpGet(`/api/users/${userId}`);
      return response.data;
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async setActiveEntity({commit}, {entityId}) {
    try {
      const response = await this.httpPut("/api/users/current/active_entity", {
        entityId,
      });
      commit("user", response.data, {root: true});
      this.setJWT(response.data.token, response.data.tokenExpiresOn, false);
      removeFromStorage(`${StorageKey.CONTACT_OPTIONS}_${entityId}`);
      commit("error", null);
      return response.data;
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async sendDigest({commit}, {full} = {}) {
    try {
      await this.httpGet("/api/admin/tools/test/digest", {full});
    } catch (e) {
      commit("error", e.response);
    }
  },
  async resendRequest({commit}, {entityId}) {
    try {
      await this.httpPost(`/api/entities/${entityId}/resend_request`);
    } catch (e) {
      commit("error", e.response);
    }
  },
  async updateEntityAssociationRoles({commit, state}) {
    try {
      const response = await this.httpPut("/api/users/current/entities", {
        entities: state.userEntities,
        tz: moment.tz.guess(),
        locale: window.navigator.userLanguage || window.navigator.language,
      });
      commit("editingEntities", false);
      commit("user", response.data, {root: true});
      commit("error", null);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async updateEntityAssociationRole({dispatch, state}, {entityId, activeConnectionRole, defaultConnectionRole}) {
    let userEntities = cloneDeep(state.userEntities);
    const foundIndex = findIndex(propEq("entityId", entityId), userEntities);
    userEntities[foundIndex].activeConnectionRole = activeConnectionRole;
    userEntities[foundIndex].defaultConnectionRole = defaultConnectionRole;
    state.userEntities = userEntities;
    await dispatch("updateEntityAssociationRoles", {});
  },
  async updateProfile({commit, dispatch, state}) {
    try {
      if (state.userProfile.password !== state.userProfile.password2) {
        commit("error", {
          code: ErrorCode.USER_PASSWORDS_DO_NOT_MATCH,
          status: 400,
        });
        return;
      }
      const response = await this.httpPatch("/api/users/current", {
        name: state.userProfile.name,
        email: state.userProfile.email,
        password: state.userProfile.password,
        locale: state.userProfile.language,
        localeSettings: {
          timezone: state.userProfile.timezone || moment.tz.guess(),
          language: state.userProfile.language || window.navigator.userLanguage || window.navigator.language,
          dateFormat: state.userProfile.dateFormat, // calculate default based on tz in controller
          timeFormat: state.userProfile.timeFormat,
          groupSeparator: state.userProfile.groupSeparator || ",",
          decimalSeparator: state.userProfile.decimalSeparator || ".",
        },
        communicationPreferences: {
          digest: state.userProfile.commDigest,
          messages: state.userProfile.commMessages,
          tasks: state.userProfile.commTasks,
        },
        title: state.userProfile.title,
        country: state.userProfile.country,
        phoneNumber: state.userProfile.phoneNumber,
        fax: state.userProfile.fax,
        ssoAttributes: state.userProfile.ssoAttributes,
      });

      localStore.setItem("language", state.userProfile.language);
      commit("language", state.userProfile.language, {root: true});

      commit("user", response.data, {root: true});
      commit("error", null);
      dispatch("resetProfile");
      loadLanguageAsync(state.userProfile.language);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  async updatePassword({commit, state, dispatch}, {oldPassword}) {
    try {
      if (state.userProfile.password !== state.userProfile.password2) {
        commit("error", {
          code: ErrorCode.USER_PASSWORDS_DO_NOT_MATCH,
          status: 400,
        });
        return;
      }
      logger.debug("update password");
      const response = await this.httpPost("/api/users/change_password", {
        password: state.userProfile.password,
        oldPassword,
      });

      if (response.status >= 400) {
        commit("error", response.data);
      } else {
        commit("error", null);
      }
      await dispatch("completeSignIn", {signInResponse: response.data}, {root: true});
    } catch (e) {
      commit("error", e.response.data);
    }
  },
  resetProfile({commit, rootState}) {
    const localeSettings = path(["user", "localeSettings"], rootState);
    if (!localeSettings) {
      rootState.user.localeSettings = {};
    }

    const commPrefs = path(["user", "communicationPreferences"], rootState);
    if (!commPrefs) {
      rootState.user.communicationPreferences = {};
    }

    commit("userProfile", {
      id: rootState.user._id,
      name: rootState.user.displayName,
      email: rootState.user.email,
      password: "",
      password2: "",
      timezone: rootState.user.localeSettings.timezone || "",
      language: rootState.user.localeSettings.language || "",
      dateFormat: rootState.user.localeSettings.dateFormat || "",
      timeFormat: rootState.user.localeSettings.timeFormat || "",
      groupSeparator: rootState.user.localeSettings.groupSeparator || "",
      decimalSeparator: rootState.user.localeSettings.decimalSeparator || "",
      commDigest: path(["user", "communicationPreferences", "digest"], rootState),
      commMessages: path(["user", "communicationPreferences", "messages"], rootState),
      commTasks: path(["user", "communicationPreferences", "tasks"], rootState),
      twoFactorAuth: rootState.user.twoFactorAuth,
      title: rootState.user.title || "",
      country: rootState.user.country || "",
      phoneNumber: rootState.user.phoneNumber || {
        phoneNumber: "",
        type: PhoneNumberType.MOBILE,
        country: "",
        verified: false,
      },
      fax: rootState.user.fax || {phoneNumber: "", type: PhoneNumberType.FAX, country: "", verified: false},
      ssoAttributes: rootState.user.ssoAttributes || {},
    });
    commit("editingPersonal", false);
    commit("editingLocale", false);
    commit("error", null);
  },
  startEditingEntities({commit, state}) {
    commit("editingEntities", true);
    commit("originalUserEntities", cloneDeep(state.userEntities));
  },
  cancelEditingEntities({commit, state}) {
    commit("editingEntities", false);
    commit("userEntities", state.originalUserEntities);
  },
  async updateAssociationInvite({commit, dispatch}, {association, status}) {
    try {
      await this.httpPut("/api/users/association/status", {
        entityId: association.entityId,
        status,
      });
      await dispatch("getUserEntities");
      commit("error", null);
    } catch (e) {
      commit("error", e.response.data);
    }
  },
};

function hasSSOAttributeMapping(attributeName, ssoSettings) {
  return !!(ssoSettings?.ssoAttributeMapping || []).find((m) => m.attributeName === attributeName);
}

function hasSSOAttributeMappingWithDisplayName(ssoSettings) {
  return !!(ssoSettings?.ssoAttributeMapping || []).some((m) => !!m.displayName);
}

export default {
  namespaced: true,
  state,
  actions,
  getters: {
    getSSOSettings: (_state, _getters, rootState) => {
      return rootState.entity.ssoSettings;
    },
    hasTitleMapping: (_state, getters) => {
      return hasSSOAttributeMapping(SsoUserAttribute.Title, getters.getSSOSettings);
    },
    hasPhoneNumberMapping: (_state, getters) => {
      return hasSSOAttributeMapping(SsoUserAttribute.PhoneNumber, getters.getSSOSettings);
    },
    hasCountryMapping: (_state, getters) => {
      return hasSSOAttributeMapping(SsoUserAttribute.Country, getters.getSSOSettings);
    },
    getSSOAttributeMappingWithDisplayNames: (_state, getters) => {
      return getters.getSSOSettings?.ssoAttributeMapping?.filter((m) => !!m.displayName);
    },
  },
  mutations: {
    ...autoMutations(state),
  },
};
