import cloneDeep from "lodash/cloneDeep";
import isPlainObject from "lodash/isPlainObject";
import set from "lodash/set";
import unset from "lodash/unset";
import {
  ascend,
  assocPath,
  contains,
  curry,
  descend,
  find,
  forEachObjIndexed,
  fromPairs,
  isEmpty,
  KeyValuePair,
  mergeDeepRight,
  mergeDeepWithKey,
  omit,
  pick,
  prop,
  reduce,
  sortWith,
  uniq,
} from "ramda";
import type {AnswerKeys, Answers} from "../api/answers";
import {GroupSortData} from "../api/answers";
import {ConnectionDocument} from "../api/search";
import {Entity, Internal, TopicReviewAnswerKeys} from "../enums";
import {Connection, MagicAnswerKeys} from "../enums/answers";
import {isEmptyOrUndefined, JSONObject, JSONValue} from "../utils";
import {INSTANCE_ID, INSTANCE_ORDER} from "../utils/constants";

export type GroupAnswer = JSONObject & {[INSTANCE_ORDER]: string[]};

export const filteredEach = curry((filterFn: (v, k, obj) => boolean, fn: (v, k, obj) => void, data) => {
  forEachObjIndexed((value, key, obj) => {
    if (filterFn(value, key, obj)) {
      fn(value, key, obj);
    }
  }, data);
});

export const filteredEachAsync = curry(
  async (filterFn: (v, k, obj) => Promise<boolean> | boolean, fn: (v, k, obj) => Promise<void> | boolean, data) => {
    for (const key of Object.keys(data || {})) {
      const value = data[key];
      if (await filterFn(value, key, data)) {
        await fn(value, key, data);
      }
    }
  },
);

export function isGroupAnswer(value: any): value is GroupAnswer {
  return value && typeof value === "object" && !Array.isArray(value) && INSTANCE_ORDER in value;
}

export const isNotGroupAnswer = (value: any) => !isGroupAnswer(value);
export const processGroups = filteredEach(isGroupAnswer);
export const processNonGroups = filteredEach(isNotGroupAnswer);
export const processGroupInstances = filteredEach((_, k) => k !== INSTANCE_ORDER);
export const processUndeletedGroupInstances = filteredEach(
  (inst, k) => k !== INSTANCE_ORDER && !inst?.[Internal.DELETED],
);

export const processGroupsAsync = filteredEachAsync(isGroupAnswer);
export const processNonGroupsAsync = filteredEachAsync(isNotGroupAnswer);
export const processGroupInstancesAsync = filteredEachAsync((_, k) => k !== INSTANCE_ORDER);
export const processUndeletedGroupInstancesAsync = filteredEachAsync(
  (inst, k) => k !== INSTANCE_ORDER && !inst?.[Internal.DELETED],
);

export const processAnswers = curry(
  (filterFn: (v, k, obj) => boolean, fn: (v, k, obj) => void, answers: JSONObject) => {
    const f = filteredEach(filterFn, fn);
    f(answers);
    processGroups((group) => {
      processGroupInstances(f, group);
    }, answers);
  },
);

export async function processAnswersRecursive(
  answers: JSONObject,
  processor: (val: any, key: string, obj: any, instanceId?: string, _groupKey?: string) => Promise<void>,
) {
  const doGroupInstances = async (group: any, groupKey: string) => {
    await processGroupInstancesAsync(async (instance, instanceId) => {
      await processNonGroupsAsync(async (value, key) => {
        await processor(value, key, group[instanceId], instanceId, groupKey);
      }, instance);
      await processGroupsAsync(doGroupInstances, instance);
    }, group);
  };

  await processNonGroupsAsync(async (value, key) => {
    await processor(value, key, answers);
  }, answers);
  await processGroupsAsync(doGroupInstances, answers);
}

export const processTreeCompoundKey = (
  answers: JSONObject,
  cb: (compoundKey: string, value: any, answers: JSONObject) => void,
): void => {
  processNonGroups((value, key) => {
    cb(key, value, answers);
  }, answers);
  processGroups((group, groupKey) => {
    cb([groupKey, INSTANCE_ORDER].join("."), group[INSTANCE_ORDER], answers);
    processGroupInstances((instance, instanceId) => {
      if (!instance[MagicAnswerKeys.FIRST]) {
        cb([groupKey, instanceId, MagicAnswerKeys.FIRST].join("."), "n", answers);
      }
      processNonGroups((value, key) => {
        const compoundKey = [groupKey, instanceId, key].join(".");
        cb(compoundKey, value, answers);
      }, instance);
    }, group);
  }, answers);
};

export const processAnswersAsync = curry(
  async (
    filterFn: (v, k, obj, instanceId) => Promise<boolean> | boolean,
    fn: (v, k, obj, instanceId) => Promise<void>,
    answers: JSONObject,
  ) => {
    await filteredEachAsync(
      (v, k, obj) => filterFn(v, k, obj, undefined),
      (v, k, obj) => fn(v, k, obj, undefined),
      answers,
    );
    await processGroupsAsync(async (group) => {
      await processGroupInstancesAsync(async (groupInstance, instanceId) => {
        await filteredEachAsync(
          (v, k, obj) => filterFn(v, k, obj, instanceId),
          (v, k, obj) => fn(v, k, obj, instanceId),
          groupInstance,
        );
      }, group);
    }, answers);
  },
);

const filterByKeys = (theseKeys: string[]) => (_, k) => contains(k, theseKeys);

export const processAnswersByKeys = curry((fn: (v, k, obj) => any, theseKeys: string[], answers: JSONObject) =>
  processAnswers(filterByKeys(theseKeys), fn, answers),
);

export const groupKeys = (doc: JSONObject) => {
  return Object.keys(doc).filter((k) => isGroupAnswer(doc[k]));
};

// TODO: Can we merge these in a way that preserves the order of both, at least to the degree that the orderings are compatible
export const mergeInstanceOrders = (a: string[], b: string[]) => a.concat(b.filter((i) => !contains(i, a)));

function mergeGroupAnswers(
  profileAnswer: GroupAnswer,
  connectionAnswer: GroupAnswer,
  profileGroup: boolean,
): GroupAnswer {
  if (profileGroup) {
    if (!profileAnswer) {
      return {[INSTANCE_ORDER]: []};
    } else if (!connectionAnswer) {
      return cloneDeep(profileAnswer);
    }
    if (!profileAnswer[INSTANCE_ORDER]) {
      profileAnswer[INSTANCE_ORDER] = [];
    }
    const result: GroupAnswer = {[INSTANCE_ORDER]: [...profileAnswer[INSTANCE_ORDER]]};
    for (const instanceId of result[INSTANCE_ORDER]) {
      result[instanceId] = mergeDeepRight(profileAnswer[instanceId], connectionAnswer[instanceId] || {});
    }
    return result;
  } else {
    return mergeDeepWithKey<GroupAnswer, GroupAnswer>(
      (key, a, b) => {
        return key === INSTANCE_ORDER ? mergeInstanceOrders(a, b) : b;
      },
      profileAnswer || ({} as GroupAnswer),
      connectionAnswer || ({} as GroupAnswer),
    );
  }
}

export const mergeAnswers = (
  profileAnswers: JSONObject,
  connectionAnswers: JSONObject,
  profileGroups: string[] = [],
): JSONObject => {
  const result = cloneDeep(profileAnswers);
  forEachObjIndexed((value: JSONValue, key: string | number) => {
    // hack to work around broken history records from __Validation_Status updates that don't include an instanceOrder:
    // Check both answers and if either looks like a multiple group, merge them as multiple groups.
    if (isGroupAnswer(value) || isGroupAnswer(profileAnswers[key])) {
      result[key] = mergeGroupAnswers(
        result[key] as GroupAnswer,
        connectionAnswers[key] as GroupAnswer,
        contains(key, profileGroups),
      );
    } else {
      result[key] = cloneDeep(value);
    }
  }, connectionAnswers);
  return result;
};

// Applies changes in dotted key form to an answer doc
export function applyAnswers(answers: JSONObject, changes: JSONObject) {
  let result = answers;
  for (const key of Object.keys(changes)) {
    const splitKey = key.split(".");
    const value = changes[key];
    result = assocPath(splitKey, value, result);
  }
  return result;
}

export const removeGroupInstances = (
  filterFn: (instance, instanceId, a) => boolean,
  groupAnswer: GroupAnswer,
  removeEmtpyFromInstanceOrder: boolean = true,
) => {
  processGroupInstances((instance, instanceId) => {
    if (filterFn(instance, instanceId, groupAnswer)) {
      delete groupAnswer[instanceId];
    }
  }, groupAnswer);
  if (removeEmtpyFromInstanceOrder) {
    groupAnswer[INSTANCE_ORDER] = groupAnswer[INSTANCE_ORDER].filter((instanceId) =>
      groupAnswer.hasOwnProperty(instanceId),
    );
  }
};

export const isInheritedInstance = (instance: JSONObject) => instance[TopicReviewAnswerKeys.INHERITED] === "y";
export function withoutInherited(answers: JSONObject): JSONObject {
  const filtered = cloneDeep(answers);
  processGroups((groupAnswers) => {
    removeGroupInstances(isInheritedInstance, groupAnswers);
  }, filtered);
  return filtered;
}

export interface NewKeys {
  [groupKey: string]: {[clientKey: string]: string};
}

export interface AnswerChanges {
  [key: string]:
    | true
    | {
        [instanceId: string]: {
          [subKey: string]: true;
        };
      };
}

export function translateNewGroupReferences(
  groupReferenceAnswerKeys: {[answerKey: string]: string} | undefined,
  newKeys: NewKeys,
  answers: JSONObject,
) {
  processAnswersByKeys(
    (v: JSONValue, k: string, obj: JSONObject) => {
      const groupKey: string = groupReferenceAnswerKeys![k];
      const translate = (value: string) => (newKeys[groupKey] && newKeys[groupKey][value]) || value;
      if (typeof v === "string") {
        obj[k] = translate(v);
      } else if (Array.isArray(v)) {
        obj[k] = (v as string[]).map(translate);
      }
    },
    Object.keys(groupReferenceAnswerKeys || {}),
    answers,
  );
}

export function answerKeysToDottedKeys(answerKeys: AnswerKeys): string[] {
  const result: string[] = [];
  for (const key of Object.keys(answerKeys)) {
    result.push(key);
    if (typeof answerKeys[key] !== "object") {
      continue;
    }

    for (const subKey of Object.keys(answerKeys[key])) {
      result.push(`${key}.${subKey}`);
    }
  }
  return result;
}

export function answerChangesToAnswerKeys(answerChanges: AnswerChanges): AnswerKeys {
  const result: AnswerKeys = {};
  for (const k of Object.keys(answerChanges || {})) {
    if (answerChanges[k] === true) {
      result[k] = true;
    } else {
      result[k] = Object.assign({}, ...Object.values(answerChanges[k]));
    }
  }
  return result;
}

export function historyQueryGroupKey(answerKeys: AnswerKeys): string | undefined {
  const groupKey = find((k) => typeof answerKeys[k] === "object", Object.keys(answerKeys));
  if (groupKey) {
    if (Object.keys(answerKeys).length > 1) {
      throw new Error("Can only fetch history for one group at a time");
    }
  }
  return groupKey;
}

export function intersectAnswerKeys(keys1: AnswerKeys, keys2: AnswerKeys): AnswerKeys {
  const result: AnswerKeys = {};
  for (const key of Object.keys(keys1)) {
    if (keys1[key] === true && (keys2[key] === true || typeof keys2[key] === "object")) {
      result[key] = keys2[key];
    } else if (keys2[key] === true && typeof keys1[key] === "object") {
      result[key] = keys1[key];
    } else if (typeof keys1[key] === "object" && typeof keys2[key] === "object") {
      result[key] = intersectAnswerKeys(keys1[key] as AnswerKeys, keys2[key] as AnswerKeys) as {[key: string]: true};
      if (isEmpty(result[key])) {
        delete result[key];
      }
    }
  }
  return result;
}

export function differenceAnswerKeys(left: AnswerKeys, right: AnswerKeys): AnswerKeys {
  const result: AnswerKeys = {};
  for (const key of Object.keys(left || {}) || []) {
    result[key] = left[key];
    if ((left[key] === true || typeof left[key] === "object") && right[key] === true) {
      delete result[key];
    } else if (typeof left[key] === "object" && typeof right[key] === "object") {
      result[key] = differenceAnswerKeys(left[key] as AnswerKeys, right[key] as AnswerKeys) as {[key: string]: true};
      if (isEmpty(result[key])) {
        delete result[key];
      }
    }
  }
  return result;
}

export function buildAnswerKeys(answerKeys: string[], groupKey?: string): AnswerKeys {
  const result: AnswerKeys = {};
  for (const answerKey of answerKeys) {
    const split = answerKey.split(".");
    if (split.length <= 2) {
      result[split[0]] = true;
      if (groupKey && result[groupKey] !== true) {
        set(result, [groupKey, split[0]], true);
      }
    } else if (result[split[0]] !== true) {
      set(result, [split[0], ...split.slice(2)], true);
    }
  }
  return result;
}

export const unionAnswerKeys = (...keys: AnswerKeys[]) => reduce(mergeDeepRight, {} as object, keys) as AnswerKeys;

function filterGroup(answerKeys: AnswerKeys, group: JSONObject) {
  const groupProjection = pick(Object.keys(answerKeys));
  processGroupInstances((instance, instanceId) => {
    group[instanceId] = instance && groupProjection(instance);
  }, group);
}

export function projectAnswers(answerKeys: AnswerKeys, answers: Answers): Answers {
  const projection = cloneDeep(answers);
  forEachObjIndexed((_, k, obj) => {
    if (!answerKeys[k] && k !== Entity.Id && k !== Connection.Id) {
      delete obj[k];
    }
  }, projection);

  // Giant hack: If we're looking to project a multiple group and the answer record is broken and lacking an instance order,
  // just invent one out of the instance keys, because otherwise processGroups is never even going to look at the instances
  const instanceOrdersToThrowAway: Array<string | number> = [];
  forEachObjIndexed((_, k) => {
    if (typeof answerKeys[k] === "object" && projection[k] && !projection[k][INSTANCE_ORDER]) {
      projection[k][INSTANCE_ORDER] = Object.keys(projection[k]);
      instanceOrdersToThrowAway.push(k);
    }
  }, projection);

  processGroups((group, groupKey) => filterGroup(answerKeys[groupKey] as AnswerKeys, group), projection);

  // But then we'll throw it away again so we don't muck up anything else
  for (const k of instanceOrdersToThrowAway) {
    delete projection[k][INSTANCE_ORDER];
  }
  return projection;
}

// Easing the transition while we're not using AnswerKeys everywhere. This should go away.
export function flattenAnswerKeys(keys: AnswerKeys): string[] {
  const result = Object.keys(keys);
  for (const key of Object.keys(keys)) {
    if (typeof keys[key] === "object") {
      result.push(...Object.keys(keys[key]));
    }
  }
  return uniq(result);
}

// This is super bad when used with groupKey, but really no worse than we were before. Seek to eradicate the need for this.
export function unflattenAnswerKeys(keys: string[], groupKey?: string): AnswerKeys {
  let result: AnswerKeys = fromPairs(keys.map<KeyValuePair<string, true>>((key) => [key, true]));
  if (groupKey) {
    result = {...result, [groupKey]: result as {[key: string]: true}};
  }
  return result;
}

export function toAnswerKeys(answers: JSONObject): AnswerKeys {
  const result = unflattenAnswerKeys(Object.keys(answers));
  processGroups((group, groupKey) => {
    result[groupKey] = {};
    processGroupInstances((instance) => {
      Object.assign(result[groupKey], unflattenAnswerKeys(Object.keys(instance || {})));
    }, group);
  }, answers);
  return result;
}

export function toAnswerChanges(answers: JSONObject): AnswerChanges {
  const result: AnswerChanges = {};
  processNonGroups((_, k) => {
    result[k] = true;
  }, answers);
  processGroups((group, groupKey) => {
    result[groupKey] = {};
    processGroupInstances((instance, instanceId) => {
      result[groupKey][instanceId] = {};
      for (const subkey of Object.keys(instance || {})) {
        result[groupKey][instanceId][subkey] = true;
      }
    }, group);
  }, answers);
  return result;
}

const companyUpdateDateBlackList: string[] = [
  Internal.VERIFIED_BY,
  Internal.VERIFY_DATE,
  Internal.VALIDATION_STATUS,
  Internal.VALIDATION_NOTE,
  Internal.VALIDATED_BY,
  Internal.VALIDATED_DATE,
  Internal.COMPANY_UPDATE_DATE,
  Internal.NON_GRAPHITE_NETWORK,
  "connectionId",
  "updatedBy",
];

export function isValidCompanyUpdate(answers: JSONObject & {updatedBy: string}): boolean {
  let companyUpdate: boolean = false;
  processNonGroups(
    (_, k) => (companyUpdate = companyUpdate || !companyUpdateDateBlackList.find((bl) => k.includes(bl))),
    answers,
  );
  if (companyUpdate) {
    return true;
  }

  processGroups((groupAnswer) => {
    processGroupInstances((v) => {
      companyUpdate = companyUpdate || v === null;
      if (!v) {
        return;
      }
      processNonGroups(
        (_, k) => (companyUpdate = companyUpdate || !companyUpdateDateBlackList.find((bl) => k.includes(bl))),
        v,
      );
    }, groupAnswer);
  }, answers);
  return companyUpdate;
}

export function groupObjectToArray(obj: GroupAnswer): Array<JSONObject & {[INSTANCE_ID]: string}> {
  return obj[INSTANCE_ORDER]
    ? obj[INSTANCE_ORDER].map((instanceId) => ({...(obj[instanceId] as JSONObject), [INSTANCE_ID]: instanceId}))
    : [];
}

export function groupArrayToObject(arr: Array<JSONObject & {[INSTANCE_ID]: string}>): GroupAnswer {
  const obj: GroupAnswer = {[INSTANCE_ORDER]: []};
  for (const inst of arr) {
    const instanceId = inst[INSTANCE_ID];
    obj[INSTANCE_ORDER].push(instanceId);
    obj[instanceId] = omit([INSTANCE_ID], inst);
  }
  return obj;
}

function isGroupArray(value: string | undefined | (string & any[]) | (undefined & any[])) {
  return Array.isArray(value) && value.length > 0 && value[0] && typeof value[0] === "object" && value[0][INSTANCE_ID];
}

export function groupAnswersArraysToObjects(answers: Answers): Answers;
export function groupAnswersArraysToObjects(answers: JSONObject): JSONObject;
export function groupAnswersArraysToObjects(answers: JSONObject): JSONObject {
  filteredEach(
    isGroupArray,
    (v: Array<JSONObject & {[INSTANCE_ID]: string}>, k: string) => {
      answers[k] = groupArrayToObject(v);
    },
    answers,
  );
  return answers;
}

export function groupAnswersObjectsToArrays(answers: Answers): Answers;
export function groupAnswersObjectsToArrays(answers: JSONObject): JSONObject;
export function groupAnswersObjectsToArrays(answers: JSONObject | Answers): JSONObject {
  processGroups((v: GroupAnswer, k: string) => {
    answers[k] = groupObjectToArray(v);
  }, answers);
  return answers;
}

// recursively omits entries in `answers` that exist in `toRemove`
// mutates answers
export function subtractAnswers(answers: JSONObject, toRemove: JSONObject) {
  processTreeCompoundKey(toRemove, (compoundKey) => {
    unset(answers, compoundKey);
  });

  return answers;
}

export function sortGroups(
  groupSortKeyMap: {[groupAnswerKey: string]: GroupSortData[]} | undefined,
  answers: JSONObject | ConnectionDocument,
) {
  for (const groupKey of Object.keys(groupSortKeyMap || {})) {
    if (answers[groupKey] && !isEmptyOrUndefined(answers[groupKey])) {
      const sorts = groupSortKeyMap![groupKey].map((sd) => (sd.ascend ? ascend(prop(sd.key)) : descend(prop(sd.key))));
      const anySort = sortWith(sorts);
      Object.assign(answers, {[groupKey]: anySort(answers[groupKey]! as JSONObject[])});
    }
  }
  return answers;
}

export function pruneHydratedAnswerFields(answers: any) {
  if (answers?.[Internal.HYDRATED_ANSWER_KEY]) {
    delete answers[Internal.HYDRATED_ANSWER_KEY];
  }

  if (Array.isArray(answers)) {
    answers.forEach((ans: any) => {
      pruneHydratedAnswerFields(ans);
    });
  } else if (isPlainObject(answers)) {
    for (const key in answers) {
      if (Object.prototype.hasOwnProperty.call(answers, key)) {
        pruneHydratedAnswerFields(answers[key]);
      }
    }
  }
}
