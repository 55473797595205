import type {PGActionTree, PGGetterTree} from "./../../index-types";
import httpPlugin from "../../plugins/http";
import {autoMutations} from "../../utils";
import {clone, path, pathOr} from "ramda";
import {ThirdPartyData, Entity as Ent, RapidRatings} from "pg-isomorphic/enums/answers";
import {EntityFeature} from "pg-isomorphic/enums/entity";
import type {Answers} from "pg-isomorphic/api/connection";
import {getCurrentUser} from "@/composables/vuex";

const plugins = [httpPlugin];

export interface RapidRatingsState {
  answers: Answers;
  error: string;
  loading: boolean;
  searchResults: any[];
  lastRefresh: Date;
  outreach: {
    counterparty: {
      company_name: string;
      reference_id: string;
      contact: {
        first_name: string;
        last_name: string;
        country: string;
        email: string;
        tel: string;
      };
    };
    relationship_manager: {
      email: string;
      name: string;
    };
  };
}

const initialState: RapidRatingsState = {
  answers: {},
  error: null,
  loading: false,
  searchResults: [],
  lastRefresh: new Date(0),
  outreach: {
    counterparty: {
      company_name: "",
      reference_id: "",
      contact: {
        first_name: "",
        last_name: "",
        country: "",
        email: "",
        tel: "",
      },
    },
    relationship_manager: {
      email: "",
      name: "",
    },
  },
};

const REFRESH_WAIT = 1000 * 60 * 60;

const getters: PGGetterTree<RapidRatingsState> = {
  haveRapidRatingsFeature() {
    return pathOr(false, ["activeEntityInfo", "features", EntityFeature.RAPID_RATINGS], getCurrentUser());
  },
  rapidRatingsData(state) {
    return state.answers?.[ThirdPartyData.RapidRatingsData];
  },
  outreachData(state) {
    return pathOr(undefined, [ThirdPartyData.RapidRatingsOutreach], state.answers?.[ThirdPartyData.RapidRatingsData]);
  },
  entityName(state): string {
    return state.answers?.[Ent.Name];
  },
  entityId(state): string {
    return pathOr(undefined, ["entityId"], state.answers);
  },
  connectionId(state): string {
    return pathOr(undefined, ["connectionId"], state.answers);
  },
  haveRapidRatingsConnection(_state, getters): boolean {
    return !!pathOr(false, ["id"], getters.rapidRatingsData);
  },
  haveRapidRatingsData(_state, getters): boolean {
    return !!pathOr(false, ["name"], getters.rapidRatingsData);
  },
  outreachRequestId(_state, getters) {
    return pathOr(false, ["outreach_request_id"], getters.outreachData);
  },
  haveOutreachRequest(_state, getters) {
    return !!getters.outreachRequestId;
  },
  outreachDate(_state, getters): string {
    return pathOr(undefined, ["modified_at"], getters.outreachData);
  },
  outreachStatus(_state, getters) {
    return pathOr(undefined, ["status"], getters.outreachData);
  },
  latestRating(_state, getters) {
    return pathOr(undefined, ["latestRatings", 0], getters.rapidRatingsData);
  },
  fhr(_state, getters) {
    return pathOr(0, ["fhr"], getters.latestRating);
  },
  delta(_state, getters) {
    return pathOr(0, ["delta"], getters.latestRating);
  },
  reportingPeriod(_state, getters) {
    return pathOr(undefined, ["reportingPeriod"], getters.latestRating);
  },
  period(_state, getters) {
    return pathOr(undefined, ["period"], getters.latestRating);
  },
  eqyYear(_state, getters) {
    return pathOr(undefined, ["eqyYear"], getters.latestRating);
  },
  pdfReportLink(_state, getters) {
    return pathOr(undefined, ["reportLinks", "fhr"], getters.rapidRatingsData);
  },
  availableReportTypes(_state, getters) {
    return pathOr([], ["reports"], getters.rapidRatingsData).map((r) => r.reportType);
  },
  portalUrl(_state, _getters, rootState): string {
    return rootState.envConfig.rapidRatingsPortal;
  },
  href(_state, getters): string {
    const pdf = getters.pdfReportLink;
    const match = /reportsapi\/(.*)\/fhr/.exec(pdf);
    if (match && match[1]) {
      return `${getters.portalUrl}/company-profile/${match[1]}`;
    }
    return pdf;
  },
  outreachHref(_state, getters) {
    return `${getters.portalUrl}/network/program-management/?requestId=${getters.outreachRequestId}`;
  },
  reviewConsent(state) {
    return state.answers?.[Ent.FinancialReviewConsent] !== "n";
  },
};

const actions: PGActionTree<RapidRatingsState> = {
  async init({commit}, answers) {
    commit("answers", answers);
  },
  async clear({commit}) {
    Object.keys(initialState).forEach((key) => commit(key, clone(initialState[key])));
  },
  async refresh({state, commit}, connectionId) {
    // @ts-ignore
    if (new Date().valueOf() - state.lastRefresh < REFRESH_WAIT) {
      return;
    }
    commit("loading", true);
    try {
      if (connectionId) {
        const response = await this.httpPost(`/api/rapidratings/${connectionId}`, {});
        commit("lastRefresh", new Date());
        return response.data;
      }
    } catch (e) {
      commit("error", path(["response", "data"], e));
    } finally {
      commit("loading", false);
    }
  },
  async linkRapidRatingsId({commit, getters}, {rapidRatingsId}) {
    commit("loading", true);
    try {
      if (!getters.connectionId) {
        return;
      }
      await this.httpPatch("/api/answers", {
        connectionId: getters.connectionId,
        pertainingToEntityId: getters.entityId,
        [ThirdPartyData.RapidRatingsData]: {
          id: rapidRatingsId,
        },
      });
    } catch (e) {
      commit("error", path(["response", "data"], e));
    } finally {
      commit("loading", false);
    }
  },
  async search({commit, getters}, {searchString}) {
    commit("loading", true);
    try {
      const response = await this.httpGet(
        `/api/rapidratings/search?searchString=${decodeURIComponent(searchString)}&connectionId=${
          getters.connectionId
        }`,
      );
      // @ts-ignore I don't know what this contract looks like
      commit("searchResults", response.data.results);
    } catch (e) {
      commit("error", path(["response", "data"], e));
    } finally {
      commit("loading", false);
    }
  },
  resetOutreachFromRequest({commit, getters, dispatch}) {
    dispatch("resetOutreach");
    const fresh = clone(initialState.outreach);
    const data = getters.outreachData;
    fresh.counterparty.company_name = pathOr(fresh.counterparty.company_name, ["counterparty", "company_name"], data);
    fresh.counterparty.contact.first_name = pathOr(
      fresh.counterparty.contact.first_name,
      ["counterparty", "contact", "first_name"],
      data,
    );
    fresh.counterparty.contact.last_name = pathOr(
      fresh.counterparty.contact.last_name,
      ["counterparty", "contact", "last_name"],
      data,
    );
    fresh.counterparty.contact.email = pathOr(
      fresh.counterparty.contact.email,
      ["counterparty", "contact", "email"],
      data,
    );
    fresh.counterparty.contact.tel = pathOr(fresh.counterparty.contact.tel, ["counterparty", "contact", "tel"], data);
    fresh.counterparty.contact.country = pathOr(
      fresh.counterparty.contact.country,
      ["counterparty", "contact", "country"],
      data,
    );
    fresh.relationship_manager.name = pathOr(fresh.relationship_manager.name, ["relationship_manager", "name"], data);
    fresh.relationship_manager.email = pathOr(
      fresh.relationship_manager.email,
      ["relationship_manager", "email"],
      data,
    );
    commit("outreach", fresh);
  },
  resetOutreach({state, commit, getters}) {
    const fresh = clone(initialState.outreach);
    const answers = state.answers;

    const publicId = this.getUserLocalizedText(pathOr("", [Ent.PublicId], answers));
    fresh.counterparty.company_name = this.getUserLocalizedText(pathOr("", [Ent.Name], answers));
    fresh.counterparty.reference_id = `${publicId}-${getters.connectionId}`;
    const finName = pathOr("", [Ent.FinancialReviewConsentName], answers);
    fresh.counterparty.contact.first_name = finName.substr(0, finName.indexOf(" "));
    fresh.counterparty.contact.last_name = finName.substr(finName.indexOf(" "));
    fresh.counterparty.contact.email = pathOr("", [Ent.FinancialReviewConsentEmail], answers);
    fresh.counterparty.contact.tel = pathOr("", [Ent.FinancialReviewConsentPhone], answers);
    fresh.counterparty.contact.country = pathOr("", [Ent.Country], answers);
    fresh.relationship_manager.name =
      pathOr("", [RapidRatings.RelationshipOwnerName], answers) ||
      pathOr("", ["ADBE_Overview_Primary_Business_Owner_Name"], answers);
    fresh.relationship_manager.email =
      pathOr("", [RapidRatings.RelationshipOwnerEmail], answers) ||
      pathOr("", ["ADBE_Overview_Primary_Business_Owner_Email"], answers);

    commit("outreach", fresh);
  },
  async sendOutreach({commit, getters, state}) {
    commit("loading", true);
    try {
      const response = await this.httpPost(`/api/rapidratings/outreach/${getters.connectionId}`, state.outreach);
      return response.data;
    } catch (e) {
      commit("error", path(["response", "data"], e));
    } finally {
      commit("loading", false);
    }
  },
};

export default {
  namespaced: true,
  state: clone(initialState),
  getters,
  actions,
  mutations: {
    ...autoMutations(initialState),
  },
  plugins,
};
