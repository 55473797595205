<template><div></div></template>

<script lang="ts" setup>
import {injectStrict} from "@/composables/provideInject";
import {CspNonceKey} from "@/composables/root";
import {defineProps, onMounted, toRefs, watch} from "vue";

const props = defineProps({
  zendeskKey: {
    type: String,
  },
});

const {zendeskKey} = toRefs(props);

const cspNonce = injectStrict(CspNonceKey);
let created = false;
const createScript = () => {
  let zds = document.createElement("script");
  zds.setAttribute("id", "ze-snippet");
  zds.setAttribute("src", `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey.value}`);
  zds.setAttribute("nonce", cspNonce);
  document.head.append(zds);
};

onMounted(() => {
  if (zendeskKey.value) {
    created = true;
    createScript();
  }
});

defineOptions({
  compatConfig: {
    MODE: 3,
  },
});
</script>
