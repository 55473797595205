import type {PGActionTree} from "@/store/index-types";
import {autoMutations} from "@/store/utils";
import cloneDeep from "lodash/cloneDeep";
import type {LoadCommand, LoaderHistoryApiResponse, LoaderHistoryItem} from "pg-isomorphic/api/loader";
import {LoaderResults} from "pg-isomorphic/api/loader";
import {LoaderStatus} from "pg-isomorphic/enums/loader";
import {identity} from "ramda";

export interface LoaderState {
  item: LoaderHistoryItem | null;
  history: LoaderHistoryItem[];
  historyLoading: boolean;
}

const initialState: LoaderState = {
  item: null,
  history: [],
  historyLoading: false,
};

const actions: PGActionTree<LoaderState> = {
  startUpload({commit}, {jobId, command}: {jobId: string; command: LoadCommand}) {
    commit(
      "item",
      identity<LoaderHistoryItem>({
        jobId,
        status: LoaderStatus.UPLOADING,
        results: new LoaderResults(),
        command,
        _id: "",
        createdAt: String(new Date()),
        updatedAt: String(new Date()),
        user: {
          email: "",
        },
      }),
    );
  },
  async refreshHistory({state, commit}, filter) {
    commit("historyLoading", true);
    const response = await this.httpGet<LoaderHistoryApiResponse>(
      `/api/admin/tools/questions/history?${new URLSearchParams({filter})}`,
    );
    commit("history", response.data.history);
    commit("historyLoading", false);
  },
  async loadMoreHistoryResults({state, commit}, filter) {
    commit("historyLoading", true);
    const response = await this.httpGet<LoaderHistoryApiResponse>(
      `/api/admin/tools/questions/history?${new URLSearchParams({filter, offset: String(state.history.length)})}`,
    );
    const completeList = state.history.concat(response.data.history);
    commit("history", completeList);
    commit("historyLoading", false);
  },
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: autoMutations(initialState),
  actions,
};
