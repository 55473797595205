<template>
  <Paginator
    v-bind="$attrs"
    v-model:first="currentFirst"
    :totalRecords="totalRows"
    :rows="perPage"
    class="graphite-paginator"
    :aria-label="ariaLabel || $t('prime.aria.paginator')"
  />
</template>

<script lang="ts" setup>
import Paginator from "primevue/paginator";
import {computed, ref, watch} from "vue";

const props = defineProps<{
  totalRows: number;
  perPage: number;
  modelValue: number;
  ariaLabel?: string;
}>();

const emit = defineEmits<{
  (event: "update:modelValue", num: number): void;
  (event: "firstChange", num: number): void;
  /** @deprecated kept for backward compatibility, but prefer using `update:modelValue` */
  (event: "change", num: number): void;
}>();

const currentPage = ref<number>(props.modelValue);
const currentFirst = computed<number>({
  set(val: number) {
    const newVal = val / props.perPage + 1;

    if (currentPage.value === newVal) {
      return;
    }

    currentPage.value = newVal;
  },
  get(): number {
    return props.modelValue * props.perPage - 1;
  },
});

watch(currentPage, (newVal: number) => {
  emit("update:modelValue", newVal);
  // if you care about which item to start at without having to do per page calculations
  emit("firstChange", currentFirst.value);
  // for backward compatibility
  emit("change", newVal);
});
</script>
