import {getRouter} from "@/router/util";
import {BroadcastChannel} from "broadcast-channel";
import {getFreeTextAnswer} from "pg-isomorphic/answers/values";
import {isEmpty, path, pathOr, prop, filter, propEq, findIndex, reject, isNil, dissoc} from "ramda";
import {createStore} from "vuex";
import cloneDeep from "lodash/cloneDeep";
import {ConnectionRole} from "pg-isomorphic/enums/connections";
import signUp from "./modules/signup";
import Cookies from "js-cookie";
import userStore from "./modules/users";
import connectionStore from "./modules/profile/connectionStore";
import assignTopicOwners from "@/store/modules/profile/assignTopicOwners";
import questionTasks from "@/store/modules/profile/questionTasks";
import rapidratings from "@/store/modules/profile/rapidratings";
import tasks from "./modules/tasks";
import notifications from "./modules/notifications";
import persist from "./plugins/persist";
import httpPlugin from "./plugins/http";
import authPlugin from "./plugins/auth";
import resetPlugin from "./plugins/reset";
import socketPlugin from "./plugins/socket";
import emitterPlugin from "./plugins/emitter";
import home from "./modules/home";
import userProfile from "./modules/userProfile";
import floatingChatWindow from "./modules/messaging/floating-chat-window";
import messageCounts, {syncCounts} from "./modules/messaging/message-counts";
import notes from "./modules/notes";
import taskDetails from "./modules/taskDetails";
import entity from "./modules/profile/entityStore";
import reminder from "./modules/reminders/reminder";
import messageCenter from "./modules/messageCenter";
import history from "./modules/history";
import twoFactor from "./modules/twoFactor";
import forgot from "./modules/forgot";
import workflow from "./modules/workflow";
import translationText from "./modules/translationText";
import dataShare from "./modules/dataShare";
import invite from "./modules/invite";
import info from "./modules/info";
import {autoMutations} from "./utils";
import admin from "./modules/admin";
import approvals from "./modules/approvals";
import exportStore from "./modules/export";
import {RoutingKey, registerSocketEnvPrefix} from "pg-isomorphic/queue";
import {NotificationChangeType} from "pg-isomorphic/enums/notification";
import {default as globalLogger} from "../logging";
import throttle from "lodash/throttle";
import {AuditEvent} from "pg-isomorphic/enums/events";
import {Locale} from "pg-isomorphic/enums";
import moment from "moment";
import momenttz from "moment-timezone";
import {default as moduleUtils} from "./plugins/util";
import globalConfirm from "./modules/globalConfirm";
import {loadLanguageAsync} from "@/i18n";
import actionPlans from "./modules/actionPlans";
import actionPlanReminders from "./modules/reminders/actionPlanReminders";
import {ResponderStorageKey, ResponderExcel} from "@/composables/responder/excel-commands";
import {isExcel} from "@/utils/excel";
import {ChangeType} from "pg-isomorphic/enums/queue";
import {EntityFeature} from "pg-isomorphic/enums/entity";
import {clearSentryUser, initSentry, setSentryUser} from "@/utils/sentry";
import {mixpanelClearUser, mixpanelInit, mixpanelSetUser, mixpanelTrack, mixpanelUnsetUser} from "@/utils/mixpanel";
import {changeLanguage} from "@/composables/menu";

const logger = globalLogger.getLogger("root-store");
const DEFAULT_JWT_TTL = 20;
const SIGN_OUT_WARNING_SECONDS = 60;
const JWT_REFRESH_THROTTLE_MS = 5 * 60 * 1000;

const channel = new BroadcastChannel("activity");
let newClientVersionMessage;

const initialState = {
  loading: false,
  isAuthenticated: false,
  requestedPath: null,
  requestedEntity: null,
  authenticationError: false,
  user: null,
  needsVerification: false,
  language: null,
  hasEntities: false,
  apiError: null,
  scrollY: 0,
  JWT: null,
  alwaysReconnect: true,
  JWTExpiresOn: null,
  notificationCounts: [],
  messageCounts: [],
  showSignOutWarning: false,
  signOutSecondsRemaining: SIGN_OUT_WARNING_SECONDS,
  signOutTimeoutHandle: null,
  signOutWarningTimeoutHandle: null,
  signOutWarningIntervalHandle: null,
  signInError: null,
  ssoUrl: null,
  ssoType: null,
  ssoAllowPassword: false,
  apiVersionInfo: {},
  webAppVersionInfo: {},
  webAppIsStale: false,
  fetchedConfig: false,
  envConfig: {
    socketEnvPrefix: "",
    env: "production",
    auth: {
      minPassword: 8,
      minScore: 3,
    },
    jwtTTL: DEFAULT_JWT_TTL,
    gaCode: "",
    ssAuthKey: "",
    gMap: {},
    jiraIssueCollectorURL: "",
    environmentName: "local",
    rapidRatingsPortal: "https://portal.sandbox.rrilabs.co",
    sentryDsn: "",
    mixpanelToken: "",
    zendeskKey: "",
  },
  secondMenuHeight: 0,
  // the vue router doesn't play nice with the composition API, so we store a copy in the store on route change
  currentRoute: {
    name: "",
    meta: {},
    path: "",
    hash: "",
    query: {},
    params: {},
    fullPath: "",
  },
  previousRoute: undefined,
  cancelSaveBarEditing: false,
  cancelSaveBarDisabled: false,
  cancelSaveBarEditKey: "",
  cancelSaveProcessing: false,
  cancelSaveAnswersBeingSaved: null,
  cancelSaveValidationStates: {},
  cancelSaveSuppressedErrors: [], // array of answer keys errors to suppress
  cancelSaveShowValidationStatus: false,
  haltRouter: false,
  haltRouterOpenDialog: false,
  haltRouterNextMethod: () => {},
  websocket: {
    connected: false,
    lastConnectionAttemptedAt: new Date(0),
    elapsedSinceLastConnectionAttempt: 0,
    lastSuccessfullyConnectedAt: new Date(0),
    reconnectAttempt: 0,
    showDisconnectedWarning: false,
    reconnectingInSeconds: 0,
    timerHandle: null,
  },
  debugQuestionTree: false,
  debugOpen: false,
  debugQuestionTreeStartingKey: null,
};

function identifyUserToExternalServices(envConfig, user) {
  if (!user) {
    return;
  }
  setSentryUser(user);
  mixpanelSetUser(user);
}

const inactivityTimeoutSeconds = (state) => 60 * pathOr(DEFAULT_JWT_TTL, ["envConfig", "jwtTTL"], state);

const store = createStore({
  plugins: [httpPlugin, persist, socketPlugin, authPlugin, resetPlugin, emitterPlugin, moduleUtils],
  state: {
    ...initialState,
  },
  mutations: {
    ...autoMutations(initialState),
    updateLoading: (state, loading) => {
      state.loading = loading;
    },
    authenticated: (state, isAuthenticated) => {
      state.isAuthenticated = isAuthenticated;
    },
    user: (state, user) => {
      if (user) {
        state.user = {
          ...user,
          permissions: new Set(reject(isNil, user.permissions)),
          topicOwnerRoles: new Set(user.topicOwnerRoles),
        };
        const entities = user ? user.entities : [];
        state.hasEntities = !isEmpty(entities.filter((e) => e.status === "accepted"));
        state.isAuthenticated = !!user;
      }
    },
    authenticationError: (state, authenticationError) => {
      state.authenticationError = authenticationError;
    },
    apiError: (state, apiError) => {
      state.apiError = apiError;
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    resetAll: () => {},

    addValidationState(state, {validationKey, validationState}) {
      state.cancelSaveValidationStates[validationKey] = validationState;
    },

    removeValidationState(state, validationKey) {
      state.cancelSaveValidationStates = dissoc(validationKey, state.cancelSaveValidationStates);
    },
  },
  actions: {
    async checkUserLocaleDefaults({state}) {
      if (!state.user) {
        return;
      }
      const user = state.user;
      if (!(user.localeSettings && user.localeSettings.language)) {
        const response = await this.httpPut("/api/users/locale", {
          timezone: momenttz.tz.guess(),
          language: state.language || window.navigator.userLanguage || window.navigator.language,
        });
        user.localeSettings = response.data;
      }
    },
    async getCurrentUser({commit, dispatch, state}, force) {
      if (state.user && !force) {
        return state.user;
      }
      if (this.userPromise) {
        return this.userPromise;
      }
      if (!this.state.JWT) {
        return null;
      }
      this.userPromise = this.httpGet("/api/users/current")
        .then((response) => response.data)
        .catch(() => null);
      const user = await this.userPromise;
      this.userPromise = null;
      commit("user", user);
      if (user) {
        await dispatch("checkUserLocaleDefaults");
        loadLanguageAsync(user.localeSettings.language);
        moment.locale(user.localeSettings.language);
      }
      return user;
    },

    async setRequestedPath({commit}, path) {
      commit("requestedPath", path);
      if (path) {
        Cookies.set("ssoRequestedPath", path, {expires: 60 * 5, secure: true}); // expire in 5 min
      } else {
        Cookies.remove("ssoRequestedPath");
      }
    },

    async setRequestedEntity({commit}, entity) {
      commit("requestedEntity", entity);
    },

    async signIn({state, commit, dispatch}, {email, password, rememberMe}) {
      let signInResponse;
      try {
        const response = await this.httpPost("/api/users/login", {
          email,
          password,
          tz: momenttz.tz.guess(),
          locale: state.language || window.navigator.userLanguage || window.navigator.language,
          language: state.language, // selected in menu
        });
        signInResponse = response.data;
        identifyUserToExternalServices(state.envConfig, signInResponse.user);
        mixpanelTrack("Sign In", {
          email,
          locale: signInResponse.user?.locale,
          name: signInResponse.user?.displayName,
        });
      } catch (e) {
        clearSentryUser();
        mixpanelClearUser();
        commit("signInError", e);
        signInResponse = null;
        commit("authenticationError", true);
      }
      return dispatch("completeSignIn", {signInResponse, rememberMe});
    },

    async fetchEnvironmentConfig({state, commit}) {
      try {
        if (state.fetchedConfig) {
          return;
        }
        commit("fetchedConfig", true);
        const [envResponse, versionResponse] = await Promise.all([
          this.httpGet("/api/env"),
          this.httpGet("/version.json"),
        ]);
        commit("envConfig", envResponse.data);
        registerSocketEnvPrefix(envResponse.data.socketEnvPrefix || "");
        initSentry(envResponse.data, versionResponse.data);
        mixpanelInit(envResponse.data);
      } catch (e) {
        logger.error("error fetching config", e);
      }
    },

    // Check for authentication via sessionStorage JWT and a cookie
    async checkAuthentication({dispatch, state}) {
      await dispatch("fetchEnvironmentConfig");
      if (this.checkPromise) {
        return this.checkPromise;
      }
      // The check API returns a 200 in all scenarios
      this.checkPromise = this.httpPost("/api/users/check");
      const signInPromise = this.checkPromise.then((response) => {
        if (response.data && response.data.authenticated === false) {
          return null;
        }
        if (
          response.data.passwordUnset &&
          !response.data.user.isDataShareUser &&
          !window.location.pathname.match(/\/signup\/password/i) &&
          !window.location.pathname.match(/\/signout/i)
        ) {
          window.location = "/signup/password?unset=true";
        }
        return response.data;
      });
      this.userPromise = signInPromise.then((signInResponse) => (signInResponse ? signInResponse.user : null));
      let signInResponse;
      try {
        signInResponse = await signInPromise;
        identifyUserToExternalServices(state.envConfig, signInResponse.user);
      } catch (e) {
        clearSentryUser();
        mixpanelClearUser();
        signInResponse = null;
      }
      this.userPromise = null;
      this.checkPromise = null;
      return dispatch("completeSignIn", {signInResponse});
    },

    async signOut({commit, dispatch, state}) {
      try {
        if (isExcel) {
          await ResponderExcel.storage.clear();
        }
        const response = await this.httpGet("/api/users/logout");
        mixpanelTrack("Sign Out", {email: state.user.email, name: state.user.displayName});
        await mixpanelUnsetUser("active");
        commit("resetAll", true);
        dispatch("signUp/clearInvite");
        dispatch("signUp/clearCampaign");
        this.removeJWT();
        clearSentryUser();
        mixpanelClearUser();
        return response.data.redirectUrl;
      } catch (err) {
        /*empty*/
      }
    },

    signOutWarning({commit, state}) {
      commit("signOutSecondsRemaining", SIGN_OUT_WARNING_SECONDS);
      commit("showSignOutWarning", true);
      const handle = setInterval(() => {
        const remaining = state.signOutSecondsRemaining - 1;
        commit("signOutSecondsRemaining", remaining);
        if (remaining <= 0) {
          clearInterval(state.signOutWarningIntervalHandle);
          commit("signOutWarningIntervalHandle", null);
          commit("showSignOutWarning", false);
        }
      }, 1000);
      commit("signOutWarningIntervalHandle", handle);
    },

    cancelSignOut({commit, state, dispatch}) {
      clearInterval(state.signOutWarningIntervalHandle);
      commit("showSignOutWarning", false);
      commit("signOutWarningIntervalHandle", null);
      dispatch("resetUserActivityTimer");
    },

    resetUserActivityTimer({commit, dispatch, state}, {type} = {}) {
      let {
        signOutTimeoutHandle,
        signOutWarningTimeoutHandle,
        // eslint-disable-next-line prefer-const
        signOutWarningIntervalHandle,
        // eslint-disable-next-line prefer-const
        isAuthenticated,
      } = state;

      // Don't reset timers if we're already warning
      if (!isAuthenticated || signOutWarningIntervalHandle) {
        return;
      }
      if (signOutTimeoutHandle) {
        clearTimeout(signOutTimeoutHandle);
      }
      if (signOutWarningTimeoutHandle) {
        clearTimeout(signOutWarningTimeoutHandle);
      }

      // refresh JWT if needed, at most, every JWT_REFRESH_THROTTLE_MS
      if (!this.jwtRefreshThrottled) {
        this.jwtRefreshThrottled = throttle(() => dispatch("jwtRefresh"), JWT_REFRESH_THROTTLE_MS, {
          trailing: true,
          leading: false,
        });
      }
      this.jwtRefreshThrottled();

      // don't resend message events -- infinite loop
      if (type !== "message") {
        // Send a broadcast message to other tabs/windows to reset activity timer
        channel.postMessage(true);
      }

      const timeout = inactivityTimeoutSeconds(state) * 1000;
      signOutTimeoutHandle = setTimeout(async () => {
        const redirectUrl = await dispatch("signOut");
        window.location.href = redirectUrl || "/signin?r=timeout";
      }, timeout);

      const warning = timeout - 1000 * SIGN_OUT_WARNING_SECONDS;
      signOutWarningTimeoutHandle = setTimeout(() => {
        dispatch("signOutWarning");
      }, warning);

      commit("signOutTimeoutHandle", signOutTimeoutHandle);
      commit("signOutWarningTimeoutHandle", signOutWarningTimeoutHandle);
    },

    async jwtRefresh() {
      try {
        const response = await this.httpPut("/api/users/renew");
        const userJwt = response.data;
        this.setJWT(userJwt.token, userJwt.tokenExpiresOn, false);
        if (isExcel) {
          const signInResponse = await ResponderExcel.storage.getItem(ResponderStorageKey.SignInResponse);
          await ResponderExcel.storage.setItem(ResponderStorageKey.SignInResponse, {
            ...signInResponse,
            token: userJwt.token,
            tokenExpiresOn: userJwt.tokenExpiresOn,
          });
        }
      } catch (err) {
        logger.error("Error refreshing JWT", err);
        window.location.href = "/signin?r=jwt";
      }
    },

    async completeSignIn({commit, dispatch}, {signInResponse, rememberMe}) {
      if (signInResponse && signInResponse.user) {
        if (isExcel) {
          ResponderExcel.storage.setItem(ResponderStorageKey.SignInResponse, signInResponse);
          ResponderExcel.storage.setItem(ResponderStorageKey.RememberMe, rememberMe);
        }
        commit("user", signInResponse.user);
        await dispatch("checkUserLocaleDefaults");
        const token = signInResponse.token;
        const tokenExpiresOn = signInResponse.tokenExpiresOn;
        this.setJWT(token, tokenExpiresOn, rememberMe);
        changeLanguage(signInResponse.user.localeSettings.language);
        return signInResponse.user;
      } else {
        commit("user", null);
        return null;
      }
    },

    async setTwoFactorVerified({state}, val) {
      state.user["twoFactorAuthVerified"] = val;
    },

    async setTwoFactorAuth({state}, twoFactor) {
      state.user["twoFactorAuth"] = twoFactor;
    },

    updateUserEntity({state}, {field, value}) {
      const activeEntityId = state.user.activeEntityId;
      const updateEntityArray = (entityArray) => {
        for (const currentEntity of entityArray) {
          if (currentEntity.entityId === activeEntityId) {
            currentEntity[field] = getFreeTextAnswer(value, state.user.locale);
          }
        }
      };

      // Active Entity
      const clonedActiveEntityInfo = cloneDeep(state.user.activeEntityInfo);
      clonedActiveEntityInfo[field] = getFreeTextAnswer(value, state.user.locale);
      state.user.activeEntityInfo = clonedActiveEntityInfo;

      // Avail Connection Roles
      const clonedActiveEntities = cloneDeep(state.user.activeEntities);
      updateEntityArray(clonedActiveEntities);
      state.user.activeEntities = clonedActiveEntities;

      // Avail Connection Roles
      const clonedEntities = cloneDeep(state.user.entities);
      updateEntityArray(clonedEntities);
      state.user.entities = clonedEntities;
    },
    async getNotificationCounts({state}) {
      if (state.user) {
        try {
          const response = await this.httpGet(`/api/notifications/count?notOfTypes=${AuditEvent.MESSAGE_CREATED}`);
          state.notificationCounts = response.data.counts;
          logger.debug(`notification counts: ${state.user.activeEntityId}`, JSON.stringify(state.notificationCounts));
        } catch (e) {
          logger.error("Error retrieving notification counts", e);
        }
      }
    },
    setNotificationUnreadCount({state}, {entityId, count}) {
      const currentEntityIndex = findIndex(propEq(["_id"], entityId))(state.notificationCounts);
      if (currentEntityIndex > -1) {
        state.notificationCounts[currentEntityIndex].count = count;
      }
    },
    async getMessageCounts({state}) {
      if (state.user) {
        try {
          const response = await this.httpGet(`/api/notifications/count?onlyOfTypes=${AuditEvent.MESSAGE_CREATED}`);
          state.messageCounts = response.data.counts;
          logger.debug(`message counts: ${state.user.activeEntityId} -> ${JSON.stringify(state.messageCounts)}`);
        } catch (e) {
          logger.error("Error retrieving message counts", e);
        }
      }
    },
    setMessageUnreadCount({state}, {entityId, count}) {
      const currentEntityIndex = findIndex(propEq(["_id"], entityId))(state.messageCounts);
      if (currentEntityIndex > -1) {
        state.messageCounts[currentEntityIndex].count = count;
      }
    },
    joinNotificationRooms({dispatch, state}) {
      if (!state.user) return;

      this._insertNotification = (wsResponse) => {
        const entityId = state.user.activeEntityId;
        let counts = state.notificationCounts;
        let module = "notifications";
        if (wsResponse.notification && wsResponse.notification.type === AuditEvent.MESSAGE_CREATED) {
          counts = state.messageCounts;
          module = "messageCenter";
        }
        const currentEntityIndex = findIndex(propEq(["_id"], entityId))(counts);
        if (currentEntityIndex > -1) {
          counts[currentEntityIndex].count++;
        } else {
          counts.push({
            _id: entityId,
            count: 1,
          });
        }

        dispatch(`${module}/insertNotification`, {notification: wsResponse.notification});
      };

      this._deleteNotification = (wsResponse) => {
        let module = "notifications";
        if (wsResponse.notification && wsResponse.notification.type === AuditEvent.MESSAGE_CREATED) {
          module = "messageCenter";
        }
        dispatch(`${module}//deleteNotification`, {notification: wsResponse.notification});
      };

      this._readNotification = (wsResponse) => {
        logger.debug("decrement read count", wsResponse);
        const entityId = state.user.activeEntityId;
        let counts = state.notificationCounts;
        let module = "notifications";
        if (wsResponse.notification && wsResponse.notification.type === AuditEvent.MESSAGE_CREATED) {
          counts = state.messageCounts;
          module = "messageCenter";
        }
        const currentEntityIndex = findIndex(propEq(["_id"], entityId))(counts);
        if (currentEntityIndex > -1) {
          if (counts[currentEntityIndex].count > 0) {
            counts[currentEntityIndex].count--;
          }
        }

        dispatch(`${module}/readNotification`, {notification: wsResponse.notification});
      };

      this.join(RoutingKey.userNotifications({userId: state.user._id, entityId: state.user.activeEntityId}));
      this.socketOn(
        RoutingKey.userNotifications({
          userId: state.user._id,
          entityId: state.user.activeEntityId,
          evt: NotificationChangeType.INSERT,
        }),
        this._insertNotification,
      );
      this.socketOn(
        RoutingKey.userNotifications({
          userId: state.user._id,
          entityId: state.user.activeEntityId,
          evt: NotificationChangeType.DELETE,
        }),
        this._deleteNotification,
      );
      this.socketOn(
        RoutingKey.userNotifications({
          userId: state.user._id,
          entityId: state.user.activeEntityId,
          evt: NotificationChangeType.READ,
        }),
        this._readNotification,
      );

      this.join(RoutingKey.messageCounts(state.user._id, state.user.activeEntityId));
      this.socketOn(RoutingKey.messageCounts(state.user._id, state.user.activeEntityId, ChangeType.UPDATE), syncCounts);
    },

    leaveNotificationRooms({state}) {
      if (!state.user) return;
      this.leave(RoutingKey.userNotifications({userId: state.user._id, entityId: state.user.activeEntityId}));
      this.socketOff(
        RoutingKey.userNotifications({
          userId: state.user._id,
          entityId: state.user.activeEntityId,
          evt: NotificationChangeType.INSERT,
        }),
        this._insertNotification,
      );
      this.socketOff(
        RoutingKey.userNotifications({
          userId: state.user._id,
          entityId: state.user.activeEntityId,
          evt: NotificationChangeType.DELETE,
        }),
        this._deleteNotification,
      );
      this.socketOff(
        RoutingKey.userNotifications({
          userId: state.user._id,
          entityId: state.user.activeEntityId,
          evt: NotificationChangeType.READ,
        }),
        this._readNotification,
      );

      this.leave(RoutingKey.messageCounts(state.user._id, state.user.activeEntityId));
      this.socketOff(
        RoutingKey.messageCounts(state.user._id, state.user.activeEntityId, ChangeType.UPDATE),
        syncCounts,
      );
    },

    async checkSSO({commit, dispatch, state}, {email}) {
      if (!email) {
        return dispatch("resetSSO");
      }
      const response = await this.httpGet(`/api/entities/ssocheck/${encodeURIComponent(email)}`);
      const data = response.data;
      if (data.success) {
        commit("ssoUrl", data.url);
        commit("ssoType", data.type);
        commit("ssoAllowPassword", data.allowPassword);
      } else {
        dispatch("resetSSO");
      }
      commit("needsVerification", data.verify || false);
    },

    async resetSSO({commit}) {
      commit("ssoUrl", null);
      commit("ssoType", null);
      commit("ssoAllowPassword", false);
      commit("needsVerification", false);
    },

    setSocketReconnect({commit}, {value}) {
      commit("alwaysReconnect", value);
    },
    // mostly used for testing
    // eslint-disable-next-line no-empty-pattern
    async genericApiGet({}, {url}) {
      try {
        return this.httpGet(url);
      } catch (e) {
        /*empty*/
      }
    },

    async getClientVersion({commit}) {
      try {
        const response = await this.httpGet("/version.json");
        commit("webAppVersionInfo", response.data);
      } catch (err) {
        logger.error("failed version check", err);
      }
    },

    joinClientVersionRooms({commit, state, dispatch}) {
      dispatch("getClientVersion");
      newClientVersionMessage = (msg) => {
        const previous = state.webAppVersionInfo;
        if (!previous.date) {
          commit("webAppVersionInfo", msg);
          return;
        }
        if (previous.date && msg.date) {
          if (moment(previous.date).add(30, "seconds").isBefore(moment(msg.date))) {
            commit("webAppIsStale", true);
          }
        }
      };
      this.join(RoutingKey.clientVersion());
      this.socketOn(RoutingKey.clientVersion(), newClientVersionMessage);
    },

    leaveClientVersionRooms({}) {
      this.leave(RoutingKey.clientVersion());
      this.socketOff(RoutingKey.clientVersion(), newClientVersionMessage);
    },

    updateVersion({}, {version}) {
      newClientVersionMessage(version);
    },
  },
  getters: {
    // loading: prop('loading'),
    isAuthenticated: prop("isAuthenticated"),
    loading(state) {
      return state.loading;
    },
    menuHeight() {
      const defaultMenuHeight = 62;
      const menuEl = document.getElementById("main-top-nav");
      return menuEl && menuEl.offsetHeight ? menuEl.offsetHeight : defaultMenuHeight;
    },
    userCurrentEntityData(state) {
      if (!state.user) {
        return {};
      }
      const activeEntity = filter(propEq("entityId", state.user.activeEntityId), state.user.entities);
      return activeEntity[0] || {};
    },
    userActiveConnectionRole(state, getters) {
      return getters.userCurrentEntityData.activeConnectionRole;
    },
    userDefaultConnectionRole(state, getters) {
      return (
        getters.userCurrentEntityData.defaultConnectionRole ||
        (getters.userActiveConnectionRole === ConnectionRole.BOTH
          ? ConnectionRole.BUYER
          : getters.userActiveConnectionRole)
      );
    },
    canInviteSeller(state, getters) {
      return (
        (getters.userActiveConnectionRole === ConnectionRole.BOTH ||
          getters.userActiveConnectionRole === ConnectionRole.BUYER) &&
        pathOr(false, ["user", "activeEntityInfo", "features", EntityFeature.BUYER_INVITE], state)
      );
    },
    canInviteBuyer(state, getters) {
      return (
        (getters.userActiveConnectionRole === ConnectionRole.BOTH ||
          getters.userActiveConnectionRole === ConnectionRole.SELLER) &&
        pathOr(false, ["user", "activeEntityInfo", "features", EntityFeature.SELLER_INVITE], state)
      );
    },
    locale(state) {
      return pathOr(Locale.EN_US, ["user", "locale"], state);
    },
    smartyKey(state) {
      return path(["envConfig", "ssAuthKey"], state);
    },
    googleMapsKey(state) {
      return path(["envConfig", "gMap", "static"], state);
    },
  },
  modules: {
    signUp,
    // profile: profileStore,
    connection: connectionStore,
    tasks,
    notifications,
    invite,
    userProfile,
    users: userStore,
    admin,
    floatingChatWindow,
    messageCounts2: messageCounts,
    notes,
    taskDetails,
    reminder,
    history,
    twoFactor,
    forgot,
    messageCenter,
    home,
    entity,
    workflow,
    dataShare,
    globalConfirm,
    approvals,
    info,
    translate: translationText,
    actionPlans,
    actionPlanReminders,
    export: exportStore,
    assignTopicOwners,
    questionTasks,
    rapidratings,
  },
});

export function useStore() {
  return store;
}

// support keeping track of state when doing a hot reload in development
if (import.meta.hot) {
  if (import.meta.hot.data?.state) {
    store.replaceState(import.meta.hot.data?.state);
  }

  import.meta.hot.on("vite:beforeUpdate", () => {
    import.meta.hot.data.state = store.state;
  });
}
