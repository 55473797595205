import {getStore} from "@/composables/get.store";
import {autoMutations} from "@/store/utils";
import sortBy from "lodash/sortBy";
import cloneDeep from "lodash/cloneDeep";
import type {
  MessageCounts,
  MessageCountsEvent,
  MessageThreadInfo,
  RelatedThreadsInput,
} from "pg-isomorphic/api/message";
import {TEMP_THREAD_ID} from "pg-isomorphic/enums/message";
import type {ActionTree, GetterTree, Module, MutationTree} from "vuex";

export type ThreadInfoCountPartial = Pick<MessageThreadInfo, "id" | "totalMessages" | "unreadMessages">;

export function makeCountKey(inputOrThreadId: Partial<RelatedThreadsInput> | string) {
  if (typeof inputOrThreadId === "string") {
    return `threadId:${inputOrThreadId}`;
  }

  const filteredAndSortedEntries = sortBy(
    Object.entries(inputOrThreadId).filter(([, value]) => !!value),
    ([key]) => key,
  );

  return filteredAndSortedEntries.map(([key, value]) => `${key}:${value}`).join("|");
}

export interface MessageCountsState {
  counts: Record<string, Partial<MessageCounts>>;
}

const initialState: MessageCountsState = {
  counts: {},
};

const getters: GetterTree<MessageCountsState, any> = {
  getCounts(state: MessageCountsState): (threadCountOrId: string | ThreadInfoCountPartial) => Partial<MessageCounts> {
    return (threadCountOrId: string | ThreadInfoCountPartial) => {
      const threadId = typeof threadCountOrId === "string" ? threadCountOrId : threadCountOrId?.id;

      if (!threadId || threadId === TEMP_THREAD_ID) {
        return {};
      }

      // someone tried to access this, which means they're interested in counts, so we should listen for updates
      if (!state.counts[threadId]) {
        if (typeof threadCountOrId === "string") {
          // TODO get counts from server? (this hasn't come up yet)
          // getStore().dispatch("messageCounts2/getCounts", {
          //   threadId: threadCountOrId,
          // });
          state.counts[threadId] = {};
        } else {
          state.counts[threadId] = {
            total: threadCountOrId.totalMessages || 0,
            unread: threadCountOrId.unreadMessages || 0,
          };
        }
      }

      return state.counts[threadId];
    };
  },
};

const actions: ActionTree<MessageCountsState, any> = {
  updateCounts({commit, state}, {threadId, unread, total}) {
    state.counts[threadId] = {total, unread};
    commit("counts", {...state.counts});
  },
};

export function syncCounts(event: MessageCountsEvent) {
  getStore().dispatch("messageCounts2/updateCounts", {
    threadId: event.threadId,
    total: event.counts.total,
    unread: event.counts.unread,
  });
}

const mutations: MutationTree<MessageCountsState> = {
  ...autoMutations(initialState),
};

const messageCountsStore: Module<MessageCountsState, any> = {
  namespaced: true,
  state: cloneDeep(initialState),
  actions,
  mutations,
  getters,
};

export default messageCountsStore;
