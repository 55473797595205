import {getStore} from "@/composables/get.store";
import type {HttpRequestDetails} from "@/composables/xhr/types";
import type {AxiosRequestHeaders, AxiosResponse, Method} from "axios";
import {default as axios} from "axios";
import {path} from "ramda";
import globalLogger from "../../logging";
import * as Sentry from "@sentry/browser";

const logger = globalLogger.getLogger("axios-xhr-composable");

const getHeaders = () => {
  const store = getStore();

  return store.state.JWT ? {Authorization: `Bearer ${store.state.JWT}`} : {};
};

const httpRequest = async <T>(
  method: string,
  {url, params, data, contentType}: HttpRequestDetails,
): Promise<AxiosResponse<T>> => {
  try {
    // store.commit('apiError', null, {root: true});
    return (await axios({
      method: method as Method,
      url,
      headers: {
        ...getHeaders(),
        ...(contentType ? {"Content-Type": contentType} : {}),
      },
      data,
      params,
    })) as AxiosResponse<T>;
  } catch (e) {
    // store.commit('apiError', e, {root: true});
    // store.commit('loading', false, {root: true});
    globalLogger.error(
      () => `HTTP ${e.response?.status} (${e.response?.statusText}) error: ${method} ${url} ${JSON.stringify(params)}`,
      path(["response"], e),
    );
    if (e.response?.status === 401) {
      // redirect to login if unauthorized
      setTimeout(() => {
        logger.trace(() => `requested ${url} resulted in 401; current ${window.location.pathname}`);
        if (window.location.pathname !== "/signin") {
          window.location.href = "/signin?r=xhrauth&auth=false";
        }
      }, 200);
    } else {
      Sentry.captureException(e);
    }
    throw e;
  }
};

export async function httpGet<T>(url: string, params: object = {}): Promise<AxiosResponse<T>> {
  return httpRequest<T>("GET", {url, params});
}
export async function httpPost<T>(url: string, data?: object, params?: object): Promise<AxiosResponse<T>> {
  return httpRequest<T>("POST", {url, params, data});
}
export async function httpPostUpload<T>(url: string, data?: object, params?: object): Promise<AxiosResponse<T>> {
  const formData = new FormData();
  Object.keys(data || {}).forEach((key) => formData.append(key, data[key]));
  return httpRequest<T>("POST", {url, params, data: formData, contentType: "multipart/form-data"});
}
export async function httpPatch<T>(url: string, data: object): Promise<AxiosResponse<T>> {
  return httpRequest<T>("PATCH", {url, data});
}
export async function httpPut<T>(url: string, data: object): Promise<AxiosResponse<T>> {
  return httpRequest<T>("PUT", {url, data});
}
export async function httpDelete<T>(url: string, params?: object, data?: object): Promise<AxiosResponse<T>> {
  return httpRequest<T>("DELETE", {url, params, data});
}
