import {QuestionType} from "../enums";
import {QuestionOption} from "../options";

export enum SearchOperator {
  DATE_EQUALS = "date_equals",
  DATE_NOT_EQUALS = "date_not_equals",
  EQUALS = "equals",
  NOT_EQUALS = "not_equals",
  GREATER = "greater",
  GREATER_EQUAL = "greater_equal",
  LESS = "less",
  LESS_EQUAL = "less_equal",
  BETWEEN = "between",
  // TOP_10 = 'top_10',
  // BOTTOM_10 = 'bottom_10',
  // ABOVE_AVERAGE = 'above_average',
  // BELOW_AVERAGE = 'below_average',
  // BEGINS = 'begins',
  // NOT_BEGINS = 'not_begins',
  // ENDS = 'ends',
  // NOT_ENDS = 'not_ends',
  // CONTAINS = 'contains',
  // NOT_CONTAINS = 'not_contains',
  BEFORE = "before",
  AFTER = "after",
  THIS_MONTH = "this_month",
  LAST_MONTH = "last_month",
  THIS_QUARTER = "this_quarter",
  LAST_QUARTER = "last_quarter",
  THIS_YEAR = "this_year",
  LAST_YEAR = "last_year",
  AND = "and",
  OR = "or",
  NONE = "none",
  IS_EMPTY = "is_empty",
  NOT_EMPTY = "not_empty",
  IS_TRUE = "is_true",
  IS_FALSE = "is_false",
}

export const rangeOperators = new Set<SearchOperator>([
  SearchOperator.GREATER,
  SearchOperator.GREATER_EQUAL,
  SearchOperator.LESS,
  SearchOperator.LESS_EQUAL,
  SearchOperator.GREATER,
  SearchOperator.BETWEEN,
  SearchOperator.BEFORE,
  SearchOperator.AFTER,
  SearchOperator.THIS_MONTH,
  SearchOperator.DATE_NOT_EQUALS,
  SearchOperator.DATE_EQUALS,
  SearchOperator.LAST_MONTH,
  SearchOperator.THIS_QUARTER,
  SearchOperator.LAST_QUARTER,
  SearchOperator.THIS_YEAR,
  SearchOperator.LAST_YEAR,
]);

// Multiple terms must have a parent specifying the operator between children.
// ex: Country = US Connected last quarter is represented as
// {operator: and, children:[
//     {operator: equals, answerKey: Country, value:["US"]}
//     {operator: last_quarter, answerKey: Connected}]}
export interface SearchCondition {
  answerKey?: string;
  operator: SearchOperator;
  values?: unknown[];
  children?: SearchCondition[];
  questionType?: QuestionType;
  isRegexSearch?: boolean; // for pointer search now, only check the first element of "values"
  isBoolean?: boolean;
}

// The front-end only creates simple queries:
export interface SearchConditionRoot {
  operator: SearchOperator;
  children?: SimpleSearchCondition[];
}

export interface SimpleSearchCondition {
  questionId?: string;
  answerKey?: string;
  operator: SearchOperator;
  values?: unknown[];
  children?: SearchCondition[];
}

export type SearchConditionValue = QuestionOption | QuestionOption[] | SimpleSearchCondition;

export function isSimpleSearchCondition(input: SearchConditionValue): input is SimpleSearchCondition {
  return "operator" in input;
}
