<template>
  <Message
    v-if="show"
    :class="`graphite-alert graphite-alert-${severityAttr}`"
    :severity="severityAttr"
    :closable="dismissible"
    :sticky="!life"
    :life="life"
    class="align-items-center"
  >
    <slot />
    <template #messageicon v-if="icon || $slots.icon || hideIcon">
      <span v-if="hideIcon"><!-- empty span to trick PrimeVue --></span>
      <div v-else class="alert-icon mr-3">
        <slot name="icon">
          <font-awesome-icon :icon="icon" />
        </slot>
      </div>
    </template>
  </Message>
</template>

<script lang="ts" setup>
import {NoteVariants} from "@/components/shared/Note.vue";
import type {PropType} from "vue";
import {defineProps, computed, toRefs} from "vue";
import Message from "primevue/message";

const props = defineProps({
  variant: {
    type: String,
  },
  dismissible: {
    type: Boolean,
  },
  show: {
    type: Boolean,
  },
  icon: {
    type: Array as PropType<string[]>,
  },
  hideIcon: {
    type: Boolean,
  },
  life: {
    type: Number,
  },
});

const {variant} = toRefs(props);

const severityAttr = computed(() => {
  // normalize some values from legacy code
  switch (variant.value) {
    case NoteVariants.DANGER:
    case "destruction":
    case "danger":
    case "error":
      return "error";
    case NoteVariants.WARNING:
    case "warn":
    case "warning":
      return "warn";
    case "info":
    case NoteVariants.PRIMARY:
      return "info";
    case "success":
    case NoteVariants.SUCCESS:
      return "success";
    case NoteVariants.DARK:
      return "custom-dark";
    case NoteVariants.PURPLE:
      return "custom-purple";
  }

  return variant.value || "info";
});
</script>

<style lang="less" scoped>
@import "@{globals}";

.graphite-alert {
  display: flex;
  border-style: solid;
  border-radius: 4px;
  border-width: 0 0 0 4px;
}

.graphite-alert-error .alert-icon {
  color: @red600;
}
.graphite-alert-warn .alert-icon {
  color: @yellow600;
}
.graphite-alert-info .alert-icon {
  color: @blue500;
}
.graphite-alert-success .alert-icon {
  color: @green600;
}
.graphite-alert-custom-dark {
  background-color: @grey200;
  border-color: @grey700;

  .alert-icon {
    color: @grey700;
  }
}
.graphite-alert-custom-purple {
  background-color: @purple100;
  border-color: @purple500;

  .alert-icon {
    color: @purple500;
  }
}

.graphite-alert {
  :deep(.p-message-icon) {
    margin-right: 1rem;
  }
}
</style>
