// import globalLogger from '../../logging';
import {autoMutations} from "../utils";
import {TranslateEvents} from "pg-isomorphic/enums/translate";
import {pathOr} from "ramda";
import axios from "axios";

const state = {
  showTranslations: {},
};

// getters
const getters = {};

const actions = {
  async getAnswerTranslation({}, {elementKey, connectionId, pertainingToEntityId}) {
    const splitElementKey = elementKey.split(".");
    let answerKey = "";
    let instanceId = "";
    let groupKey = "";
    if (splitElementKey.length > 1) {
      [groupKey, instanceId, answerKey] = elementKey.split(".");
    } else {
      answerKey = elementKey;
    }

    try {
      const response = await this.httpPost(`/api/translate`, {
        answerKey,
        groupKey,
        instanceId,
        connectionId,
        pertainingToEntityId,
      });

      this.emitter.$emit(TranslateEvents.TRANSLATED, {elementKey, ...pathOr({}, ["data"], response)});
    } catch (e) {
      console.error("e", e);
      this.emitter.$emit(TranslateEvents.TRANSLATED, {elementKey, error: true});
    }
  },
  async rateAnswerTranslation({}, {elementKey, connectionId, pertainingToEntityId, goodTranslation, sourceLocale}) {
    const splitElementKey = elementKey.split(".");
    let answerKey = "";
    let instanceId = "";
    let groupKey = "";
    if (splitElementKey.length > 1) {
      [groupKey, instanceId, answerKey] = elementKey.split(".");
    } else {
      answerKey = elementKey;
    }

    await this.httpPost(`/api/translate/rating`, {
      answerKey,
      groupKey,
      instanceId,
      pertainingToEntityId,
      connectionId,
      goodTranslation,
      sourceLocale,
    });
  },
  async detectTextLanguage({rootState}, {text}) {
    const googleMapsToken = pathOr("", ["envConfig", "gMap", "static"], rootState);
    try {
      return await axios.post(
        "https://translation.googleapis.com/language/translate/v2/detect",
        {
          q: text,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=utf-8",
          },
          params: {
            key: googleMapsToken,
          },
        },
      );
    } catch (e) {
      console.error("e", e);
    }
  },
  resetShowTranslations({commit}) {
    commit("showTranslations", {});
  },
};

const mutations = {
  singleTranslationShow(state, {translationNamespace, value}) {
    state.showTranslations[translationNamespace] = value;
  },
  ...autoMutations(state),
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
