<template>
  <label
    class="file-input-wrapper"
    :class="{'no-file': !hasFile && !isDragging, 'is-dragging': isDragging}"
    @drop.prevent="fileDrop($event)"
    @dragover.prevent
    @dragenter.prevent="isDragging = true"
    @dragleave.prevent="isDragging = false"
  >
    <input ref="fileInputElemRef" class="file-input" @change="onFileInputChange" :multiple="multiple" type="file" />
    <span class="flex-grow-1 file-name">{{ fileStr }}</span>
    <GraphiteButton type="button" @click="onFileInputClick()">Browse</GraphiteButton>
  </label>
</template>

<script lang="ts" setup>
import {computed, ref} from "vue";

const props = defineProps<{
  modelValue: File | File[] | undefined;
  multiple?: boolean;
}>();

const emit = defineEmits<{
  "update:modelValue": [File | File[]];
  change: [File | File[]];
}>();

const fileInputElemRef = ref<HTMLInputElement>();
const isDragging = ref(false);

const _fileOrFiles = ref(props.modelValue);
const fileOrFiles = computed<File | File[] | undefined>({
  set(val: File | File[] | undefined) {
    emit("update:modelValue", val);
    emit("change", fileOrFiles.value);
    _fileOrFiles.value = val;
  },
  get(): File | File[] | undefined {
    return _fileOrFiles.value;
  },
});

const fileArray = computed<File[]>(() => {
  return Array.isArray(fileOrFiles.value) ? fileOrFiles.value : fileOrFiles.value ? [fileOrFiles.value] : [];
});

const hasFile = computed<boolean>(() => {
  return fileArray.value.length > 0;
});

function onFileInputClick() {
  fileInputElemRef.value?.click();
}

function onFileInputChange(event: Event) {
  const files = [...((event.target as HTMLInputElement)?.files || [])];
  processNewFiles(files);
}

function fileDrop(drop: DragEvent) {
  const files = [...(drop.dataTransfer.files || [])];
  processNewFiles(files);
  isDragging.value = false;
}

function processNewFiles(files: File[]) {
  if (props.multiple) {
    fileOrFiles.value = files;
  } else {
    fileOrFiles.value = files[0];
  }
}

const fileStr = computed<string>(() => {
  if (isDragging.value) {
    return "Drop files here";
  }

  if (!hasFile.value) {
    return "No file chosen";
  }

  return fileArray.value.map((f) => f?.name).join(", ");
});

defineOptions({
  compatConfig: {
    MODE: 3,
  },
});
</script>

<style lang="less" scoped>
@import "@{globals}";

.file-input-wrapper {
  display: flex;
  width: 100%;
  outline: 1px solid @grey300;
  background: white;
  align-items: center;
  padding-left: 14px;

  &.no-file {
    color: @grey700;
  }
}

.file-input {
  display: none;
}

.file-name {
  pointer-events: none;
}
</style>
