import cloneDeep from "lodash/cloneDeep";
import {has, is, pathOr} from "ramda";
import {Localizable} from "../api";
import type {LocaleObject} from "../api/answers";
import {FreeTextAnswerKeys, isValidEnum, Locale} from "../enums";
import {TranslationSource} from "../enums/answers";
import {TranslationShortLocaleException} from "../translation/locales";
import {INSTANCE_ORDER} from "./constants";
import type {JSONObject} from "./index";

export function shortLocale(locale: string = "en"): string {
  if (isShortLocaleException(locale)) {
    return locale;
  }
  const idx = locale.indexOf("-");
  return idx > -1 ? locale.substring(0, idx) : locale;
}

export function isShortLocaleException(locale: string = "en"): boolean {
  return TranslationShortLocaleException.indexOf(locale) > -1;
}

export function isLocaleObj(text: any) {
  return has(FreeTextAnswerKeys.ANSWER_LOCALE, text || {});
}

export function translationExistsInLocaleObj(text: any, locale: string): string {
  if (isLocaleObj(text)) {
    if (has(locale, text)) {
      return locale;
    } else {
      const shortenedLocale = shortLocale(locale);
      for (const key of Object.keys(text)) {
        const shortenedKey = shortLocale(key);
        if (shortenedLocale === shortenedKey) {
          return key;
        }
      }
    }
  }
  return "";
}

export function getTextFromLocaleObj(text: any, locale?: string, fallbackToUserAnsweredLocale?: boolean): string {
  if (isLocaleObj(text)) {
    const useLocale = locale || pathOr("en-US", [FreeTextAnswerKeys.ANSWER_LOCALE], text);
    let finalLocale = translationExistsInLocaleObj(text, useLocale);
    if (fallbackToUserAnsweredLocale && !finalLocale) {
      finalLocale = pathOr("en-US", [FreeTextAnswerKeys.ANSWER_LOCALE], text);
    }
    return pathOr("", [finalLocale, FreeTextAnswerKeys.VALUE], text);
  }
  return text;
}

export function setTextOnLocaleObj(text: any, locale: string, newString: string): any {
  if (isLocaleObj(text)) {
    text[locale] = newString;
  }

  return newString;
}

// return a copy of a locale obj without any machine translations
export function withoutMachineTranslations(obj: any): any {
  if (!isLocaleObj(obj)) {
    return obj;
  }
  const copy = cloneDeep(obj);
  for (const key of Object.keys(copy)) {
    if (key === FreeTextAnswerKeys.ANSWER_LOCALE) {
      continue;
    }
    const translation = copy[key];
    if (translation && translation[FreeTextAnswerKeys.TRANSLATION_SOURCE] === TranslationSource.MACHINE) {
      delete copy[key];
    }
  }
  return copy;
}

// remove translation that has the same locale as the latest answer
export function removeTranslations(obj: any): any {
  if (!isLocaleObj(obj)) {
    return obj;
  }
  const copy = cloneDeep(obj);
  let localeTime: Date | undefined;
  let answerLocale;
  for (const key of Object.keys(copy)) {
    if (key === FreeTextAnswerKeys.ANSWER_LOCALE) {
      continue;
    }
    const localeAnswer = copy[key];
    if (
      !localeAnswer[FreeTextAnswerKeys.TRANSLATION_SOURCE] &&
      (!localeTime || localeTime.getTime() < new Date(localeAnswer[FreeTextAnswerKeys.DATE]).getTime())
    ) {
      localeTime = new Date(localeAnswer[FreeTextAnswerKeys.DATE]);
      answerLocale = key;
    }
  }

  for (const key of Object.keys(copy)) {
    if (key === FreeTextAnswerKeys.ANSWER_LOCALE) {
      continue;
    }
    const localeAnswer = copy[key];
    if (
      localeAnswer &&
      localeAnswer[FreeTextAnswerKeys.TRANSLATION_SOURCE] !== undefined &&
      shortLocale(localeAnswer[FreeTextAnswerKeys.FROM_LOCALE]) === shortLocale(answerLocale)
    ) {
      delete copy[key];
    }
  }
  return copy;
}

export function coerceToLocaleObject(value: string | LocaleObject, locale?: Locale): LocaleObject {
  if (!isLocaleObj(value)) {
    const defaultLocale = locale && isValidEnum(locale, Locale) ? locale : Locale.EN_US;
    return {
      [FreeTextAnswerKeys.ANSWER_LOCALE]: defaultLocale,
      [defaultLocale]: {
        [FreeTextAnswerKeys.VALUE]: value || "",
        [FreeTextAnswerKeys.DATE]: new Date().toISOString(),
      },
    } as LocaleObject;
  } else {
    return cloneDeep(value) as LocaleObject;
  }
}

export function translateAnswerWithLocaleObjects(answers: JSONObject, locale: string) {
  const translatedAnswers: JSONObject = {};
  for (const currentKey of Object.keys(answers)) {
    const currentValue = answers[currentKey];
    if (is(Object, currentValue) && has(INSTANCE_ORDER, currentValue)) {
      translatedAnswers[currentKey] = cloneDeep(currentValue) || {};
      const currentInstanceOrder = pathOr([], [INSTANCE_ORDER], currentValue);
      for (const currentInstanceKey of currentInstanceOrder) {
        for (const currentGroupKey of Object.keys(pathOr({}, [currentInstanceKey], currentValue))) {
          const currentGroupValue = pathOr(null, [currentInstanceKey, currentGroupKey], currentValue);
          translatedAnswers[currentKey]![currentInstanceKey][currentGroupKey] = isLocaleObj(currentGroupValue)
            ? getTextFromLocaleObj(currentGroupValue, locale, true)
            : currentGroupValue;
        }
      }
    } else {
      translatedAnswers[currentKey] = isLocaleObj(currentValue)
        ? getTextFromLocaleObj(currentValue, locale, true)
        : currentValue;
    }
  }
  return translatedAnswers;
}

export function makeLocalizableString(value: string, locale: Locale | string): Localizable<string>;
export function makeLocalizableString(
  value: string | undefined,
  locale: Locale | string | undefined,
): Localizable<string> | undefined;
export function makeLocalizableString(
  value: string | undefined,
  locale: Locale | string | undefined,
): Localizable<string> | undefined {
  return value !== undefined && locale !== undefined ? {[locale as Locale]: value} : undefined;
}
