import cloneDeep from "lodash/cloneDeep";
import type {ApprovalResponse, CreateApproval, UpdateApproval} from "pg-isomorphic/api/approval";
import type {UserAvatar} from "pg-isomorphic/api/user";
import type {KitType} from "pg-isomorphic/enums/element";
import {ProfileEvent, ReviewEvent} from "pg-isomorphic/enums/events";
import {ApprovalType} from "pg-isomorphic/enums/task";
import type {APIError} from "pg-isomorphic/errors";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {isEmptyOrUndefined, makeKey} from "pg-isomorphic/utils";
import {has, identity, omit, pathOr} from "ramda";
import type {GetterTree} from "vuex";
import globalLogger from "../../../logging";
import type {PGActionContext, PGActionTree, PGStore, RootState} from "../../index-types";
import {actionRequestWrap} from "../../index-types";
import {questionLabelBreadcrumbs} from "../../typed-utils";
import {autoMutations} from "../../utils";
import {RoutingKey} from "pg-isomorphic/queue";
import {ConnectionRole, TaskChangeType} from "pg-isomorphic/enums";

const logger = globalLogger.getLogger("approvals");

const APPROVALS_MODAL_ID = "approval_manage_modal";

interface ManageApprovals {
  open: boolean;
  loading: boolean;
  showSendAlert: boolean;
  showUpdateError: boolean;
  showLoadError: boolean;
  error: APIError | null;
  question: JSONQuestion | null;
  questionActual: JSONQuestion | null;
  connectionId: string | null;
  instanceId: string | null;
  pertainingToEntityId: string | null;
  approvals: {[key: string]: ApprovalResponse[]};
  creating: Partial<CreateApproval> | null;
  editing: Partial<ApprovalResponse> | null;
  viewing: Partial<ApprovalResponse> | null;
  bothActorTypes: boolean;
  actionPlanId: string | null;
  connectionRole: string | null;
  placeAboveMenu: boolean | null;
  kitId: string | null;
  kitType: KitType | null;
}

const initialState: ManageApprovals = {
  open: false,
  loading: false,
  showSendAlert: false,
  showUpdateError: false,
  showLoadError: false,
  error: null,
  question: null,
  questionActual: null,
  connectionId: null,
  instanceId: null,
  pertainingToEntityId: null,
  approvals: {},
  creating: null,
  editing: null,
  viewing: null,
  bothActorTypes: false,
  actionPlanId: null,
  connectionRole: null,
  placeAboveMenu: null,
  kitId: null,
  kitType: null,
};

type Ctx = PGActionContext<ManageApprovals>;

const req = actionRequestWrap(logger, "error loading approvals API");

interface InitArgs {
  question?: JSONQuestion;
  approvals?: {};
  actionPlanId?: string;
  pertainingToEntityId: string;
  connectionId?: string;
  connectionRole?: string;
  instanceId?: string;
  approvalId?: string;
  placeAboveMenu?: boolean;
  kitId?: string;
  kitType?: KitType;
  open?: boolean;
}

function makeUserAvatar(rootState: RootState): UserAvatar {
  const user = rootState.user || ({} as any);
  return {
    email: user.email || "??",
    displayName: user.displayName || "??",
    _id: user._id || "??",
    imageThumbnailUrl: user.imageThumbnailUrl,
    createdAt: user.createdAt,
  };
}

interface ApprovalStore extends PGStore {
  reloadApprovals: (approval: CreateApproval) => void;
}

export function approvalsKey(state: ManageApprovals) {
  if (state.actionPlanId) {
    return state.actionPlanId;
  }

  return makeKey([
    pathOr("", ["question", "_id"], state) || state.kitId,
    state.pertainingToEntityId,
    state.connectionId,
    state.instanceId,
  ]);
}

const actions: PGActionTree<ManageApprovals, ApprovalStore> = {
  async init({commit, dispatch, rootState, state}: Ctx, args: InitArgs) {
    const manage: ManageApprovals = cloneDeep(initialState);
    manage.question = args.question || null;
    manage.actionPlanId = args.actionPlanId || null;
    manage.questionActual = pathOr(null, ["children", 0, "children", 0], args.question);
    manage.connectionId = args.connectionId || null;
    manage.connectionRole = args.connectionRole || null;
    manage.instanceId = args.instanceId || null;
    manage.pertainingToEntityId = args.pertainingToEntityId;
    manage.approvals = args.actionPlanId
      ? {[args.actionPlanId]: args.approvals[args.actionPlanId] || []}
      : args.approvalId && args.approvalId !== "new"
      ? {...state.approvals}
      : {}; // only used for action plans
    manage.open = args.open === undefined ? true : args.open;
    manage.editing = null;
    manage.placeAboveMenu = args.placeAboveMenu || null;
    manage.kitId = args.kitId || null;
    manage.kitType = args.kitType || null;
    logger.trace(() => `manage approvals init`, omit(["question"], args)); // question sometimes has a circular reference
    Object.keys(manage).forEach((k) => {
      if (!(k === "approvals" && isEmptyOrUndefined(manage[k]) && !isEmptyOrUndefined(state.approvals))) {
        // don't set approvals blank if already has data - prevents jumping on the approvals count button
        commit(k, (manage as any)[k]);
      }
    });

    if (has("approvals", args) && args.actionPlanId) {
      // if approvals were passed in
      logger.trace(() => `approvals already loaded`, args.approvals);
      if (args.approvalId) {
        await dispatch("autoSelect", {selected: args.approvalId});
      }
    } else if (!(args.approvalId && args.approvalId !== "new") && !args.kitId) {
      await dispatch("loadApprovals", {selectApproval: args.approvalId});
    } else if ((!has("approvals", args) || args.approvals === undefined) && !args.kitId) {
      await dispatch("loadApprovals");
    } else if (args.kitId) {
      if (args.approvalId) {
        await dispatch("loadApprovals", {selectApproval: args.approvalId});
      } else {
        await dispatch("loadApprovals");
      }
    }

    await dispatch("joinApprovalsRoom", {entity: manage.pertainingToEntityId});
    const userEntity = pathOr(null, ["user", "activeEntityId"], rootState);

    if (manage.connectionRole === ConnectionRole.BUYER && userEntity !== manage.pertainingToEntityId) {
      // if in a connection and user is buyer, join their own room as well
      logger.trace(() => `joining room ${userEntity} !== ${manage.pertainingToEntityId}`);
      await dispatch("joinApprovalsRoom", {entity: userEntity});
    }
  },

  async setApprovals({commit, state}: Ctx, {actionPlanId, approvals}: {actionPlanId: string; approvals: []}) {
    logger.trace(() => `setApprovals`, approvals);
    state.approvals[actionPlanId] = approvals;
    commit("approvals", state.approvals);
  },

  makeNew({commit, state, rootState}: Ctx) {
    commit("editing", null);
    commit("viewing", null);
    commit(
      "creating",
      identity<Partial<ApprovalResponse>>({
        type: ApprovalType.ANYONE,
        createdByUserAvatar: makeUserAvatar(rootState),
        assignees: [],
        element: pathOr(undefined, ["question", "_id"], state),
        actionPlan: state.actionPlanId || undefined,
        pertainingToEntity: state.pertainingToEntityId || undefined,
        connection: state.connectionId || undefined,
        instanceId: state.instanceId || undefined,
        kitId: state.kitId || undefined,
        kitType: state.kitType || undefined,
      }),
    );
    commit("showSendAlert", false);
  },

  edit({commit}, {approval}: {approval: ApprovalResponse}) {
    commit("creating", null);
    commit("viewing", null);
    commit("editing", cloneDeep(approval));
    commit("showSendAlert", false);
  },

  async view({commit, dispatch}, {approval}: {approval: ApprovalResponse}) {
    commit("creating", null);
    commit("editing", null);
    commit("viewing", approval);
    commit("showSendAlert", false);

    await dispatch("approvals/approve/setApproveData", {approval}, {root: true});
  },

  cancelViewEdit({commit}) {
    commit("creating", null);
    commit("viewing", null);
    commit("editing", null);
  },

  removeObjectApprovals({commit, state}, key: string) {
    delete state.approvals[key];
    commit("approvals", state.approvals);
  },

  sendReloadEvent({state}: Ctx) {
    this.emitter.$emit(ReviewEvent.RELOAD_APPROVALS, {
      questionId: state.question?._id,
      instanceId: state.instanceId,
      kitType: state.kitType,
      kitId: state.kitId,
    });
  },

  async cancelEdit({dispatch, state, commit}: Ctx) {
    const editing = (state.approvals[approvalsKey(state)] || []).find((a) => a._id === state.editing?._id);
    if (editing) {
      await dispatch("view", {approval: editing});
    }
    commit("showUpdateError", false);
  },

  async autoSelect({dispatch, state}: Ctx, {selected}: {selected: string}): Promise<void> {
    if (selected) {
      const toSelect = (state.approvals[approvalsKey(state)] || []).find((a) => a._id === selected);
      if (toSelect) {
        this.emitter.$emit(ProfileEvent.CST_CHILD_TOGGLE, {id: APPROVALS_MODAL_ID});
        await dispatch("view", {approval: toSelect});
      } else if (selected === "new") {
        this.emitter.$emit(ProfileEvent.CST_CHILD_TOGGLE, {id: APPROVALS_MODAL_ID});
        await dispatch("makeNew");
      }
    }
  },

  async loadApprovals({commit, state, dispatch}: Ctx, {selectApproval}: {selectApproval?: string} = {}): Promise<void> {
    commit("showLoadError", false);
    const {pertainingToEntityId, connectionId, instanceId, question, actionPlanId, kitId, kitType} = state;
    let url = `/api/approvals/entities/${pertainingToEntityId}?elementId=${question?._id || ""}`;
    if (actionPlanId) {
      url += `&actionPlanId=${actionPlanId}`;
    }
    if (connectionId) {
      url += `&connectionId=${connectionId}`;
    }
    if (instanceId) {
      url += `&instanceId=${instanceId}`;
    }
    if (kitId) {
      url += `&kitId=${kitId}&kitType=${kitType}`;
    }
    return req(async () => {
      try {
        const response = await this.httpGet<{approvals: ApprovalResponse[]}>(url);
        const approvals = {...state.approvals};
        approvals[approvalsKey(state)] = response.data.approvals;
        commit("approvals", approvals);
        if (selectApproval) {
          await dispatch("autoSelect", {selected: selectApproval});
        }
      } catch (e) {
        logger.error(() => `unable to load approvals`, e);
        commit("showLoadError", true);
      }
    }, commit);
  },

  async sendUpdate({commit, dispatch, state, rootState}: Ctx): Promise<void> {
    const edit = state.editing!;
    const approvals = [...state.approvals[approvalsKey(state)]];
    const originalIndex = approvals.findIndex((a) => a._id === edit._id);
    const originalApproval: ApprovalResponse = approvals[originalIndex];

    let update: UpdateApproval = {
      approvalId: edit._id!,
    };

    // if already approved, only send updatable items
    if (originalApproval && originalApproval.approved !== undefined) {
      const createdBy = originalApproval.createdByUserAvatar;
      const completedBy = originalApproval.completedBy;
      if (completedBy === rootState.user!._id) {
        update = {
          approvalId: edit._id!,
          approved: edit.approved,
          notes: edit.approvalNotes,
        };
      } else if (createdBy._id === rootState.user!._id) {
        update = {
          approvalId: edit._id!,
          notes: edit.creatorNotes,
          title: edit.title,
          subtitle: edit.subtitle,
        };
      } else {
        logger.error(() => `approval already completed and user not approver`);
        commit("showUpdateError", true);
      }
    } else {
      update = {
        approvalId: edit._id!,
        assignees: edit.assignees,
        notes: edit.creatorNotes,
        files: edit.files,
        title: edit.title,
        subtitle: edit.subtitle,
      };
    }

    const url = "/api/approvals/" + edit._id;
    return req(async () => {
      try {
        await this.httpPut<{approval: ApprovalResponse}>(url, update);
        await dispatch("sendReloadEvent");
      } catch (e) {
        logger.error(() => `unable to update approval: ${edit._id}`, e);
        commit("error", e);
        commit("showUpdateError", true);
      }
    }, commit);
  },

  async sendCreate({commit, dispatch, state}: Ctx): Promise<void> {
    const url = "/api/approvals";
    return req(async () => {
      try {
        await this.httpPost<{approvals: ApprovalResponse[]}>(url, state.creating!);
        await dispatch("sendReloadEvent");
      } catch (e) {
        logger.error(() => `unable to create approval`, e);
        commit("error", e);
        commit("showUpdateError", true);
      }
    }, commit);
  },

  async sendDelete({commit, dispatch, state}: Ctx): Promise<void> {
    const viewing = state.viewing!;
    const url = "/api/approvals/" + viewing._id;
    return req(async () => {
      try {
        await this.httpDelete(url);
        await dispatch("sendReloadEvent");
      } catch (e) {
        logger.error(() => `unable to delete approval: ${viewing._id}`, e);
        commit("error", e);
        commit("showUpdateError", true);
      }
    }, commit);
  },

  async remove({commit, dispatch}: Ctx, {approvalId}: {approvalId: string}): Promise<void> {
    return req(async () => {
      try {
        await this.httpDelete(`/api/approvals/${approvalId}`);
        await dispatch("sendReloadEvent");
      } catch (e) {
        logger.error(() => `unable to remove approval: ${approvalId}`, e);
        commit("error", e);
        commit("showUpdateError", true);
      }
    }, commit);
  },

  async joinApprovalsRoom({state, dispatch}: Ctx, {entity}: {entity?: string} = {}): Promise<void> {
    this.reloadApprovals = (approval: CreateApproval) => {
      logger.trace(() => `got approval change event`, approval);
      const elementMatch = String(approval.element) === (state.question && state.question._id) || approval.actionPlan;
      const instanceMatch = !state.instanceId || String(approval.instanceId) === state.instanceId;
      const connectionMatch = !state.connectionId || String(approval.connection) === state.connectionId;
      const kitId = !state.kitId || approval.kitId === state.kitId;
      const kitType = !state.kitType || approval.kitType === state.kitType;

      if ((connectionMatch && elementMatch && instanceMatch) || (kitId && kitType)) {
        dispatch("loadApprovals");
      } else {
        logger.warn(
          () => `not re-loading approvals because one of these`,
          {
            connectionMatch,
            elementMatch,
            instanceMatch,
          },
          {
            kitId,
            kitType,
          },
        );
      }
    };

    const entityId = entity || state.pertainingToEntityId;
    if (entityId) {
      logger.trace(() => `joining approval room for entity: ${entityId}`);
      this.join(RoutingKey.entityApproval({entityId}));
      this.socketOn(RoutingKey.entityApproval({entityId, evt: TaskChangeType.NEW}), this.reloadApprovals);
      this.socketOn(RoutingKey.entityApproval({entityId, evt: TaskChangeType.UPDATE}), this.reloadApprovals);
      this.socketOn(RoutingKey.entityApproval({entityId, evt: TaskChangeType.DELETE}), this.reloadApprovals);
      this.socketOn(RoutingKey.entityApproval({entityId, evt: TaskChangeType.COMPLETE}), this.reloadApprovals);
    }
  },

  async leaveApprovalsRoom({state}: Ctx, {entity}: {entity?: string} = {}): Promise<void> {
    const entityId = entity || state.pertainingToEntityId;
    if (entityId) {
      this.leave(RoutingKey.entityApproval({entityId}));
      this.socketOff(RoutingKey.entityApproval({entityId, evt: TaskChangeType.NEW}), this.reloadApprovals);
      this.socketOff(RoutingKey.entityApproval({entityId, evt: TaskChangeType.UPDATE}), this.reloadApprovals);
      this.socketOff(RoutingKey.entityApproval({entityId, evt: TaskChangeType.DELETE}), this.reloadApprovals);
      this.socketOff(RoutingKey.entityApproval({entityId, evt: TaskChangeType.COMPLETE}), this.reloadApprovals);
    }
  },
};

const getters: GetterTree<ManageApprovals, RootState> = {
  breadcrumbs(state: ManageApprovals): string[] {
    if (!state.question) {
      return [];
    }
    return questionLabelBreadcrumbs(state.question);
  },
  questionLabel(state: ManageApprovals): string {
    return state.question && state.question.label ? state.question.label : "";
  },
  allApprovals(state: ManageApprovals): ApprovalResponse[] {
    return state.approvals[approvalsKey(state)] || [];
  },
  incompleteApprovals(state: ManageApprovals): ApprovalResponse[] {
    return (state.approvals[approvalsKey(state)] || []).filter((a) => a.completedAt === undefined);
  },
  completeApprovals(state: ManageApprovals): ApprovalResponse[] {
    return (state.approvals[approvalsKey(state)] || []).filter((a) => a.completedAt !== undefined);
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: {
    ...autoMutations(initialState),
  },
  actions,
  getters,
};
