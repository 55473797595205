import {httpGet, httpPost} from "@/composables/xhr";
import {globalEmitter, GlobalEvent} from "@/store/plugins/emitter";
import type {BasicEntityInfoLite} from "pg-isomorphic/api/entity";
import type {
  SupplierSurveyData,
  SupplierSurveyResults,
  SupplierSurveySearchParams,
  SupplierSurveySearchResults,
} from "pg-isomorphic/api/suppliersurvey";
import type {UserAvatar} from "pg-isomorphic/api/user";

export interface SupplierSurveyOptions {
  headerText?: string; // "Thank You!";
  headerIcon?: string[]; // ["far", "check-circle"];
  bodyText?: string; // "";
  nextText?: string; // "continue";
  nextUrl?: string; // "/dash";
  connectionId?: string;
}

export function openSupplierSurvey(options: SupplierSurveyOptions = {}) {
  globalEmitter.emit(GlobalEvent.OpenSupplierSurvey, options);
}

export async function submitSupplierSurvey(results: SupplierSurveyResults): Promise<SupplierSurveyData> {
  return (await httpPost<SupplierSurveyData>("/api/supplier-surveys/submit", results)).data;
}

// SUPER ADMIN ENDPOINTS BELOW

export async function searchSupplierSurveys(params: SupplierSurveySearchParams): Promise<SupplierSurveySearchResults> {
  return (await httpPost<SupplierSurveySearchResults>("/api/admin/supplier-surveys/search", params)).data;
}

export async function supplierSurveysUserOptions(): Promise<UserAvatar[]> {
  return (await httpGet<UserAvatar[]>("/api/admin/supplier-surveys/user-options")).data;
}

export async function supplierSurveysEntityOptions(): Promise<BasicEntityInfoLite[]> {
  return (await httpGet<BasicEntityInfoLite[]>("/api/admin/supplier-surveys/entity-options")).data;
}
