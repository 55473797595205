import {createRouter, createWebHistory} from "vue-router";
import {responderExcelRoute, responderExcelSsoHandlerRoute, responderSignUpRoute} from "./responderRoutes";

// remove the `menu_type` from the meta
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const {menu_type, ...meta} = responderSignUpRoute.meta;
const responderExcelSignUpRoute = {
  ...responderSignUpRoute,
  meta: {
    ...meta,
  },
};

export function makeExcelRouter() {
  return createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
      if (from && Object.keys(to.query).length) {
        if (to.fullPath.split("?")[0] === from.fullPath.split("?")[0]) return;
      }
      if (savedPosition) {
        return savedPosition;
      } else {
        return {left: 0, top: 0};
      }
    },
    routes: [
      {
        path: "/responder/signin",
        redirect: "/signin",
      },
      {
        path: "/signin",
        component: () => import("@/components/auth/SignIn.vue"),
        meta: {
          requiresAuth: false,
          title: "nav.sign_in",
        },
        props: {
          isResponder: true,
        },
      },
      {
        path: "/signout",
        component: () => import("@/components/auth/SignOut.vue"),
        meta: {
          requiresAuth: false,
        },
      },
      responderExcelSignUpRoute,
      responderExcelSsoHandlerRoute,
      // this must go last!
      responderExcelRoute,
    ],
  });
}
