import jwtDecode from "jwt-decode";
import globalLogger from "../../logging";

/**
 * Handles storing the JWT and reading it back out
 * @param store vuex store
 */
export default function (store) {
  store.setJWT = (jwt, expiresOn) => {
    store.commit("JWT", jwt);
    store.commit("JWTExpiresOn", expiresOn);
    globalLogger.trace("jwt", jwtDecode(jwt));
  };

  store.removeJWT = () => {
    store.commit("JWT", null);
    store.commit("JWTExpiresOn", null);
  };
}
