import {prop} from "ramda";
import io from "socket.io-client";
import {WebSocketServerEvent} from "pg-isomorphic/enums/queue";
import globalLogger from "../../logging";
import {getJWTJson} from "../utils";
import * as Sentry from "@sentry/browser";

const logger = globalLogger.getLogger("socket");

const WARN_AFTER_MS = 15 * 1000;
const RECONNECT_AFTER_SECONDS = 10;

function startWebsocketConnectionTimer(store, socket) {
  stopReconnectTimer(store);
  if (!store.state.JWT) {
    return;
  }
  const ws = store.state.websocket;
  ws.showDisconnectedWarning = false;
  ws.lastConnectionAttemptedAt = new Date();
  ws.reconnectingInSeconds = RECONNECT_AFTER_SECONDS;
  ws.timerHandle = setInterval(() => {
    ws.elapsedSinceLastConnectionAttempt = new Date().valueOf() - ws.lastConnectionAttemptedAt.valueOf();
    if (ws.elapsedSinceLastConnectionAttempt >= WARN_AFTER_MS) {
      if (!ws.showDisconnectedWarning) {
        ws.showDisconnectedWarning = true;
        logger.debug("startWebsocketConnectionTimer turned on socket disconnected warning");
        reportConnectionError(store, socket);
      }
      if (ws.reconnectingInSeconds <= 0) {
        ws.reconnectingInSeconds = RECONNECT_AFTER_SECONDS;
      } else {
        ws.reconnectingInSeconds -= 1;
      }
    }
  }, 1000);
  logger.debug(`started ws timer ${ws.timerHandle}`);
}

function websocketAuthenticated(store) {
  const ws = store.state.websocket;
  stopReconnectTimer(store);
  store.socketAuthenticated = true;
  ws.lastSuccessfullyConnectedAt = new Date();
  ws.reconnectAttempt = 0;
  ws.showDisconnectedWarning = false;
  ws.connected = true;
}

function stopReconnectTimer(store) {
  const ws = store.state.websocket;
  if (ws.timerHandle !== null) {
    logger.debug(`clearing ws timer ${ws.timerHandle}`);
    clearInterval(ws.timerHandle);
    ws.timerHandle = null;
  }
}

function reportConnectionError(store, socket) {
  Sentry.withScope((scope) => {
    scope.setTag("websocket", "disconnected");
    scope.setExtra(
      "socketInfo",
      JSON.stringify({
        id: socket.id,
        rawSocketConnected: socket.connected,
        authenticated: store.socketAuthenticated,
        ...store.state.websocket,
      }),
    );
    if (store.state.isAuthenticated) {
      Sentry.captureException(new Error(`socket unable to connect`));
    }
  });
}

export default function (store) {
  store.socketAuthenticated = false;
  let manuallyDisconnected = false;

  const socket = (store.socket = io({
    transports: ["websocket"],
    autoConnect: !!store.state.JWT,
  }));

  startWebsocketConnectionTimer(store, socket);

  function reconnect() {
    if (socket.connected) {
      socket.close();
    }
    setTimeout(() => socket.connect(), 1000);
  }

  const currentRooms = [];
  function addRoom({room, context}) {
    currentRooms.push({room, context});
  }
  function removeRoom(room) {
    const toKeep = currentRooms.filter((rc) => rc.room !== room);
    currentRooms.length = 0;
    toKeep.forEach((rc) => currentRooms.push(rc));
  }
  function rejoinRooms() {
    currentRooms.forEach(({room, context}) => {
      socket.emit(WebSocketServerEvent.JOIN, {room, context});
    });
  }

  let _socketResolver = null;
  let _socketPromise = null;
  let firstTime = true;
  const joinPromises = {};

  function setupSocketPromise() {
    _socketPromise = new Promise((resolve) => {
      _socketResolver = resolve;
    });
  }
  setupSocketPromise();

  const socketEvents = {};

  function onConnect() {
    logger.debug(() => `${socket.id} socket connected`);
    if (store.state.JWT && store.state.alwaysReconnect) {
      socket.emit(WebSocketServerEvent.AUTHENTICATE, {token: store.state.JWT});
    }
    if (store.state.JWT && !store.state.alwaysReconnect) {
      logger.debug(() => `${socket.id} onConnect not authenticating because alwaysReconnect is false`);
    }
  }
  socket.on("connect", onConnect);

  socket.on(WebSocketServerEvent.API_VERSION_INFO, (info) => {
    store.commit("apiVersionInfo", info);
  });

  socket.io.on("reconnect", (attempt) => {
    logger.debug(`${socket.id} reconnected after ${attempt} attempts`);
  });

  socket.on("connect_error", (error) => {
    logger.debug(() => `${socket.id} reconnecting ${error}`);
  });

  socket.io.on("reconnect_attempt", (attempt) => {
    store.state.websocket.reconnectAttempt = attempt;
    logger.debug(`${socket.id} reconnect attempt ${attempt}`);
  });

  socket.on("disconnect", (reason) => {
    logger.debug(`${socket.id} disconnected: ${reason}, always reconnect=${store.state.alwaysReconnect}`);
    store.state.websocket.connected = false;
    if (!manuallyDisconnected) {
      startWebsocketConnectionTimer(store, socket);
    }
    if (!firstTime) {
      setupSocketPromise();
    }
    if (reason === "io server disconnect") {
      // the disconnection was initiated by the server, you need to reconnect manually
      if (store.state.alwaysReconnect) {
        setTimeout(() => socket.connect(), 1000);
      }
    }
  });

  socket.on(WebSocketServerEvent.AUTHENTICATED, () => {
    logger.debug(() => `${socket.id} socket authenticated`);
    websocketAuthenticated(store);
    _socketResolver(socket);
    if (firstTime) {
      firstTime = false;
    } else {
      rejoinRooms();
    }
  });

  socket.on(WebSocketServerEvent.UNAUTHORIZED, (error) => {
    logger.debug(`${socket.id} socket connect unauthorized`, error);
    store.state.websocket.connected = false;
  });

  socket.on(WebSocketServerEvent.JOINED, ({room, context}) => {
    logger.debug(() => `${socket.id} joined room ${room} ${JSON.stringify(context)}`);
    if (joinPromises[room]) {
      joinPromises[room].forEach(({resolve}) => resolve());
      joinPromises[room].length = 0;
    }
  });

  socket.on(WebSocketServerEvent.LEFT, (room) => {
    logger.debug(() => `${socket.id} left room ${room}`);
  });

  store.watch(prop("JWT"), (jwt, oldJwt) => {
    const newInfo = getJWTJson(jwt);
    const oldInfo = getJWTJson(oldJwt);
    const sameLogin = newInfo.entity === oldInfo.entity && newInfo.sub === oldInfo.sub;
    const same2fa = newInfo.tfa === oldInfo.tfa;
    logger.debug(() => `${socket.id} JWT changed! have=${!!jwt} sameLogin=${sameLogin} same2fa=${same2fa}`);
    const oldSocketId = socket.id;
    if (socket.connected && store.socketAuthenticated && (!sameLogin || !same2fa)) {
      logger.debug(() => `${socket.id} session changed, closing socket`);
      socket.close();
      store.socketAuthenticated = false;
    }

    if (jwt && (!socket.connected || !store.socketAuthenticated)) {
      logger.debug(() => `${oldSocketId} reconnecting`);
      startWebsocketConnectionTimer(store, socket);
      reconnect();
    }
  });

  store.join = async (room, context) => {
    addRoom({room, context});
    const s = await _socketPromise;
    s.emit(WebSocketServerEvent.JOIN, {room, context});
  };

  store.leave = async (room) => {
    removeRoom(room);
    const s = await _socketPromise;
    s.emit(WebSocketServerEvent.LEAVE, {room});
  };

  store.socketOn = async (event, onEvent) => {
    const s = await _socketPromise;

    // wrap listeners so we can log errors
    const wrap = function (...args) {
      try {
        onEvent(...args);
      } catch (e) {
        logger.error(`Socket error on ${event}`, e);
        throw e;
      }
    };
    if (!socketEvents[event]) {
      socketEvents[event] = [];
    }
    socketEvents[event].push([onEvent, wrap]);
    // logger.trace(() => `${socket.id} register handler for ${event}`);
    s.on(event, wrap);
  };

  store.socketOff = async (event, onEvent) => {
    const s = await _socketPromise;
    const listeners = socketEvents[event] || [];
    // remove the matching wrapper
    for (const pair of listeners) {
      if (pair[0] === onEvent) {
        s.off(event, pair[1]);
      }
    }
  };

  store.emit = async (event, data) => {
    const s = await _socketPromise;
    // logger.trace(() => `${socket.id} emitting ${event}`);
    s.emit(event, data);
  };

  store.whenRoomJoined = async (room) => {
    if (!joinPromises[room]) {
      joinPromises[room] = [];
    }
    return new Promise((resolve, reject) => {
      joinPromises[room].push({resolve, reject});
    });
  };

  store.socketDisconnect = () => {
    manuallyDisconnected = true;
    if (socket && socket.connected) {
      socket.close();
    }
  };

  if (window && !window._socket) {
    window._socket = {
      socket,
      store,
      _socketPromise,
      currentRooms,
      joinPromises,
    };
  }
}
