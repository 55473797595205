import {contains, sort, union} from "ramda";
import {isVisibleAt, VisibleStages} from "../connections";
import {ConnectionRole, Stage} from "../enums";
import {VisibilitySummary, VisibilitySummaryLabel} from "../enums/question";
import {JSONQuestion} from "../utils";
// import {checkForEmptyValue, isQuestion, processTreeBottomUp} from "./index";
import {isQuestion, processTreeBottomUp} from "./index";

const simpleSort = sort((a: number, b: number) => a - b);

const processVisibility =
  (propName: string, elementVisibility: (q: JSONQuestion) => number) => (questions: JSONQuestion) => {
    const setVisibilitySummary = (q: JSONQuestion): void => {
      let accessVisibility: number[] = [];
      if (!isQuestion(q) && q.children) {
        q.children.forEach((c) => {
          accessVisibility = simpleSort(union(accessVisibility, c[propName] as number[]));
        });
      }
      const elementVisibilityValue = elementVisibility(q);
      // console.log(`exiting value "${elementVisibilityValue}"`, accessVisibility);
      if (elementVisibilityValue && !contains(elementVisibilityValue, accessVisibility)) {
        accessVisibility.push(elementVisibilityValue);
      }
      q[propName] = accessVisibility;
    };
    processTreeBottomUp(setVisibilitySummary)(questions);
  };

export const processVisibilitySummary = processVisibility("visibilitySummary", (q) => {
  if (!(isQuestion(q) && q.visible && q.actorRole)) {
    return VisibilitySummary.NONE;
  }

  if (q.visibleStages === 0) {
    return VisibilitySummary.PRIVATE;
  } else if (isVisibleAt(q.visibleStages as VisibleStages, Stage.PUBLIC)) {
    return VisibilitySummary.PUBLIC;
  } else {
    if (q.actorRole === ConnectionRole.BOTH) {
      return VisibilitySummary.CONNECTIONS;
    } else if (q.actorRole === ConnectionRole.SELLER) {
      return VisibilitySummary.CUSTOMER_CONNECTIONS;
    } else if (q.actorRole === ConnectionRole.BUYER) {
      return VisibilitySummary.SUPPLIER_CONNECTIONS;
    }
  }

  return VisibilitySummary.NONE;
});

export function summarizeVisibilitySummaries(visibilitySummary: number[]) {
  if (contains(VisibilitySummary.PUBLIC, visibilitySummary)) {
    if (visibilitySummary.length > 2) {
      return VisibilitySummaryLabel.MIX_CONNECTIONS;
    } else if (visibilitySummary.length > 1) {
      if (contains(VisibilitySummary.SUPPLIER_CONNECTIONS, visibilitySummary)) {
        return VisibilitySummaryLabel.MIX_SUPPLIER_CONNECTIONS;
      } else if (contains(VisibilitySummary.CUSTOMER_CONNECTIONS, visibilitySummary)) {
        return VisibilitySummaryLabel.MIX_CUSTOMER_CONNECTIONS;
      } else {
        return VisibilitySummaryLabel.MIX_CONNECTIONS;
      }
    }
    return VisibilitySummaryLabel.PUBLIC;
  } else if (
    contains(VisibilitySummary.CONNECTIONS, visibilitySummary) ||
    (contains(VisibilitySummary.CUSTOMER_CONNECTIONS, visibilitySummary) &&
      contains(VisibilitySummary.SUPPLIER_CONNECTIONS, visibilitySummary))
  ) {
    return VisibilitySummaryLabel.CONNECTIONS;
  } else if (contains(VisibilitySummary.CUSTOMER_CONNECTIONS, visibilitySummary)) {
    return VisibilitySummaryLabel.CUSTOMER_CONNECTIONS;
  } else if (contains(VisibilitySummary.SUPPLIER_CONNECTIONS, visibilitySummary)) {
    return VisibilitySummaryLabel.SUPPLIER_CONNECTIONS;
  } else if (contains(VisibilitySummary.PRIVATE, visibilitySummary)) {
    return VisibilitySummaryLabel.PRIVATE;
  }

  return VisibilitySummaryLabel.NONE;
}
