import {getStore} from "@/composables/get.store";
import jwtDecode from "jwt-decode";
import {fromPairs, keys, map, pathOr} from "ramda";
import type {MutationTree} from "vuex";

import type {ComputedOptions} from "vue";

// export declare function autoMutations(initialState: any): any;
// export declare function simpleMutation<STATE>(propName: string): (state: STATE, value: any) => void;
// export declare function computedProperty<T>(propName: string, path?: string, defaultValue?: any): ComputedOptions<T>;
// export declare function mapComputedProperties(path: string, propNames: string[]): any;
// export declare function postToNewWindow(url: string, body: object, jsonFieldName: string): void;
// export declare function oppositeProfile(profile: string): string;
// export declare function dynamicMapState(path: string, propNames: string[]): any;

export const simpleMutation = (propName: string) => (state, value) => {
  state[propName] = value;
};

const resolvePath = (ctx, path) => {
  if (typeof path === "function") {
    return path.call(ctx);
  }
  return path;
};

export const computedProperty = (propName: string, path: string, defaultValue?: any) => {
  const store = getStore();
  defaultValue = typeof defaultValue !== "undefined" ? defaultValue : {};
  return {
    get() {
      if (path) {
        return pathOr(defaultValue, resolvePath(this, path).split("/"), store.state)[propName];
      }
      return store.state[propName];
    },
    set(x) {
      let propPath = propName;
      if (path && resolvePath(this, path).length) {
        propPath = `${resolvePath(this, path)}/${propName}`;
      }
      store.commit(propPath, x);
    },
  };
};

export const mapComputedProperties = (path: string, propNames: string[]) => {
  const mapped = map((name) => [name, computedProperty(name, path)], propNames);
  return fromPairs(mapped as any);
};

export const autoMutations = (initialState: any): MutationTree<any> => {
  const stateKeys = keys(initialState);
  const mapped = map((key) => [key, simpleMutation(String(key))], stateKeys);
  return fromPairs(mapped as any);
};

// Like vuex mapState but path can be a function, evaluated in the component scope
// prop names must be an array of strings, no fancy objects, functions, etc...
export const dynamicMapState = (path, propNames) => {
  const store = getStore();
  const props = {};
  for (const propName of propNames) {
    props[propName] = function () {
      return pathOr(null, [...resolvePath(this, path).split("/"), propName], store.state);
    };
  }
  return props;
};

export function postToNewWindow(url, body, jsonFieldName) {
  const form = document.createElement("form");
  form.setAttribute("method", "post");
  form.setAttribute("action", url);
  form.setAttribute("target", "_blank");

  const input = document.createElement("input");
  input.type = "hidden";
  input.name = jsonFieldName;
  input.value = JSON.stringify(body);
  form.appendChild(input);

  document.body.appendChild(form);

  form.submit();

  document.body.removeChild(form);
}

export const createTranslatedTextGetters = (fieldList: string[]) => {
  const getters = {};
  for (const fieldName of fieldList) {
    getters[`${fieldName}Translated`] = () => {};
  }
  return getters;
};

export const getJWTJson = (token) => {
  if (!token) {
    return {};
  }
  try {
    return jwtDecode(token);
  } catch (err) {
    return {};
  }
};
