export enum ValidationStatus {
  VALIDATED = "validated",
  PENDING = "pending",
  FAILED = "failed",
}
export interface GetValidationTaskParameters {
  customer?: string;
  updatedBy?: string;
  topic?: string[];
  status?: string[];
  stage?: string[];
  sortBy?: string;
  sortOrder?: string;
  limit?: string;
}

export interface TestBankDocResponse {
  arrayOfFieldMatches: string[][];
  arrayOfRawLines: Array<string[] | undefined>;
  matchResult: boolean;
}

export enum ProveIdentityVersion {
  DOMESTIC = "domestic",
  INTERNATIONAL = "international",
}
