import type {Logger} from "@/logging";
import type {FallbackLegacyEmitter} from "@/store/plugins/emitter";
import type {AxiosResponse} from "axios";
import type {BasicUser} from "pg-isomorphic/api/user";
import {ErrorCode} from "pg-isomorphic/enums";
import type {ActionContext, ActionObject, Commit, Getter, Store, createStore} from "vuex";
import type {Permission} from "pg-isomorphic/permissions";
import type {JSONObject} from "pg-isomorphic/utils";

// Basic User does has Permission[] and not Set, but in our store we use set. Changing basic user breaks
// other stuff on the backend so I'm extending with the exception following exceptions.
export interface RootStateUser extends Omit<BasicUser, "permissions" | "topicOwnerRoles"> {
  permissions: Set<Permission>;
  topicOwnerRoles: Set<string>;
}

export interface RootStateProfile {
  mineEntity: JSONObject;
}

export interface RootState {
  loading: boolean;
  isAuthenticated: boolean;
  user?: RootStateUser;
  profile?: RootStateProfile;
  envConfig: any;
  JWT?: string;
}

export interface PGStore<AnyState = RootState> extends Store<AnyState> {
  emitter: FallbackLegacyEmitter;

  httpGet<T>(url: string, params?: object): Promise<AxiosResponse<T>>;

  httpPost<T>(url: string, data: object): Promise<AxiosResponse<T>>;

  httpPatch<T>(url: string, data: object): Promise<AxiosResponse<T>>;

  httpPut<T>(url: string, data: object): Promise<AxiosResponse<T>>;

  httpDelete<T>(url: string, params?: object): Promise<AxiosResponse<T>>;

  httpRequest<T>(method: string, options: {url: string; params: object; data: object}): Promise<AxiosResponse<T>>;

  setJWT(token: string, expiresOn: number, remember: boolean): void;

  join(room: string, context?: {[key: string]: any}): void;

  whenRoomJoined(room: string): Promise<void>;

  leave(room: string): void;

  socketOn(roomKey: string, func: (msg: any) => void): void;

  socketOff(roomKey: string, func: (msg: any) => void): void;

  socket?: {id: string};

  getUserLocalizedText(txtOrObj: string | {[key: string]: any}, allowMachineTranslations?: boolean): string;

  getUserLocale(): string;
}

export interface WithPGStore {
  $store: PGStore;
}

export type PGActionHandler<S, T extends PGStore = PGStore> = (
  this: T,
  injectee: ActionContext<S, RootState>,
  payload?: any,
) => any;
export type PGAction<S, T extends PGStore = PGStore> = PGActionHandler<S, T> | ActionObject<S, RootState>;
export type PGActionContext<S> = ActionContext<S, RootState>;

export interface PGActionTree<S, T extends PGStore = PGStore> {
  [key: string]: PGAction<S, T>;
}

export type PGGetter<S> = Getter<S, RootState>;

export interface PGGetterTree<S> {
  [key: string]: PGGetter<S>;
}

export type PGMutation<S, T extends PGStore = PGStore> = (this: T, state: S, payload?: any, payload2?: any) => any;

export interface PGMutationTree<S, T extends PGStore = PGStore> {
  [key: string]: PGMutation<S, T>;
}

type ActionRequestFunc<T> = (fn: () => Promise<T>, commit: Commit, errorMessage?: string) => Promise<T | undefined>;
export const actionRequestWrap =
  <T = void>(logger: Logger, generalErrorMessage?: string): ActionRequestFunc<T> =>
  async (fn: () => Promise<T>, commit: Commit, errorMessage?: string): Promise<T | undefined> => {
    try {
      commit("loading", true);
      return await fn();
    } catch (err) {
      logger.error(errorMessage || generalErrorMessage || "error loading request", err);
      if (err.response) {
        commit("error", err.response.data);
      } else {
        commit("error", {code: ErrorCode.UNKNOWN_ERROR});
      }
      return undefined;
    } finally {
      commit("loading", false);
    }
  };
