export enum ImportSeverity {
  FATAL = "fatal",
  ERROR = "error",
  WARNING = "warning",
}

export enum ImportState {
  RUNNING = "running",
  CANCELLED = "cancelled",
  COMPLETED = "completed",
}

export enum ExportStatus {
  SEARCHING = "searching",
  LOADING_QUESTIONS = "loading_questions",
  LOADING_CONNECTIONS = "loading_connections",
  ROW = "row",
  TASKS = "tasks",
  UPLOAD = "upload",
  READY = "ready",
  ERROR = "error",
  ROW_ERROR = "row_error",
  TABLE_2FA_ERROR = "table_2fa_error",
  APPROVALS = "approvals",
}

export enum ReportExportStatus {
  SEARCHING = "searching",
  LOADING_VALIDATIONS = "loading_validations",
  LOADING_CONNECTIONS = "loading_connections",
  LOADING_ENTITIES = "loading_entities",
  WRITING_TO_FILE = "writing_to_file",
  READY = "ready",
  ERROR = "error",
  DUPLICATES = "finding_duplicates",
}
