import type {MessageRepo} from "@/composables/messaging/message-repo";
import cloneDeep from "lodash/cloneDeep";
import type {Module, MutationTree} from "vuex";
import {autoMutations} from "../../utils";

export interface FloatingChatWindowState {
  open: boolean;
  messageRepo: MessageRepo | null;
}

const initialState: FloatingChatWindowState = {
  open: false,
  messageRepo: null,
};

const mutations: MutationTree<FloatingChatWindowState> = {
  ...autoMutations(initialState),
};

const floatingChatWindowStore: Module<FloatingChatWindowState, any> = {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations,
};

export default floatingChatWindowStore;
