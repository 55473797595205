import globalLogger from "../../logging";
import {autoMutations} from "../utils";
import {findByKey, processTreeTopDown, findSubTopic, findTopic} from "pg-isomorphic/profile";
import {GroupType} from "pg-isomorphic/enums";
import {unflattenAnswerKeys} from "pg-isomorphic/answers";

const logger = globalLogger.getLogger("history");

const state = {
  loading: false,
  breadcrumbs: [],
  contextName: "",
  contextImg: "",
  elementId: null,
  ackId: null,
  questionData: null,
  showHistoryModal: false,
  windowOpen: false,
  entityId: null,
  connectionId: null,
  messageTitle: "",
  answerKeys: [],
  elementsByKey: [],
  results: [],
};

const getters = {};

const actions = {
  async init({commit, dispatch}, {questionData, connectionId, entityId, breadcrumbs, contextName, contextImg}) {
    commit("loading", true);

    logger.debug(`initHistory ${entityId} ${connectionId} ${questionData.key}`);
    commit("showHistoryModal", true);
    commit("entityId", entityId);
    commit("elementId", questionData._id);
    commit("connectionId", connectionId);
    commit("breadcrumbs", breadcrumbs);
    commit("contextName", contextName);
    commit("contextImg", contextImg);
    commit("questionData", questionData);
    commit("messageTitle", questionData.label || (questionData.parent && questionData.parent.label));

    if (connectionId) {
      if (questionData.type === GroupType.TOPIC || questionData.type === GroupType.SUBTOPIC) {
        commit("ackId", questionData._id);
      } else {
        const topic = findSubTopic(questionData) || findTopic(questionData);
        commit("ackId", topic._id);
      }
    }

    await dispatch("answerKeys", {questionData});

    const elementsByKey = {};
    if (state.answerKeys && state.answerKeys.length > 0) {
      state.answerKeys.forEach((key) => {
        elementsByKey[key] = findByKey(key, questionData);
      });
    } else {
      elementsByKey[questionData.key] = questionData;
    }
    commit("elementsByKey", elementsByKey);

    await dispatch("getHistory");
  },
  async getHistory({commit, state}) {
    state.results = [];
    let query = {};

    if (!state.questionData) {
      return false;
    }

    const keyParts = state.questionData.key.split(".");

    if (keyParts.length === 1 && state.answerKeys.length < 1) {
      // basic question
      query = {
        answerKeys: unflattenAnswerKeys([state.questionData.key]),
      };
    } else if (keyParts.length > 1) {
      // part of a group
      query = {
        instanceId: keyParts[1],
        answerKeys: {[keyParts[0]]: unflattenAnswerKeys(state.answerKeys.map((k) => k.split(".")[2]))},
      };
    } else {
      // topic
      query = {
        answerKeys: unflattenAnswerKeys(state.answerKeys),
      };
    }

    const data = {
      pertainingToEntityId: state.entityId,
      connectionId: state.connectionId,
      query,
    };

    if (state.ackId) {
      data.acknowledgementElementId = state.ackId;
    }

    logger.trace("history query", data);

    try {
      const results = await this.httpPost("/api/answers/history", data);

      if (results.status === 200) {
        state.results = results.data.results;
        logger.trace("history results", state.results);
        commit("loading", false);
      } else {
        logger.error("history error", results.data);
        commit("loading", false);
      }
    } catch (e) {
      logger.error("history ex", e);
      commit("loading", false);
    }
  },
  async answerKeys({commit}, {questionData}) {
    const kidsKeys = [];
    processTreeTopDown(async (q) => {
      if (q.type !== GroupType.SUBTOPIC && q.visible) {
        kidsKeys.push(q.key);
      }
    })(questionData);

    commit("answerKeys", kidsKeys);
  },
};

const mutations = {
  ...autoMutations(state),
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
