import type {MutationTree} from "vuex";
import {autoMutations} from "../utils";

export interface DataShareState {
  dialogOpen: boolean;
}

export interface LoginResponseData {
  token: string;
  tokenExpiresOn: number;
}

const initialState: DataShareState = {
  dialogOpen: false,
};

const mutations: MutationTree<DataShareState> = {
  ...autoMutations(initialState),
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations,
};
