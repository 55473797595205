import type {PGActionContext, PGActionTree} from "../index-types";
import {autoMutations} from "../utils";
import type {TranslateResult} from "vue-i18n";

export interface ConfirmState {
  isOpen: boolean;
  text: string;
  title: string;
  buttonText: string;
  cancelText: string;
  showCancel: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const initialState: ConfirmState = {
  isOpen: false,
  text: "",
  title: "",
  buttonText: "",
  cancelText: "",
  showCancel: true,
  onCancel: () => {
    /**/
  },
  onConfirm: () => {
    /**/
  },
};

type Ctx = PGActionContext<ConfirmState>;
const actions: PGActionTree<ConfirmState> = {
  confirm(
    {commit}: Ctx,
    {
      text,
      title,
      buttonText,
      cancelText,
      showCancel,
    }: {
      text: string | TranslateResult;
      title?: string | TranslateResult;
      buttonText?: string | TranslateResult;
      cancelText?: string | TranslateResult;
      showCancel?: boolean | TranslateResult;
    },
  ) {
    commit("text", text);
    commit("title", title);
    commit("buttonText", buttonText);
    commit("cancelText", cancelText);
    if (showCancel !== undefined) {
      commit("showCancel", showCancel);
    }
    commit("isOpen", true);
    return new Promise((resolve) => {
      commit("onCancel", () => resolve(false));
      commit("onConfirm", () => resolve(true));
    });
  },
};

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  mutations: autoMutations(initialState),
  actions,
};
