import dompurify from "dompurify";
import {getUserLocale} from "@/composables/vuex";
import {Locale} from "pg-isomorphic/enums";

export function sanitizeTextForHtml(dirty: string): string {
  return dompurify().sanitize(dirty, {USE_PROFILES: {html: true}});
}

export function textDirection(txt: string): string {
  const locale = getUserLocale();
  if (locale !== Locale.HE_IL) {
    return "ltr";
  } else if (/[\u0590-\u05FF]/.test(txt)) {
    return "rtl";
  }
}
