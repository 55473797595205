import {topicPermissionUnlocked} from "@/composables/2FA";
import {TwoFaInputMode} from "@/composables/questions/types";
import type {PGActionContext, PGActionTree, RootState} from "@/store/index-types";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import type {TwoFaNonProfileSection, TwoFaTopicPermission} from "pg-isomorphic/api/user";
import {Profile} from "pg-isomorphic/enums";
import {ExpressionEvaluator} from "pg-isomorphic/rules/expression";
import {sleep} from "pg-isomorphic/utils";
import {path, pluck, uniq} from "ramda";
import type {GetterTree} from "vuex";
import globalLogger from "../../logging";
import {autoMutations} from "../utils";

const logger = globalLogger.getLogger("two-factor");

interface TwoFactorState {
  loading: boolean;
  reVerifyLoading: boolean;
  // Below flags mean user is 2fa auth'd and their identity is verified for that area.
  verifiedForAdmin: boolean; // Admin sections.
  verifiedForPersonal: boolean; // Editing personal 2fa settings.
  verifiedForQuestions: boolean; // Editing questions -- banking topic is handled specially.
  verifiedForApprovals: boolean; // Approvals.
  verifyingTopic: string;
  verifyingTopicId: string;
  verifiedTopics: Record<string, TwoFaTopicPermission>;
  siblings: any[];
  connectionId: string;
  cacheSpecificAnswers: {};
  currentProfile: Profile;
  show2fa: boolean;
  nonProfile: boolean;
  section: TwoFaNonProfileSection | "";
  onVerified: () => Promise<void>;
  onCancel: null;
  getAndUpdateSpecificAnswers: {};
  securedForApproval: boolean;
  editMode: boolean;
  securityRoles: string[];
  instanceKeys: string[];
  serverSideTopicIds: string[];
}

const state: TwoFactorState = {
  loading: false,
  reVerifyLoading: false,
  verifiedForAdmin: false,
  verifiedForPersonal: false,
  verifiedForQuestions: false,
  verifiedForApprovals: false,
  verifyingTopic: "",
  verifyingTopicId: "",
  verifiedTopics: {},
  siblings: [],
  connectionId: "",
  cacheSpecificAnswers: {},
  currentProfile: Profile.MINE,
  show2fa: false,
  nonProfile: false,
  section: "",
  onVerified: null,
  onCancel: null,
  getAndUpdateSpecificAnswers: {},
  securedForApproval: false,
  editMode: false,
  securityRoles: [],
  instanceKeys: [],
  serverSideTopicIds: [],
};

type Ctx = PGActionContext<TwoFactorState>;

const getters: GetterTree<TwoFactorState, RootState> = {
  nameOfVerifiedFlag(state: TwoFactorState): string {
    if (state.section === "admin") {
      return "verifiedForAdmin";
    } else if (state.section === "personal") {
      return "verifiedForPersonal";
    } else if (state.securedForApproval) {
      return "verifiedForApprovals";
    } else {
      return "verifiedForQuestions";
    }
  },
  inputMode(state: TwoFactorState): TwoFaInputMode {
    if (state.nonProfile) {
      return TwoFaInputMode.NON_PROFILE;
    } else if (state.verifyingTopic) {
      return TwoFaInputMode.QUESTION;
    } else if (!isEmpty(state.securityRoles)) {
      return TwoFaInputMode.SECURITY_ROLES;
    } else if (!isEmpty(state.instanceKeys)) {
      return TwoFaInputMode.INSTANCE_KEYS;
    } else {
      throw new Error(`Invalid input mode for 2fa`);
    }
  },
};

function getAnswerKeysWithCalcDeps(siblings: any[]) {
  const siblingKeys = pluck("key", siblings);
  const keys = [];
  siblingKeys.map((s) => {
    const parts = s.split(".");
    if (parts.length > 1) {
      // it's a group_instance key
      keys.push(parts[0]); // group name
      keys.push(parts[2]); // actual key
    } else {
      keys.push(s);
    }
  });
  siblings.map((s) => {
    if (s.calculation) {
      const calcDeps = new ExpressionEvaluator(s.calculation).answerKeys;
      keys.push(...calcDeps);
    }
  });
  return uniq(keys);
}

const actions: PGActionTree<TwoFactorState> = {
  async init(
    {commit, dispatch, state, rootState, getters}: Ctx,
    {
      siblings,
      topicElement,
      securedForApproval,
      editMode,
      securityRoles,
      instanceKeys,
      serverSideTopicIds,
      currentProfile,
      connectionId,
      onVerified,
      onCancel,
    },
  ) {
    commit("connectionId", connectionId);
    commit("currentProfile", currentProfile);
    commit("verifyingTopic", topicElement?.key);
    commit("verifyingTopicId", topicElement?._id);
    commit("nonProfile", false);
    commit("section", "");
    commit("securedForApproval", securedForApproval);
    commit("editMode", editMode);
    commit("securityRoles", securityRoles);
    commit("instanceKeys", instanceKeys);
    commit("serverSideTopicIds", serverSideTopicIds);
    commit("onVerified", onVerified);
    commit("onCancel", onCancel);

    const inputMode = getters.inputMode;
    switch (inputMode) {
      case TwoFaInputMode.NON_PROFILE:
        throw new Error(`Invalid input mode for twoFactor.init: ${inputMode}`);
      case TwoFaInputMode.QUESTION: {
        const siblingKeys = getAnswerKeysWithCalcDeps(siblings);
        commit("siblings", siblingKeys);
        logger.debug(`init 2fa topic ${state.verifyingTopic}, siblings ${siblingKeys}`);
        break;
      }
      case TwoFaInputMode.SECURITY_ROLES:
        break;
      case TwoFaInputMode.INSTANCE_KEYS:
        break;
    }

    const user2FAAuthed = path(["user", "twoFactorAuthVerified"], rootState);
    const treatUserAsIdentityVerified = path(["user", "treatUserAsIdentityVerified"], rootState);
    commit("show2fa", true);
    logger.debug(
      `init 2fa: user2FAAuthed=${user2FAAuthed} treatUserAsIdentityVerified=${treatUserAsIdentityVerified} inputMode=${inputMode}`,
    );

    if (user2FAAuthed && treatUserAsIdentityVerified) {
      commit("reVerifyLoading", true);
      await sleep(50); // Give time for a loading indicator to render

      await dispatch("finishVerification");

      commit("reVerifyLoading", false);
      return;
    }

    commit("loading", true);
    commit("loading", false);
  },

  async initNonProfile({commit, rootState, getters}, {onVerified, onCancel, section}) {
    const user2FAAuthed = path(["user", "twoFactorAuthVerified"], rootState);
    const treatUserAsIdentityVerified = path(["user", "treatUserAsIdentityVerified"], rootState);
    logger.debug(
      `init non profile: user2FAAuthed=${user2FAAuthed} treatUserAsIdentityVerified=${treatUserAsIdentityVerified}`,
    );
    commit("nonProfile", true);
    commit("section", section);
    commit("verifyingTopic", "");
    commit("verifyingTopicId", "");
    commit("onVerified", onVerified);
    commit("onCancel", onCancel);
    commit("securedForApproval", false);
    commit("editMode", false);
    commit("securityRoles", []);
    commit("instanceKeys", []);
    commit("serverSideTopicIds", []);
    if (user2FAAuthed && treatUserAsIdentityVerified) {
      logger.trace(() => `initNonProfile marking as verified: ${getters.nameOfVerifiedFlag}`);
      commit(getters.nameOfVerifiedFlag, true);
      if (onVerified) {
        onVerified();
      }
    } else {
      commit("show2fa", true);
    }
  },
  async finishVerification({commit, rootState, state, getters}) {
    const inputMode = getters.inputMode;
    const isBankApproval = state.securedForApproval;
    const treatUserAsIdentityVerified: boolean = path(["user", "treatUserAsIdentityVerified"], rootState);
    logger.debug(
      `2fa finishVerification, inputMode=${inputMode} treatUserAsIdentityVerified=${treatUserAsIdentityVerified}`,
    );
    if (!state.nonProfile && inputMode === TwoFaInputMode.QUESTION) {
      commit("getAndUpdateSpecificAnswers", {
        answerKeys: state.siblings,
        whose: state.currentProfile,
        connectionId: state.connectionId,
      });
      commit("cacheSpecificAnswers", {[state.verifyingTopic]: true});

      let topics = state.verifiedTopics;
      topics[state.verifyingTopic] = topicPermissionUnlocked(
        state.editMode,
        isBankApproval,
        treatUserAsIdentityVerified,
      );
      commit("verifiedTopics", cloneDeep(topics));
    }

    if (state.onVerified) {
      await state.onVerified();
    }

    // reset everything
    logger.trace(() => `finishVerification marking as verified: ${getters.nameOfVerifiedFlag}`);
    commit("verifyingTopic", "");
    commit("verifyingTopicId", "");
    commit(getters.nameOfVerifiedFlag, true);
    if (isBankApproval) {
      commit("verifiedForQuestions", true);
    }

    commit("show2fa", false);
  },
  reset({commit}) {
    commit("verifiedTopics", {});
  },
};

const mutations = {
  ...autoMutations(state),
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
