import {getEntity} from "@/composables/entities";
import httpPlugin from "../../plugins/http";
import {autoMutations} from "../../utils";
import {clone} from "ramda";
import {Internal} from "pg-isomorphic/enums";
import type {PGActionTree, PGGetterTree} from "./../../index-types";
import type {EntityFeature} from "pg-isomorphic/enums/entity";
import type {ExtendedEntityInfo, SsoSettings} from "pg-isomorphic/api/entity";

const plugins = [httpPlugin];

export interface ConnectionEntityState {
  entityId: string;
  publicId: string;
  riskStats: {
    [key: string]: any;
  };
  identityScore: any;
  isSkeleton: boolean;
  privateProfile: boolean;
  publicIdData: {};
  basicInfo: {
    name: string;
    logo: string;
  };
  loadingPublicIdData: boolean;
  updatedAt: string;
  isNonNetwork: boolean;
  adminDomains: string[];
  features: {[feature in EntityFeature]?: boolean};
  isResponderOnly: boolean;
  displayBadges: [];
  restrictConnectionAdvancementBySegmentation: boolean;
  sellerTypes: {label: string; value: string}[];
  buyerTypes: {label: string; value: string}[];
  sellerTypesPlural: {label: string; value: string}[];
  buyerTypesPlural: {label: string; value: string}[];
  ssoSettings?: SsoSettings;
}

const initialState: ConnectionEntityState = {
  entityId: "",
  publicId: "",
  riskStats: {},
  identityScore: null,
  isSkeleton: false,
  privateProfile: false,
  publicIdData: {},
  basicInfo: {name: "", logo: ""},
  loadingPublicIdData: false,
  updatedAt: null,
  isNonNetwork: false,
  adminDomains: [],
  features: null,
  isResponderOnly: false,
  displayBadges: [],
  restrictConnectionAdvancementBySegmentation: false,
  sellerTypes: [],
  buyerTypes: [],
  sellerTypesPlural: [],
  buyerTypesPlural: [],
  ssoSettings: null,
};

const getters: PGGetterTree<ConnectionEntityState> = {};

const actions: PGActionTree<ConnectionEntityState> = {
  async getEntity({commit}, {entityId}: {entityId: string}) {
    commit("entityId", entityId);
    try {
      if (entityId) {
        const data: ExtendedEntityInfo = await getEntity(entityId);
        commit("publicId", data.publicId);
        commit("identityScore", data.identityScore);
        commit("riskStats", data.riskStats);
        commit("adminDomains", data.adminDomains);
        commit("updatedAt", data.updatedAt);
        const isSkeleton = data.isSkeleton;
        commit("isSkeleton", isSkeleton || false);
        commit("privateProfile", data.privateProfile);
        commit("setInitialState");
        const isNonNetwork = data[Internal.NON_GRAPHITE_NETWORK];
        commit("isNonNetwork", isNonNetwork || false);
        commit("features", data.features);
        commit("basicInfo", {
          name: data.dbaName,
          logo: data.logo,
        });
        const isResponderOnly = data.isResponderOnly;
        commit("isResponderOnly", isResponderOnly || false);
        commit("displayBadges", data.displayBadges || []);
        commit(
          "restrictConnectionAdvancementBySegmentation",
          data.restrictConnectionAdvancementBySegmentation || false,
        );
        commit("sellerTypes", data.sellerTypes || []);
        commit("buyerTypes", data.buyerTypes || []);
        commit("sellerTypesPlural", data.sellerTypesPlural || []);
        commit("buyerTypesPlural", data.buyerTypesPlural || []);
        commit("ssoSettings", data.ssoSettings || {});
        return data;
      }
    } catch (e) {
      /*empty*/
    }
  },
  async refresh({state, dispatch}) {
    return dispatch("getEntity", {entityId: state.entityId});
  },
  async refreshIfEmpty({state, dispatch}, {entityId}) {
    if (!state.entityId) {
      dispatch("getEntity", {entityId});
    }
  },
  reset({commit}) {
    commit("resetState");
  },
  async getInheritanceByPublicId({commit}, {publicIds}: {publicIds: string[]}) {
    try {
      if (publicIds && publicIds.length) {
        commit("loadingPublicIdData", true);
        const response = await this.httpPost(`/api/entities/public_ids`, {publicIds});
        commit("publicIdData", response.data);
        commit("loadingPublicIdData", false);
        return response.data;
      }
    } catch (e) {
      /*empty*/
    }
  },
  async getBasicEntity({commit}, {entityId}: {entityId: string}) {
    try {
      if (entityId) {
        const response = await this.httpGet(`/api/entities/info/${entityId}`);
        commit("basicInfo", response.data);
        return response.data;
      }
    } catch (e) {
      /*empty*/
    }
  },
};

export default {
  namespaced: true,
  state: clone(initialState),
  getters,
  actions,
  mutations: {
    ...autoMutations(initialState),
    resetState(state) {
      const entity = clone(initialState);
      Object.keys(entity).forEach((key) => {
        state[key] = entity[key];
      });
    },
    setInitialState(state) {
      const entity = clone(state);
      Object.keys(entity).forEach((key) => {
        initialState[key] = entity[key];
      });
    },
  },
  plugins,
};
