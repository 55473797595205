import {getFreeTextAnswer} from "pg-isomorphic/answers/values";
import {clone, indexBy, isNil, path, pathOr, pick, reject, without} from "ramda";
import {autoMutations} from "../utils";
import {QuestionTag, EntityAccessStatus, ConnectionRole, Stage, ErrorCode} from "pg-isomorphic/enums";
import globalLogger from "../../logging";
import jwtDecode from "jwt-decode";
import * as moment from "moment-timezone";
import {Entity as Ent, MagicAnswerKeys} from "pg-isomorphic/enums/answers";

const logger = globalLogger.getLogger("signup");

const state = {
  country: "",
  companyName: "",
  website: "",
  taxIds: {},
  displayName: "",
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  password2: "",
  userExists: false,
  invitationInvalid: false,
  signUpError: null,
  postSignUpEmail: null,
  emailValidationRequired: false,
  createEntityError: false,
  confirmError: null,
  existingShow: false,
  existingSearching: false,
  gettingInvitingEntityDomains: false,
  existingResults: null,
  existingMustRequestAccess: false,
  requestingAccess: false,
  accessRequestError: null,
  accessRequested: false,
  taxQuestions: null,
  confirming: false,
  confirmResult: null,
  invite: null,
  inviteToken: null,
  tokenInfo: null,
  verifyResent: false,
  invitingEntity: null,
  invitingEntityName: "",
  invitingUserName: "",
  inviteConnection: null,
  connectionId: null,
  inviteParams: {},
  inviteDetailQuestions: {},
  showLogo: false,
  contactInviteToken: null,
  entityId: null,
  campaignInfo: null,
  campaignError: null,
  campaignConnectionError: null,
  isUserInvite: false,
  branchRequest: false,
  sendingBranchRequest: false,
  branchRequestError: null,
  requestBranchMessage: "",
  entityAdminDomains: {},
};

// getters
const getters = {
  taxAnswers(state) {
    return {
      [Ent.PublicId]: "fake",
      [MagicAnswerKeys.COMPANY_CREATE]: "y",
      ...state.taxIds,
      ...(state.inviteDetailQuestions ? state.inviteDetailQuestions.answers : {}),
      [Ent.Country]: state.country,
    };
  },
};

const INVITE_DATA_KEY = "invite_data";
const CAMPAIGN_DATA_KEY = "campaign_data";
const localStore = window.localStorage;

// actions
const actions = {
  async signUp({commit, state, dispatch, rootState}, {token, entityId}) {
    try {
      const signUpInfo = pick(["email", "password", "displayName", "firstName", "lastName"], state);
      const response = await this.httpPost("/api/users/signup", {
        ...signUpInfo,
        token,
        tz: moment.tz.guess(),
        locale: rootState.language || window.navigator.userLanguage || window.navigator.language,
        campaign: path(["campaignInfo", "id"], state),
        entityId,
      });
      commit("postSignUpEmail", state.email);
      dispatch("clear");
      logger.trace(() => `signup completed: ${state.email} ${state.displayName} ${response.status}`);
      if (response.status === 201) {
        // User created and logged in, proceed
        const signInResponse = response.data;
        await dispatch("completeSignIn", {signInResponse}, {root: true});
      } else if (response.status === 204) {
        // User email validation required
        commit("emailValidationRequired", true);
      } else if (response.status === 409) {
        logger.error("signup conflict", response);
      }
    } catch (e) {
      if (e.response.status === 409) {
        logger.warn(`account already exists: ${state.email}`);
        commit("userExists", true);
      } else if (e.response.status === 410) {
        logger.warn(`the invitation is no longer valid`);
        commit("invitationInvalid", true);
      } else {
        commit("signUpError", e.response.data);
      }
    }
  },

  async setUserPassword({commit, state, dispatch}, {token, skipVerify}) {
    try {
      const response = await this.httpPost("/api/users/signup_password", {
        email: state.email,
        password: state.password,
        token,
        skipVerify,
      });

      if (response.status === 201) {
        const signInResponse = response.data;
        return await dispatch("completeSignIn", {signInResponse}, {root: true});
      }
    } catch (e) {
      if (e.response.status === 410) {
        logger.warn(`the invitation is no longer valid`);
        commit("invitationInvalid", true);
      } else {
        commit("signUpError", e.response.data);
      }
    }
  },

  async checkInvitationEntity({commit}, {token}) {
    try {
      await this.httpPost("/api/users/validate_invitation_entity", {token});
    } catch (e) {
      if (e.response.status === 410) {
        logger.warn(`This invitation is no longer valid`);
        commit("invitationInvalid", true);
      } else {
        commit("signUpError", e.response.data);
      }
    }
  },

  async checkEmail({commit, state}, {token}) {
    try {
      const response = await this.httpPost("/api/users/email_exists", {
        email: state.email,
        token,
      });
      if (response.status === 200) {
        commit("userExists", response.data.exists);
      }
    } catch (e) {
      commit("signUpError", e.response.data);
    }
  },

  async changeEmail({}, {email, token, name, entityName}) {
    await this.httpPost("/api/users/redirect_invitation", {email, token, name, entityName});
  },

  async clear({commit}) {
    ["userExists", "createEntityError", "existingSearching", "requestingAccess", "existingShow"].forEach((k) =>
      commit(k, false),
    );
    ["signUpError", "accessRequestError", "accessRequested"].forEach((k) => commit(k, null));
    ["displayName", "firstName", "lastName", "email", "password", "password2", "country", "website"].forEach((k) =>
      commit(k, ""),
    );

    commit("taxIds", {});
  },

  async clearInvite({commit}, clearConnection) {
    commit("invite", null);
    commit("inviteToken", null);
    commit("tokenInfo", null);
    commit("companyName", null);
    commit("country", null);
    commit("inviteDetailQuestions", clone({}));
    commit("taxIds", clone({}));

    if (clearConnection) {
      commit("connectionId", null);
    }

    localStore.removeItem(INVITE_DATA_KEY);
  },

  async getInvite({}) {
    return localStore.getItem(INVITE_DATA_KEY);
  },

  async storeInvite({}, params) {
    localStore.setItem(INVITE_DATA_KEY, JSON.stringify(params));
  },

  async getTokenInfo({commit, state}) {
    if (state.inviteToken) {
      const info = jwtDecode(state.inviteToken);
      commit("tokenInfo", info);
    }
  },

  async getInviteTokenConnection({state, dispatch}) {
    if (!state.inviteToken) {
      return undefined;
    }
    dispatch("getTokenInfo");
    try {
      const connectionId = state.tokenInfo.connectionId;
      const response = await this.httpGet(`/api/connections/${connectionId}`);
      return response.data ? response.data.connection : undefined;
    } catch (err) {
      logger.error(`failed loading connection for invite token`, err);
      return undefined;
    }
  },

  async getInviteDetailQuestions({state, commit, rootState}) {
    if (!state.connectionId) {
      logger.warn(() => `getInviteDetailQuestions no connectionId`);
      return;
    }

    logger.trace(() => `getInviteDetailQuestions for connection: ${state.connectionId}`);
    try {
      const language =
        rootState.language ||
        window.localStorage.getItem("language") ||
        window.navigator.userLanguage ||
        window.navigator.language;
      commit("inviteDetailQuestions", {});
      const response = await this.httpGet("/api/questions/invitation_details", {
        connectionId: state.connectionId,
        locale: language,
      });

      if (response.status === 200) {
        commit("inviteDetailQuestions", response.data);
      } else {
        logger.error(`failed to get invitation details status code: ${response.status}`, response);
      }
    } catch (e) {
      logger.error(`failed to get invitation details for connection: ${state.connectionId}`, e);
    }
  },

  async createEntity({commit, state, dispatch}, answers) {
    try {
      const entity = {...pick(["country", "companyName"], state), taxIds: answers};
      let response;
      if (state.campaignInfo) {
        entity.campaignId = state.campaignInfo._id;
        const res = await this.httpPost("/api/entities", entity);

        this.setJWT(res.data.token, res.data.tokenExpiresOn, false);

        response = await dispatch("createCampaignConnection", {entityId: res.data.entity._id});

        // set the activeConnectionRole
        await dispatch("userProfile/getUserEntities", {}, {root: true});
        await dispatch(
          "userProfile/updateEntityAssociationRole",
          {
            entityId: res.data.entity._id,
            activeConnectionRole: ConnectionRole.SELLER,
            defaultConnectionRole: ConnectionRole.SELLER,
          },
          {root: true},
        );
      } else if (state.inviteToken) {
        entity.token = state.inviteToken;
        response = await this.httpPost("/api/connections/acceptinvite", entity);
        this.setJWT(response.data.token, response.data.tokenExpiresOn, false);
      } else {
        response = await this.httpPost("/api/entities", entity);
        this.setJWT(response.data.token, response.data.tokenExpiresOn, false);
      }
      await dispatch("getCurrentUser", true, {root: true});
      return response.data;
    } catch (e) {
      commit("createEntityError", true);
    }
  },

  async createCampaignConnection({commit, state}, {entityId}) {
    if (!state.campaignInfo?._id) {
      return;
    }
    const connectionData = {
      campaign: state.campaignInfo._id,
      requestingEntity: entityId,
      requestingRole:
        state.campaignInfo.entityConnectionRole === ConnectionRole.SELLER
          ? ConnectionRole.BUYER
          : ConnectionRole.SELLER,
      respondingEntity: state.campaignInfo.entity,
      respondingRole: state.campaignInfo.entityConnectionRole,
      status: Stage.INVITE,
    };
    try {
      const response = await this.httpPost("/api/connections", {data: connectionData});
      logger.trace(() => `connection response data`, response.data);
      commit("connectionId", response.data.connection.id);
      return response;
    } catch (e) {
      logger.error(() => `error creating campaign connection`, e);
      commit("campaignConnectionError", e);
    }
  },

  async searchExisting({commit, state, dispatch, rootState}, {fields, answers, mustRequestAccess}) {
    commit("existingSearching", true);
    commit("createEntityError", false);
    try {
      const search = reject(isNil, pick(fields, state));
      search.filterSkeletons = true;
      search.filterNonNetwork = true;
      search.taxIds = answers;

      const tokenInfo = state.tokenInfo || {};
      if (tokenInfo) {
        search.entity = tokenInfo.entityId;
      }
      search.taxAnswerKeys = Object.keys(answers || {});

      const entities = pathOr([], ["user", "entities"], rootState);
      const userEntityAssociationsByEntityId = indexBy((e) => e.entityId, entities);
      const response = await this.httpPost("/api/entities/existing", search);
      const results = response.data;
      results.results = results.results.map((searchResult) => {
        searchResult.userConnection = userEntityAssociationsByEntityId[searchResult.entityId];
        searchResult.Entity_Name = getFreeTextAnswer(searchResult.Entity_Name, rootState.user.locale);
        return searchResult;
      });
      commit("existingResults", results);
      commit("existingShow", results.results.length > 0);
      dispatch("getExistingEntityAdminDomains");
      logger.trace(() => `existingShow: ${state.existingShow} ${results.results.length}`);
      commit("accessRequestError", null);
      commit("existingMustRequestAccess", !!mustRequestAccess);
      return results;
    } catch (e) {
      logger.error(() => `error searching existing`, e);
      commit("createEntityError", true);
    } finally {
      commit("existingSearching", false);
    }
  },

  async requestNewBranch({commit, state}) {
    commit("sendingBranchRequest", true);
    commit("branchRequestError", null);

    try {
      const response = await this.httpPost("/api/entities/request_branch", {
        ...pick(["country", "companyName", "website"], state),
        invitingEntityId: state.invitingEntity,
        message: state.requestBranchMessage,
        matches: state.existingResults.results.map((r) => `${r[Ent.Name]} (${r[Ent.PublicId]})`).join("; "),
      });

      if (response.status === 204) {
        commit("branchRequest", false);
        commit("accessRequested", true);
      } else {
        commit("branchRequestError", "Invalid response");
      }
    } catch (e) {
      logger.error(() => `requestNewBranch response error`, e);
      commit("branchRequestError", e);
    } finally {
      commit("sendingBranchRequest", false);
    }
  },

  async reassignEntityInvite({commit, dispatch, state}, {entityId, inviteToken}) {
    commit("requestingAccess", true);
    commit("accessRequestError", null);
    try {
      const response = await this.httpPut("/api/connections/reassign", {token: inviteToken, toEntityId: entityId});
      const tokenInfo = state.tokenInfo || {};
      tokenInfo.entity = entityId;
      tokenInfo.entityId = entityId;
      commit("tokenInfo", tokenInfo);
      tokenInfo.connectionId = response.data.connectionId;
      commit("tokenInfo", tokenInfo);
      const signInResponse = response.data.signInResponse;
      if (signInResponse) {
        await dispatch("completeSignIn", {signInResponse}, {root: true});
      }
      dispatch("clear");
    } catch (e) {
      commit("accessRequestError", e);
    } finally {
      commit("requestingAccess", false);
    }
  },

  async getExistingEntityDetails({commit, state, rootState}, {user, entityId, useCountryFilter}) {
    commit("existingSearching", true);
    try {
      let entityIds = null;
      let countryFilter = null;
      if (useCountryFilter && state.inviteDetailQuestions?.questions) {
        for (const question of state.inviteDetailQuestions.questions) {
          if (question.tags && question.tags.includes(QuestionTag.NSJ_DEFAULT_COUNTRY)) {
            countryFilter = state.inviteDetailQuestions.answers[question.key];
          }
        }
      }
      if (countryFilter) {
        entityIds = user
          ? user.entities
              .filter((e) => e.status === EntityAccessStatus.ACCEPTED && e.info.country === countryFilter)
              .map((e) => e.entityId)
          : [];
      } else {
        entityIds = user
          ? user.entities.filter((e) => e.status === EntityAccessStatus.ACCEPTED).map((e) => e.entityId)
          : [];
      }

      if (!entityIds.length && !entityId) {
        commit("existingResults", []);
        return;
      }

      logger.trace(() => `getExisting ${entityId}`, entityIds, user);

      if (entityId) {
        entityIds.push(entityId);
      }
      const taxAnswerKeys = without(
        "Entity_Country_Mismatch",
        state.taxQuestions.map((q) => q.key),
      );

      const response = await this.httpPost(`/api/entities/existing/ids`, {
        entityIds,
        taxAnswerKeys,
      });

      const results = response.data.results.map((searchResult) => {
        searchResult.Entity_Name = getFreeTextAnswer(searchResult.Entity_Name, rootState.user.locale);
        return searchResult;
      });

      commit("existingResults", results);
    } catch (e) {
      logger.trace(() => `getExistingEntityDetails error`, e);
      commit("accessRequestError", e);
    } finally {
      commit("existingSearching", false);
    }
  },

  async requestAccess({commit, state, dispatch}, {entityId, skipToken}) {
    commit("requestingAccess", true);
    commit("accessRequestError", null);
    let redirectSuccess = false;
    try {
      // when user is invited to campaign but isn't already part of the entity, skip token check
      if (state.inviteToken && !skipToken) {
        redirectSuccess = await dispatch("redirectInvite", entityId);
        if (!redirectSuccess) {
          return;
        }
      }
      const body = state.contactInviteToken
        ? {
            activeConnectionRole: ConnectionRole.SELLER,
          }
        : {};
      logger.trace(() => `requesting access to ${entityId}`, entityId);
      const response = await this.httpPost(`/api/entities/${entityId}/request`, body);
      commit("accessRequested", !!response.data);
      return response.data;
    } catch (e) {
      const alreadyRequested =
        pathOr(false, ["response", "data", "code"], e) === ErrorCode.ENTITY_ACCESS_PREVIOUSLY_REQUESTED;
      if (alreadyRequested && redirectSuccess) {
        commit("accessRequested", true);
        return;
      }
      commit("accessRequestError", pathOr(null, ["response", "data"], e));
    } finally {
      commit("requestingAccess", false);
    }
  },

  async redirectInvite({commit, state}, entityId) {
    try {
      await this.httpPut("/api/connections/reassign", {token: state.inviteToken, toEntityId: entityId});
      return true;
    } catch (e) {
      logger.error(`unable to reassign invitation to entity: ${entityId} => ${state.inviteToken}`, e);
      commit("accessRequestError", pathOr(null, ["response", "data"], e));
      return false;
    }
  },

  async getTaxQuestions({commit, state, rootState}, {locale, connection} = {}) {
    if (!rootState.user) {
      return;
    }
    logger.trace(() => `get tax questions for conn ${connection || state.connectionId} ${locale}`);
    const response = await this.httpGet(`/api/questions/meta`, {
      connectionId: connection || state.connectionId,
      tags: QuestionTag.TAX_ID,
      campaignId: state.campaignInfo?.id,
      locale,
    });
    const questions = response.data.questions;
    commit("taxQuestions", questions);
    return questions;
  },

  async confirmEmail({commit, dispatch}, {token, email}) {
    commit("confirming", true);
    await commit("confirmError", null);
    try {
      // send the confirmation (include a guess at the user's timezone)
      const response = await this.httpGet("/api/users/verify", {
        token,
        email,
        tz: moment.tz.guess(),
        locale: window.navigator.userLanguage || window.navigator.language,
      });
      const result = response.data;
      await commit("confirmResult", result);
      if (result.signInResponse) {
        await dispatch("completeSignIn", {signInResponse: result.signInResponse}, {root: true});
      }
      return result;
    } catch (e) {
      logger.error(() => `confirmEmail error`, JSON.stringify(e));
      await commit("confirmError", e.response.data);
    } finally {
      await commit("confirming", false);
    }
  },

  async resendVerify({commit, rootState}, {email, campaign}) {
    commit("confirming", true);
    try {
      await this.httpPost("/api/users/resend_verification", {
        email,
        campaign,
        requested: rootState.requestedPath,
        entity: rootState.requestedEntity,
      });
      commit("verifyResent", true);
    } catch (e) {
      commit("confirmError", e.response.data);
    } finally {
      commit("confirming", false);
    }
  },

  async getInviteConnection({commit}, {connectionId} = {}) {
    commit("existingSearching", true);
    try {
      const response = await this.httpGet(`/api/connections/${connectionId}`);
      commit("inviteConnection", response.data.connection);
      return response.data.connection;
    } catch (e) {
      commit("confirmError", e.response.data);
    } finally {
      commit("existingSearching", false);
    }
  },

  async getExistingEntityAdminDomains({commit, state}, {} = {}) {
    commit("gettingInvitingEntityDomains", true);
    let ids = state.existingResults?.results.map((e) => e.entityId) || [];
    try {
      const response = await this.httpPost(`/api/entities/admin_domains`, {ids});
      commit("entityAdminDomains", response?.data);
      return response?.data;
    } catch (e) {
      commit("confirmError", e.response.data);
    } finally {
      commit("gettingInvitingEntityDomains", false);
    }
  },

  async getCampaignData({commit, dispatch}, slug) {
    try {
      const res = await this.httpGet(`/api/campaigns/${slug}`);
      const info = res.data.campaign;
      if (info) {
        commit("campaignInfo", info);
        commit("invitingEntityName", info.entityDetails.dbaName);
        commit("invitingEntity", info.entity);
        await dispatch("storeCampaign", info);
      }
    } catch (e) {
      logger.error(() => "getCampaignData error", JSON.stringify(e));
      commit("campaignError", e.response.data);
    }
  },

  async clearCampaign({commit}) {
    commit("campaignInfo", null);

    localStore.removeItem(CAMPAIGN_DATA_KEY);
  },

  async getCampaign({commit}) {
    const campaignData = localStore.getItem(CAMPAIGN_DATA_KEY);
    if (campaignData) {
      logger.trace(() => "getCampaign from local", campaignData);
      commit("campaignInfo", JSON.parse(campaignData));
    }
    return JSON.parse(campaignData);
  },

  async storeCampaign({}, params) {
    localStore.setItem(CAMPAIGN_DATA_KEY, JSON.stringify(params));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations: {
    ...autoMutations(state),
  },
  persistedFields: [
    "country",
    "companyName",
    "website",
    "inviteToken",
    "invitingEntity",
    "invitingEntityName",
    "invitingUserName",
    "isUserInvite",
  ],
};
