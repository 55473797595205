// It's not happy that there is not declaration file, this will be resolved when we convert to .ts
// tsconfig-paths
import {i18n} from "@/i18n";
import {getTextFromLocaleObj, withoutMachineTranslations} from "pg-isomorphic/utils/locale";
import type {VueI18n} from "vue-i18n";
import type {JSONObject} from "pg-isomorphic/utils";
import {getUserLocale} from "@/composables/vuex";
import {Locale} from "pg-isomorphic/enums";
import {pathOr} from "ramda";

export function getI18n(): VueI18n {
  return i18n;
}

/**
 * Translation method
 *
 * @param localeKey
 * @param vars
 */
export function translate(localeKey: string, vars?: JSONObject | any[]): string {
  const i18nInstance = getI18n();
  return i18nInstance.t(localeKey, vars as any) as string;
}

export function translateWithFallback(key: string, fallback: string): string {
  const result = translate(key);

  if (result === key) {
    return translate(fallback);
  }

  return result;
}

/**
 * Translate text and handle pluralization.
 *
 * @param localeKey
 * @param choice
 * @param vars
 */
export function $tc(localeKey: string, choice?: any, vars?: JSONObject): string {
  const i18nInstance = getI18n();
  return i18nInstance.tc(localeKey, choice, vars);
}

/**
 * Helper method to convert a locale object to a string using users locale if possible
 *
 * @param txtOrLocaleObj
 * @param allowMachineTranslations
 */
export function getUserLocalizedText(txtOrLocaleObj: any, allowMachineTranslations = false) {
  let value = txtOrLocaleObj;
  if (!allowMachineTranslations) {
    value = withoutMachineTranslations(txtOrLocaleObj);
  }
  return getTextFromLocaleObj(value, getUserLocale(), true);
}

export function localize(obj: any): string | undefined {
  if (!obj) {
    return obj;
  }
  const loc = getUserLocale() || Locale.EN_US;
  if (typeof obj === "string") {
    return obj;
  }
  return pathOr("", [loc], obj);
}

export function localizeLabel(label, locale) {
  if (!label) {
    return "";
  } else if (typeof label === "string") {
    return label;
  } else if (label[locale]) {
    return label[locale];
  } else if (label[Locale.EN_US]) {
    return label[Locale.EN_US];
  } else {
    return label["en"];
  }
}

export function userLocalizeLabel(label) {
  return localizeLabel(label, getUserLocale());
}

export function formatCurrency(value: number, currencyCode: string, includeCents = false): string {
  const formatter = new Intl.NumberFormat(getUserLocale(), {
    style: "currency",
    currency: currencyCode,
    ...(!includeCents ? {maximumFractionDigits: 0} : {}),
  });
  return formatter.format(value);
}
