<template>
  <GraphiteAlert :class="`pg-alert flex ${hasActionLabel ? 'has-action' : ''}`" show :variant="variant">
    <template #icon>
      <div class="notes-icon" v-if="iconSource || $slots.icon">
        <slot name="icon">
          <font-awesome-icon :icon="iconSource" />
        </slot>
      </div>
    </template>

    <div class="flex gap-2">
      <div class="text-wrap">
        <div class="title">
          <slot name="title">
            {{ title }}
          </slot>
        </div>
        <div class="sub-title">
          <slot name="subTitle">
            {{ subTitle }}
          </slot>
        </div>
      </div>

      <slot name="action">
        <div v-if="hasActionLabel" class="action-wrap" @click="handleActionClick">{{ actionLabel }}</div>
      </slot>
    </div>
  </GraphiteAlert>
</template>

<script lang="ts">
import {computed, defineComponent, toRefs} from "vue";
import type {PropType} from "vue";
import {getRouter} from "@/router/util";

export enum NoteVariants {
  PRIMARY = "primary",
  WARNING = "warning",
  SUCCESS = "success",
  DARK = "dark",
  DANGER = "danger",
  PURPLE = "purple",
}

export enum NoteIconVariants {
  LIGHTBULB = "lightbulb",
  CHECK = "check",
  MESSAGE_EXCLAMATION = "message-exclamation",
  EXCLAMATION_TRIANGLE = "exclamation-triangle",
  INFO_CIRCLE = "info-circle",
}

export default defineComponent({
  compatConfig: {
    MODE: 3,
  },
  name: "note",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    variant: {
      type: String as PropType<NoteVariants>,
      default: NoteVariants.PRIMARY,
    },
    iconVariant: {
      type: String as PropType<NoteIconVariants>,
      default: NoteIconVariants.LIGHTBULB,
    },
    actionLabel: {
      type: String,
      default: null,
    },
    actionUrl: {
      type: String,
      default: null,
    },
  },
  setup(props, setupContext) {
    // COMPONENT DATA
    const {iconVariant, actionLabel, actionUrl} = toRefs(props);

    // COMPUTED
    const iconSource = computed(() => {
      if (iconVariant.value === NoteIconVariants.LIGHTBULB) {
        return ["far", "lightbulb"];
      } else if (iconVariant.value === NoteIconVariants.CHECK) {
        return ["fas", "check"];
      } else if (iconVariant.value === NoteIconVariants.MESSAGE_EXCLAMATION) {
        return ["far", "message-exclamation"];
      } else if (iconVariant.value === NoteIconVariants.EXCLAMATION_TRIANGLE) {
        return ["fas", "exclamation-triangle"];
      } else if (iconVariant.value === NoteIconVariants.INFO_CIRCLE) {
        return ["fal", "info-circle"];
      }
    });
    const hasActionLabel = computed(() => Boolean(actionLabel.value));

    // METHODS
    const handleActionClick = () => {
      if (actionUrl.value) {
        const router = getRouter();
        router.push(actionUrl.value);
        return;
      }
      setupContext.emit("actionClick", {});
    };

    // EXPOSE TO COMPONENT
    return {
      iconSource,
      hasActionLabel,
      handleActionClick,
    };
  },
});
</script>

<style lang="less" scoped>
@import "@{globals}";

.pg-alert {
  display: flex;
  border-width: 0 0 0 4px;
  border-radius: 4px;
  color: @primaryText;
  position: relative;

  .notes-icon-wrap {
    flex: 0 1 auto;
    align-self: center;
    display: inline-block;
    padding: 0 16px 0 0;
    // top: 22px;
    // left: 10px;

    .notes-icon {
      font-size: 19px;
    }
  }

  &.has-action {
    // padding-right: 150px;
  }

  .text-wrap {
    flex: 1 1 auto;
    align-self: center;

    .title {
      font-weight: 600;
      font-size: @font14;
    }

    .sub-title {
      font-size: @font14;
    }
  }

  .action-wrap {
    flex: 0 1 auto;
    align-self: center;
    display: inline-block;
    font-weight: 600;
    font-size: @font14;
    cursor: pointer;
  }
}
</style>
