export enum AnswerEvent {
  ANSWER_CHANGED = "ANSWER_CHANGED",
  ANSWER_CHANGE_COMPLETED = "ANSWER_CHANGE_COMPLETED", // on blur for text/textarea fields, change for others
  ANSWER_UPDATED = "ANSWER_UPDATED", // answer actually got changed from initial value
  ANSWER_REVERTED = "ANSWER_REVERTED",
  QUESTIONS_CHANGED = "QUESTIONS_CHANGED",
  FILTERED_QUESTIONS_CHANGED = "F_QUESTIONS_CHANGED",
  NEW_TOPIC_REVIEW_CREATED = "NEW_REVIEW",
  SWAPPED_GROUP_IDS = "SWAPPED_GROUP_IDS",
  ADD_GROUP_INSTANCE = "ADD_GROUP_INSTANCE",
  REMOVE_GROUP_INSTANCE = "REMOVE_GROUP_INSTANCE",
}

export enum ConnectionState {
  QUESTIONS_LOADED = "questions_loaded",
  FILTER_CLICKED = "filter_clicked",
  QUESTIONS_SAVED = "questions_saved",
  QUESTIONS_UPDATED = "questions_updated",
  TASKS_LOADED = "tasks_loaded",
  TASK_CLICKED_URL_THE_SAME = "task_clicked_no_url",
  TABLE_SHOW_STATE_UPDATED = "table_show_state_updated",
}

export enum AuditEvent {
  USER_PERMISSION_CHANGED = "USER_PERMISSION_CHANGED",
  USER_ADMIN_CHANGED = "USER_ADMIN_CHANGED",
  FAILED_LOGIN_ATTEMPT = "FAILED_LOGIN_ATTEMPT",
  USER_LOGIN = "USER_LOGIN",
  USER_LOCALE_CHANGED = "USER_LOCALE_CHANGED",
  USER_ENTITY_ACCESS_CHANGED = "USER_ENTITY_ACCESS_CHANGED",
  USER_ACCESS_REQUESTED = "USER_ENTITY_ACCESS_REQUESTED",
  USER_INVITED = "USER_INVITED",
  USER_CONNECTION_ROLE_CHANGED = "USER_CONNECTION_ROLE_CHANGED",

  CONNECTION_CREATED = "CONNECTION_CREATED",
  CONNECTION_RECONNECTED = "CONNECTION_RECONNECTED",
  CONNECTION_INVITED = "CONNECTION_INVITED",
  CONNECTION_INVITE_RESENT = "CONNECTION_INVITE_RESENT",
  CONNECTION_ACCEPTED = "CONNECTION_ACCEPTED",
  CONNECTION_COLLABORATION_COMPLETED = "CONNECTION_COLLABORATION_COMPLETED",
  CONNECTION_COMPLETED = "CONNECTION_COMPLETED",
  CONNECTION_DISCONNECT_STARTED = "CONNECTION_DISCONNECT_STARTED",
  CONNECTION_DISCONNECTED = "CONNECTION_DISCONNECTED",
  CONNECTION_DELETED = "CONNECTION_DELETED",
  CONNECTION_REDIRECTED = "CONNECTION_REDIRECTED",
  CONNECTION_REDIRECT_UNDONE = "CONNECTION_REDIRECT_UNDONE",
  CONNECTION_MADE_NONNETWORK = "CONNECTION_MADE_NONNETWORK",
  CONNECTION_BATCH_JOB = "CONNECTION_BATCH_JOB",
  EXTERNAL_INVITATION_ACCEPTED = "EXTERNAL_INVITATION_ACCEPTED",

  DATA_SHARE_VISIT = "DATA_SHARE_VISIT",

  TASK_CREATED = "TASK_CREATED",
  TASK_COMPLETED = "TASK_COMPLETED",
  TASK_DELETED = "TASK_DELETED",
  TASK_FALLBACK = "TASK_FALLBACK",
  USER_GENERATED_TASK_ACTIVATED = "USER_GENERATED_TASK_ACTIVATED",
  USER_GENERATED_TASK_STATUS_CHANGED = "USER_GENERATED_TASK_STATUS_CHANGED",
  MESSAGE_CREATED = "MESSAGE_CREATED",
  TASK_REASSIGNMENT = "TASK_REASSIGNMENT",
  TASK_STATE_CHANGE = "TASK_STATE_CHANGE",

  REMINDER_ARRIVED = "REMINDER_ARRIVED",

  ROLE_CHANGED = "ROLE_CHANGED",
  USERS_IN_ROLE_CHANGED = "USERS_IN_ROLE_CHANGED",

  ANSWERS_CHANGED = "ANSWERS_CHANGED",
  INSTANCE_ADDED = "INSTANCE_ADDED",
  KIT_CHANGED = "KIT_CHANGED",
  USER_PROFILE_CHANGED = "USER_PROFILE_CHANGED",

  SYSTEM = "SYSTEM",

  TWO_FACTOR_AUTH_SENT = "TWO_FACTOR_AUTH_SENT",
  TWO_FACTOR_AUTH_CONFIRMED = "TWO_FACTOR_AUTH_CONFIRMED",

  APPROVAL_COMPLETED = "APPROVAL_COMPLETED",

  SUBMIT = "SUBMIT",

  API_ACKNOWLEDGMENT = "API_ACKNOWLEDGMENT",

  REVIEW_BLOCKED = "REVIEW_BLOCKED",
  REVIEW_REQUESTED = "REVIEW_REQUESTED",
  REVIEW_TRIGGER = "REVIEW_TRIGGER",

  ENTITY_VALIDATION = "ENTITY_VALIDATION",
  FAILED_VALIDATION = "FAILED_VALIDATION",
  DOCUSIGN_STATUS_UPDATE = "DOCUSIGN_STATUS_UPDATE",
  ADOBE_SIGN_STATUS_UPDATE = "ADOBE_SIGN_STATUS_UPDATE",
  RAPIDRATINGS_OUTREACH_UPDATE = "RAPIDRATINGS_OUTREACH_UPDATE",
  CAMPAIGN_REGISTRATION_STARTED = "CAMPAIGN_REGISTRATION_STARTED",
  CAMPAIGN_REGISTRATION_UNREGISTERED = "CAMPAIGN_REGISTRATION_UNREGISTERED",
  CAMPAIGN_REGISTRATION_COMPLETED = "CAMPAIGN_REGISTRATION_COMPLETED",
  CAMPAIGN_INVITATION = "CAMPAIGN_INVITATION",
  DOW_JONES_AUTO_QUERY = "DOW_JONES_AUTO_QUERY",
  DOW_JONES_CHECK = "DOW_JONES_CHECK",
  SECZETTA_API_SUCCESS = "SECZETTA_API_SUCCESS",
  SECZETTA_API_FAILURE = "SECZETTA_API_FAILURE",
  THIRD_PARTY_API_FAILURE = "THIRD_PARTY_API_FAILURE",
  BANK_DOC_VALIDATION = "BANK_DOC_VALIDATION",
  UNIQUE_CONSTRAINT_VIOLATION = "UNIQUE_CONSTRAINT_VIOLATION",
  FAILED_GIACT_CHECK = "FAILED_GIACT_CHECK",

  EMAIL_STATUS = "EMAIL_STATUS",

  ENTITY_DELETED = "ENTITY_DELETED",
  ENTITY_STATS_SUPPLIER_ONBOARDING = "ENTITY_STATS_SUPPLIER_ONBOARDING",
  ENTITY_STATS_CUSTOMER_ONBOARDING = "ENTITY_STATS_CUSTOMER_ONBOARDING",
  ENTITY_STATS_REVIEWS = "ENTITY_STATS_REVIEWS",

  TASK_PROCESSING_GAVE_UP = "TASK_PROCESSING_GAVE_UP",

  WEBHOOK_RESPONSE = "WEBHOOK_RESPONSE",
  WEBHOOK_FAILURE = "WEBHOOK_FAILURE",

  SCORE_OVERRIDE = "SCORE_OVERRIDE",

  // These event types are internal to trigger processing and do not appear in the event log
  USER_ROLE_ADDED_TRIGGER = "USER_ROLE_ADDED_TRIGGER",
  USER_ROLE_REMOVED_TRIGGER = "USER_ROLE_REMOVED_TRIGGER",
  ANSWERS_INSTANCE_CHANGED = "ANSWERS_INSTANCE_CHANGED",
  KIT_ADDED = "KIT_ADDED",
  CONNECTION_NONE = "CONNECTION_NONE",
  // IF YOU'RE ADDING NEW REAL EVENTS THEY DO NOT BELONG DOWN HERE.
}

export const userEvents = new Set<AuditEvent>([
  AuditEvent.USER_ACCESS_REQUESTED,
  AuditEvent.USER_ADMIN_CHANGED,
  AuditEvent.USER_CONNECTION_ROLE_CHANGED,
  AuditEvent.USER_ENTITY_ACCESS_CHANGED,
  AuditEvent.USER_INVITED,
  AuditEvent.USER_PERMISSION_CHANGED,
]);

export enum ReviewEvent {
  SAVE_PROGRESS = "SAVE",
  COMPLETE = "COMPLETE",
  CANCEL = "CANCEL",
  DELETE = "DELETE",
  RELOAD_APPROVALS = "RELOAD_APPROVALS",
}

export enum AcknowledgmentType {
  SUCCESS = "success",
  FAILURE = "failure",
}

export enum ProfileEvent {
  TOPIC_STATE_CHANGE = "topic_state",
  REVIEW_COLLAPSED_STATE_CHANGE = "review_collapsed_state",
  CONNECTION_BAR_COLLAPSE_CHANGE = "cb_collapse_change",
  ELEMENT_MOUNTED = "element_mounted",
  CST_CHILD_TOGGLE = "COMPACT_SIDE_TRAY_CHILD_TOGGLE",
}

export enum TaskEvent {
  NEW_TASK = "new_task",
  TASK_UPDATE = "task_update",
  STATE_UPDATE = "state_update",
  DELETE_TASK = "delete_task",
}

export enum ActionPlanEvent {
  ACTION_PLANS_LOADED = "action_plans_loaded",
  NEW_ACTION_PLAN = "new_action_plan",
}

export type StatsEvents =
  | AuditEvent.ENTITY_STATS_REVIEWS
  | AuditEvent.ENTITY_STATS_SUPPLIER_ONBOARDING
  | AuditEvent.ENTITY_STATS_CUSTOMER_ONBOARDING;

export enum ReviewRequestType {
  MANUAL = "manual",
  SCHEDULED = "scheduled",
  WORKFLOW = "workflow",
}
