import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import {all, isNil, pathOr, pick, reject, uniq} from "ramda";
import {FreeTextAnswerKeys, Locale} from "../enums";
import {TranslationSource} from "../enums/answers";
import {JSONObject} from "../utils";
import {shortLocale} from "../utils/locale";
import {processAnswers} from "./index";

export function getFreeTextAnswerWithFallback(value: any, locale?: string): any {
  if (isFreeTextAnswer(value)) {
    const useLocale = locale || value[FreeTextAnswerKeys.ANSWER_LOCALE] || Locale.EN_US;
    const localesMap = getAllLanguagesMap(value);
    // if answer is saved as "en-GB", when user's locale is "en-US", we will return the en-GB answer -
    // sometimes null or blank answers are returned so return en-US as fallback if others are "nullish"
    return (
      pathOr("", [localesMap[useLocale], FreeTextAnswerKeys.VALUE], value) ||
      pathOr("", [localesMap[shortLocale(useLocale)], FreeTextAnswerKeys.VALUE], value)
    );
  }

  return value;
}

export function getFreeTextAnswer(value: any, locale?: string): any {
  if (isFreeTextAnswer(value)) {
    return (
      getFreeTextAnswerWithFallback(value, locale) ||
      getFreeTextAnswerWithFallback(value, Locale.EN_US) ||
      getFreeTextAnswerWithFallback(value)
    );
  }

  return value;
}

export function getFreeTextAnswerNotOriginalLocale(value: any, locale?: string): any {
  if (isFreeTextAnswer(value)) {
    return getFreeTextAnswerWithFallback(value, locale) || getFreeTextAnswerWithFallback(value, Locale.EN_US);
  }
  return value;
}

export function getLanguageMapFromAnswer(v: any): Record<string, string> | undefined {
  if (typeof v === "string") {
    return {[Locale.EN_US]: v};
  }
  if (!isFreeTextAnswer(v)) {
    return undefined;
  }
  const flat = {};
  for (const key of Object.keys(v)) {
    if (key === FreeTextAnswerKeys.ANSWER_LOCALE) {
      continue;
    }
    const answer = v[key];
    if (answer?.value) {
      flat[key] = answer.value;
    }
  }
  return flat;
}

function getAllLanguagesMap(value: any) {
  if (isFreeTextAnswer(value)) {
    const allKeys = Object.keys(value);
    allKeys.splice(allKeys.indexOf(FreeTextAnswerKeys.ANSWER_LOCALE), 1);
    const map = {};
    allKeys.forEach((locale) => {
      map[locale] = locale;
      if (locale !== shortLocale(locale)) {
        map[shortLocale(locale)] = locale;
      }
    });

    return map;
  }
  return {};
}

export function getAllLocaleAnswsers(value: any) {
  if (isFreeTextAnswer(value)) {
    const answers: string[] = [];
    const allKeys = Object.keys(value);
    allKeys.splice(allKeys.indexOf(FreeTextAnswerKeys.ANSWER_LOCALE), 1);
    allKeys.forEach((key) => {
      answers.push(getFreeTextAnswer(value, key));
    });
    return answers;
  }
  return [];
}

export function allLanguagesUnanswered(value: any) {
  if (!isFreeTextAnswer(value)) {
    return false;
  }

  return all(
    (k) => k === FreeTextAnswerKeys.ANSWER_LOCALE || isEmpty(value[k]) || isEmpty(value[k][FreeTextAnswerKeys.VALUE]),
    Object.keys(value),
  );
}

export function getAnswerAndUserLocaleText(value: any, userLocales: string[]): any {
  if (isFreeTextAnswer(value)) {
    const localesMap = getAllLanguagesMap(value);
    const locales: string[] = uniq(
      reject(isNil, [
        value[FreeTextAnswerKeys.ANSWER_LOCALE],
        ...userLocales.map((ul) => localesMap[ul] || localesMap[shortLocale(ul)]),
      ]),
    );
    locales.unshift(FreeTextAnswerKeys.ANSWER_LOCALE);
    return pick(locales, value);
  }
  return value;
}

export function toTranslationFormat(locale?: string, text?: string): any {
  if (!text) {
    return text;
  }

  locale = locale || "en-US";
  return {
    [FreeTextAnswerKeys.ANSWER_LOCALE]: locale,
    [locale]: {
      [FreeTextAnswerKeys.VALUE]: text,
      [FreeTextAnswerKeys.DATE]: new Date(),
    },
  };
}

export function setUserLocalizedValue(value: any, text: string, locale?: string) {
  if (!isFreeTextAnswer(value)) {
    value = toTranslationFormat(locale, text);
  } else {
    value = cloneDeep(value);
  }

  const effectiveLocale = locale || Locale.EN_US;
  if (text !== value[effectiveLocale]?.[FreeTextAnswerKeys.VALUE]) {
    Object.assign(value, {
      [effectiveLocale]: {
        [FreeTextAnswerKeys.VALUE]: text,
        [FreeTextAnswerKeys.DATE]: new Date(),
      },
    });
  }

  return value;
}

export function addTranslation(value: any, locale: string, text: string): any {
  if (!isFreeTextAnswer(value)) {
    return value;
  }

  Object.assign(value, {
    [locale]: {
      [FreeTextAnswerKeys.VALUE]: text,
      [FreeTextAnswerKeys.DATE]: new Date(),
      [FreeTextAnswerKeys.FROM_LOCALE]: value[FreeTextAnswerKeys.ANSWER_LOCALE],
      [FreeTextAnswerKeys.TRANSLATION_SOURCE]: TranslationSource.MACHINE,
    },
  });

  return value;
}

export function getMultiNestedFreeTextAnswer(value) {
  let answer = getFreeTextAnswer(value);
  while (typeof answer === "object" && Object.keys(answer).indexOf(FreeTextAnswerKeys.ANSWER_LOCALE) > -1) {
    answer = getFreeTextAnswer(answer);
  }
  return answer;
}

export function isFreeTextAnswer(answer: any): boolean {
  return (
    !isNil(answer) && typeof answer === "object" && Object.keys(answer).indexOf(FreeTextAnswerKeys.ANSWER_LOCALE) > -1
  );
}

export function localizeFreeTextAnswers(answer: JSONObject, userLocale: string): void {
  processAnswers(
    () => true,
    (v, k, obj) => {
      if (isFreeTextAnswer(v)) {
        obj[k] = getFreeTextAnswer(v, userLocale);
      }
    },
    answer,
  );
}
