import cloneDeep from "lodash/cloneDeep";
import globalLogger from "../../logging";
import {autoMutations} from "../utils";
import moment from "moment";
import {pathOr, find, findIndex} from "ramda";
import {RoleType, EntityAccessStatus, TaskChangeType} from "pg-isomorphic/enums";
import {RoutingKey} from "pg-isomorphic/queue";
import {ChangeType} from "pg-isomorphic/enums/queue";
import clone from "lodash/clone";
import {TableType} from "pg-isomorphic/enums/task";
import {formatDate} from "@/composables/dates";

const logger = globalLogger.getLogger("homepage");

export const CONN_TYPE_MY = "my";
export const CONN_TYPE_ALL = "all";

const state = {
  messages: [],
  homeTasks: [],
  tasksType: TableType.ME,
  reminders: [],
  connections: [],
  connectionType: CONN_TYPE_MY,
  numUsers: 0,
  numOwners: 0,
};

// Helper class to get data into expected format
export class Helper {
  static translateTask(t, user) {
    const dateFormat = pathOr("MM/DD/YYYY", ["localeSettings", "dateFormat"], user);

    const effUser =
      find((u) => u._id === user._id, t.effectiveAssignedUsers) ||
      (t.effectiveAssignedUsers.length && t.effectiveAssignedUsers[0]) ||
      {};

    return {
      date: moment(t.createdAt).format(dateFormat),
      entityInfo: t.entityInfo,
      authorInfo: effUser,
      ...t,
      title: t.localizedTitle,
      subtitle: t.localizedSubtitle,
    };
  }

  static translateReminder(r, user) {
    const dateFormat = pathOr("MM/DD/YYYY", ["localeSettings", "dateFormat"], user);

    const effUser =
      find((u) => u._id === user._id, r.effectiveAssignedUsers) ||
      (r.effectiveAssignedUsers.length && r.effectiveAssignedUsers[0]) ||
      {};

    return {
      title: r.notes,
      date: moment(r.remindAt).format(dateFormat),
      entityInfo: r.entityInfo,
      authorInfo: effUser,
      ...r,
    };
  }

  static translateConnection(c) {
    return {
      Entity_Name: c.companyName,
      Entity_Dba: c.companyDba,
      Entity_Logo: c.companyLogo,
      Entity_Industry: c.companyIndustry,
      Entity_Products: c.companyProducts,
      entityId: c._id,
      ...c,
    };
  }

  static translateMessage(m) {
    const thread = m.data.messageThreadInfo;
    const message = m.data.messageInfo;
    return {
      data: m.data,
      title: message?.author?.displayName,
      date: formatDate(thread?.createdAt || message?.createdAt),
      entityInfo: message?.authorEntity,
      authorInfo: {
        displayName: message?.author?.displayName,
        imageThumbnailUrl: message?.author?.imageThumbnailUrl || "",
      },
    };
  }

  static countTopicOwners(users) {
    let count = 0;
    users.forEach((user) => {
      if (user.status === EntityAccessStatus.ACCEPTED) {
        // ensure user is accepted
        if (find((r) => r.type === RoleType.TOPIC_OWNER && !r.deleted)(user.roles)) {
          count++;
        }
      }
    });
    return count;
  }
}

// getters
const getters = {};

const actions = {
  async getUserCounts({commit}) {
    const resUsers = await this.httpPost("/api/users/query", {limit: 0});
    commit("numUsers", resUsers.data.total);
    commit("numOwners", Helper.countTopicOwners(resUsers.data.users));
  },

  async joinHomeRooms({dispatch}) {
    const user = await dispatch("getCurrentUser", false, {root: true});

    this._removeTask = (task) => dispatch("removeTask", task);
    this._updateTask = (task) => dispatch("updateTask", task);
    this._addTask = (task) => dispatch("addTask", task);

    this.join(RoutingKey.entityTasks({entityId: user.activeEntityId}));
    this.socketOn(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.COMPLETE}),
      this._removeTask,
    );
    this.socketOn(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.UPDATE}),
      this._updateTask,
    );
    this.socketOn(RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.NEW}), this._addTask);
    this.socketOn(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.DELETE}),
      this._removeTask,
    );
  },

  async leaveHomeRooms({dispatch}) {
    const user = await dispatch("getCurrentUser", false, {root: true});

    this.leave(RoutingKey.entityTasks({entityId: user.activeEntityId}));
    this.socketOff(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.COMPLETE}),
      this._removeTask,
    );
    this.socketOff(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.UPDATE}),
      this._updateTask,
    );
    this.socketOff(RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.NEW}), this._addTask);
    this.socketOff(
      RoutingKey.entityTasks({entityId: user.activeEntityId, evt: TaskChangeType.DELETE}),
      this._removeTask,
    );

    this.leave(RoutingKey.entityConnections({entityId: user.activeEntityId}));
    this.socketOff(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.INSERT}),
      this._addConnection,
    );
    this.socketOff(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.DELETE}),
      this._removeConnection,
    );
    this.socketOff(
      RoutingKey.entityConnections({entityId: user.activeEntityId, evt: ChangeType.UPDATE}),
      this._updateConnection,
    );
  },

  removeTask({state, commit}, task) {
    logger.debug(() => `task removed ${task.localizedTitle}`);
    const removeTaskHandler = (namespace) => {
      const ix = findIndex((t) => t._id === task._id, state[namespace]);
      if (ix > -1) {
        const updated = clone(state[namespace]);
        updated.splice(ix, 1);
        commit(namespace, updated);
      }
    };
    removeTaskHandler("homeTasks", "myTotalCount");
  },

  addTask({state, rootState, commit}, task) {
    logger.debug(() => `task added ${task.localizedTitle}`);
    if (task.dashboardTableType === state.tasksType) {
      const updated = clone(state.homeTasks);
      updated.unshift(Helper.translateTask(task, rootState.user));
      commit("homeTasks", updated);
    }
  },

  updateTask({state, rootState, commit}, task) {
    logger.debug(() => `task updated ${task.localizedTitle}`);
    const updateTaskHandler = (namespace) => {
      const ix = findIndex((t) => t._id === task._id, state[namespace]);
      if (ix > -1) {
        const updated = clone(state[namespace]);
        updated[ix] = Helper.translateTask(task, rootState.user);
        commit(namespace, updated);
      }
    };
    updateTaskHandler("homeTasks");
  },
  clear({commit}) {
    Object.keys(state).forEach((k) => {
      commit(k, cloneDeep(state[k]));
    });
  },
};

export default {
  namespaced: true,
  state: cloneDeep(state),
  getters,
  actions,
  mutations: {
    ...autoMutations(state),
  },
};
