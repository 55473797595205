<template>
  <div :class="[colClass, smClass, mdClass, lgClass, xlClass, offsetClass]" v-bind="{...$props, ...$attrs}">
    <slot />
  </div>
</template>

<script lang="ts" setup>
import {computed, toRefs} from "vue";

const props = defineProps({
  cols: {
    type: [String, Number],
  },
  offset: {
    type: [String, Number],
  },
  xl: {
    type: [String, Number],
  },
  lg: {
    type: [String, Number],
  },
  md: {
    type: [String, Number],
  },
  sm: {
    type: [String, Number],
  },
  xs: {
    type: [String, Number],
  },
});

const {cols, xs, sm, md, lg, xl, offset} = toRefs(props);

const colClass = computed(() => {
  return cols.value || xs.value
    ? `col-${xs.value ? xs.value : cols.value}`
    : sm.value || md.value || lg.value || xl.value
    ? ""
    : "col";
});

const smClass = computed(() => {
  return sm.value ? `sm:col-${sm.value}` : "";
});

const mdClass = computed(() => {
  return md.value ? `md:col-${md.value}` : "";
});

const lgClass = computed(() => {
  return lg.value ? `lg:col-${lg.value}` : "";
});

const xlClass = computed(() => {
  return xl.value ? `xl:col-${xl.value}` : "";
});

const offsetClass = computed(() => {
  return offset.value ? `col-offset-${offset.value}` : "";
});

defineOptions({
  compatConfig: {
    MODE: 3,
  },
});
</script>

<style lang="less" scoped>
@import "@{globals}";
</style>
