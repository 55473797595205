import {getStore} from "@/composables/get.store";
import type {UserWithSets} from "@/composables/vuex";
import globalLogger from "@/logging";
// import mixpanel from "mixpanel-browser";
import type {EnvironmentConfig} from "pg-isomorphic/api/env";
import type {BasicUser} from "pg-isomorphic/api/user";

const logger = globalLogger.getLogger("mixpanel");

export function mixpanelInit(env: EnvironmentConfig) {
  if (env.mixpanelToken) {
    // logger.trace(() => `init ${env.mixpanelToken} ${env.environmentName}`);
    // const config = {
    //   debug: env.environmentName !== "production",
    //   disable_cookie: true,
    //   persistence: "localStorage",
    //   api_host: undefined,
    // };
    // if (env.environmentName !== "local") {
    //   config.api_host = "/mix";
    // }
    // mixpanel.init(env.mixpanelToken, config);
    // mixpanel.set_config(config);
  }
}

export function mixpanelTrack(event: string, data?: any) {
  const store = getStore();
  if (store?.state?.envConfig?.mixpanelToken) {
    // mixpanel.track(event, data);
  }
}

export function mixpanelSetUser(user: BasicUser | UserWithSets) {
  const store = getStore();
  if (store?.state?.envConfig?.mixpanelToken && user) {
    // logger.trace(() => `identify user ${user._id}`);
    // mixpanel.identify(user._id);
    // mixpanel.people.set({
    //   $distinct_id: user._id,
    //   $email: user.email,
    //   locale: user.locale,
    //   $name: user.displayName,
    //   active: true,
    // });
    // mixpanel.people.increment("sign_in");
    // mixpanel.people.union("entities", user.activeEntityInfo?.dbaName);
    // mixpanel.set_group("company", user.activeEntityInfo?.publicId);
    // mixpanel.get_group("company", user.activeEntityInfo?.publicId).set({
    //   "Company ID": user.activeEntityId,
    //   "Company Name": user.activeEntityInfo?.dbaName,
    //   "Company PublicID": user.activeEntityInfo?.publicId,
    // });
  }
}

export async function mixpanelUnsetUser(data: string | string[] | object) {
  const store = getStore();
  if (store?.state?.envConfig?.mixpanelToken) {
    // logger.trace(() => `user unset`, data);
    // await mixpanel.people.unset(data);
    // await mixpanel.flush();
  }
}

export function mixpanelClearUser() {
  const store = getStore();
  if (store?.state?.envConfig?.mixpanelToken) {
    // logger.trace(() => `clear user`);
    // mixpanel.identify(null);
  }
}
